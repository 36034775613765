import { Collapse, Modal, Typography, Button, Input } from "antd";
import React, { useCallback, useEffect, useState, useRef } from "react";
import { OverlayProps } from "saas/model/SaasCommonModel";
import SaasProfileLayout from "./SaasProfileLayout";
import OverlayLayout from "./OverlayLayout";
import "./Overlay03.less";
import { valuePatch } from "component/values/ValuesConfig";
import SparkIcon from "saas/icons/SparkIcon";
import AiGeneratePrinciplesTextarea from "./AiGeneratePrinciplesTextarea";
import { getQuestionsByPrinciple } from "saas/utils/http/SaasHttpClient";
const { Paragraph, Text } = Typography;
const { Panel } = Collapse;
const { TextArea } = Input;

interface Overlay03Props extends OverlayProps {
    initialActivePanelKey: string | string[]; // Add this line to include the initialActivePanelKey prop in the interface
}
const Overlay03: React.FC<Overlay03Props> = ({
    isOpen,
    profileInfo,
    initialActivePanelKey,
    onSave,
    onClose
}) => {

    const closeModal = useCallback(() => {
        onClose();
    }, [onClose]);

    const [activePanelKey, setActivePanelKey] = useState<string | string[]>(initialActivePanelKey);
    const [aiTextareaContext, setAiTextareaContext] = useState<{ principle: string, questions: string[], showAiTextarea: boolean }[]>([]);

    const prevPrinciples = useRef('');



    useEffect(() => {
        setActivePanelKey(initialActivePanelKey);
        if (!profileInfo?.engineeringPrinciples) return;

        // Only update if principles actually changed
        const currentPrinciples = profileInfo.engineeringPrinciples.map(p => p.principle).join(',');

        if (currentPrinciples !== prevPrinciples.current) {
            prevPrinciples.current = currentPrinciples;

            getQuestionsByPrinciple(profileInfo.engineeringPrinciples.map(p => p.principle),)
                .then((questionList: any[]) => {
                    setAiTextareaContext(profileInfo.engineeringPrinciples.map(principle => ({
                        principle: principle.principle,
                        questions: questionList.find((item: any) => item?.principle?.toLowerCase() === principle?.principle?.toLowerCase())?.questions || ["No Found Questions"],
                        showAiTextarea: false
                    })));
                });

        }
    }, [profileInfo?.engineeringPrinciples?.map(p => p.principle).join(','), initialActivePanelKey])

    const showAiTextareaStatus = (principle: string) => {
        return aiTextareaContext.find(item => item.principle === principle)?.showAiTextarea || false;
    }

    const getQuestionByPrinciple = (principle: string) => {
        let questions = aiTextareaContext.find(item => item.principle === principle)?.questions || [''];
        const aiAsk = "Lucy: Answer what's relevant. \n" + questions.join('\nYour Answer: \n\n') + "\nYour Answer: \n\n";
        return aiAsk;
    }

    const toggleTextarea = (index: number, principle: string) => {
        setAiTextareaContext(prevState =>
            prevState.map(item =>
                item.principle === principle
                    ? { ...item, showAiTextarea: !item.showAiTextarea }
                    : item
            )
        );
    };

    // If user clears the textarea, hide the AI textarea
    const onAiGenerateValueChanged = (value: string, principle: string) => {
        if (!value || value.trim() === '') {
            setAiTextareaContext(prevState =>
                prevState.map(item =>
                    item.principle === principle
                        ? { ...item, showAiTextarea: !item.showAiTextarea }
                        : item
                )
            );
        }
    }

    const onFinishAiGenerate = (value: string, panelKey: number) => {
        profileInfo && onSave({ ...profileInfo, engineeringPrinciples: profileInfo.engineeringPrinciples.map((p, i) => i === panelKey ? { ...p, description: value } : p) });
        if (panelKey < profileInfo.engineeringPrinciples.length - 1) {
            setActivePanelKey((panelKey + 1).toString());
        }
    };

    const renderModalContent = () => {
        const PanelHeader = ({ header, panelKey }: { header: string, panelKey: string }) => {
            console.log("Rendering PanelHeaderin overlay:", panelKey);
            return (
                <div className="principle-title-container">
                    <p className="principle-title" id={header}>{header}</p>
                </div>);
        }

        return (
            <Collapse bordered={false} activeKey={activePanelKey} expandIconPosition={'end'} onChange={(key) => { setActivePanelKey(key) }}>
                {
                    profileInfo.engineeringPrinciples.map((principle, index) => {
                        const header = valuePatch(principle.principle);
                        return (
                            <Panel key={index.toString()} header={<PanelHeader header={header} panelKey={index.toString()} />}>
                                <div className="principle-ai-editor-container">
                                    <div className="ai-editor-title-container">
                                        <Paragraph className='ai-editor-title'>Write your principles below or try our AI agent, Lucy</Paragraph>
                                        {!showAiTextareaStatus(principle.principle) && <Button className="try-ai-button" shape="round" onClick={() => {
                                            toggleTextarea(index, principle.principle);
                                        }} >
                                            Ask Lucy
                                            <p className="spark-icon"><SparkIcon /></p>
                                        </Button>}
                                    </div>
                                    <div className="ai-editor-textarea-container">
                                        {!showAiTextareaStatus(principle.principle) && (
                                            <TextArea
                                                rows={6}
                                                className='ai-editor-textarea'
                                                value={principle.description}
                                                onChange={(e) => {
                                                    profileInfo && onSave({ ...profileInfo, engineeringPrinciples: profileInfo.engineeringPrinciples.map((p, i) => i === index ? { ...p, description: e.target.value } : p) });
                                                }}
                                            />
                                        )}

                                        {showAiTextareaStatus(principle.principle) && (
                                            <AiGeneratePrinciplesTextarea
                                                principle={principle.principle}
                                                questions={getQuestionByPrinciple(principle.principle)}
                                                onChange={(value) => {
                                                    onAiGenerateValueChanged(value, principle.principle);
                                                }}
                                                onFinishAiGenerate={(value) => onFinishAiGenerate(value, index)}
                                            />
                                        )}
                                    </div>
                                </div>
                            </Panel>
                        );
                    })
                }
            </Collapse>
        );
    }
    return (
        <Modal
            open={isOpen}
            width={'60%'}
            onCancel={closeModal}
            footer={null}
            className="overlay-03-modal"
        >
            <SaasProfileLayout>
                <OverlayLayout layoutWidth="80%">
                    {renderModalContent()}
                </OverlayLayout>
            </SaasProfileLayout>
        </Modal>
    );
};

export default Overlay03;
