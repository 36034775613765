import React, {useState} from "react";
import {Col, Modal, Row} from "antd";

import "./AJModalV2.css"

export default function AJModalV2(props) {

    let {onClose, width, centered, top, closable, footer, wrapClassName, destroyOnClose} = props
    width = width || 1170
    onClose = onClose || (()=>{})
    const style = props.style || {};

    return (
        (<Modal
            wrapClassName={wrapClassName || 'ajmodal-v2'}
            width={width}
            footer={footer || null}
            closable={closable || false}
            open={props.visible}
            style={{top : top, ...style}}
            maskClosable={true}
            // onOk={handleOk}
            onCancel={onClose}
            centered={centered}
            destroyOnClose={destroyOnClose}
        >
            <Row type="flex" justify="center">
                <Col>
                    {props.children}
                </Col>
            </Row>
        </Modal>)
    );
}