import React, { useEffect, useState } from "react";
import CustomerOnboarding from "../pages/customer-onboarding/CustomerOnboarding";
import "./SaasStart.less";
import { Route, Routes, useNavigate } from "react-router-dom";
import SaasCustomerRoute from "./SaasCustomerRoute";
import PageWrapper from "page/PageWrapper";
import NotFound404 from "page/NotFound404";
import { SaasAuthProvider } from "../context/SaasAuthProvider";
import { getSaasUserToken } from "../utils/storage/SaasStorage";
import { checkToken } from "../utils/http/SaasHttpClient";
import SaasPitchingPage from "saas/pages/pitching/SaasPitchingPage";

export default function SaasStart() {
    const loginBackgroundColor = 'linear-gradient(180deg, rgba(168, 94, 187, 0.54) 2.6%, rgba(143, 195, 242, 0.138) 100%)'
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        const checkUserToken = async () => {
            const token = getSaasUserToken() || '';
            console.log('Begin to check token:', token)
            // const checkRes = await checkToken(token)
            // if (checkRes?.token) {
            //     navigate('/saas/customer/profile')
            // }
        };
        checkUserToken();
    }, [])



    return (
        <div className="saas-start-container">
            <SaasAuthProvider>
                <Routes>
                    <Route path="/" element={<SaasPitchingPage />} />
                    <Route path="onboarding" element={<CustomerOnboarding />} />

                    {/* Need Customer Login */}
                    <Route path="customer/*" element={<SaasCustomerRoute />} />


                    <Route path="*" element={
                        <PageWrapper space={138} background={loginBackgroundColor}>
                            <NotFound404 />
                        </PageWrapper>} />

                </Routes>
            </SaasAuthProvider>
        </div>
    );
}