import React, { useContext, useEffect, useRef, useState } from "react";
import { Typography, Row, Col, Button, Image, Carousel } from "antd";
import "./SaasProfile.less";
import { Header } from "antd/lib/layout/layout";
import EditIcon from "saas/icons/EditIcon";
import { SaasUserProfileInfo } from "saas/model/SaasCommonModel";
import { SaasPickValuesContext } from "saas/context/SaasPickValuesProvider";
import Overlay01 from "saas/component/Overlay01";
import Overlay02 from "saas/component/Overlay02";
import Overlay03 from "saas/component/Overlay03";
import EmptyLine from "ui/EmptyLine";
import SaasProfileLayout from "saas/component/SaasProfileLayout";
import { getProfile, saveProfile } from "saas/utils/http/SaasHttpClient";
import { currentUserId } from "saas/utils/storage/SaasStorage";
import SaasJobCard from "saas/component/SaasJobCard";
import SaasProfileTechStack from "saas/component/SaasProfileTechStack";
import Overlay04 from "saas/component/Overlay04";
import EngineeringPrincipleContainer from "./EngineeringPrincipleContainer";

const { Paragraph, Text } = Typography;
export default function SaasCustomerProfile() {
    const OurValuesSection = useRef<HTMLElement>(null);
    const JobOpeningsSection = useRef<HTMLElement>(null);
    const OurTechnologySection = useRef<HTMLElement>(null);
    const WhyWorkingForUsSection = useRef<HTMLElement>(null);

    const scrollToSection = (ref: React.RefObject<HTMLElement | null>) => {
        if (ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const userId = currentUserId() || 0;

    const initialProfileInfo: SaasUserProfileInfo = {
        userId: userId,
        logotype: "https://storage.googleapis.com/genvalues-saas/c4c60944-1b36-4d3f-9617-e85b87f55c80.svg", // Default genvalues logo
        profileHeadline: "Einride designs, develops, and deploys technologies for sustainable freight mobility.",
        profileImage: "https://storage.googleapis.com/genvalues-saas/224ac046-dd97-4f9f-ba4d-8021cd131845.png",
        engineeringCultureTitle: "Our Engineering Culture",
        engineeringCultureContent: "Transform the way your goods move with the world's leading solution for intelligent road freight. Einride designs state-of-the-art digital, electric and autonomous technologies that get global companies on track to reach their business goals. Lorem ipsum dolores solaris dolores ipsum.",
        teamGallery: {
            title: "Build the future with us",
            teamMembers: [
                {
                    id: 1,
                    name: "Robert Falck",
                    image: "https://storage.googleapis.com/genvalues-saas/224ac046-dd97-4f9f-ba4d-8021cd131845.png",
                    title: "Founder and CEO",
                    quote: "A serial entrepreneur, Robert Falck founded and co-founded several businesses and worked his way up from the factory floor of major OEMs before starting Einride in 2016. He holds a B.Sc and M.Sc. in Mechanical Engineering, a B.Sc. in Economics and Finance, and a B.Sc. in Business Administration."
                },
                {
                    id: 2,
                    name: "Linnéa Kornehed Falck",
                    image: "https://storage.googleapis.com/genvalues-saas/01ae4c86-d325-4118-9c2e-120f4fdf1b48_camping-7856198.jpg",
                    title: "Deputy CEO, Founder & Board member",
                    quote: "Linnéa Kornehed Falck has taken Einride from a small startup to a household name in digital, electric, and autonomous technologies. She is a member of the World Economic Forum's tech innovators community, a Forbes' 30 Under 30 All-Star Alumni and has been awarded for her innovative vision and leadership."
                },
                {
                    id: 3,
                    name: "Filip Lilja",
                    image: "https://storage.googleapis.com/genvalues-saas/224ac046-dd97-4f9f-ba4d-8021cd131845.png",
                    title: "Co-Founder",
                    quote: "A passionate business developer and builder of high-performing teams, Filip Lilja is Founder at Einride. He has a wide range of experience in several fields, from IT consultancy to sonar operation in the Swedish Armed Forces."
                },
                {
                    id: 4,
                    name: "Max Ahlberg",
                    image: "https://storage.googleapis.com/genvalues-saas/224ac046-dd97-4f9f-ba4d-8021cd131845.png",
                    title: "Autonomous Drive Engineer",
                    quote: "Lorem ipsum dolor sit amet consectetur. Ridiculus velit lobortis egestas non in amet hac. Nisi urna imperdiet amet lectus rutrum proin luctus. Gravida magna ultrices lectus elementum amet leo dui quisque"
                }
            ]
        },
        engineeringPrincipleTitle: "Our Engineering Principles",
        engineeringPrinciples: [],
        ourTechnology: {
            title: "Our Technology",
            image: "https://storage.googleapis.com/genvalues-saas/606fa83d-eb33-4ad4-a318-6dc502f2eaa7.svg",
            video: "",
            technologies: [
                { name: "C++", image: "/img/tech-stack/c++.png" },
                { name: "Python", image: "/img/tech-stack/python.png" },
                { name: "Java", image: "/img/tech-stack/java.png" },
            ],
            freeText: "",
            description: "Lorem ipsum dolor sit amet consectetur. Ridiculus velit lobortis egestas non in amet hac. Nisi urna imperdiet amet lectus rutrum proin luctus. Gravida magna ultrices lectus elementum amet leo dui quisque vel. Eu pretium diam dolor vestibulum sed eget. Neque vel mauris volutpat morbi sagittis. Facilisis lectus tristique morbi in laoreet quam neque ultricies. Dolor pellentesq"
        },
        benefits: {
            title: "Our Benefits",
            benefit: "",
            image: "https://storage.googleapis.com/genvalues-saas/606fa83d-eb33-4ad4-a318-6dc502f2eaa7.svg",
        }
    };

    const [profileInfo, setProfileInfo] = useState<SaasUserProfileInfo>(initialProfileInfo);
    const [overlay01Visible, setOverlay01Visible] = useState(false);
    const [overlay02Visible, setOverlay02Visible] = useState(false);
    const [overlay03Visible, setOverlay03Visible] = useState(false);
    const [overlay04Visible, setOverlay04Visible] = useState(false);
    const [userPrincipleArray, setUserPrincipleArray] = useState<any[]>([]);
    const [overlay03InitialActivePanelKey, setOverlay03InitialActivePanelKey] = useState<string | string[]>(['0']);
    const [profileInfoFromServer, setProfileInfoFromServer] = useState<boolean>(false); // If it's the first time user visits the page, the server doesn't have the profile info yet.

    const pickValueContext = useContext(SaasPickValuesContext);
    const pickedPrinciples = pickValueContext?.pickedValues;
    const defaultPrincipleImage = "https://storage.googleapis.com/genvalues-saas/4095a6cc-a03c-4d79-9718-d42b94a18ba7.jpg";

    useEffect(() => {
        getProfile(userId).then(response => {
            if (response) {
                setProfileInfo(response);
                setProfileInfoFromServer(true);
            }
        });
    }, [userId]);

    useEffect(() => {
        if (pickValueContext && pickedPrinciples) {
            const newUserPrincipleArray = Object.values(pickedPrinciples()).flat();
            if (!newUserPrincipleArray || newUserPrincipleArray.length === 0) {
                return;
            }

            setUserPrincipleArray(newUserPrincipleArray);
            const engineeringPrinciples = newUserPrincipleArray.map(principle => ({
                principle: principle,
                description: "",
                image: defaultPrincipleImage,
                jobOpening: [] as string[]
            }));

            if (profileInfo?.engineeringPrinciples?.length > 0) {
                engineeringPrinciples.forEach(item => {
                    const principleFromServer = profileInfo.engineeringPrinciples?.find(p => p.principle === item.principle);
                    item.description = principleFromServer?.description || "";
                    item.image = principleFromServer?.image || defaultPrincipleImage;
                    item.jobOpening = principleFromServer?.jobOpening || [];
                });
            }

            setProfileInfo(prevProfile => ({
                ...prevProfile,
                engineeringPrinciples: engineeringPrinciples
            }));
        }
    }, [pickValueContext, pickedPrinciples, profileInfoFromServer]);

    const handleEditModalSave = async (updatedProfile: SaasUserProfileInfo) => {
        if (updatedProfile) {
            setProfileInfo(updatedProfile);
        }
    };

    const onModalClose = () => {
        setOverlay01Visible(false);
        setOverlay02Visible(false);
        setOverlay03Visible(false);
        setOverlay04Visible(false);
        if (profileInfo) {
            const req = {
                userId: userId,
                profile: profileInfo
            };
            saveProfile(req);
        }
    };

    const ProfileHeader: React.FC = () => {
        return (
            <div className="profile-header-container">
                <SaasProfileLayout>
                    <Header className="profile-header">
                        <div className="logo">
                            <img src={profileInfo.logotype} alt="Logo" style={{ height: '42px', width: 'auto' }} />
                        </div>
                        <div className="edit-icon" onClick={() => setOverlay01Visible(true)}><EditIcon /></div>
                    </Header>
                </SaasProfileLayout>
                <div className="prifile-header-bottom-border"></div>
            </div>
        )
    }

    const ProfileHeadline: React.FC = () => {
        return (
            <SaasProfileLayout>
                <div className="profile-headline">
                    {profileInfo.profileHeadline}
                    <span className="edit-icon" onClick={() => setOverlay01Visible(true)}><EditIcon /></span>
                </div>
            </SaasProfileLayout>
        )
    }

    const ProfileHeroImage: React.FC = () => {
        return (
            <SaasProfileLayout>
                <div className="profile-hero-container">
                    <img src={profileInfo.profileImage} alt="Hero" className="hero-image" />
                    <div className="edit-icon-wrapper" onClick={() => setOverlay01Visible(true)}>
                        <EditIcon />
                    </div>
                </div>
            </SaasProfileLayout>
        )
    }

    const SectionButtonList: React.FC = () => {
        return (
            <>
                <button className="section-button button-margin" onClick={() => scrollToSection(OurValuesSection)}>Our Values</button>
                <button className="section-button button-margin" onClick={() => scrollToSection(JobOpeningsSection)}>Job Openings</button>
                <button className="section-button button-margin" onClick={() => scrollToSection(OurTechnologySection)}>Our Technology</button>
                <button className="section-button" onClick={() => scrollToSection(WhyWorkingForUsSection)}>Why Working For Us?</button>
            </>
        )
    }

    const ProfileSectionNavigator: React.FC = () => {
        return (
            <SaasProfileLayout>
                <div className="section-button-list">
                    <SectionButtonList />
                </div>
            </SaasProfileLayout>
        )
    }

    const OurEngineeringCultureHeadSection: React.FC = () => {
        return (
            <SaasProfileLayout>
                <div className="our-engineering-culture-container">
                    <div className="section-headline">
                        {profileInfo.engineeringCultureTitle}
                        <span className="edit-icon" onClick={() => setOverlay01Visible(true)}><EditIcon /></span>
                    </div>
                    <Paragraph className="section-text">
                        {profileInfo.engineeringCultureContent}
                    </Paragraph>
                </div>
                <EmptyLine height={7} />
                <div className="our-engineering-culture-principles-container">
                    {userPrincipleArray.map((principle, index) => (
                        <button key={index} className="our-engineering-culture-principle">{principle}</button>
                    ))}
                </div>
            </SaasProfileLayout>
        )
    }

    const TeamGallery: React.FC = () => {
        return (
            <div className="team-gallery-container">
                <SaasProfileLayout>
                    <EmptyLine height={55} />
                    <div className="team-gallery-title">
                        {profileInfo.teamGallery.title}
                        <span className="edit-icon" onClick={() => setOverlay02Visible(true)}><EditIcon /></span>
                    </div>
                    <EmptyLine height={37} />
                    <Row className="team-gallery-content" gutter={[32, 32]} >
                        {profileInfo.teamGallery.teamMembers.map((member) => (
                            <Col key={member.id} span={6}>
                                <div className="team-member-card" key={member.id}>
                                    <img src={member.image} alt={member.name} style={{ width: '100%', height: 'auto' }} />
                                    <Paragraph className="team-member-quote">{member.quote}</Paragraph>
                                    <Text className="team-member-name">{member.name}</Text>
                                    <Text className="team-member-title">{member.title}</Text>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </SaasProfileLayout>
                <EmptyLine height={125} />
            </div>
        )
    }

    const JobOpenings: React.FC = () => {
        interface Job {
            id: number;
            title: string;
        }

        const jobs: Job[] = Array.from({ length: 17 }, (_, i) => ({
            id: i + 1,
            title: `Job Position ${i + 1} Title - Test`,
        }));

        const [cardsPerPage, setCardsPerPage] = useState(5); // Default to 5 cards per page

        useEffect(() => {
            const calculateCardsPerPage = () => {
                const cardWidth = 303;
                const gap = 32;
                const containerWidth = window.innerWidth - 64;
                const maxCards = Math.floor((containerWidth + gap) / (cardWidth + gap));
                setCardsPerPage(Math.max(1, maxCards));
            };

            calculateCardsPerPage();   // calculate cards per page on initial render
            window.addEventListener("resize", calculateCardsPerPage);

            return () => {
                window.removeEventListener("resize", calculateCardsPerPage);
            };
        }, []);

        const pages: Job[][] = [];
        for (let i = 0; i < jobs.length; i += cardsPerPage) {
            pages.push(jobs.slice(i, i + cardsPerPage));
        }

        const onChange = (currentSlide: number) => {
            console.log(currentSlide);
        };

        return (
            <>
                <SaasProfileLayout>
                    <div className="saas-job-openings-container">
                        <div className="saas-job-openings-title-container">
                            Job Openings
                            <span className="edit-icon" onClick={() => console.log("Edit Job Openings")}>
                                <EditIcon />
                            </span>
                        </div>
                    </div>
                </SaasProfileLayout>
                <div className="saas-job-carousel-container">
                    <Carousel afterChange={onChange} draggable={true} dots={{ className: "custom-dots" }}>
                        {pages.map((page, index) => (
                            <div key={index} className="carousel-page" style={{ display: "flex", gap: "32px" }}>
                                {page.map((job) => (
                                    <SaasJobCard
                                        key={job.id}
                                        title={job.title}
                                        description={""}
                                        location={"Remote"}
                                        type={""}
                                        href={""}
                                        company={""}
                                    />
                                ))}
                            </div>
                        ))}
                    </Carousel>
                </div>
            </>
        );
    };

    const OurTechnology: React.FC = () => {
        return (
            <SaasProfileLayout>
                <div className="our-technology-container">
                    <div className="our-technology-cards">
                        <div className="our-technology-title">
                            Our Technology
                            <span className="edit-icon" onClick={() => setOverlay04Visible(true)}>
                                <EditIcon />
                            </span>
                        </div>
                        <EmptyLine height={20} />
                        <div className="our-technology-tech-stack">
                            <SaasProfileTechStack ourTechnology={profileInfo.ourTechnology} />
                        </div>
                        <div className="our-technology-description">
                            {profileInfo.ourTechnology.description}
                        </div>
                    </div>

                    <div className="our-technology-image-container">
                        {profileInfo.ourTechnology.image && <Image className="our-technology-image" src={profileInfo.ourTechnology.image} preview={false} />}
                    </div>
                </div>
            </SaasProfileLayout>
        )
    };

    const Benefits: React.FC = () => {
        return (
            <SaasProfileLayout>
                <div className="benefits-container">
                    <div className="benefits-text-container">
                        <Paragraph className="benefits-title">
                            Benefits
                            <span className="edit-icon" onClick={() => setOverlay04Visible(true)}>
                                <EditIcon />
                            </span>
                        </Paragraph>
                        <div>
                            <div className="benefits-text" style={{ "whiteSpace": "pre-wrap" }}>
                                {profileInfo.benefits.benefit}
                            </div>
                        </div>
                    </div>
                    <div className="benefits-image-container">
                        {profileInfo.benefits.image && <Image className="benefits-image" src={profileInfo.benefits.image} preview={false} />}
                    </div>

                </div>
            </SaasProfileLayout>
        )
    }

    const ProfilePageFooter: React.FC = () => {
        return (
            <div className="profile-page-footer">
                <SaasProfileLayout>
                    <div className="profile-page-footer-container">
                        <div className="profile-page-footer-content">
                            <div className="logo-container">
                                <img src={profileInfo.logotype} alt="Logo" style={{ height: '42px', width: 'auto', filter: 'invert(100%) grayscale(100%) saturate(0%)' }} />
                            </div>
                            <div className="nav-container">
                                <SectionButtonList />
                            </div>
                        </div>
                        <div className="copyright-container">
                            Powered by&nbsp;<a href="https://genvalues.com">Genvalues</a>
                        </div>
                    </div>
                </SaasProfileLayout>
            </div>
        )
    }


    return (
        <div className="saas-user-profile">
            <ProfileHeader />
            <EmptyLine height={35} />
            <ProfileHeadline />
            <EmptyLine height={20} />
            <ProfileHeroImage />
            <EmptyLine height={40} />

            <ProfileSectionNavigator />
            <EmptyLine height={135} />

            <OurEngineeringCultureHeadSection />
            <EmptyLine height={140} />

            <Overlay01
                isOpen={overlay01Visible}
                profileInfo={profileInfo}
                onClose={onModalClose}
                onSave={handleEditModalSave}
            />


            <TeamGallery />
            <EmptyLine height={150} />
            <Overlay02
                isOpen={overlay02Visible}
                profileInfo={profileInfo}
                onClose={onModalClose}
                onSave={handleEditModalSave}
            />

            <section ref={OurValuesSection}>
                <EngineeringPrincipleContainer profileInfo={profileInfo} onEditOverlay={(panelKey) => {
                    setOverlay03InitialActivePanelKey(panelKey);
                    setOverlay03Visible(true);
                }} />
            </section>
            <EmptyLine height={155} />

            <Overlay03
                isOpen={overlay03Visible}
                profileInfo={profileInfo}
                onClose={onModalClose}
                onSave={handleEditModalSave}
                initialActivePanelKey={overlay03InitialActivePanelKey} // Pass the initial active panel key here
            />


            <section ref={JobOpeningsSection}>
                <JobOpenings />
            </section>
            <EmptyLine height={160} />

            <section ref={OurTechnologySection}>
                <OurTechnology />
            </section>
            <EmptyLine height={160} />

            <Benefits />
            <section ref={WhyWorkingForUsSection}>
                <EmptyLine height={160} />
            </section>

            <Overlay04
                isOpen={overlay04Visible}
                profileInfo={profileInfo}
                onClose={onModalClose}
                onSave={handleEditModalSave}
            />

            <ProfilePageFooter />
        </div >
    );
}
