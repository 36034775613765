import { DownloadOutlined } from '@ant-design/icons';
import { Button, Radio, Spin } from 'antd';
import type { SizeType } from 'antd/es/config-provider/SizeContext';
import React, { useRef, useState } from 'react';
import { uploadImageFile } from 'saas/utils/http/SaasHttpClient';


interface SaasImageUploadButtonProps {
  image?: string | null;
  onImageChange?: (url: string) => void;
}

const SaasImageUploadButton: React.FC<SaasImageUploadButtonProps> = ({ image, onImageChange }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(image || null);
  const [loading, setLoading] = useState<boolean>(false);

  const handleImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const formData = new FormData();
      formData.append('image', file);
      setLoading(true);
      try {
        const uploadRes = await uploadImageFile(formData);

        console.log('success upload image, imageurl:', uploadRes.url);

        setImageUrl(uploadRes.url);
        onImageChange?.(uploadRes.url);
      } catch (error) {
        console.error('Error uploading image:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <div className='saas-image-upload-button' style={{ display: 'flex', alignItems: 'center', height: '54px' }}>
        {!loading && (
          <Button className='upload-button' type="primary" shape="round" size={'large'} onClick={() => fileInputRef.current?.click()}>
            Upload File
          </Button>
        )}
        {loading && <Spin size="large" style={{ marginLeft: '90px' }} />}
      </div>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        accept="image/*"
        onChange={handleImageChange}
      />
    </>
  )
};

export default SaasImageUploadButton;