import { Checkbox, Form, Input } from "antd";
import React from "react";
import "./CustomerRegisterForm.less";
import { OnFinishProps } from "saas/model/SaasCommonModel";

const CustomerRegisterForm: React.FC<OnFinishProps> = ({ onFinish }) => {
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    };

    const form = Form.useFormInstance();


    return (
        <Form form={form}
            className="saas-user-form"
            {...formItemLayout}
            onFinish={onFinish}
            scrollToFirstError
        >
            {/* Form Items */}
            <Form.Item
                name="company"
                rules={[{ required: true, message: 'Please input your company name!', whitespace: true }]}
            >
                <Input placeholder="Company Name" />
            </Form.Item>

            <Form.Item
                name="email"
                rules={[
                    {
                        type: 'email',
                        message: 'The input is not valid E-mail!',
                    },
                    {
                        required: true,
                        message: 'Please input your E-mail!',
                    },
                ]}
            >
                <Input placeholder="E-mail" />
            </Form.Item>

            <Form.Item
                name="phone"
                rules={[{ required: true, message: 'Please input your phone number!' }]}
            >
                <Input placeholder="Phone" />
            </Form.Item>

            <Form.Item
                name="password"
                rules={[
                    {
                        required: true,
                        message: 'Please input your password!',
                    },
                ]}
                hasFeedback
            >
                <Input.Password className="saas-user-password" placeholder="Password" />
            </Form.Item>

            <Form.Item
                name="confirm"
                dependencies={['password']}
                hasFeedback
                rules={[
                    {
                        required: true,
                        message: 'Please confirm your password!',
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('The two passwords that you entered do not match!'));
                        },
                    }),
                ]}
            >
                <Input.Password className="saas-user-password" placeholder="Confirm Password" />
            </Form.Item>

            <Form.Item
                name="agreement"
                valuePropName="checked"
                rules={[
                    {
                        validator: (_, value) =>
                            value ? Promise.resolve() : Promise.reject(new Error('Should accept agreement')),
                    },
                ]}
            >
                <Checkbox className="agreement">
                    I agree to <a href="">terms & Conditions</a>
                </Checkbox>
            </Form.Item>
        </Form>
    );
};

export default CustomerRegisterForm;