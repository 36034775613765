import React, { useContext, useEffect, useState } from "react";

import "./LoginCommon.less"
import "./AddJobOpenings.less"
import "../../ui/AJCommonCSS.less"

import { Icon as LegacyIcon } from '@ant-design/compatible';
import { CheckCircleOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';

import { Row, Col, message, Empty, Table } from "antd";
import AJInput from "../../ui/AJInput";
import EmptyLine from "../../ui/EmptyLine";
import AJButton from "../../ui/AJButton";
import CompanyConfigureContainer, { FooterButtons, FooterButtonsWithOK } from "./CompanyConfigureContainer";
import { useNavigate } from "react-router-dom";
import AJButton3 from "../../ui/AJButton3";
import VCenterContainer, { HCenterContainer } from "../../ui/VCenterContainer";
import AJCard from "../../ui/AJCard";
import AJInput2, { AJAutoCheckInput2, AJUrlInput } from "../../ui/AJInput2";
import EmptySpan from "../../ui/EmptySpan";
import JobOpeningsContext from "../../context/JobOpeningsContext";
import fs from "less/lib/less-node/fs";
import { companyRegister, jobExtract } from "../../http/AJHttp";
import AJPopover from "../../ui/AJPopover";
import SocialLinksContext from "../../context/SocialLinksContext";
import CompanyRegisterContext from "../../context/CompanyRegisterContent";
import CompanyStructureContext from "../../context/CompanyStructureContext";
import PickValuesContext from "../../context/PickValuesContext";
import DescribeValuesContext from "../../context/DescribeValuesContext";
import CreateAccountContext from "../../context/CreateAccountContext";
import AppContext from "../../context/AppContext";
import {
    currentCompanyName,
    currentUserId,
    currentUserName,
    getJobOpenings,
    saveCompanyRegisterData,
    saveJobOpenings
} from "../../storage/AJStorage";
import { requirePickedValueCount } from "../../context/PickValuesProvider";
import CompanyRegisterTemplate from "./CompanyRegisterTemplate";
import AJModalV2 from "../../ui/AJModalV2";
import uuid from 'react-uuid'
import SvgOutlineArrowRight from "../../icons/OutlineArrowRight";

export default function AddJobOpenings(props) {

    return (
        <div className={'add-job-openings'}>
            <div className={'add-job-openings-container'}>
                <Row>
                    <Col span={14}>
                        <div className={'add-job-openings-title '}>
                            Add job openings<br /> to your profile
                        </div>
                        <br />
                        <br />

                        <div className={'add-job-openings-sub-title'}>
                            The more job links, the nicer profile
                        </div>
                        <br />
                        <br />
                        <AJInput label={'Title'}
                            width={303}
                        // separate={true}
                        // backgroundColor={'transparent'}
                        />
                        <EmptyLine height={36} />
                        <AJInput label={'Location'}
                            width={303}
                        // separate={true}
                        // backgroundColor={'transparent'}
                        />
                        <EmptyLine height={36} />

                        <AJInput label={'Url to opening(s)'}
                            width={303}
                        // separate={true}
                        // backgroundColor={'transparent'}
                        />
                    </Col>
                    <Col span={10}>
                        <div className={'add-job-openings-describe'}>
                            Companies that link many job openings will get more activity on their profile. More value-driven talents will apply to their job openings.
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                        </div>
                        <EmptyLine height={126} />
                        <div className={'add-job-openings-add-more'}>
                            <div className={'add-job-openings-sub-title'}>
                                Add more openings
                            </div>
                            <EmptyLine height={9} />

                            <div className={'add-job-openings-plus-button'}>
                                <AJButton title={'+'}
                                    containerClass={'add-job-openings-plus-button-container'}
                                    textClass={'add-job-openings-plus-button-text'} />

                            </div>
                        </div>
                    </Col>
                </Row>
                <EmptyLine height={100} />
                {FooterButtons()}
            </div>
        </div>
    )

}

export function SyncJobOpenings(props) {
    const navigate = useNavigate()

    const { setJobOpeningsSource, setCareerPageUrl } = useContext(JobOpeningsContext)

    const [disableNext, setDisableNext] = useState(true)

    const header = `Sync your job openings`

    const gotoCareer = (source) => {
        setJobOpeningsSource(source)
        navigate("/register/input-career-url")
    }

    const body = (
        <div>

            <Row type={'flex'} align={'bottom'}>
                <Col span={12}>
                    <br />
                    <div className={'login-common-description'}>
                        If your job openings hosted on following port, you can link and sync job openings to your profile automatically by carreer url.
                    </div>

                    <EmptyLine height={42} />
                    <AJButton3
                        width={260}
                        height={60} type={'secondary'}
                        title={'Teamtailor'}
                        icon={'right'}
                        shadow={'0px 10px 20px rgba(0, 0, 0, 0.15)'}

                        onClick={() => {
                            gotoCareer('teamtailor')
                        }} />
                    <EmptyLine height={25} />

                    <AJButton3
                        width={260}
                        height={60}
                        type={'secondary'}
                        title={'Jobylon'}
                        icon={'right'}
                        shadow={'0px 10px 20px rgba(0, 0, 0, 0.15)'}

                        onClick={() => {
                            gotoCareer('jobylon')
                        }}
                    />
                    <EmptyLine height={25} />

                    <AJButton3
                        width={260}
                        height={60}
                        type={'secondary'}
                        title={'The Hub'}
                        icon={'right'}
                        shadow={'0px 10px 20px rgba(0, 0, 0, 0.15)'}
                        onClick={() => {
                            gotoCareer('thehub')
                        }}
                    />

                </Col>
                {/*<Col span={2}/>*/}
                <Col span={12}>
                    {/*<EmptyLine height={120}/>*/}
                    <div className={'add-job-openings-manually-tips'}>
                        If you have your job openings on your own website, link below.
                        <br /><br />
                    </div>
                    <AJButton3
                        width={260}
                        height={60}
                        type={'secondary'}
                        title={'Manually'}
                        icon={'right'}
                        shadow={'0px 10px 20px rgba(0, 0, 0, 0.15)'}
                        onClick={() => {
                            setJobOpeningsSource(null)
                            setCareerPageUrl(null)
                            navigate("/register/add-job-openings-manually")
                        }}
                    />

                    {/*<EmptyLine height={8}/>*/}

                    {/*<div className={'add-job-openings-manually-desc'}>*/}
                    {/*    Tips:<br/><br/>*/}
                    {/*    If you have your job openings hosted on other platform, please click <i><b>Next</b></i> to add them manually.*/}
                    {/*</div>*/}
                    <EmptyLine height={85} />
                    <div style={{ width: 303 }}>
                        {/*<AJUrlInput*/}
                        {/*    label={'Url to opening(s)'}*/}
                        {/*    width={303}*/}
                        {/*    onChange={(url) => {*/}
                        {/*        console.log(manuallySource)*/}

                        {/*        if (url) {*/}
                        {/*            setManuallySource(url)*/}
                        {/*            setDisableNext(false)*/}
                        {/*        }*/}
                        {/*        else {*/}
                        {/*            setManuallySource(null)*/}
                        {/*            setDisableNext(true)*/}
                        {/*        }*/}
                        {/*    }}*/}
                        {/*/>*/}


                    </div>

                </Col>
            </Row>
        </div>
    )

    const footer = (
        <FooterButtons disabledNext={false && disableNext}
            // hiddenNext={true}
            onNext={() => {
                setJobOpeningsSource(null)
                setCareerPageUrl(null)
                navigate("/register/add-social-links")
            }} />
    )

    return (
        <CompanyRegisterTemplate tips={'People work for money, but they perform for values and\n' +
            'purpose'}>
            <CompanyConfigureContainer header={header} body={body} footer={footer} hideClose={true} />
        </CompanyRegisterTemplate>
    )
}

export function InputCareerUrl(props) {
    const sourceToUrl = {
        teamtailor: "https://detectify.teamtailor.com/jobs",
        jobylon: "https://northvolt.com/career",
        thehub: "https://thehub.io/startups/edai-technologies"
    }
    const header = "Link you career page from\n Teamtailor / Jobylon / The Hub"
    const navigate = useNavigate();
    const { jobOpeningsSource, careerPageUrl, setJobOpenings } = useContext(JobOpeningsContext)

    const { setCareerPageUrl, setJobOpeningsSource } = useContext(JobOpeningsContext)
    const [url, setUrl] = useState(careerPageUrl())

    const [disableNext, setDisableNext] = useState(!(url && url.length > 0))
    // console.log(url)

    const body = (
        <Row>
            <Col span={18}>
                <br />
                <Row type={'flex'} justify={'space-between'}>
                    <Col span={10}>
                        <div className={'login-common-description'}>
                            Enter your career page url* below
                        </div>
                    </Col>
                    <Col span={8}>
                        <div className={'input-career-url-help-info'}>
                            *Help info
                        </div>
                    </Col>
                </Row>
                <EmptyLine height={85} />
                <div className={'login-common-sub-title'} style={{ color: '#181414' }}>
                    Url
                </div>
                <EmptyLine height={37} />

                <div style={{ width: 454 }}>
                    <AJUrlInput
                        // label={'https://career.northvolt.com'}
                        placeholder={sourceToUrl[jobOpeningsSource()]}
                        width={303}
                        defaultValue={url}
                        onChange={(value) => {
                            // console.log(value)
                            if (value) {
                                setDisableNext(false)
                                setUrl(value)
                            }
                            else {
                                setDisableNext(true)
                                setUrl(null)
                            }
                        }}
                    />
                </div>


            </Col>
        </Row>
    )

    const syncJobOpenings = async () => {
        //http://server1:8000/jobextract?url=https://northvolt.com/career&source=jobylon
        // TODO for test
        let source = jobOpeningsSource() || 'jobylon'
        //let url = careerPageUrl || 'https://northvolt.com/career'
        // store url
        setCareerPageUrl(url)


        if (!url || url.length === 0) {
            navigate("/register/confirm-openings")
            return
        }

        if (!source || source.length === 0) {
            navigate("/register/confirm-openings")
            return
        }

        setLoadingNext(true)
        const response = await jobExtract(source, url)
        setLoadingNext(false)
        if (response) {
            // console.log(response.data)
            setJobOpenings(response.data)
            navigate("/register/confirm-openings")
        }
        else {
            // just for test
            //navigate("/register/confirm-openings")
            message.error('Sync job error!')
        }

    }

    const [loadingNext, setLoadingNext] = useState(false)

    const footer = <FooterButtons
        disabledNext={false && disableNext}
        loadingNext={loadingNext}
        onNext={syncJobOpenings}
        onOK={() => {
            navigate("/register/confirm-openings")
        }
        }
    />

    return (
        <HCenterContainer>
            <CompanyConfigureContainer header={header} body={body} footer={footer} hideClose={true} />
        </HCenterContainer>
    )
}

function CheckLine(props) {
    const data = props.data || {}

    const { title, location } = data

    const jobTextStyle = {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '20px',
        lineHeight: '24px',
        color: '#181414',
        paddingRight: 8,
        overflow: `hidden`,
        whiteSpace: `nowrap`, /* Don't forget this one */
        textOverflow: `ellipsis`

    }
    const locationTextStyle = {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '19px',
        color: '#181414',
        paddingLeft: 8,
        overflow: `hidden`,
        whiteSpace: `nowrap`, /* Don't forget this one */
        textOverflow: `ellipsis`
    }

    return (
        <div>
            <Row style={{ height: props.height || 63 }}>
                <Col span={16}>

                    <div style={{ textAlign: 'left', paddingTop: props.paddingTop || "34px", ...jobTextStyle }}>
                        <AJPopover content={title} containerClass={'ajpopover-info-container'}>
                            {title}
                        </AJPopover>
                    </div>
                </Col>
                <Col span={8}>
                    <div style={{ textAlign: 'right', paddingTop: props.paddingTop || "34px", paddingRight: "8px", ...locationTextStyle }}>
                        {/*<AJPopover content={location} containerClass={'ajpopover-info-container'}>*/}
                        {location}
                        {/*</AJPopover>*/}
                    </div>
                </Col>
            </Row>
            <div style={{ borderTop: '1px solid #BDBDBD' }}>

            </div>
        </div>
    )
}

const jobContainerStyle = {
    height: '260px',
    width: '100%',
    // background: "yellow",
    overflowY: 'auto'
}

export function ConfirmOpenings() {
    const navigate = useNavigate()

    const { jobOpenings } = useContext(JobOpeningsContext)

    const jobs = jobOpenings
    const header = 'Confirm openings'
    const body = jobs && jobs.length > 0 ? (
        <div>
            <EmptyLine height={58} />
            <div style={jobContainerStyle}>
                <div style={{ width: '98%' }}>
                    {
                        jobs.map((data, index) => <CheckLine data={data} id={index} key={index} />)
                    }
                </div>

            </div>
        </div>
    )
        : (
            <div style={{ paddingTop: 120 }}>
                <Empty />

            </div>
        )

    const footer = (<FooterButtons
        disableNext={true}
        onNext={() => {
            navigate("/register/add-social-links")
        }}
        onOK={() => {
            navigate("/register/add-social-links")
        }} />)


    return (
        <HCenterContainer>
            <CompanyConfigureContainer header={header} body={body} footer={footer} hideClose={true} />
        </HCenterContainer>
    )
}

export function AddSocialLinks(props) {
    const navigate = useNavigate()

    const backClick = props.backClick || (() => {
        navigate(-1)
    })

    const { updateSocialLink, socialLinksIsEmpty } = useContext(SocialLinksContext)

    const { companyStructure, technologies } = useContext(CompanyStructureContext)
    const { orderedValues, pickedValues } = useContext(PickValuesContext)
    const { describeValues } = useContext(DescribeValuesContext)
    let { jobOpenings } = useContext(JobOpeningsContext)
    const { socialLinks } = useContext(SocialLinksContext)
    const { accountInfo } = useContext(CreateAccountContext)
    const { currentAccount } = useContext(AppContext)
    const { jobOpeningsSource, careerPageUrl } = useContext(JobOpeningsContext)
    const [linkCount, setLinkCount] = useState(3)

    if (!jobOpeningsSource()) {
        jobOpenings = getJobOpenings() // load from localstorage
    }

    const getCompanyRegister = (save) => {
        return {
            name: accountInfo.username || currentCompanyName() || currentUserName() || '',
            // username: accountInfo.username,
            email: accountInfo.email || currentUserName() || '',
            orgNr: accountInfo['org.Nr'] || 'org.Nr',
            companyStructure: {
                structure: companyStructure(),
                technologies: technologies()
            },
            pickedValues: pickedValues(),
            orderedValues: orderedValues(),
            describeValues: describeValues(),
            jobOpenings: jobOpenings
                .filter(jobOpening => (jobOpening.title && jobOpening.title.length > 0)
                    && (jobOpening.location && jobOpening.location.length > 0)
                    && (jobOpening.link && jobOpening.link.length > 0))
                .map((jobOpening, index) => {
                    return {
                        ...jobOpening,
                        order: index,
                        source: jobOpeningsSource(),
                        sourceUrl: careerPageUrl()
                    }

                }),
            socialLinks: socialLinks,
            logo: 1, // TODO for test
            owner: parseInt(currentUserId()),
            save,
        }
    }

    // Linkedin
    // Facebook
    // Twitter
    // Instagram
    // Medium
    // Youtube
    let socials = [
        { name: 'linkedin', placeholder: 'Linkedin Url' },
        { name: 'facebook', placeholder: 'Facebook Url' },
        { name: 'instagram', placeholder: 'Instagram Url' },
        { name: 'twitter', placeholder: 'Twitter Url' },
        { name: 'medium', placeholder: 'Medium Url' },
        { name: 'youtube', placeholder: 'Youtube Url' },
    ]


    const header = 'Add your Linkedin, Facebook etc'

    const [disabledOK, setDisableOK] = useState(false)
    const [loadingOK, setLoadingOK] = useState(false)
    const [loadingLater, setLoadingLater] = useState(false)
    const body = (
        <Row>
            <br />
            <Col span={15}>
                <div className={'login-common-description'}>
                    Here you can add your Social and professional Media<br /> Channels that ar part of your company
                </div>
                <br />
                <br />
                <div>
                    <div style={{ height: 200, overflow: 'auto', width: 508 }} id={'add-social-link'}>
                        <div style={{ width: 480 }}>
                            {
                                socials.map((social, index) => {
                                    // console.log(index)
                                    // console.log(linkCount)

                                    if (index + 1 > linkCount) {
                                        return null
                                    }
                                    return (
                                        <>
                                            <AJUrlInput key={index} label={social.placeholder} onChange={(value) => {
                                                updateSocialLink(social.name, value)

                                                // setDisableOK(socialLinksIsEmpty())
                                            }} />
                                            {index === linkCount - 1 ? null : <EmptyLine height={32} />}
                                        </>
                                    )
                                })
                            }
                            <EmptyLine height={16} />
                            <div style={{
                                textAlign: 'right',
                                fontStyle: `normal`,
                                fontWeight: 600,
                                fontSize: `16px`,
                                lineHeight: `19px`,
                                color: `#BDBDBD`,
                                cursor: 'pointer'
                            }} onClick={() => {
                                if (linkCount >= socials.length) {
                                    message.warn("No more social!")
                                }
                                else {
                                    setLinkCount(linkCount + 1)
                                    setTimeout(() => {
                                        const el = document.getElementById('add-social-link')
                                        el.scrollTop = el.scrollHeight;
                                    }, 0)
                                }
                            }}>
                                Add more Social channels
                                <EmptySpan width={14} />
                                <span style={{ color: '#000' }} >
                                    <LegacyIcon type={'plus'} />
                                </span>
                            </div>
                        </div>

                    </div>




                </div>
            </Col>
        </Row>
    )

    const registerCompanyProfile = async (save) => {
        if (save) {
            setLoadingLater(true)
        }
        else {
            setLoadingOK(true)

        }
        // console.log(getCompanyRegister())
        const response = await companyRegister(getCompanyRegister(save))

        if (save) {
            setLoadingLater(false)
        }
        else {
            setLoadingOK(false)
        }

        if (response) {
            // clear stored data
            saveCompanyRegisterData(null)
            // jump to company profile
            let companyName = currentCompanyName() || currentUserName() || ''
            navigate(`/company-profile/${response.data.id}`)
        }
        else {
            message.error("Submit company profile error!")
        }
    }

    const footer = (
        <FooterButtons disabledOK={disabledOK}
            // hiddenNext={true}
            nextName={'See your profile'}
            // nextType={'secondary'}
            // loadingOK={loadingOK}
            loadingNext={loadingLater}
            onNext={() => {
                registerCompanyProfile(true)
            }}
        // onOK={() => {
        //     registerCompanyProfile(false)
        // }}
        />
    )



    return (
        <HCenterContainer>
            <CompanyConfigureContainer header={header} body={body} footer={footer} hideClose={true} />
        </HCenterContainer>
    )
}


export function AddJobOpeningManually(props) {
    const navigate = useNavigate()
    const { jobOpeningsSource, setJobOpenings } = useContext(JobOpeningsContext)
    const [currentJobOpenings, setCurrentJobOpenings] = useState(getJobOpenings() || [])
    const [current, setCurrent] = useState(null)
    const [showModal, setShowModal] = useState(false)
    const { setJobOpeningsSource, setCareerPageUrl } = useContext(JobOpeningsContext)

    useEffect(() => {
        setCareerPageUrl(null)
        setJobOpeningsSource(null)

    }, [])

    const saveManuallyJobOpenings = (jobOpenings) => {
        setCurrentJobOpenings(jobOpenings)
        saveJobOpenings(jobOpenings)
    }

    const AddModal = (props) => {
        const key = current && current.uuid || props.index
        // console.log(current)
        return (
            <AJModalV2
                visible={showModal}
                closable={true}
                centered={true}
                width={750}
                key={key}
                onClose={() => {
                    setShowModal(false)

                }}>
                <AJCard width={750}
                    height={'infinity'}
                    borderRadius={22}
                    type={'wide'}
                    hideClose={true}>
                    {/*<div>*/}
                    <div style={{ padding: '32px 32px 22px' }}>
                        <div style={{ fontSize: 22 }}>
                            Input Your Job Opening
                        </div>
                        <EmptyLine height={8} />
                        <AJInput2
                            label={'Title'}
                            id={'input-job-opening-title'}
                            defaultValue={current && current.title} />
                        <EmptyLine height={8} />
                        <AJInput2 label={'Location'} id={'input-job-opening-location'}
                            defaultValue={current && current.location} />
                        <EmptyLine height={8} />

                        <AJUrlInput label={'Link'} id={'input-job-opening-link'}
                            defaultValue={current && current.url} />
                        <EmptyLine height={16} />
                        <Row type={'flex'} align={'end'} justify={'end'}>
                            <Col>
                                <AJButton3
                                    size={'small'}
                                    title={'Save'}
                                    titleStyle={{ color: 'white' }}
                                    width={103}
                                    height={38}
                                    onClick={() => {
                                        const title = document.getElementById('input-job-opening-title').value
                                        const location = document.getElementById('input-job-opening-location').value
                                        const link = document.getElementById('input-job-opening-link').value

                                        if (!current) {
                                            currentJobOpenings.push({
                                                title,
                                                location,
                                                url: link,
                                                key: uuid()
                                                // key:
                                                // order: props.index,
                                                // source: null,
                                                // sourceUrl: null
                                            })
                                        }
                                        else {
                                            current.title = title
                                            current.location = location
                                            current.url = link
                                            // console.log(current)
                                            // console.log(currentJobOpenings)

                                        }

                                        saveManuallyJobOpenings(currentJobOpenings)
                                        setShowModal(false)
                                    }} />
                            </Col>
                        </Row>

                    </div>

                    {/*</div>*/}
                </AJCard>
            </AJModalV2>
        )
    }

    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            width: '70%',
        },
        {
            title: 'Location',
            dataIndex: 'location',
            key: 'location',
            width: '20%',

        },
        {
            title: '',
            dataIndex: 'operation',
            key: 'operation',
            width: '10%',

            render: (text, record) => (
                <>
                    <EditOutlined
                        onClick={() => {
                            setCurrent(record)
                            setShowModal(true)
                        }} />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <DeleteOutlined
                        onClick={() => {
                            // let leftFavoriteData = favoriteData.filter(data =>
                            //     data.id !== companyData.id
                            // )
                            saveManuallyJobOpenings(currentJobOpenings.filter(j => j.key !== record.key))
                        }} />
                </>
            ),
        },
    ];

    const header = "Add your job openings Manually"
    const footer = (<FooterButtons
        onNext={() => {
            // save ...
            navigate("/register/add-social-links")
        }
        } />)

    const body = (
        <Row>
            <br />
            <Col span={24}>
                <div className={'login-common-description'}>
                    Here you can add your job openings on your own website
                </div>
                <br />
                <br />
                <div style={{ height: 240 }}>
                    {/*<div style={{height: '240px',*/}
                    {/*    width: '100%',*/}
                    {/*    // background: "yellow",*/}
                    {/*    overflowY: 'auto'}}>*/}
                    {/*    <Table columns={columns} dataSource={currentJobOpenings} showHeader={false}/>*/}
                    {/*</div>*/}

                    {
                        currentJobOpenings.length === 0 ?
                            <HCenterContainer>
                                <Empty />
                            </HCenterContainer>
                            : <>
                                {/*<EmptyLine height={58}/>*/}
                                <div style={{
                                    height: '240px',
                                    width: '100%',
                                    // background: "yellow",
                                    overflowY: 'auto'
                                }}>
                                    <Table columns={columns} dataSource={currentJobOpenings}
                                        pagination={false} />
                                </div>
                            </>
                    }
                </div>
                <EmptyLine height={8} />
                <div style={{
                    textAlign: 'left',
                    // position: `absolute`,
                    // left: '50%',
                    // bottom: 0,
                    fontStyle: `normal`,
                    fontWeight: 600,
                    fontSize: `16px`,
                    lineHeight: `19px`,
                    color: `#BDBDBD`,
                    cursor: 'pointer'
                }}
                    onClick={() => {
                        setShowModal(true)
                    }}>
                    <span style={{ color: '#000' }} >
                        <LegacyIcon type={'plus'} />
                    </span>
                    <EmptySpan width={14} />
                    Add more Job Openings
                </div>
            </Col>
            <AddModal />
        </Row>
    )



    return (
        <HCenterContainer>
            <CompanyConfigureContainer header={header} body={body} footer={footer} hideClose={true} />
        </HCenterContainer>
    )
}

export function AddJobOpeningManually2(props) {

    const navigate = useNavigate()
    // const {setJobOpeningsSource, setCareerPageUrl} = useContext(JobOpeningsContext)
    const [currentJobOpenings, setCurrentJobOpenings] = useState(getJobOpenings() || [])

    // useEffect(()=>{
    //     setCareerPageUrl(null)
    //     setJobOpeningsSource(null)
    //
    // }, [])

    const JobOpeningContainer = function (props) {
        // const jobOpenings = props.jobOpenings || []
        //
        // console.log(jobOpenings)
        for (const j of currentJobOpenings) {
            if (!j.key) {
                j.key = uuid()
            }
        }

        while (currentJobOpenings.length < 3) {
            currentJobOpenings.push({ key: uuid() })
        }


        return (<>
            {
                currentJobOpenings.map((jobOpening, index) => {
                    return <div key={index}>
                        <JobOpeningLine jobOpening={jobOpening} />
                        <EmptyLine height={34} />
                    </div>
                })
            }
        </>)

    }

    const verify = function (jobOpening) {
        return jobOpening.title && jobOpening.title.length > 0
            && jobOpening.location && jobOpening.location.length > 0
            && jobOpening.link && jobOpening.link.length > 0
    }

    const JobOpeningLine = function (props) {
        const jobOpening = props.jobOpening
        const [verified, setVerified] = useState(verify(jobOpening))
        const verifyJobOpening = function () {
            if (verify(jobOpening)) {
                setVerified(true)
            }

            saveJobOpenings(currentJobOpenings.filter(jobOpening => (jobOpening.title && jobOpening.title.length > 0)
                || (jobOpening.location && jobOpening.location.length > 0)
                || (jobOpening.link && jobOpening.link.length > 0)))
        }
        return (
            <Row type={'flex'} align={'bottom'}>
                <Col span={7}>
                    <AJInput2
                        placeholder={`Job Title`}
                        defaultValue={jobOpening.title}
                        onChange={(e) => {
                            jobOpening.title = e.target.value
                            verifyJobOpening()
                        }}
                    />
                </Col >
                <Col span={1} />
                <Col span={7}>
                    <AJInput2 placeholder={`Location`} defaultValue={jobOpening.location}
                        onChange={(e) => {
                            jobOpening.location = e.target.value
                            verifyJobOpening()
                        }} />
                </Col>
                <Col span={1} />

                <Col span={7}>
                    <AJInput2 placeholder={`Url to Job`} defaultValue={jobOpening.link}
                        onChange={(e) => {
                            jobOpening.link = e.target.value
                            verifyJobOpening()
                        }} />
                </Col>
                <Col span={1}>
                    <div style={{ width: '100%', textAlign: 'right' }} hidden={!verified}>
                        <CheckCircleOutlined style={{ fontSize: 20 }} />
                    </div>
                </Col>

            </Row>
        );
    }

    const header = `Manually sync your job openings`
    const footer = (<FooterButtons
        onNext={() => {
            // save ...

            navigate("/register/add-social-links")
        }
        } />)
    const body = (<div>
        <br />
        <div className={'login-common-description'}>
            Here, you can manually link and sync job openings
            to your profile.
        </div>
        <EmptyLine height={70} />
        <div style={{ height: 240, overflow: 'auto', width: 816 }} id={'manually-job-opening-container'}>
            <div style={{ width: 786 }}>
                <JobOpeningContainer jobOpenings={currentJobOpenings} />
                <div style={{
                    textAlign: 'right',
                    fontStyle: `normal`,
                    fontWeight: 600,
                    fontSize: `16px`,
                    lineHeight: `19px`,
                    color: `#BDBDBD`,
                    cursor: 'pointer'
                }} onClick={() => {
                    // console.log(currentJobOpenings)

                    setCurrentJobOpenings([...currentJobOpenings, {}])
                    setTimeout(() => {
                        const el = document.getElementById('manually-job-opening-container')
                        el.scrollTop = el.scrollHeight;
                    }, 0)
                }}>
                    Add more Job Openings
                    <EmptySpan width={14} />
                    <span style={{ color: '#000' }} onClick={() => {

                    }}>
                        <LegacyIcon type={'plus'} />
                    </span>
                    <EmptySpan width={24} />
                </div>
            </div>

        </div>



    </div>)

    return (
        <CompanyRegisterTemplate tips={'People work for money, but they perform for values and\n' +
            'purpose'}>
            <CompanyConfigureContainer header={header} body={body} footer={footer} hideClose={true} />
        </CompanyRegisterTemplate>
    )
}


