import React, { useState } from "react";
import { SaasAccountInfo } from "saas/model/SaasCommonModel";
import { SaasLoginInfoKey, SaasUserIdKey, getSaasUserToken, getFromSaasStorage, saveToSaasStorage, setCurrentCompanyId, setCurrentCompanyName, setCurrentIsAdmin, setCurrentToken, setCurrentUserId, setCurrentUserName } from "saas/utils/storage/SaasStorage";

interface SaasAccountContextType {
    currentAccount: SaasAccountInfo | null;
    setCurrentAccount: (account: SaasAccountInfo) => void;
}

const SaasAccountContext = React.createContext<SaasAccountContextType>({
    currentAccount: null,
    setCurrentAccount: () => { },
});

function SaasAccountProvider(props: any) {
    const [currentAccount, setCurrentAccount] = useState(getFromSaasStorage(SaasUserIdKey));
    return (
        <SaasAccountContext.Provider value={{
            currentAccount: currentAccount,
            setCurrentAccount: (account: SaasAccountInfo) => {
                setCurrentAccount(account);
                setCurrentUserId(account.userId);
                setCurrentToken(account.token);
                setCurrentUserName(account.username);
                setCurrentIsAdmin(account.isAdmin);
                setCurrentCompanyId(account.companyId);;
                setCurrentCompanyName(account.companyName);

                saveToSaasStorage(SaasLoginInfoKey, account);
            },
        }}>
            {props.children}
        </SaasAccountContext.Provider>
    );
}

export { SaasAccountContext, SaasAccountProvider };