import React from "react";
import EmptyLine from "../ui/EmptyLine";
import StartFooter from "../component/start/StartFooter";
import "./HowItWorkCompany.less";
import AJImg from "../ui/AJImg";
import { TalentCardContainer } from "../component/ceo-review/CEOReviewCardContainer";
import { Col, Row } from "antd";
import { AJButton4 } from "../ui/AJButton3";
import { gotoLink } from "../component/start/StartFooter";
import { useNavigate } from 'react-router-dom';

export default function HowItWorkCompany(props) {
    const navigate = useNavigate();

    return (
        <div className={'how-it-work-company'}>
            <EmptyLine height={220} />
            <div className={'company-title'}>
                Talent
            </div>
            <EmptyLine height={420} />

            <div className={'how-it-work-company-container'}>
                <div className={'how-it-work-company-phase'}>
                    <Row type={'flex'}>
                        <Col span={3} />
                        <Col span={9}>
                            <div className={'how-it-work-company-title'}>
                                Find a Team You Want To Work With
                            </div>
                            <br />
                            <div className={'how-it-work-company-body'}>
                                <div>At Genvalues, we recognize that engineering isn't just about technical skills—it's about finding a team that works in harmony with your principles and contributing to a positive impact. This synergy is where creativity thrives. We're dedicated to matching you with teams that share your approach to technology, ensuring that your work is more than just a job, but a reflection of your beliefs.</div>
                                <br />

                                <div>In the fast-paced tech world, where precision and adaptability are key, being part of the right team can immensely boost your professional development and creative output. Join a team where open communication and innovative problem-solving aren't just encouraged but are the norm.</div>
                                <br />

                                <div>We connect you with engineering teams that not only use agile methodologies but also value continuous learning, and practice sustainability in their product development. These aren't mere buzzwords to us; they're vital components that shape how technology influences society.</div>
                                <br />
                            </div>
                            <div style={{ width: '100%', textAlign: 'center', marginTop: 50 }}>
                                <AJButton4
                                    title={'True Fit'}
                                    size={'mini'}
                                    width={248}
                                    type={'secondary'}
                                    height={60}
                                    titleStyle={{
                                        fontSize: 20,
                                        fontWeight: 400,
                                        fontFamily: 'ManropeBold',
                                        color: '#000000'
                                    }}
                                    customStyle={{
                                        borderRadius: '90px',
                                        background: '#FFD865',
                                    }}
                                    hoverToBlack={true}
                                    titleWithWaveAnimation={true}
                                    onClick={() => {
                                        gotoLink('/toolbox', navigate)
                                    }} />
                            </div>
                        </Col>
                        <Col span={9}>
                            <div style={{ paddingLeft: 69 }}>
                                <EmptyLine height={15} />
                                <AJImg src={'/img/how-it-works/talent-1.svg'} width={620} alt={'Why should I hire'} />
                            </div>
                        </Col>
                    </Row>
                </div>
                <EmptyLine height={200} />
                <div className={'how-it-work-company-phase'}>
                    <Row type={'flex'}>
                        <Col span={3} />
                        <Col span={9}>
                            {/*<EmptyLine height={40}/>*/}
                            <AJImg src={'/img/how-it-works/talent-2.svg'} width={'100%'} alt={'How do I publish a profile'} />
                        </Col>
                        <Col span={9}>
                            <div style={{ paddingLeft: 70 }}>
                                <div className={'how-it-work-company-title'}>
                                    Craft Your Career
                                </div>
                                <br />
                                <div className={'how-it-work-company-body'}>
                                    <div>Choosing a team that resonates with your principles means your work contributes to technological progress while staying true to your personal values.</div>
                                    <br />

                                    <div>Genvalues is more than a job-finding platform. It's where your talents are celebrated, and your values find a voice. In an environment that reflects your ideals, every task is a chance to grow, and every project becomes a platform for your innovation.</div>
                                    <br />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <br />
                </div>
                <EmptyLine height={200} />
            </div>

            <Row>
                <Col span={3} />
                <Col span={9}>
                    <div className={'how-it-work-company-title'}>
                        5 Key Advantages
                    </div>
                    <br />
                    <div className={'how-it-work-company-body'} style={{ marginLeft: -24 }}>
                        <ul style={{ paddingLeft: 40, paddingTop: 30 }}>
                            <li>
                                <b>Find the Right Team for You: </b>Connect your enthusiasm for teamwork and innovation with a team that shares your outlook for a more rewarding career.
                            </li>
                            <EmptyLine height={23} />
                            <li>
                                <b>Feel Appreciated and Excel: </b>In a team that values your input, you're inspired to deliver your best work.
                            </li>
                            <EmptyLine height={23} />
                            <li>
                                <b>Work with Meaning: </b>When your personal goals and your company's objectives match, you'll naturally feel more motivated and satisfied, leading to better performance.
                            </li>
                            <EmptyLine height={23} />
                            <li>
                                <b>Better Team Dynamics, More Innovation: </b>For example, if your team values open communication and transparency, you can share ideas and insights easily and drive progress much easier.
                            </li>
                            <EmptyLine height={23} />
                            <li>
                                <b>More Than Just a Job: </b>Be part of a team that cares about social responsibility and aligns with your values for a deeper sense of connection and a more fulfilling, balanced career.
                            </li>
                        </ul>
                    </div>
                </Col>
                <Col span={9}>
                    <div style={{ paddingLeft: 64 }}>
                        <AJImg src={'/img/how-it-works/talent-3.svg'} width={620} alt={'Five advantages'} />
                    </div>
                </Col>
            </Row>

            <TalentCardContainer />
            <EmptyLine height={200} />
            <StartFooter />
        </div>
    )

}