import React, { useState, useCallback, useMemo } from 'react';
import { Modal, Typography, Row, Col, Input, Button } from 'antd';
import SaasImageUploadButton from './SaasImageUploadButton';
import './Overlay02.less';
import SaasProfileLayout from './SaasProfileLayout';
import EmptyLine from 'ui/EmptyLine';
import { OverlayProps, SaasUserProfileInfo, TeamMember } from 'saas/model/SaasCommonModel';
import OverlayLayout from './OverlayLayout';

const { Paragraph, Title, Text } = Typography;
const { TextArea } = Input;

const Overlay02: React.FC<OverlayProps> = ({
  isOpen,
  profileInfo,
  onSave,
  onClose
}) => {
  const closeModal = useCallback(() => {
    onClose();
  }, [onClose]);

  const [currentTeamMemberId, setCurrentTeamMemberId] = useState<number>(0);

  const updateTeamMemberHandler = useCallback((newTeamMember: TeamMember) => {
    const updatedTeamMembers = [...profileInfo.teamGallery.teamMembers];
    updatedTeamMembers[currentTeamMemberId] = newTeamMember;

    const updatedProfileInfo = {
      ...profileInfo,
      teamGallery: {
        ...profileInfo.teamGallery,
        teamMembers: updatedTeamMembers,
      },
    };

    onSave(updatedProfileInfo);
  }, [profileInfo, currentTeamMemberId, onSave]);

  const TeamMemberEditors: React.FC<{ teamMember: TeamMember, updateTeamMember: (newValue: TeamMember) => void; }> = useCallback(({ teamMember, updateTeamMember }) => {
    return (
      <>
        <div className='overlay-head-2'>Add image here</div>
        <EmptyLine height={2} />
        <div className='overlay-format-desc'>Format should be JPG, PNG</div>
        <EmptyLine height={25} />
        <SaasImageUploadButton
          image={teamMember.image}
          onImageChange={(url) => {
            const updatedTeamMember = { ...teamMember, image: url };
            updateTeamMember(updatedTeamMember);
          }}
        />

        <EmptyLine height={42} />
        <div className='overlay-head-2'>Add your text / quote here <span className="overlay-max-length-desc">(Max xxx characters)</span></div>
        <EmptyLine height={15} />
        <TextArea className="overlay-multi-line-input" rows={4}
          value={teamMember.quote} onChange={(e) => {
            const updatedTeamMember = { ...teamMember, quote: e.target.value };
            updateTeamMember(updatedTeamMember);
        }} />

        <EmptyLine height={37} />
        <div className='overlay-head-2'>Add Name & title</div>
        <EmptyLine height={16} />
        <Input className="overlay-one-line-input"
          value={teamMember.name} onChange={(e) => {
            const updatedTeamMember = { ...teamMember, name: e.target.value };
            updateTeamMember(updatedTeamMember);
        }} />

        <EmptyLine height={24} />
        <Input className="overlay-one-line-input" value={teamMember.title} onChange={(e) => {
          const updatedTeamMember = { ...teamMember, title: e.target.value };
          updateTeamMember(updatedTeamMember);
        }} />
      </>
    );
  }, []);

  const renderModalContent = useMemo(() => {
    return (
      <>
        <div className='overlay-head-1'>Edit Section</div>
        <EmptyLine height={20} />
        <div className='overlay-description'>Here you can add content for this section, add up to 4 people</div>
        <EmptyLine height={45} />

        <div className='overlay-head-2'>Headline</div>
        <EmptyLine height={15} />
        <Input className="overlay-one-line-input" value={profileInfo.teamGallery.title} onChange={(e) => {
          const updatedProfile = { ...profileInfo, teamGallery: { ...profileInfo.teamGallery, title: e.target.value } };
          onSave(updatedProfile);
        }} />
        <EmptyLine height={45} />
        <TeamMemberEditors teamMember={profileInfo.teamGallery.teamMembers[currentTeamMemberId]} updateTeamMember={updateTeamMemberHandler} />

        <EmptyLine height={53} />

        <Row justify={'end'}>
          {currentTeamMemberId > 0 && (
            <button className="back-button" onClick={() => setCurrentTeamMemberId(currentTeamMemberId - 1)}>Back</button>
          )}
          {currentTeamMemberId < 3 && (
            <button className="next-button" onClick={() => setCurrentTeamMemberId(currentTeamMemberId + 1)}>Next Person</button>
          )}
          {currentTeamMemberId === 3 && (
            <button className="done-button" onClick={closeModal}>Done</button>
          )}
        </Row>
      </>
    );
  }, [profileInfo, currentTeamMemberId, updateTeamMemberHandler, onSave, closeModal]);

  return (
    <Modal
      open={isOpen}
      width={'60%'}
      onCancel={closeModal}
      footer={null}
      className="overlay-02-modal"
    >
      <SaasProfileLayout>
        <OverlayLayout layoutWidth="60%">
          {renderModalContent}
        </OverlayLayout>
      </SaasProfileLayout>
    </Modal>
  );
};

export default Overlay02;