export const SaasUserIdKey = 'saas-user-id';
const SaasUserNameKey = 'saas-user-name';
export const SaasLoginInfoKey = "saas-login-info";
const SaasUserTokenKey = 'saas-user-token';
const SaasCompanyNameKey = 'saas-company-name';
const SaasCompanyIdKey = 'saas-company-id';
const SaasIsAdminKey = 'saas-user-is-admin';
export const SaasCompanyRegisterDataKey = "saas-company-register-data";

export function currentUserId() {
    const userIdKey = localStorage.getItem(SaasUserIdKey);
    return userIdKey ? parseInt(userIdKey) : null;
}


function userKey(userId: number | null) {
    return `Genvalues-SaaS-user-${userId}`;
}

export function SaasStorage() {
    const key = userKey(currentUserId());
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : {};
}

export function getFromSaasStorage(key: string) {
    const storage = SaasStorage();
    return storage[key];
}

export function saveToSaasStorage(key: string, value: any) {
    const storage = SaasStorage();
    storage[key] = value;

    // console.log(`save ${userKey(currentUserId())} ${JSON.stringify(storage)}`)
    localStorage.setItem(userKey(currentUserId()), JSON.stringify(storage));
}

export function getOrderedValues(key = SaasCompanyRegisterDataKey) {
    const companyRegisterData = getFromSaasStorage(key) || {};
    return companyRegisterData.orderedValues || [];
}

export function savePickedValues(pickedValues: any, key = SaasCompanyRegisterDataKey) {
    const companyRegisterData = getFromSaasStorage(key) || {};
    companyRegisterData.pickedValues = pickedValues;
    saveCompanyRegisterData(companyRegisterData);
}

export function saveCompanyRegisterData(companyRegisterData: any, key = SaasCompanyRegisterDataKey) {
    saveToSaasStorage(key, companyRegisterData);
}

export function saveOrderedValues(orderedValues: any, key = SaasCompanyRegisterDataKey) {
    const companyRegisterData = getFromSaasStorage(key) || {};
    companyRegisterData.orderedValues = orderedValues;
    saveCompanyRegisterData(companyRegisterData);
}

export function setCurrentUserId(userId: number, key: string = SaasUserIdKey) {
    localStorage.setItem(key, userId.toString());
}

export function getSaasUserToken(key = SaasUserTokenKey) {
    return localStorage.getItem(key)
}

export function setCurrentToken(token: string, key = SaasUserTokenKey) {
    localStorage.setItem(key, token);
}

export function setCurrentUserName(userName: string, key = SaasUserNameKey) {
    localStorage.setItem(key, userName);
}
export function setCurrentCompanyName(companyName: string, key = SaasCompanyNameKey) {
    localStorage.setItem(key, companyName);
}

export function setCurrentIsAdmin(isAdmin: boolean, key = SaasIsAdminKey) {
    localStorage.setItem(key, isAdmin?.toString());
}

export function setCurrentCompanyId(companyId: number, key = SaasCompanyIdKey) {
    localStorage.setItem(key, companyId?.toString());
}
