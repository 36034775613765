import SvgRightNarrow from 'icons/rightNarrow';
import SvgValueIcon from 'icons/valueIcon';
import React, { useState } from 'react';
import './SaasPrincipleCard.less';

interface SaasPrincipleCardProps {
    value: string;
    index: number;
    onClick?: (index: number) => void;
}

const SaasPrincipleCard: React.FC<SaasPrincipleCardProps> = ({ value, index, onClick }) => {
    let [isHovered, setIsHovered] = useState(false)

    const handleClick = () => {
        console.log('click');
        if (onClick) {
            onClick(index); // call the onClick function passed as prop
        }
    }

    return (
        <div className={'value-tag'}>
            <div className="value-content" onMouseEnter={() => { setIsHovered(true); console.log('mouse hovered in card component') }} onMouseLeave={() => { setIsHovered(false) }} onClick={() => { handleClick() }}>
                <SvgValueIcon fill={isHovered ? 'white' : 'black'}></SvgValueIcon>
                <div style={{ color: isHovered ? 'white' : 'black', marginTop: 7 }} className="text">{value}
                    &nbsp;
                    <SvgRightNarrow fill={isHovered ? 'white' : 'black'}></SvgRightNarrow>
                </div>
            </div>
        </div>
    );
};

export default SaasPrincipleCard;