import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import "./CompanyProfile2.less";
import { useLocation } from "react-router";
import CompanyProfileEditorProvider from "../context/CompanyProfileEditorProvider";
import CompanyProfileImageContainer from "../component/company/CompanyProfileImageContainer";
import { DeleteOutlined, EditOutlined, InboxOutlined } from '@ant-design/icons';
import { Button, Col, Row, Upload, message, Table } from "antd";
import CompanyProfileHeader from "../component/company/CompanyProfileHeader";
import {
    companyProfile,
    getFavorite,
    jobExtract,
    removeFavorite, sendCommunityMail, subscribeCommunityMail,
    talentValueMatch,
    updateCompanyProfile
} from "../http/AJHttp";
import {
    currentCompanyId,
    currentIsAdmin,
    currentUserId,
    currentUserName,
    getLoginFTU,
    isLogged,
    setCurrentCompanyId, setJoinedCommunity,
    setLoginFTU
} from "../storage/AJStorage";
import EmptyLine from "../ui/EmptyLine";
import EmptySpan from "../ui/EmptySpan";
import JobOpenings2 from "../component/company/JobOpenings2";
import CompanyProfileTopValues from "../component/company/CompanyProfileTopValues";
import { useParams, useNavigate } from "react-router-dom";
import CompanyProfileValues from "../component/company/CompanyProfileValues";
import CompanyProfileTechStack from "../component/company/CompanyProfileTechStack";
import AJInput2, { AJEmailInput, AJUrlInput } from "../ui/AJInput2";
import AJButton3 from "../ui/AJButton3";
import { arrayContains } from "../utils/arrayUtil";
import AJModalV2 from "../ui/AJModalV2";
import AJCard from "../ui/AJCard";
import { Radio, Input } from 'antd';
import { AJFullScreenLoading } from "../ui/AJLoading";
import StartFooter from "../component/start/StartFooter";
import ReactGA, { set } from 'react-ga';
import uuid from "react-uuid";
import companyConfig from "../component/company/CompanyConfig";
import DocumentTitle from "../component/DocumentTitle";
import CompanyProfileNextEvent from "../component/company/CompanyProfileNextEvent";

const { Dragger } = Upload;

function validateProfileData(profileData) {
    const orderedValues = profileData.orderedValues ?? []
    const describeValues = profileData.describeValues ?? []
    const orderedValuesNew = []
    const values = []
    orderedValues.forEach(v => {
        if (!arrayContains(values, v.text)) {
            values.push(v.text)
            orderedValuesNew.push(v)
        }
    })

    const orderedDescribeValues = {}
    values.forEach((v, index) => {
        let found = false
        for (const key in describeValues) {
            if (describeValues[key].value === v) {
                orderedDescribeValues[index + 1] = describeValues[key]
                found = true
                break
            }
        }
        if (!found) {
            orderedDescribeValues[index + 1] = {
                text: "",
                value: v
            }
        }

    })

    profileData.describeValues = orderedDescribeValues
    profileData.orderedValues = orderedValuesNew

    return profileData
}

async function getIp() {
    // const response =  await axios.get('https://api.ipify.org')
    // localStorage.setItem('local-ip', response.data)
}

export default function CompanyProfile2Container(props) {
    let { id } = useParams();
    const navigate = useNavigate()
    const location = useLocation();
    const [editing, setEditing] = useState(false || location.state?.skipEditButton);
    const [isLoading, setIsLoading] = useState(false)
    const [profileData, setProfileData] = useState(null)
    const [savedProfileData, setSavedProfile] = useState(null)
    const [readOnly, setReadOnly] = useState(true)
    const [favoriteData, setFavoriteData] = useState(null)

    useEffect(() => {
        fetchProfileData();
        ReactGA.pageview(`/company-profile/`);
    }, [])

    const fetchProfileData = async () => {
        const [response, response2, r3] = await Promise.all([companyProfile(id), getFavorite(), getIp()])
        if (response) {
            let rd = true

            if (currentIsAdmin()) {
                rd = false
            }
            else if (response.data.owner == currentUserId()) {
                rd = false
            }

            setSavedProfile(JSON.parse(JSON.stringify(response.data)))
            setProfileData(validateProfileData(response.data))
            setReadOnly(rd)
            if (response2) {
                setFavoriteData(response2.data)
            }
        }
        else {
            message.error("Get company profile failed!")
            navigate('/404')
        }
    }

    async function updateProfile(errorMessage, callback) {
        if (JSON.stringify(savedProfileData) !== JSON.stringify(profileData)) {
            const response = await updateCompanyProfile(id, profileData)
            if (response) {
                setProfileData(response.data)
                setSavedProfile(JSON.parse(JSON.stringify(response.data)))
                if (callback) {
                    callback()
                }
            }
            else {
                message.error(errorMessage || "Update company profile failed!")
            }
        }
    }

    document.getElementsByTagName('body')[0].style.background = props.background || 'transparent'
    return (
        <>
            {
                !profileData || !favoriteData ? <AJFullScreenLoading />
                    :
                    <CompanyProfileEditorProvider>
                        <CompanyProfile2 refreshFavorite={props.refreshFavorite}
                            favoriteData={favoriteData}
                            readonly={readOnly}
                            editing={editing}
                            setEditing={setEditing}
                            profileData={profileData}
                            savedProfileData={savedProfileData}
                            updateSavedProfileData={setSavedProfile}
                            reloading={() => setProfileData(null)}
                            published={profileData.published}
                            updateProfile={updateProfile} />
                    </CompanyProfileEditorProvider>
            }
        </>
    )
}

export function CompanyProfile2(props) {
    const { editing, profileData, readonly } = props
    const [companyImage, setCompanyImage] = useState(null)
    const [version, setVersion] = useState(0)
    const [showJobOpeningsEditing, setShowJobOpeningsEditing] = useState(false)
    const [coverImageLoaded, setCoverImageLoaded] = useState(false)
    const [showTooltips, setShowTooltips] = useState(false)
    useEffect(() => {
        if (isLogged() && !profileData.published
            && (currentUserId() === profileData.owner)) {
            const loginInfo = getLoginFTU()
            if (loginInfo && loginInfo.userId === currentUserId() && profileData.id === loginInfo.companyId) {
                return
            }
            else {
                if (!currentCompanyId()) {
                    setCurrentCompanyId(profileData.id)
                }
                setLoginFTU({
                    userId: currentUserId(),
                    companyId: profileData.id
                })
                setTimeout(() => setShowTooltips(true), 1000)
            }
        }
    }, [])

    const JoinCard = (props) => {
        const [sending, setSending] = useState(false)
        const [email, setEmail] = useState('')
        const [cardClass, setCardClass] = useState('join-community-card')
        useLayoutEffect(() => {
            if (coverImageLoaded) {
                if (!props.readOnly) {
                    const card = document.getElementById('aj-join-community-card')
                    const offsetTop = getOffsetTop(card)
                    const onScroll = function (e) {
                        const scrollTop = document.documentElement.scrollTop
                        if (scrollTop >= offsetTop - 50) {
                            card.setAttribute('class', 'join-community-card-fixed')
                        }
                        else {
                            card.setAttribute('class', 'join-community-card')
                        }
                    }
                    window.addEventListener('scroll', onScroll);

                    return () => {
                        window.removeEventListener('scroll', onScroll);
                    }
                }
            }
        }, [coverImageLoaded])

        let title = null
        let description = null
        let image = null
        if (profileData && profileData.published) {
            const companyName = profileData.name
            const config = companyConfig[companyName] || {}
            title = config?.name || profileData.name
            description = config.description || profileData.introducation
            image = `${window.location.origin}${profileData.companyStructure?.imageUrl}`
        }

        return (
            <>
                <DocumentTitle title={title} description={description} image={image} url={window.location.href}>
                    <div className={'join-community-card'} hidden={!props.readonly} id={'aj-join-community-card'}>
                        <div className={'join-community-card-container'}>
                            <div className={'join-community-card-title'}>
                                Stay Ahead
                            </div>
                            <EmptyLine height={14} />
                            <div className={'join-community-card-desc'}>
                                Sign up for weekly <br />newsletter
                            </div>
                            <EmptyLine height={42} />
                            <AJEmailInput label={'Your e-mail here'}
                                id={'aj-community-mail'}
                                defaultValue={email} />

                            <EmptyLine height={59} />
                            <span className={'join-community-card-privacy'} onClick={() => {
                                window.open('/privacy')
                            }}>
                                Privacy
                            </span>
                            <AJButton3 title={'Send'}
                                size={'small'}
                                titleStyle={{ color: 'black' }}
                                loading={sending}
                                height={43}
                                width={100}
                                background={'white'}
                                onClick={async () => {
                                    const email = document.getElementById('aj-community-mail').value
                                    setSending(true)

                                    const response = await sendCommunityMail(email)
                                    if (response) {
                                        message.info("Thank you for subscribing!")
                                        setCardClass('join-community-card-start-page')
                                        setJoinedCommunity()
                                    }
                                    else {
                                        message.error("Subscribe failed!")
                                    }

                                    setSending(false)
                                }} />
                        </div>
                    </div>
                </DocumentTitle>
            </>
        )
    }

    const GotoTop = (props) => {
        const [className, setClassName] = useState('company-profile2-goto-top-hidden')
        const ref = useRef(null);
        useLayoutEffect(() => {
            const footer = document.getElementById('aj-start-footer');
            const isElementVisible = (el) => {
                const rect = el.getBoundingClientRect()
                const vWidth = window.innerWidth || document.documentElement.clientWidth
                const vHeight = window.innerHeight || document.documentElement.clientHeight


                if (
                    rect.right < 0 ||
                    rect.bottom < 0 ||
                    rect.left > vWidth ||
                    rect.top > vHeight
                ) {
                    return false
                }

                return true
            }

            if (coverImageLoaded || true) {
                window.addEventListener('scroll', toggle);
                function toggle(e) {
                    const scrollTop = document.documentElement.scrollTop
                    if (scrollTop < window.innerHeight) {
                        setClassName('company-profile2-goto-top-hidden')
                    }
                    else {
                        setClassName('company-profile2-goto-top');
                        const vHeight = window.innerHeight || document.documentElement.clientHeight
                        const rect = footer.getBoundingClientRect();
                        if (rect.top <= vHeight && rect.bottom >= 0) {

                            const offset = document.documentElement.clientHeight - rect.top;
                            ref.current.style.bottom = `${offset + 40}px`;
                        }
                        else {
                            ref.current.style.bottom = `40px`;
                        }
                    }
                }
                return () => {
                    window.removeEventListener('scroll', toggle);
                }
            }


        }, [coverImageLoaded])

        return (<div ref={ref} className={className} onClick={(e) => {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        }}>
            <img src={'/img/company-profile/goto-top.svg'} />
        </div>)
    }

    return (
        <div>
            <AJModalV2 visible={showTooltips}
                width={707}
                top={250}>
                <div className={'profile-tooltips-container'}>
                    <div className={'profile-tooltips-body'}>
                        Click on "edit profile" and make it WOW for<br /> value-driven talents that are looking for more<br /> than just a job.
                    </div>
                    <div style={{ paddingTop: 32, textAlign: 'right' }}>
                        <AJButton3 title={'I got it!'}
                            titleStyle={{ 'color': 'white' }}
                            size={'small'}
                            scheme={'dark-purple'}
                            width={105}
                            height={38}
                            shadow={true}
                            onClick={() => setShowTooltips(false)} />
                    </div>
                </div>
            </AJModalV2>

            <div style={{ position: 'relative', top: 60 }}>
                <CompanyProfileImageContainer {...props} onImageLoaded={() => {
                    setCoverImageLoaded(true)
                }} />
                <div className={'company-profile2-container'}>
                    <CompanyProfileHeader {...props} logoUrl={'/img/common/img-place-holder.png'} />
                    <div className={'job-openings-title disable-user-select'}>
                        {
                            editing ?
                                <span onClick={() => {
                                    setShowJobOpeningsEditing(true)
                                }}>
                                    <EditIcon />Sync Job Openings
                                </span>

                                : 'Job Openings'
                        }
                    </div>
                    <JobOpeningsEditing
                        key={Math.random()}
                        saveJobOpenings={
                            (newJobOpenings) => {
                                profileData.jobOpenings = newJobOpenings
                            }}
                        {...props}
                        show={showJobOpeningsEditing}
                        hide={() => {
                            setShowJobOpeningsEditing(false)
                        }} />
                    <EmptyLine height={29} />
                </div>
                <JobOpenings2
                    editing={editing}
                    data={profileData.jobOpenings}
                    company={profileData.name}
                    isCustomer={profileData.owner === currentUserId()} />
                <div className={'company-profile2-container2'}>
                    <Row>
                        <Col span={15}>
                            <CompanyProfileTopValues {...props} version={version} />
                        </Col>
                        <Col span={4} />
                        <Col span={5} className={'company-profile-detail-right'}>
                            <CompanyProfileValues {...props} onChangedValue={() => {
                                setVersion(version + 1)
                            }} />
                            <EmptyLine height={60} />
                            {/* <CompanyProfileStructure {...props}/> */}
                            {/* <EmptyLine height={60}/> */}
                            <CompanyProfileTechStack {...props} />
                            <EmptyLine height={60} />
                            <br />
                            <br />
                            {coverImageLoaded ? <JoinCard {...props} key={'ready'} /> : <JoinCard {...props} key={'not ready'} />}

                        </Col>
                    </Row>
                </div>
                <EmptyLine height={120} />

                <CompanyProfileNextEvent {...props} version={version} />



                <EmptyLine height={200} />
                <GotoTop />
            </div>

            <StartFooter />
        </div>
    )
}

const JobOpeningsEditing = (props) => {
    const { saveJobOpenings } = props
    const { jobOpenings } = props.profileData
    // const source = jobOpenings.length > 0 ? jobOpenings[0].source : ''
    // const sourceUrl = jobOpenings.length > 0 ? jobOpenings[0].sourceUrl : ""
    const [source, setSource] = useState(jobOpenings.length > 0 ? jobOpenings[0].source : '')
    const [sourceUrl, setSourceUrl] = useState(jobOpenings.length > 0 ? jobOpenings[0].sourceUrl : "")


    const staticSources = ['Teamtailor', 'Jobylon', 'The Hub', 'Manually', 'Import from CSV']
    const [step, setStep] = useState(1)
    const [loading, setLoading] = useState(false)
    const [dataSource, setDataSource] = useState(jobOpenings.map(jobOpening => {
        return {
            ...jobOpening,
            key: uuid()
        }
    }))

    const [current, setCurrent] = useState(null)
    const [showModal, setShowModal] = useState(false)



    const AddModal = (props) => {
        const key = current && current.uuid || props.index
        return (
            <AJModalV2
                visible={showModal}
                closable={true}
                centered={true}
                width={750}
                onClose={() => {
                    setShowModal(false)
                }}>
                <AJCard width={800}
                    height={'infinity'}
                    borderRadius={22}
                    type={'wide'}
                    hideClose={true}>
                    <div style={{ padding: '32px 32px 22px' }} key={key}>
                        <div style={{ fontSize: 22 }}>
                            Input Your Job Opening
                        </div>
                        <EmptyLine height={8} />
                        <AJInput2
                            label={'Title'}
                            id={'input-job-opening-title'}
                            defaultValue={current && current.title} />
                        <EmptyLine height={8} />
                        <AJInput2 label={'Location'} id={'input-job-opening-location'}
                            defaultValue={current && current.location} />
                        <EmptyLine height={8} />

                        <AJUrlInput label={'Link'} id={'input-job-opening-link'}
                            defaultValue={current && current.url} />
                        <EmptyLine height={16} />
                        <Row type={'flex'} align={'end'} justify={'end'}>
                            <Col>
                                <AJButton3
                                    size={'small'}
                                    title={'Save'}
                                    titleStyle={{ color: 'white' }}
                                    width={103}
                                    height={38}
                                    onClick={() => {
                                        const title = document.getElementById('input-job-opening-title').value
                                        const location = document.getElementById('input-job-opening-location').value
                                        const link = document.getElementById('input-job-opening-link').value

                                        if (!current) {
                                            dataSource.push({
                                                title,
                                                location,
                                                url: link,
                                                key: uuid()
                                                // key:
                                                // order: props.index,
                                                // source: null,
                                                // sourceUrl: null
                                            })
                                        }
                                        else {
                                            current.title = title
                                            current.location = location
                                            current.url = link
                                        }
                                        setDataSource(dataSource)
                                        // saveManuallyJobOpenings(currentJobOpenings)
                                        setShowModal(false)
                                    }} />
                            </Col>
                        </Row>
                    </div>
                </AJCard>
            </AJModalV2>
        )
    }


    const foundCheckItem = () => {
        for (const s of staticSources) {
            if (source && (s.toLowerCase() === source.toLowerCase()
                || s.replace(/ /, '').toLowerCase() === source.toLowerCase())) {
                return s
            }
        }

        return 'Manually'
    }

    const [checkedItem, setCheckItem] = useState(foundCheckItem)

    const sourceToUrl = {
        Teamtailor: "https://detectify.teamtailor.com/jobs",
        Jobylon: "https://northvolt.com/career",
        'The Hub': "https://thehub.io/startups/edai-technologies"
    }

    const sourceMapping = {
        'Teamtailor': 'teamtailor',
        'Jobylon': 'jobylon',
        'The Hub': 'thehub'
    }

    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            render: (title) => {
                return <div>{title}</div>
            },
            width: '60%',
        },
        {
            title: 'Location',
            dataIndex: 'location',
            key: 'location',
            width: '45%',
        }]

    const mColumns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            width: '55%',
        },
        {
            title: 'Location',
            dataIndex: 'location',
            key: 'location',
            width: '30%',

        },
        {
            title: '',
            dataIndex: 'operation',
            key: 'operation',
            width: '15%',

            render: (text, record) => (
                <>
                    <EditOutlined
                        onClick={() => {
                            // if (!record.uuid) {
                            //     record.uuid = uuid()
                            // }
                            setCurrent(record)
                            setShowModal(true)
                        }} />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <DeleteOutlined
                        onClick={() => {
                            setDataSource(dataSource.filter(j => j.key !== record.key))
                        }} />
                </>
            ),
        },
    ];

    const onChange = e => {
        const value = e.target.value
        // if (value === 'Manually') {
        //     setCheckItem(e.target.value)
        // }
        // else {

        if (value === 'Manually') {

            if (jobOpenings && jobOpenings.length > 0 && jobOpenings[0].source === null) {
                setDataSource(jobOpenings.map(jobOpening => {
                    return {
                        ...jobOpening,
                        key: uuid()
                    }
                }))
            } else {
                setDataSource([])
            }
        }

        setCheckItem(e.target.value)
        setStep(1)
        // }

    };

    function syncFromApi(checkedItem) {
        return checkedItem === 'Teamtailor' ||
            checkedItem === 'Jobylon' ||
            checkedItem === 'The Hub'
    }

    const [CSVData, setCSVData] = useState([])
    const [CSVImported, setCSVImported] = useState(false)
    const CSVColumns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Location',
            dataIndex: 'location',
            key: 'location',
        },
        {
            title: 'Url',
            dataIndex: 'url',
            key: 'url',
        }
    ];
    const draggerProps = {
        accept: ".csv",
        action: async (file) => {
            const reader = new FileReader();
            reader.readAsText(file, 'ISO-8859-1');
            reader.onload = function (e) {
                const text = e.target.result;
                const records = text.split('\n');
                let result = [];
                for (let record of records) {
                    if (record && record.includes('","')) {
                        let fields = record.split('","')
                        fields = fields.map(field => {
                            field = field.replace(/\r|\n/g, '').replace(/^"|"$/g, '')
                            return field
                        })
                        result.push(
                            {
                                title: fields[2].replace(/\\s*$/, ''),
                                url: fields[1].replace(/\\s*$/, ''),
                                location: fields[0].replace(/\\s*$/, '')
                            }
                        );
                    } else if (record && record.includes('http')) {
                        let parts = record.split('http')
                        const location = parts[0].replace(/\r|\n/g, '').replaceAll('"', '').trim().replace(/,(?=\s*$)/, '').trim()
                        const remainParts = parts[1].split(',')
                        const url = 'http' + remainParts[0].replace(/\r|\n/g, '').trim()
                        const title = remainParts[1].replace(/\r|\n/g, '').trim()
                        result.push(
                            {
                                title: title,
                                url: url,
                                location: location
                            }
                        );
                    }
                }
                setCSVData(result)
                setLoading(false)
                setCSVImported(true)
            };
        },
        customRequest: () => {
            // disable upload
        },
        showUploadList: false,
    };

    return <>
        <AJModalV2 visible={props.show}
            width={800}
            closable={true}
            centered={true}
            onClose={() => {
                if (props.hide)
                    props.hide()
            }}>
            <AJCard width={800}
                height={'infinity'}
                borderRadius={22}
                type={'wide'}
                hideClose={true}
            >

                <div style={{ padding: "50px 30px 20px" }}>
                    <div >
                        <Radio.Group
                            buttonStyle={'solid'}
                            options={staticSources}
                            onChange={onChange}
                            // style={radioStyle}
                            value={checkedItem}>
                        </Radio.Group>
                        {/*<div hidden={checkedItem !== 'Manually'}>*/}
                        {/*    <EmptyLine height={20}/>*/}
                        {/*    <Input*/}
                        {/*        id={`aj-job-openings-input-source`}*/}
                        {/*        placeholder={'Url to job opening(s)'}*/}
                        {/*        defaultValue={foundCheckItem() === 'Manually' ? sourceUrl : null}*/}
                        {/*    />*/}
                        {/*</div>*/}
                        {/*<EmptyLine height={checkedItem !== 'Manually' ? 20 : 10}/>*/}
                        <EmptyLine height={10} />
                        <div hidden={!syncFromApi(checkedItem)}>
                            <Input
                                id={`aj-job-openings-input-url`}
                                key={checkedItem}
                                defaultValue={foundCheckItem() === checkedItem ? sourceUrl : null}
                                placeholder={checkedItem === 'Manually' ? null : sourceToUrl[checkedItem]} />
                            <EmptyLine height={20} />
                            <div style={{ textAlign: 'right' }} hidden={step !== 1}>
                                <AJButton3
                                    size={'small'}
                                    heigh={30}
                                    width={120}
                                    title={'Next'}
                                    titleStyle={{ color: 'white' }}
                                    loading={loading}
                                    onClick={async () => {
                                        let sourceNew = checkedItem
                                        if (checkedItem === 'Manually') {
                                            sourceNew = document.getElementById(`aj-job-openings-input-source`).value
                                        }
                                        else {
                                            sourceNew = sourceMapping[sourceNew]
                                        }
                                        let sourceUrlNew = document.getElementById(`aj-job-openings-input-url`).value
                                        setLoading(true)
                                        const response = await jobExtract(sourceNew, sourceUrlNew)
                                        if (response) {
                                            setDataSource(response.data)
                                            setSource(sourceNew)
                                            setSourceUrl(sourceUrlNew)
                                        }
                                        else {
                                            setDataSource(null)
                                            setSource(sourceNew)
                                            setSourceUrl(sourceUrlNew)
                                        }
                                        setLoading(false)
                                        setStep(2)
                                    }} />
                            </div>
                        </div>
                        <div hidden={checkedItem !== 'Manually'}>
                            <EmptyLine height={20} />
                            <Table dataSource={dataSource} columns={mColumns} />
                            <EmptyLine height={20} />

                            <Row>
                                <Col span={12} style={{ textAlign: 'left' }}>
                                    <AJButton3
                                        size={'small'}
                                        type={'secondary'}
                                        heigh={30}
                                        width={120}
                                        title={'Add'}
                                        onClick={() => {
                                            setCurrent(null)
                                            setShowModal(true)
                                        }}
                                    />
                                </Col>
                                <Col span={12} style={{ textAlign: 'right' }}>
                                    <AJButton3
                                        size={'small'}
                                        heigh={30}
                                        width={120}
                                        title={'Save'}
                                        titleStyle={{ color: 'white' }}
                                        onClick={() => {
                                            if (props.hide)
                                                props.hide()

                                            if (saveJobOpenings) {
                                                dataSource ?
                                                    saveJobOpenings(
                                                        dataSource.map((jobOpening, index) => {
                                                            return {
                                                                ...jobOpening,
                                                                order: index,
                                                                source: null,
                                                                sourceUrl: null
                                                            }

                                                        })
                                                    ) : saveJobOpenings([])
                                            }
                                        }}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div hidden={step !== 2}>
                        <EmptyLine height={20} />

                        <Table dataSource={dataSource} columns={columns} />
                        <EmptyLine height={20} />

                        <Row>
                            <Col span={12} style={{ textAlign: 'left' }}>
                                <AJButton3
                                    size={'small'}
                                    heigh={30}
                                    width={120}
                                    title={'Back'}
                                    onClick={() => setStep(1)}
                                />
                            </Col>
                            <Col span={12} style={{ textAlign: 'right' }}>
                                <AJButton3
                                    size={'small'}
                                    heigh={30}
                                    width={120}
                                    title={'Save'}
                                    onClick={() => {
                                        if (props.hide)
                                            props.hide()

                                        if (saveJobOpenings) {
                                            saveJobOpenings(
                                                dataSource.map((jobOpening, index) => {
                                                    return {
                                                        ...jobOpening,
                                                        order: index,
                                                        source: source,
                                                        sourceUrl: sourceUrl
                                                    }

                                                })
                                            )
                                        }
                                    }}
                                />
                            </Col>
                        </Row>
                    </div>

                </div>
                <div hidden={checkedItem !== 'Import from CSV'}>
                    <div hidden={CSVImported} style={{ padding: '0 32px 48px 32px' }}>
                        <Dragger {...draggerProps}>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag jobopenings CSV file to this area</p>
                        </Dragger>
                    </div>
                    <div hidden={!CSVImported} style={{ padding: '0 32px 32px 32px' }}>
                        <div style={{ display: 'flex', padding: "16px 0" }}>
                            <div style={{ paddingRight: 16 }}>
                                <Button type={'primary'} onClick={() => {
                                    if (props.hide)
                                        props.hide()

                                    if (saveJobOpenings) {
                                        saveJobOpenings(
                                            CSVData.map((jobOpening, index) => {
                                                return {
                                                    ...jobOpening,
                                                    order: index,
                                                    source: 'CSV',
                                                    sourceUrl: ''
                                                }

                                            })
                                        )
                                    }
                                }}>
                                    Replace
                                </Button>
                            </div>
                            <div style={{ paddingRight: 16 }}>
                                <Button type={'primary'} onClick={() => {
                                    for (const job of dataSource) {
                                        if (!CSVData.find(j => j.url === job.url)) {
                                            CSVData.push(job);
                                        }
                                    }

                                    if (props.hide)
                                        props.hide()

                                    if (saveJobOpenings) {
                                        saveJobOpenings(
                                            CSVData.map((jobOpening, index) => {
                                                return {
                                                    ...jobOpening,
                                                    order: index,
                                                    source: 'CSV',
                                                    sourceUrl: ''
                                                }

                                            })
                                        )
                                    }
                                }}>
                                    Merge
                                </Button>
                            </div>
                            <div style={{ paddingRight: 16 }}>
                                <Button type={'danger'} onClick={() => {
                                    setCSVData([])
                                    setCSVImported(false)
                                }}>
                                    Cancel
                                </Button>
                            </div>
                        </div>
                        <Table loading={false} columns={CSVColumns} dataSource={CSVData} />
                    </div>

                </div>

            </AJCard>

        </AJModalV2>
        <AddModal />
    </>;
}


export function getOffsetTop(el) {
    return el.offsetParent
        ? el.offsetTop + getOffsetTop(el.offsetParent)
        : el.offsetTop
}

export function EditIcon(props) {
    const marginLeft = props.marginLeft || '-50px'
    const height = props.height || 32
    return (
        <div style={{ marginLeft, display: "inline-block", height }} onClick={props.onClick}>
            <img src={'/img/company-profile/edit.svg'} width={32} height={32} />
            <EmptySpan width={18} />
        </div>
    )
}
