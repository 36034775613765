import React, { useState, useContext } from 'react';
import {Row, Col} from 'antd'
import EmptySpan from "./EmptySpan";
import "./AJInput.css"

function AJInput(props) {

    let {
        defaultValue,
        label,
        icon,
        labelSpan,
        inputSpan,
        leftPadding,
        width,
        labelClass,
        inputClass,
        iconClass,
        onChange,
        onIconClick,
        separate,
        disabled,
        type
    } = props

    label = label || ''
    leftPadding = leftPadding || 0
    width = width || 300
    labelSpan = 0
    inputSpan = inputSpan || (24 - labelSpan - (icon ? 1 : 0))
    labelClass = labelClass || 'default-input-label'
    inputClass = inputClass || 'default-input'
    iconClass = iconClass || 'default-input-icon'
    separate = separate || false

    onChange = onChange || function () {}
    onIconClick = onIconClick || function () {}
    disabled = disabled || false
    type = type || ""
    return (
        <div>
            <EmptySpan width={leftPadding}/>
            <div style={{display:'inline-block'}}>
                <Row style={{width: width, display:'inline-block'}}>
                    {/*<Col span={labelSpan}>
                        <div className={labelClass}>
                            {label}
                        </div>
                    </Col>*/}
                    <Col span={inputSpan}>
                        <input className={inputClass}
                               spellCheck={false}
                               onChange={onChange}
                               disabled={disabled}
                               type={type}
                               style={props.backgroundColor ? { background: props.backgroundColor } : {}}
                               placeholder={label}/>
                    </Col>
                    {
                        icon ?
                            <Col span={1}>
                                <div className={iconClass} onClick={onIconClick}>
                                    <img src={icon}/>
                                </div>
                            </Col>
                            : null
                    }
                </Row>

                {
                    separate ? <div className={'default-input-separator'} style={{width: width}}/> : null
                }
            </div>


        </div>
    )
}

export function AJPasswordInput(props) {

    let newProps = {...props}
    newProps.type = 'password'

    return AJInput(newProps)

}

export default AJInput