import React, {useState} from "react";
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Upload, message, Row, Col } from 'antd';
import "./AJUpload.css"
import EmptyLine from "./EmptyLine";
import VCenterContainer, {HCenterContainer} from "./VCenterContainer";
import {uploadPath} from "../http/AJHttpConst";
import AJVideoPlayer from "./AJVideoPlayer";

const { Dragger } = Upload;


function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

function AJUpload(props) {
    const onClose = props.onClose || (() => {})
    const action = props.action || uploadPath

    let fileList = []
    const defaultProps  = {
        name: 'image',
        multiple: true,
        action: uploadPath,
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                // console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                fileList.push(info.file)
                console.log(fileList)

            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onRemove(file) {
            fileList = fileList.filter((f, index) => f.uid !== file.uid)
        },
        beforeUpload(file) {
            if (fileList.length === 1) {
                alert("only can upload 1 image")
                return false
            }

            return true
        }
    };

    return (
        <div className={'ajupload'}>
            <div style={{position: 'absolute', right: 16, top: 10, width: '20px', height: '20px'}}
            onClick={onClose}>
                <img src={'/img/login/close.svg'}/>
            </div>
            <div className={'ajupload-container'}>

                <Dragger {...defaultProps}>
                    <EmptyLine height={26}/>
                    <VCenterContainer>
                        <img src={'/img/ui/upload.svg'}/>
                    </VCenterContainer>
                    <EmptyLine height={12}/>
                    <div className={'ajupload-title'}>
                        Drop your image here, or <span style={{color: '#181414'}}>
                        browse
                    </span>
                    </div>
                    <EmptyLine height={7}/>
                    <div className={'ajupload-description'}>
                        Supports JPG, PNG. Dimensions 1440x400px
                    </div>
                    <EmptyLine height={7}/>

                </Dragger>
            </div>

        </div>

    )
}


// TODO
// TO Many hard codes

export function AJUploadAvatar(props) {

    let onUploaded = props.onUploaded || (() => {})
    let {types, size} = props

    types = types || ['image/jpeg', 'image/png', 'image/svg+xml']
    size = size || 2

    // function getBase64(img, callback) {
    //     const reader = new FileReader();
    //     reader.addEventListener('load', () => callback(reader.result));
    //     reader.readAsDataURL(img);
    // }

    function beforeUpload(file) {
        if (types.filter((t, index) => t === file.type).length === 0) {
            message.error(`You can only upload type in ${types} file, current type is ${file.type}!`);
            return false
        }

        if (props.beforeUpload) {
            props.beforeUpload(file)
        }

        // const isLt2M = file.size / 1024 / 1024 < size;
        // if (!isLt2M) {
        //     message.error(`Image must smaller than ${size}MB!`);
        //     return false
        // }
        return true;
    }

    const [loading, setLoading] = useState(false)
    const [imageUrl, setImageUrl] = useState(props.imageUrl)

    const handleChange = info => {
        if (info.file.status === 'uploading') {
            setLoading(true)
            // setImageUrl(null)
        }
        else if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, imageUrlNew => {
                setImageUrl(imageUrlNew)
                setLoading(false)
            });

            onUploaded(info.file)
        }
        else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
            setLoading(false)
        }
    };

    return (
        <Upload
            name="image"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={uploadPath}
            beforeUpload={beforeUpload}
            onChange={handleChange}
        >
            <div id={props.id}>
                {
                    loading ?
                        <LegacyIcon type={'loading'} style={{fontSize: 50}}/>
                        : <img src={imageUrl} alt="image" style={{ width: '215px', height:"127px" }}/>
                }

            </div>
        </Upload>
    );

}


export function AJUploadImage(props) {

    let onUploaded = props.onUploaded || (() => {})
    let {types, size} = props

    types = types || ['image/jpeg', 'image/png', 'image/svg+xml']
    size = size || 2

    // function getBase64(img, callback) {
    //     const reader = new FileReader();
    //     reader.addEventListener('load', () => callback(reader.result));
    //     reader.readAsDataURL(img);
    // }

    function beforeUpload(file) {
        if (types.filter((t, index) => t === file.type).length === 0) {
            message.error(`You can only upload type in ${types} file, current type is ${file.type}!`);
            return false
        }

        // const isLt2M = file.size / 1024 / 1024 < size;
        // if (!isLt2M) {
        //     message.error(`Image must smaller than ${size}MB!`);
        //     return false
        // }
        return true;
    }

    const [loading, setLoading] = useState(false)
    const [imageUrl, setImageUrl] = useState(props.imageUrl)

    const handleChange = info => {
        if (info.file.status === 'uploading') {
            setLoading(true)
            // setImageUrl(null)
        }
        else if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, imageUrlNew => {
                setImageUrl(imageUrlNew)
                setLoading(false)
            });

            onUploaded(info.file)
        }
        else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
            setLoading(false)
        }
    };

    return (
        <Upload
            name="image"
            listType="picture-card"
            className="image-uploader"
            showUploadList={false}
            action={uploadPath}
            beforeUpload={beforeUpload}
            onChange={handleChange}
        >
            <div id={props.id}>
                {
                    loading ?
                        <LegacyIcon type={'loading'} style={{fontSize: 50}}/>
                        : <img src={imageUrl} alt="image" style={{ width: 570, height: 375 }}/>
                }

            </div>
        </Upload>
    );

}

export function AJUpload2(props) {
    const onUploaded = props.onUploaded || (() => {})
    const onUploading = props.onUploading || (() => {})
    const beforeUpload = props.beforeUpload || (() => {})
    let {types, size, name, className, id} = props
    const action = props.action || uploadPath

    types = types || ['image/jpeg', 'image/png', 'image/svg+xml']
    size = size || 2

    function beforeUploadWrapper(file) {
        if (types.filter((t, index) => t === file.type).length === 0) {
            message.error(`You can only upload type in ${types} file, current type is ${file.type}!`);
            return false
        }
        // check size
        beforeUpload(file)
        return true;
    }

    const [loading, setLoading] = useState(false)
    const [imageUrl, setImageUrl] = useState(props.imageUrl)

    const handleChange = info => {
        if (info.file.status === 'uploading') {
            setLoading(true)
            onUploading(info)
            // setImageUrl(null)
        }
        else if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, imageUrlNew => {
                setImageUrl(imageUrlNew)
                onUploaded(info.file, imageUrlNew)
                setLoading(false)
            });


        }
        else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
            setLoading(false)
        }
    };

    // console.log(`img imageHeight: ${props.imageHeight}`)

    return (
        <Upload
            name={name || "image"}
            className={className}
            showUploadList={false}
            action={action}
            beforeUpload={beforeUploadWrapper}
            onChange={handleChange}
            disabled={props.disabled}
        >
            {
                loading ?
                        <Row type="flex" justify="center" align="middle"
                             style={{width: props.imageWidth,
                                 height: `${props.imageHeight}px`,
                                 minHeight: `${props.minHeight}px`,
                             background: props.loadingBackground}}>
                            <Col>
                                <LegacyIcon type={'loading'} style={{fontSize: props.loadingSize || props.imageWidth/8}}/>
                            </Col>
                        </Row>

                    :
                    <div style={{width: props.imageWidth,
                        height: `${props.imageHeight}px`}}>
                        <img src={imageUrl || props.placeholderImage}
                             alt="image"
                             width={'100%'}
                             height={'100%'}
                             style={{...props.customStyle}}
                             id={props.id}/>
                    </div>

            }

        </Upload>
    );
}

export function AJUpload3(props) {
    const onUploaded = props.onUploaded || (() => {})
    const onUploading = props.onUploading || (() => {})
    const beforeUpload = props.beforeUpload || (() => {})
    let {types, size, name, className, id} = props
    const action = props.action || uploadPath

    types = types || ['image/jpeg', 'image/png', 'image/svg+xml']
    size = size || 2

    function beforeUploadWrapper(file) {
        if (types.filter((t, index) => t === file.type).length === 0) {
            message.error(`You can only upload type in ${types} file, current type is ${file.type}!`);
            return false
        }
        // check size
        beforeUpload(file)
        return true;
    }

    const [loading, setLoading] = useState(false)
    const [imageUrl, setImageUrl] = useState(props.imageUrl)

    const handleChange = info => {
        if (info.file.status === 'uploading') {
            setLoading(true)
            onUploading(info)
            // setImageUrl(null)
        }
        else if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, imageUrlNew => {
                setImageUrl(imageUrlNew)
                onUploaded(info.file, imageUrlNew)
                setLoading(false)
            });


        }
        else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
            setLoading(false)
        }
    };

    // console.log(`img imageHeight: ${props.imageHeight}`)

    return (
        <Upload
            name={name || "image"}
            className={className}
            showUploadList={false}
            action={action}
            beforeUpload={beforeUploadWrapper}
            onChange={handleChange}
            disabled={props.disabled}
        >
            {
                <div style={{width: props.imageWidth, position: 'relative', minHeight: 160}}>
                    <img src={imageUrl || props.placeholderImage}
                         alt="image"
                         width={'100%'}
                         height={'100%'}
                         id={props.id} style={{opacity : loading ? 0.6 : 1}}/>
                    <div hidden={!loading} style={{position: "absolute", top: '50%', left: '50%'}}>
                        <LegacyIcon type={'loading'} style={{fontSize: props.loadingSize}}/>
                    </div>
                </div>
            }

        </Upload>
    );
}



export function AJUploadImageOrVideo(props) {

    const {readonly, imageUrl, videoUrl} = props

    let width = props.width || 180
    let height = props.height || 120

    if (videoUrl) {
        // force readonly
        return <AJVideoPlayer width={width} height={height} url={videoUrl}/>
    }
    else {
        if (readonly) {
            return <img width={width} height={height} src={imageUrl} alt="image"/>
        }
        else {
            return <AJUpload2 {...props} imageWidth={width} imageHeight={height}/>
        }
    }


}

export default AJUpload
