import React, { useState, useEffect, useRef } from 'react';

import 'antd/dist/antd.less';

import './App.less';
import { Drawer } from 'antd';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    useParams
} from "react-router-dom";
import AppProvider from "./context/AppProvider";
import Navigator from "./component/Navigator";
import Menu from "./component/Menu";
import { JoinCommunity2, StartPageWrapper } from "./page/StartPageV2";
import MatchedCompaniesWrapper from './page/MatchedCompanies';
import PageWrapper from "./page/PageWrapper";
import FilterValuesProvider from "./context/FilterValuesProvider";
import "./ui/AJFont.less";
import { getFavorite } from "./http/AJHttp";
import RegisterRoute from "./route/RegisterRoute";
import AboutPage from "./page/AboutPage";
import { currentToken, isLogged } from "./storage/AJStorage";
import CompanyProfile2Container from "./page/CompanyProfile2";
import CompanyProfile3Container from "./page/CompanyProfile3";
import CompanySavedPage from "./page/CompanySavedPage";
import NotFound404 from "./page/NotFound404";
import ScrollPageContainer from "./component/ScrollPageContainer";
import TermsConditionsPage from "./page/TermsConditionsPage";
import ReactGA from 'react-ga';
import PrivacyPage from "./page/PrivacyPage";
import InsightsPage from "./page/InsightsPage";
import BlogPage from "./page/BlogPage";
import HowItWorks from "./component/HowItWorks";
import DocumentTitle from "./component/DocumentTitle";
import HowItWorkCompany from "./page/HowItWorkCompany";
import HowItWorkTalent from "./page/HowItWorkTalent";
import ContactPage from "./page/ContactPage";
import NFTPage from "./page/NFTPage";
import { Provider } from "react-redux";
import store from './redux/store';
import OAuthCallback from "./component/OAuthCallback";
import QuestionsInInterview from "./page/toolbox/QuestionsInInterview";
import PricePage from "./page/price/PricePage";
import TechStackEditing from "./component/tech-stack/TechStackEditing";
import JobVisitors from "./page/JobVisitors";
import JobTruecaller from "./syncjobs/JobTruecaller";
import ValueSelectWrapper from "./component/value-select/valueSelect";
import SaasStart from 'saas/route/SaasStart';


ReactGA.initialize('UA-160729462-1');

const injectGA = () => {
    if (typeof window == 'undefined') {
        return;
    }
    window.dataLayer = window.dataLayer || [];
    function gtag() {
        window.dataLayer.push(arguments);
    }
    gtag('js', new Date());

    gtag('config', 'UA-160729462-1');
};

function App() {

    function AppNavigator(props) {
        const newProps = {
            ...props,
            onClickMenu: () => {
                //setMenuClass('app-menu-container-show')
                setMenuVisible(true)
                // setContainerHeight(window.innerHeight)
            }
        }

        return (<Navigator {...newProps} favorite={favoriteData} />)
    }

    const onCloseMenu = () => {
        setMenuVisible(false)
    }
    const [menuVisible, setMenuVisible] = useState(false)

    const loginBackgroundColor = 'linear-gradient(180deg, rgba(168, 94, 187, 0.54) 2.6%, rgba(143, 195, 242, 0.138) 100%)'

    const loginBackgroundImage = "url('/img/common/logon-bg.svg')"

    const [isLogon, setIsLogon] = useState(!!currentToken())

    const [favoriteData, setFavoriteData] = useState([])

    useEffect(() => {
        fetchFavorite()
    }, [])

    const fetchFavorite = async () => {
        const response = await getFavorite()
        if (response) {
            setFavoriteData(response.data)
            ReactGA.event(response)
        }

        ReactGA.pageview('/home');

        injectGA()

        return response
    }

    function CompanyProfileEdit() {
        const { id } = useParams();

        return (
            <div key={id}>
                <AppNavigator colorStyle={'white'} hideHowItWorks={true} />
                <CompanyProfile2Container refreshFavorite={fetchFavorite} />
            </div>
        );
    }

    function CompanyProfile() {
        const { id } = useParams();

        return (
            <div key={id}>
                <AppNavigator colorStyle={'black'} hideHowItWorks={true} />
                <CompanyProfile3Container refreshFavorite={fetchFavorite} />
            </div>
        );
    }

    return (
        <Provider store={store}>

            <AppProvider>
                <DocumentTitle title="Genvalues: Find Engineering teams based on your values">
                    <div className={'App-container'}>
                        <Router>
                            <ScrollPageContainer>

                                <Routes>
                                    <Route exact path='/' element={
                                        <div style={{ background: 'white' }}>
                                            <AppNavigator colorStyle={'black'} version={'2'} />
                                            <FilterValuesProvider>
                                                <StartPageWrapper />
                                            </FilterValuesProvider>
                                            <div hidden={isLogged()}>
                                                <JoinCommunity2 />
                                            </div>
                                            <HowItWorks />
                                        </div>
                                    }>
                                    </Route>
                                    <Route path="/company-profile-edit/:id" element={<CompanyProfileEdit />} />

                                    <Route path="/company-profile/:id" element={<CompanyProfile />} />

                                    <Route path='/value-select' element={
                                        <ValueSelectWrapper style={{ width: '100%', textAlign: 'center' }}></ValueSelectWrapper>
                                    } />

                                    <Route path='/company-matched' element={
                                        <div style={{ background: 'white', width: '100%' }}>
                                            <AppNavigator colorStyle={'black'} version={'2'} />
                                            <MatchedCompaniesWrapper />
                                        </div>
                                    } />

                                    <Route path='/company-saved' element={
                                        <>
                                            <AppNavigator colorStyle={'black'} version={'2'} />
                                            <div id='page-1' style={{ position: 'relative', top: 116 }}>
                                                <PageWrapper image={'/img/common/company-save.png'} mediaHeight={904} background={'#FFF'} id={'company-saved-pic'}>
                                                    <CompanySavedPage refreshFavorite={fetchFavorite} />
                                                </PageWrapper>
                                            </div>
                                        </>
                                    } />

                                    <Route path='/about' element={
                                        <>
                                            <AppNavigator colorStyle={'white'} />
                                            <div id='page-2' style={{ position: 'relative', top: 116 }}>
                                                <PageWrapper image={'/img/about/header-img.png'} background={'#FFF'} id={'about-pic'}>
                                                    <DocumentTitle title={'Who we are & why we care about engineering values'} description={'Millennials and GenZ talents want to find companies that do work that matters and share the same values.'}>
                                                        <AboutPage />
                                                    </DocumentTitle>
                                                </PageWrapper>
                                            </div>
                                        </>
                                    } />

                                    <Route path='/register/*' element={
                                        <>
                                            <AppNavigator colorStyle={'black'} version={'1'} />
                                            <PageWrapper space={130} backgroundImage={loginBackgroundImage}>
                                                <RegisterRoute login={() => setIsLogon(true)} />
                                            </PageWrapper>
                                        </>
                                    } />


                                    <Route path='/terms-conditions' element={
                                        <>
                                            <AppNavigator colorStyle={'white'} />
                                            <div id='page-4' style={{ position: 'relative', top: 116 }}>
                                                <PageWrapper space={0} background={'white'} mediaHeight={897} image={'/img/common/terms-conditions-header.png'} id={'terms-conditions-pic'}>
                                                    <TermsConditionsPage />
                                                </PageWrapper>
                                            </div>
                                        </>
                                    } />
                                    <Route path="/privacy" element={
                                        <>
                                            <AppNavigator colorStyle={'white'} />
                                            <div id="page-5" style={{ position: 'relative', top: 116 }}>
                                                <PageWrapper space={0} background={'white'} mediaHeight={897} image={'/img/common/privacy-header.png'} id={'privacy-pic'}                                                >
                                                    <PrivacyPage />
                                                </PageWrapper>
                                            </div>
                                        </>
                                    } />

                                    <Route path="/latest" element={
                                        <>
                                            <AppNavigator colorStyle={'black'} customStyle={{ borderBottom: '1px solid black' }} />
                                            <div className="blog-page" style={{ background: 'white' }}>
                                                <InsightsPage />
                                            </div>
                                        </>
                                    } />

                                    <Route path="/blog/:id" element={
                                        <>
                                            <AppNavigator colorStyle={'black'} customStyle={{ borderBottom: '1px solid black' }} />
                                            <div className="blog-page" style={{ background: 'white' }}>
                                                <BlogPage />
                                            </div>
                                        </>
                                    } />

                                    <Route path="/employer" element={
                                        <>
                                            <AppNavigator colorStyle={'white'} />
                                            <div id="page-8" style={{ position: 'relative', top: 116 }}>
                                                <PageWrapper space={0} background={'white'} mediaHeight={480} image={'/img/how-it-works/company-header.png'} id={'employer-pic'}>
                                                    <DocumentTitle title={'How tech companies can attract engineers based on their values'} description={'Genvalues company. How you can publish a profile for your tech team to attract top engineers based on team values looking for more than just a job.'}>
                                                        <HowItWorkCompany />
                                                    </DocumentTitle>
                                                </PageWrapper>
                                            </div>
                                        </>
                                    } />

                                    <Route path="/talent" element={
                                        <>
                                            <AppNavigator colorStyle={'white'} />
                                            <div id="page-9" style={{ position: 'relative', top: 116 }}>
                                                <PageWrapper space={0} background={'white'} mediaHeight={480} image={'/img/how-it-works/talent-header.png'} id={'talent-pic'}>
                                                    <DocumentTitle title={'How engineers can find jobs & teams based on their values'} description={'Genvalues talent. Engineers to find cool tech teams & jobs based on shared values at companies with a great mission.'}>
                                                        <HowItWorkTalent />
                                                    </DocumentTitle>
                                                </PageWrapper>
                                            </div>
                                        </>
                                    } />

                                    <Route path="/contact" element={
                                        <>
                                            <AppNavigator colorStyle={'white'} />
                                            <div id="page-10" style={{ position: 'relative', top: 116 }}>
                                                <PageWrapper space={0} background={'white'} mediaHeight={583} image={'/img/toolbox/page-header-v2.png'} id={'contact-pic'}>
                                                    <DocumentTitle title={'Contact Genvalues'} description={'Contact us'}>
                                                        <ContactPage />
                                                    </DocumentTitle>
                                                </PageWrapper>
                                            </div>
                                        </>
                                    } />

                                    <Route path="/nft" element={
                                        <>
                                            <AppNavigator colorStyle={'white'} />
                                            <div id="page-11" style={{ position: 'relative', top: 116 }}>
                                                <PageWrapper space={0} background={'white'} mediaHeight={480} image={'/img/common/nft-page-header.png'} id={'nft-pic'}>
                                                    <DocumentTitle title={'nft'} description={'nft'}>
                                                        <NFTPage />
                                                    </DocumentTitle>
                                                </PageWrapper>
                                            </div>
                                        </>
                                    } />

                                    <Route path="/toolbox" element={
                                        <>
                                            <AppNavigator colorStyle={'white'} />
                                            <div id="page-12" style={{ position: 'relative', top: 116 }}>
                                                <PageWrapper space={0} background={'white'} mediaHeight={583} image={'/img/toolbox/page-header-v2.png'} id={'toolbox-pic'}>
                                                    <DocumentTitle title={'toolbox'} description={'toolbox'}>
                                                        <QuestionsInInterview />
                                                    </DocumentTitle>
                                                </PageWrapper>
                                            </div>
                                        </>
                                    } />

                                    <Route path="/pricing" element={
                                        <>
                                            <AppNavigator colorStyle={'white'} />
                                            <div id="page-13" style={{ position: 'relative', top: 116 }}>
                                                <PageWrapper space={0} background={'white'} mediaHeight={596} image={'/img/price-header-v2.png'} id={'pricing-pic'}>
                                                    <DocumentTitle title={'pricing'} description={'pricing'}>
                                                        <PricePage />
                                                    </DocumentTitle>
                                                </PageWrapper>
                                            </div>
                                        </>
                                    } />

                                    <Route path="/oauth/callback" element={<OAuthCallback />} />

                                    <Route path="/test" element={<TechStackEditing />} />

                                    <Route path="/jobvisitors" element={<JobVisitors />} />

                                    <Route path="/syncjobs/truecaller" element={<JobTruecaller />} />

                                    <Route path="/saas/*" element={<SaasStart />} />


                                    <Route path={'*'} element={
                                        <>
                                            <AppNavigator colorStyle={'black'} version={'2'} />
                                            <PageWrapper space={138} background={loginBackgroundColor}>
                                                <NotFound404 />
                                            </PageWrapper>
                                        </>
                                    } />

                                </Routes>


                                <Drawer
                                    placement={'right'}
                                    closable={false}
                                    onClose={onCloseMenu}
                                    open={menuVisible}
                                    drawerStyle={{ background: "#000", overflow: 'hidden' }}
                                    width={405}>
                                    <div>
                                        <Menu onClose={onCloseMenu} isLogon={isLogon} logout={() => setIsLogon(false)} visibleChange={menuVisible} />
                                    </div>
                                </Drawer>

                            </ScrollPageContainer>

                        </Router>

                    </div>
                </DocumentTitle>
            </AppProvider>
        </Provider>
    )
}



export default App;
