import React, { createContext, useState, ReactNode } from "react";
import { SaasPickValuesProvider } from "./SaasPickValuesProvider";
import { SaasCreateAccountProvider } from "./SaasCreateAccountProvider";
import { SaasAccountProvider } from "./SaasAccountProvider";
import { getSaasUserToken } from "saas/utils/storage/SaasStorage";
import { SaasAuthProvider } from "./SaasAuthProvider";

interface SaasRegisterContextType {
    saasRegister: Record<string, any>;
    setSaasRegister: React.Dispatch<React.SetStateAction<Record<string, any>>>;
}

const SaasContext = createContext<SaasRegisterContextType | undefined>(undefined);

const SaasProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [saasRegister, setSaasRegister] = useState<Record<string, any>>({});


    return (
        <SaasContext.Provider value={{ saasRegister, setSaasRegister }}>
            <SaasCreateAccountProvider>
                <SaasAuthProvider>
                    <SaasAccountProvider>
                        <SaasPickValuesProvider>
                            {children}
                        </SaasPickValuesProvider>
                    </SaasAccountProvider>
                </SaasAuthProvider>
            </SaasCreateAccountProvider>
        </SaasContext.Provider>
    );
};

export { SaasContext, SaasProvider };