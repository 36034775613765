import React, {useEffect, useState} from "react";
import axios from "axios";
import {Button, Table} from "antd";
import CsvExportor from "csv-exportor"
import { Upload, message } from 'antd';

const { Dragger } = Upload;

export default function JobTruecaller() {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    useEffect(async () => {
        const response = await axios.get('https://api.greenhouse.io/v1/boards/truecaller/jobs?content=true')
        const remoteJobOpenings = response.data.jobs
        const jobInfo = remoteJobOpenings.filter(job => {
            return job.offices[0]?.location.match(/Stockholm/i);
        })
            .map(job => {
                return {
                    location: job.location.name?.replace(/\\s+$/, ''),
                    url: job.absolute_url,
                    title: job.title
                };
            })

        setData(jobInfo)
        setLoading(false)
    }, [])

    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Location',
            dataIndex: 'location',
            key: 'location',
        },
        {
            title: 'Url',
            dataIndex: 'url',
            key: 'url',
        }
    ];

    const csvHeaders = ['Title', 'Location', 'Url']

    return (
        <div id="jobTruecaller">
            <div style={{ width: '100%', height: '100%'}}>
                <br/><br/>
                <div id='jobTruecaller-button-container' style={{padding: '0 32px'}}>
                    <span>
                        <Button onClick={() => {
                            CsvExportor.downloadCsv(data, csvHeaders, 'jobopenings.csv')
                        }}>
                            Export as CSV
                        </Button>
                    </span>
                </div>
                <br/><br/>
                <div>
                    <Table loading={loading} columns={columns} dataSource={data} pagination={{pageSize: 100}}/>
                </div>
            </div>
        </div>
    )
}