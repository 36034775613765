import React from 'react';
import "./AJButton.css"
import VCenterContainer from "./VCenterContainer";

function AJButton(props) {

    let {containerClass,
        textClass,
        containerStyle,
        textStyle,
        onClick,
        title,
        disable,
        height,
        width,
        reverse,
        shadow,
    position} = props;
    containerClass = containerStyle? "" : (containerClass || (!reverse? 'default-button-container' : 'default-button-container-reverse'))
    textClass = textStyle ? "": (textClass || (!reverse? 'default-button-text' : 'default-button-text-reverse'))

    containerStyle = containerStyle || {}
    textStyle = textStyle || {}

    onClick = onClick || function () {};

    shadow = shadow || false

    disable = disable || false
    //    width: 303px;
    //     height: 60px;
    // height = height || '60px'
    // width = width || '303px'
    function body() {
        return (
            <div className={containerClass} onClick={onClick} style={{...containerStyle, boxShadow: shadow? '0px 10px 20px rgba(0, 0, 0, 0.15)' : ''}}>
                <VCenterContainer className={textClass} style={{...textStyle}}>
                    <div>{title}</div>
                </VCenterContainer>
            </div>
        )
    }

    return (
        <VCenterContainer>
            <div className={'button-container'}>
                { disable ? <div className={'button-container-disable'}>{body()}</div>  : body()}
            </div>

        </VCenterContainer>
    )
}

export default AJButton