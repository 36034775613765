import { Col, Form, message, Row, Typography } from "antd";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { SaasAccountContext } from "saas/context/SaasAccountProvider";
import "./CustomerRegister.less"
import CustomerRegisterForm from "saas/component/CustomerRegisterForm";
import { checkToken, customerRegister } from "saas/utils/http/SaasHttpClient";
import { SaasAccountInfo } from "saas/model/SaasCommonModel";
const { Paragraph } = Typography;

export default function CustomerRegister() {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const saasAccountContext = useContext(SaasAccountContext);
    const setCurrentAccount = saasAccountContext.setCurrentAccount;


    function onFormSubmit() {
        console.log('Customer register submit');
        form.submit();
    }


    const handleCustomerRegister = async (registerData: any) => {
        let registerResult = false;
        registerData.username = registerData.email;
        try {
            const res = await customerRegister(registerData);

            const account: SaasAccountInfo = await checkToken(res.token); // Use await to wait for the promise
            setCurrentAccount(account);
            console.log(account);
            registerResult = true;
            if (registerResult) {
                message.success('Customer registered successfully!');
                navigate('/saas/customer/select-principle');
            }
        } catch (error: any) {
            if (error.response) {
                console.log('error.response.data.message: ', error.response);
                message.error(`Error: ${error.response.data.message}`);
            } else if (error.request) {
                message.error('No response received from server');
            } else {
                message.error('Error setting up the request');
            }
            console.error('Register error:', error);
        }
    };

    return (
        <div className="saas-user-register-container">
            <Row>
                <Col span={2}></Col>
                <Col span={20}>
                    <Paragraph className="new-user-title">New user</Paragraph>
                    <Row>
                        <Col span={14}>
                            <Form form={form}>
                                <CustomerRegisterForm onFinish={handleCustomerRegister}></CustomerRegisterForm>
                            </Form>
                        </Col>
                        <Col span={10} className="saas-user-register-button">
                            <button onClick={() => onFormSubmit()}>
                                You're almost done
                            </button>
                        </Col>
                    </Row>
                </Col>
                <Col span={2}></Col>
            </Row>
        </div>
    );
}