import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Col, Row, message } from "antd";
import "./CompanyProfileHeader.less";
import EmptySpan from "../../ui/EmptySpan";
import EmptyLine from "../../ui/EmptyLine";
import AJButton3, { AJCenterButton } from "../../ui/AJButton3";
import { EditIcon } from "../../page/CompanyProfile2";
import AJEditableDiv from "../../ui/AJEditableDiv";
import AJAvatar from "../../ui/AJAvatar";
import { SocialMediaIconWithText } from "../SocialMediaIcons";
import SvgFavorite from "../../icons/Favorite";
import { AJUpload2 } from "../../ui/AJUpload";
import { AJImageUrl } from "../../http/AJHttpConst";
import AJModalV2 from "../../ui/AJModalV2";
import { addClapCount, removeFavorite, submitFavorite, updateCompanyProfile } from "../../http/AJHttp";
import { getAJNoLoginUUID, getClapCount, isLogged, saveClapCount } from "../../storage/AJStorage";
import StructureChanging from "./StructureChanging";
import AJCard from "../../ui/AJCard";
import { AJUrlInput } from "../../ui/AJInput2";
import AJPopover from "../../ui/AJPopover";
import Clap from "../../component/react-clap-button";
import Claps from "../../icons/Claps";

export default function CompanyProfileHeader(props) {
    const navigate = useNavigate();
    const location = useLocation();

    function CompanyProfileLogo(props) {
        // let onUploadedLogo = props.onUploadedLogo || (()=>{})
        const { editing, profileData } = props
        const logoUrl = profileData.companyStructure.logoUrl
        const avatarSize = 172
        const avatarPadding = 0
        const imageSize = avatarSize - avatarPadding
        return (
            <div style={{ position: "relative", width: avatarSize, height: avatarSize }}>
                <AJAvatar size={172} padding={avatarPadding}>
                    {
                        <>
                            <div hidden={!editing} style={{ width: 172, height: 172, overflow: 'hidden', borderRadius: '50%' }}>
                                <AJUpload2 imageWidth={172}
                                    imageHeight={172}
                                    imageUrl={AJImageUrl(logoUrl)}
                                    placeholderImage={'/img/common/upload-placeholder.png'}
                                    onUploaded={(file) => {
                                        const logoUrl = file.response.image
                                        profileData.companyStructure.logoUrl = logoUrl
                                    }} />
                            </div>

                            <div hidden={editing}>
                                <div style={{ width: 172, height: 172, overflow: 'hidden', borderRadius: '50%' }}>
                                    <img src={AJImageUrl(logoUrl) || '/img/common/upload-placeholder.png'} width={172} height={172} />
                                </div>
                            </div>
                        </>
                    }
                </AJAvatar>

                <div style={{ position: "absolute", bottom: 0, right: 0 }} hidden={!editing}>
                    <EditIcon />
                </div>
            </div>

        )
    }
    const [favorite, setFavorite] = useState(props.profileData.favorite)

    function checkIsSaved() {
        if (!props.favoriteData) {
            return false
        }
        if (!Array.isArray(props.favoriteData)) {
            return false
        }
        const found = props.favoriteData.filter(f => {
            return (f.company === props.profileData.id
                && (f.uuid === getAJNoLoginUUID() /*|| localStorage.getItem('local-ip') === f.ipaddress*/))
        })

        return found.length > 0
    }
    const [isSaved, setIsSaved] = useState(checkIsSaved())
    const toggleSave = async () => {
        if (isSaved) {
            let response = await removeFavorite(props.profileData.id)
            if (response) {
                let f = favorite - 1
                if (f < 0) {
                    f = 0
                }
                setFavorite(f)
                if (props.refreshFavorite) {
                    props.refreshFavorite()
                }
            }
        }
        else {
            let response = await submitFavorite(props.profileData.id)
            if (response) {
                setFavorite(favorite + 1)
                setIsSaved(true)
                if (props.refreshFavorite) {
                    props.refreshFavorite()
                }
            }
        }
    }

    function CompanyProfileIntroduction(props) {
        const [showModal, setShowModal] = useState(false)
        const [version, setVersion] = useState(0)

        const EditingModal = () => (
            <AJModalV2 visible={showModal} centered={true} width={969} onClose={() => {
                setShowModal(false)
            }}>
                <StructureChanging {...props}
                    onClose={() => { setShowModal(false) }}
                    onSave={(data) => {
                        props.profileData.name = data.name
                        props.profileData.location = data.location
                        props.profileData.website = data.website
                        props.profileData.introducation = data.introduction
                        setVersion(version + 1)
                    }} />
            </AJModalV2>
        )

        return (
            <div className={'company-profile-introduction2'}>
                <EditingModal />
                <div className={'company-profile-company-name2'}>
                    {props.editing ? <EditIcon onClick={() => setShowModal(true)} /> : null}
                    <div style={{ display: 'inline-block', width: '50%' }}>
                        <AJEditableDiv text={props.profileData.name}
                            isInput={true}
                            onChange={(e) => {
                                props.profileData.name = e.target.value
                            }} />
                    </div>
                </div>
                <EmptyLine height={17} />
                <div>
                    <div style={{ display: "inline-block", verticalAlign: 'bottom' }}>
                        <img src={'/img/company-profile/location.svg'} />
                    </div>
                    <EmptySpan width={6} />
                    <div className={'company-profile-company-addr2'}>
                        <AJEditableDiv text={props.profileData.location || 'Stockholm, Sweden'}
                            isInput={true}
                            onChange={(e) => {
                                props.profileData.location = e.target.value
                            }} />
                    </div>
                    <EmptySpan width={26} />
                </div>
                <EmptyLine height={14} />

                <div className={'company-profile-company-sum2'}>
                    <AJEditableDiv
                        disablePreLine={true}
                        text={props.profileData.introducation || 'Please input company tags here!'}
                        autoSize={{ minRows: 2, maxRows: 3 }}
                        // editable={props.editing}
                        onChange={(e) => {
                            props.profileData.introduction = e.target.value
                        }} />

                </div>
                <EmptyLine height={14} />
            </div>
        )
    }

    function CompanyProfileOperations(props) {
        const { editing, readonly, updateProfile } = props
        const [isSaving, setIsSaving] = useState(false)
        const [alertVisible, setAlertVisible] = useState(false)
        const [isPublishing, setIsPublishing] = useState(false)
        const [published, setPublished] = useState(props.profileData.published)
        const [count, setCount] = useState(props.profileData.clapTotal || 0)
        let setEditing = props.setEditing || (() => { })
        let saveEditing = props.updateProfile || (() => { })

        const Readonly = (
            <>
                <div style={{ minWidth: 400, display: "inline-block" }}>
                    <div hidden={isSaved || isLogged()} style={{ display: "inline-block" }}>
                        <div onClick={toggleSave} style={{ cursor: 'pointer', width: 'fit-content', display: "inline-block", }}>
                            <div style={{ display: "inline-block", verticalAlign: 'bottom' }} >
                                {
                                    isSaved ?
                                        <SvgFavorite fill={'#181414'}
                                            width={24}
                                            height={24}
                                            style={{ verticalAlign: 'bottom' }} />
                                        : <img src={'/img/company-profile/favorite.svg'} style={{ bottom: '4px', position: 'relative' }} />
                                }

                            </div>
                            <EmptySpan width={11} />
                            <div className={'company-profile-company-share2'}>
                                SAVE
                            </div>
                        </div>

                    </div>
                    <div style={{ cursor: 'pointer', width: 'fit-content', display: "inline-block" }}>
                        <Clap
                            count={getClapCount(props.profileData.id)}
                            countTotal={count}
                            maxCount={50}
                            isClicked={false}
                            unclicked={false}
                            onCountChange={() => {
                                setCount(count + 1);
                                saveClapCount(getClapCount(props.profileData.id) + 1, props.profileData.id)
                                addClapCount(getClapCount(props.profileData.id), getAJNoLoginUUID(), props.profileData.id)
                            }}
                            style={{ border: 'none', background: 'transparent' }}
                            iconComponent={props => <Claps {...props} style={{ marginTop: -4 }} size={38} />}
                        />
                        <div style={{ marginLeft: -6, display: "inline-block", color: '#181414', fontSize: 24, fontWeight: 600 }}>
                            {count}
                        </div>
                    </div>
                </div>

                <EmptyLine height={117} />
            </>
        )
        const renderAlert = () => {
            return (
                <div className={'company-profile-alert'}>
                    <div className={'company-profile-alert-container'}>
                        <EmptyLine height={4} />
                        <div className={'company-profile-alert-title'}>
                            Thank you!
                        </div>
                        <EmptyLine height={22} />
                        <div className={'company-profile-alert-body'}>
                            We are now reviewing your amazing values.
                            <br />
                            <br />
                            Your Profile will be public within maximum 24 hours
                        </div>
                        <br />
                        <EmptyLine height={20} />
                        <AJCenterButton title={'OK'}
                            width={274}
                            height={60}
                            onClick={() => {
                                setAlertVisible(false)
                            }} />
                    </div>
                </div>
            )
        }
        const CanEditing = (
            <>
                <EmptyLine height={40} />
                <div hidden={published}>
                    <AJButton3 title={'Publish Profile'}
                        titleStyle={{ 'color': 'white' }}
                        width={273}
                        height={60}
                        shadow={true}
                        loading={isPublishing}
                        onClick={async () => {
                            setIsPublishing(true)
                            props.profileData.published = true
                            const response = await updateCompanyProfile(props.profileData.id, props.profileData)
                            setIsPublishing(false)
                            if (response) {
                                setPublished(true)
                                setAlertVisible(true)
                            }
                            else {
                                message.error("Publish profile failed!")
                            }
                        }} />
                </div>

                <EmptyLine height={20} />
                <AJPopover content={<>To make your profile<br /> attractive, click on edit<br /> profile.</>}
                    placement={'left'} width={160} scheme={'dark'} mouseEnterDelay={0.1}>
                    <div>
                        <AJButton3 title={'Edit Profile'}
                            type={'secondary'}
                            scheme={'purple'}
                            width={273}
                            height={60}
                            shadow={true}
                            titleStyle={{ color: '#ffdc74', fontSize: '21px' }}
                            onClick={() => {
                                setEditing(true)
                            }} />
                    </div>
                </AJPopover>

                <AJModalV2 visible={alertVisible} width={338}
                    top={200} onClose={() => setAlertVisible(false)}>
                    {renderAlert()}
                </AJModalV2>
            </>
        )

        const SaveEditing = (
            <>
                <EmptyLine height={40} />
                <EmptyLine height={20} />
                <AJButton3 title={'Save and View'}
                    type={'secondary'}
                    width={273}
                    height={60}
                    titleStyle={{ fontSize: '21px' }}
                    loading={isSaving}
                    onClick={async () => {
                        setIsSaving(true)
                        const r = await saveEditing()
                        setIsSaving(false)
                        setEditing(false)
                        const currentPath = location.pathname

                        if (currentPath.includes('company-profile-edit')) {
                            const newPath = currentPath.replace('company-profile-edit', 'company-profile')
                            navigate(newPath)
                        }
                    }}
                />
            </>
        )

        const selectComponent = () => {
            if (readonly) {
                return Readonly
            }
            else if (editing) {
                return SaveEditing
            }
            else {
                return CanEditing
            }
        }

        return (
            <div className={'company-profile-operations2'}>
                {
                    selectComponent()
                }
            </div>
        )
    }

    const [showModal, setShowModal] = useState(false)
    const [editingSocial, setEditingSocial] = useState(null)

    const editSocialLink = (social) => {
        setEditingSocial(social)
        setShowModal(true)
    }
    const capitalize = (string) => {
        if (!string || string.length === 0) {
            return string
        }
        return string.charAt(0).toUpperCase() + string.slice(1)
    }
    const EditSocialLinkModal = () => {
        return (<AJModalV2
            visible={showModal}
            closable={true}
            centered={true}
            width={750}
            key={editingSocial}
            onClose={() => {
                setShowModal(false)
            }}>
            <AJCard width={750}
                height={166}
                borderRadius={22}
                type={'wide'}
                hideClose={true}>
                <Row type={'flex'} align="middle" style={{ height: '100%', padding: '0 31px' }} >
                    <Col span={24}>
                        <div className={'value-media-upload-video-title'}>
                            Update {editingSocial} link
                        </div>
                        <EmptyLine height={17} />
                        <div style={{ width: 512, display: "inline-block" }}>
                            <AJUrlInput
                                id={'aj-update-social-link-input'}
                                placeholder={`${capitalize(editingSocial)} link`}
                                defaultValue={props.profileData.socialLinks && props.profileData.socialLinks[editingSocial]}
                                onChange={(e) => {
                                    // if (onVideoChange) {
                                    //     onVideoChange(e.target.value)
                                    // }
                                }} />
                        </div>
                        <EmptySpan width={28} />
                        <div style={{ textAlign: 'right', display: "inline-block" }}>
                            <AJButton3
                                size={'small'}
                                title={'Save'}
                                width={103}
                                height={38}
                                onClick={() => {
                                    const link = document.getElementById('aj-update-social-link-input').value
                                    props.profileData.socialLinks[editingSocial] = link
                                    setShowModal(false)
                                }} />
                        </div>
                    </Col>
                </Row>
            </AJCard>
        </AJModalV2>)
    }

    return (
        <div className={'company-profile-header2'}>
            <EmptyLine height={48} />

            <Row type="flex" align={'top'}>
                <div style={{ display: 'inline-block' }}>
                    <CompanyProfileLogo {...props} />
                </div>
                <EmptySpan width={42} />
                <Row type={'flex'} align="bottom" className={'company-profile-header2-container'}>
                    <Col span={15}>
                        <CompanyProfileIntroduction {...props} />
                    </Col>
                    <Col span={4}>

                    </Col>
                    <Col span={5}>
                        <CompanyProfileOperations {...props} />
                    </Col>
                </Row>

                <div style={{ marginTop: 0 }}>
                    <EmptySpan width={172 + 44} />

                    <Row type={'flex'} align="bottom"
                        className={'company-profile-header2-container2'}
                    >
                        <Col span={15}>
                            {/*<div style={{display: "inline-block", verticalAlign: 'bottom'}} >*/}
                            {/*    <img src={'/img/company-profile/forward.svg'}/>*/}
                            {/*</div>*/}
                            {/*<EmptySpan width={2}/>*/}
                            {/*<div className={'company-profile-company-web2'}>*/}
                            {/*    <a onClick={()=>{*/}
                            {/*        if (!props.editing) {*/}
                            {/*            window.open(props.profileData.website)*/}
                            {/*        }*/}
                            {/*    }}>Visit Website</a>*/}
                            {/*</div>*/}
                        </Col>
                        <Col span={4} />

                        <Col span={5}>
                            {
                                props.editing ? <div className={'company-profile-company-icon-group'}>
                                    <SocialMediaIconWithText
                                        color={'#181414'}
                                        width={19}
                                        height={19}
                                        space={16}
                                        editing={true}
                                        socials={{
                                            ... {
                                                linkedin: '',
                                                facebook: '',
                                                twitter: '',
                                                instagram: '',
                                                medium: '',
                                                youtube: ''
                                            },
                                            ...props.profileData.socialLinks,

                                        }}
                                        onClick={(social) => {
                                            console.log(social)
                                            editSocialLink(social)
                                        }} />
                                </div>
                                    :
                                    <div className={'company-profile-company-icon-group'} hidden={!props.readonly}>
                                        <SocialMediaIconWithText
                                            color={'#181414'}
                                            width={19}
                                            height={19}
                                            space={16}
                                            socials={props.profileData.socialLinks} />
                                    </div>
                            }

                        </Col>
                    </Row>
                </div>
            </Row>
            <EditSocialLinkModal />
        </div>
    )
}



