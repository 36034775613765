import React, { useContext, useState } from 'react'
import { useDrop } from 'react-dnd'
import DndCard from './DndCard'
import update from 'immutability-helper'
import ItemTypes from './ItemTypes'
import "./DndContainer.css"
import EmptyLine from "../EmptyLine";
import EmptySpan from "../EmptySpan";
import VCenterContainer from "../VCenterContainer";
import AJButton from "../AJButton";
import { useNavigate } from "react-router-dom";
import { AJCenterButton } from "../AJButton3";
import { Col, Row } from "antd";
import PickValuesContext from "../../context/PickValuesContext";
import { reOrderDescribeValues } from "../../storage/AJStorage";
import { requirePickedValueCount } from "../../context/PickValuesProvider";
import { valuePatch } from "../../component/values/ValuesConfig";

// TODO
// Need to make it be a common component
const DndContainer = (props) => {

    // TODO make a wrapper component
    const navigate = useNavigate()

    let ITEMS = []
    const { pickedValues, setOrderedValues, orderedValues } = useContext(PickValuesContext)

    let index = 0
    if (orderedValues() && orderedValues().length === requirePickedValueCount) {
        console.log(orderedValues())
        ITEMS = [...orderedValues()]
    }
    else {
        for (const key in pickedValues()) {
            const values = pickedValues()[`${key}`]
            values.forEach((v) => {
                ITEMS.push({
                    id: index++,
                    text: v
                })
            })
        }
    }

    const [cards, setCards] = useState(ITEMS)
    // storeOrderedValues(ITEMS)
    setOrderedValues(cards)

    const moveCard = (id, atIndex) => {
        const { card, index } = findCard(id)
        const newCards = update(cards, {
            $splice: [
                [index, 1],
                [atIndex, 0, card],
            ],
        });
        setCards(newCards)
        // setOrderedValues(newCards)
        // setTimeout(() => {
        //     console.log(orderedValues)
        // }, 1000)


    }
    const findCard = id => {
        const card = cards.filter(c => `${c.id}` === id)[0]
        return {
            card,
            index: cards.indexOf(card),
        }
    }
    const [, drop] = useDrop({ accept: ItemTypes.CARD })
    return (
        <div className={'dnd-container'}>

            <EmptyLine height={97} />

            <ol ref={drop} >

                <div className={'dnd-container-context'}>
                    {cards.map((card, index) => (
                        <DndCard
                            key={card.id}
                            index={index}
                            id={`${card.id}`}
                            text={valuePatch(card.text)}
                            moveCard={moveCard}
                            findCard={findCard}
                            onEnd={() => {
                                setOrderedValues(cards)
                            }}
                        />
                    ))}
                </div>

            </ol>

            <div className={'dnd-container-button'}>
                <Row type="flex" justify="start">
                    <Col span={6}>
                        <AJCenterButton
                            title={'Back'}
                            height={60}
                            width={135}
                            type={'secondary'}
                            onClick={(event) => {
                                // event.preventDefault()
                                navigate('/register/filter-key-values')
                            }} />
                    </Col>
                    <Col span={12}>
                        <AJCenterButton
                            title={'Next'}
                            height={60}
                            width={191}
                            onClick={(event) => {
                                // event.preventDefault()
                                if (props.update) {
                                    navigate(`/register/describe-values/update/${props.companyId}`)
                                }
                                else {
                                    reOrderDescribeValues(orderedValues())
                                    //navigate("/register/describe-values")
                                    navigate("/register/describe-values/1")
                                }
                            }} />
                    </Col>

                </Row>
            </div>

        </div>
    )
}
export default DndContainer
