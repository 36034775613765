import React, { useState } from "react";
import CheckCircleOutlined from '@ant-design/icons/CheckCircleOutlined';
import { Input } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import fs from "less/lib/less-node/fs";
import EmptySpan from "./EmptySpan";
import "./AJInput2.less";


export default function AJInput2(props) {
    const placeholder = props.placeholder || props.label
    const newProps = {
        ...props,
        placeholder
    }
    return (
        <div className={'aj-input-2'}>
            <Input {...newProps} />
        </div>
    )
}

export function AJMandatoryInput(props) {
    const placeholder = props.placeholder || props.label
    const style = {
        color: props.color || '#FF3572',
        textAlign: 'right'
    }
    return (
        <div className={'aj-input-2'}>
            <Input {...props} placeholder={placeholder}
                suffix={<span style={style}>*</span>} />
        </div>
    )
}

export function AJAutoCheckInput2(props) {
    const placeholder = props.placeholder || props.label
    const style = {
        color: props.color || '#FF3572',
        textAlign: 'right'
    }

    const onChangeOrig = props.onChange || (() => { })

    const verifyFun = props.verifyFun || (() => true)
    const defaultValue = props.defaultValue

    const [showIcon, setShowIcon] = useState(defaultValue ? verifyFun(defaultValue) : false)
    const onChange = (e) => {
        const value = e.target.value
        if (value && value.length > 0 && verifyFun(value)) {
            setShowIcon(true)
            onChangeOrig(value)
        }
        else {
            setShowIcon(false)
            onChangeOrig(null)
        }
    }

    return (
        <div className={'aj-input-2'}>
            <Input {...props}
                placeholder={placeholder}
                addonAfter={<div style={{ opacity: showIcon ? 1.0 : 0 }}>
                    <CheckCircleOutlined style={{ color: 'green' }} />
                </div>}
                suffix={<>
                    {
                        props.mandatory ? <>
                            <EmptySpan width={4} />
                            <span style={style}>*</span>
                        </>
                            : <span />
                    }
                </>}
                onChange={onChange} />
        </div>
    );
}

export function AJEmailInput(props) {
    return (
        <AJAutoCheckInput2 {...props} verifyFun={(value) => {
            const pattern = props.regex || /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,10})$/;
            return pattern.test(value)
        }} />
    );
}

export function AJPhoneInput(props) {
    return (
        <AJAutoCheckInput2 {...props} verifyFun={(value) => {
            const pattern = props.regex || /^[0-9\-\+]+$/;
            return pattern.test(value)
        }} />
    );

}

///^(https?:\/\/)([0-9a-z.]+)(:[0-9]+)?([/0-9a-z.]+)?(\?[0-9a-z&=]+)?(#[0-9-a-z]+)?/i
export function AJUrlInput(props) {
    return (
        <AJAutoCheckInput2 {...props} verifyFun={(value) => {
            const pattern = props.regex || /^(https?:\/\/)([0-9a-z.]+)(:[0-9]+)?([/0-9a-z.]+)?(\?[0-9a-z&=]+)?(#[0-9-a-z]+)?/i;
            return pattern.test(value)
        }} />
    );
}

export function AJPasswordInput2(props) {
    const placeholder = props.placeholder || props.label
    const newProps = {
        ...props,
        placeholder
    }

    return (
        <div className={'aj-input-2'}>
            <Input.Password {...newProps} iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
        </div>
    )
}

export function AJCheckPasswordInput2(props) {
    const onChangeOrig = props.onChange || (() => { })
    const placeholder = props.placeholder || props.label
    const showIcon = props.showIcon || false

    return (
        <div className={'aj-input-2'}>
            <Input.Password {...props}
                placeholder={placeholder}
                addonAfter={<div style={{ opacity: showIcon ? 1.0 : 0 }}>
                    <CheckCircleOutlined style={{ color: 'green' }} />
                </div>}
                onChange={onChangeOrig} />
        </div>
    );
}
