import * as React from "react";
const AiGenerateIcon = () => (
    <svg
        width={49}
        height={49}
        viewBox="0 0 49 49"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx={24.5} cy={24.5} r={24.5} fill="black" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.6954 28.3686L15.8072 35.0817L38.2607 23.995L31.1116 20.7566L15.6954 28.3686ZM20.8119 23.6525L29.1466 19.5371L15.5938 13.3979L15.7262 21.3488L20.8119 23.6525Z"
            fill="white"
        />
    </svg>
);
export default AiGenerateIcon;