import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SaasAccountContext } from "saas/context/SaasAccountProvider";
import { customerLogin } from "saas/utils/http/SaasHttpClient";
import { message, Typography, Form } from "antd";
import { SaasAuthContext } from "saas/context/SaasAuthProvider";
import SaasStartLayout from "saas/component/SaasStartLayout";
import './CustomerLogin.less'
import CustomerLoginForm from "saas/component/CustomerLoginForm";

const { Paragraph } = Typography;

declare global {
    interface Window {
        dataLayer: any[];
    }
}

export default function CustomerLogin(props: any) {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [onLogin, setOnLogin] = useState(false)

    const saasAccountContext = useContext(SaasAccountContext);
    const setCurrentAccount = saasAccountContext.setCurrentAccount;

    const saasAuthContext = useContext(SaasAuthContext);
    const setLoginStatus = saasAuthContext.setLoginStatus;

    const login = (formValues: any) => {
        console.log('formValues: ', formValues)
        setOnLogin(true)
        customerLogin({ email: formValues.email, password: formValues.password }).then(loginResult => {

            const data = loginResult
            const token = loginResult.token
            const userId = loginResult.userId
            const isAdmin = loginResult.isAdmin
            const companyId = loginResult.companyId
            const companyName = loginResult.companyName

            setCurrentAccount(loginResult);
            setLoginStatus(true);

            message.success("Login success.")
            setTimeout(() => {
                navigate("/saas/customer/select-principle")
            }, 800);

        }).catch((error: any) => {
            if (error.response) {
                console.log('error.response.data.message: ', error.response);
                message.error(`Error: ${error.response.data.message}`);
            }
            console.error('Register error:', error);
            setLoginStatus(false);
        })
    }

    const onFormSubmit = () => {
        console.log("Customer Login submit");
        form.submit();
    }


    return (
        <SaasStartLayout>
            <div className="saas-user-login-container">
                <Paragraph className="login-header">Welcome</Paragraph>
                <Form form={form}>
                    <CustomerLoginForm onFinish={login} />
                </Form>
                <div className="saas-user-login-button">
                    <button onClick={() => { onFormSubmit() }}>
                        Login
                    </button>
                    <button onClick={() => { navigate('/saas/customer/register') }}>
                        Register
                    </button>
                </div>
            </div>

        </SaasStartLayout>
    );
}