import React from "react";
import { Route, Routes } from "react-router-dom";
import { SaasProvider } from "saas/context/SaasContext";
import SaasCustomerProfile from "saas/pages/profile/SaasProfile";
import CustomerRegister from "saas/pages/customer-register/CustomerRegister";
import CustomerLogin from "saas/pages/customer-register/CustomerLogin";
import PageWrapper from "page/PageWrapper";
import NotFound404 from "page/NotFound404";
import PrinciplesSelection from "saas/pages/select-principle/PrincipleSelection";
import Publication from "saas/pages/publication/publication";
import DomainSetup from "saas/pages/publication/DomainSetup";

export default function SaasCustomerRoute(props: any) {

    const loginBackgroundColor = 'linear-gradient(180deg, rgba(168, 94, 187, 0.54) 2.6%, rgba(143, 195, 242, 0.138) 100%)'

    return <>
        <SaasProvider>
            <Routes>
                <Route path="/" element={<CustomerLogin />} />
                <Route path="register" element={<CustomerRegister />} />
                <Route path="select-principle" element={<PrinciplesSelection />} />
                <Route path="profile" element={<SaasCustomerProfile />} />
                <Route path="publish" element={<Publication />} />
                <Route path="publish2" element={<DomainSetup />} />

                <Route path="*" element={
                    <PageWrapper space={138} background={loginBackgroundColor}>
                        <NotFound404 />
                    </PageWrapper>} />
            </Routes>
        </SaasProvider>
    </>
}