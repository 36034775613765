import { Modal, Typography, Image, Button, Input } from "antd";
import React, { useCallback, useState } from "react";
import { OverlayProps, Technology } from "saas/model/SaasCommonModel";
import SaasProfileLayout from "./SaasProfileLayout";
import OverlayLayout from "./OverlayLayout";
import "./Overlay04.less";
import EmptyLine from "ui/EmptyLine";
import TechStackEditing from "component/tech-stack/TechStackEditing";
import SaasImageUploadButton from "./SaasImageUploadButton";
const { Paragraph, Text } = Typography;
const { TextArea } = Input;

const Overlay04: React.FC<OverlayProps> = ({
    isOpen,
    profileInfo,
    onSave,
    onClose
}) => {

    const closeModal = useCallback(() => {
        onClose();
    }, [onClose]);

    const renderModalContent = () => {

        return (
            <>
                <div className="overlay-head-1">Edit Section</div>
                <EmptyLine height={20} />
                <div className="overlay-description">
                    Here You can add content and customize your page
                </div>
                <EmptyLine height={55} />

                <div className='overlay-head-2'>Add your Techstack</div>
                <EmptyLine height={7} />
                <TechStackEditing
                    selected={profileInfo.ourTechnology.technologies} text={profileInfo.ourTechnology.freeText}
                    onChange={(newTechStack: { freeText: string, selected: Technology[] }) => {
                        console.log("newTechStack", newTechStack);
                        profileInfo.ourTechnology.technologies = newTechStack.selected;
                        profileInfo.ourTechnology.freeText = newTechStack.freeText;
                        onSave(profileInfo);
                    }}
                    isSaas={true} />
                <EmptyLine height={30} />
                <div className='overlay-head-2'>Technology Content</div>
                <EmptyLine height={15} />
                <TextArea
                    className="overlay-multi-line-input"
                    value={profileInfo.ourTechnology.description}
                    rows={4}
                    onChange={(e) => {
                        const updatedOurTechnology = { ...profileInfo.ourTechnology, description: e.target.value };
                        onSave({ ...profileInfo, ourTechnology: updatedOurTechnology });
                    }} />
                <EmptyLine height={55} />

                <div className='overlay-head-2'>Benefits <span className="overlay-max-length-desc">(Max xxx characters)</span></div>
                <EmptyLine height={15} />
                <TextArea className="overlay-multi-line-input"
                    value={profileInfo.benefits.benefit}
                    rows={4}
                    onChange={(e) => {
                        const updatedBenefits = { ...profileInfo.benefits, benefit: e.target.value };
                        onSave({ ...profileInfo, benefits: updatedBenefits });
                    }} />

                <EmptyLine height={20} />
                <div className='overlay-head-2'>Add images or videos here</div>
                <EmptyLine height={3} />
                <div className='overlay-format-desc'>Format should be JPG, PNG</div>
                <EmptyLine height={20} />

                <div className='overlay-head-3'>First image/video</div>
                <EmptyLine height={15} />
                <SaasImageUploadButton
                    image={profileInfo.ourTechnology.image}
                    onImageChange={(url) => {
                        const updatedBenefits = { ...profileInfo.ourTechnology, image: url };
                        onSave({ ...profileInfo, ourTechnology: updatedBenefits });
                    }}
                />
                <EmptyLine height={25} />
                <div className='overlay-head-3'>Second image/video</div>
                <EmptyLine height={15} />
                <SaasImageUploadButton
                    image={profileInfo.benefits.image}
                    onImageChange={(url) => {
                        const updatedBenefits = { ...profileInfo.benefits, image: url };
                        onSave({ ...profileInfo, benefits: updatedBenefits });
                    }}
                />


            </>
        );
    }
    return (
        <Modal
            open={isOpen}
            width={'60%'}
            onCancel={closeModal}
            footer={null}
            className="overlay-04-modal"
        >
            <SaasProfileLayout>
                <OverlayLayout layoutWidth="70%">
                    {renderModalContent()}
                </OverlayLayout>
            </SaasProfileLayout>
        </Modal>
    );
};

export default Overlay04;