import React from 'react';
import './SaasJobCard.less';
import EmptyLine from 'ui/EmptyLine';
import { SaasJobCardProps } from 'saas/model/SaasCommonModel';
import { Button } from 'antd';

const SaasJobCard: React.FC<SaasJobCardProps> = ({ title, description, location, type, href, company, customStyle }) => {
    return (
        <div className="saas-jobcard-container">
            <div className="saas-jobcard">
                <EmptyLine height={23} />
                <div className={'saas-jobcard-title'}>{title}</div>

                <EmptyLine height={10} />
                <div className={'jobcard-description'}>
                    <div className={'jobcard-description-location'}>
                        {location}
                        <br />
                    </div>
                </div>
                <EmptyLine height={10} />
                <div className={'jobcard-button-container'}>
                    <Button className='jobcard-button'>Read more</Button>
                </div>
            </div>
        </div>
    );
};

export default SaasJobCard;