import { Form, Input } from "antd";
import React from "react";
import "./CustomerRegisterForm.less";
import { OnFinishProps } from "saas/model/SaasCommonModel";



const CustomerLoginForm: React.FC<OnFinishProps> = ({ onFinish }) => {
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    };

    const form = Form.useFormInstance();


    return (
        <Form form={form}
            className="saas-user-form"
            {...formItemLayout}
            onFinish={onFinish}
            scrollToFirstError
        >
            {/* Form Items */}

            <Form.Item
                name="email"
                rules={[
                    {
                        type: 'email',
                        message: 'The input is not valid E-mail!',
                    },
                    {
                        required: true,
                        message: 'Please input your E-mail!',
                    },
                ]}
            >
                <Input placeholder="E-mail" />
            </Form.Item>

            <Form.Item
                name="password"
                rules={[
                    {
                        required: true,
                        message: 'Please input your password!',
                    },
                ]}
                hasFeedback
            >
                <Input.Password className="saas-user-password" placeholder="Password" />
            </Form.Item>
        </Form>
    );
};

export default CustomerLoginForm;