import React, { useContext, useEffect, useState } from 'react';
import { Col, Row, Typography } from 'antd';

import './CustomerOnboarding.less';
import SaaSContainer from '../../SaaSContainer';
import { useNavigate } from 'react-router-dom';
import { SaasAuthContext } from 'saas/context/SaasAuthProvider';
import EmptyLine from 'ui/EmptyLine';

const { Paragraph } = Typography;

export default function CustomerOnboarding() {
    const [firstLine, setFirstLine] = useState('');
    const [secondLine, setSecondLine] = useState('');
    const [showFirstCursor, setShowFirstCursor] = useState(true);
    const [showSecondCursor, setShowSecondCursor] = useState(false);
    const [isTypingDone, setIsTypingDone] = useState(false);
    const [showHeading, setShowHeading] = useState(true);

    const firstText = 'Your Product Team';
    const secondText = 'Deserves to Be Famous.';
    const navigate = useNavigate();

    const saasAuthContext = useContext(SaasAuthContext);
    const loginStatus = saasAuthContext.isLoggedIn;

    useEffect(() => {
        let index = 0;
        const typingInterval = setInterval(() => {
            if (index < firstText.length) {
                setFirstLine((prev) => prev + firstText.charAt(index));
                index++;
            } else if (index === firstText.length) {
                // Hide first line cursor when first line is done
                setShowFirstCursor(false);
                // Start printing second line
                setShowSecondCursor(true);
                index++;
            } else if (index < firstText.length + secondText.length + 1) {
                setSecondLine((prev) => prev + secondText.charAt(index - firstText.length - 1));
                index++;
            } else {
                clearInterval(typingInterval);
                setIsTypingDone(true);
                // Blink second line cursor 3 times
                let blinkCount = 0;
                const blinkInterval = setInterval(() => {
                    setShowSecondCursor((prev) => !prev);
                    blinkCount++;
                    if (blinkCount === 6) {
                        clearInterval(blinkInterval);
                        setShowSecondCursor(false);
                        // setShowHeading(false);
                    }
                }, 500); // Blink speed
            }
        }, 100); // Typing speed

        return () => {
            clearInterval(typingInterval);
        };
    }, []);

    return (
        <SaaSContainer>
            <div className="customer-onboarding-container">
                <Row justify={'start'} className="customer-onboarding-heading-text fade-out" onAnimationEnd={() => setShowHeading(false)}>
                    <Col span={2}></Col>
                    <Col span={16}>
                        {showHeading && (
                            <div>
                                <div className='line-container'>
                                    <div className="line">
                                        {firstLine}
                                        {showFirstCursor && <span className="cursor">|</span>}
                                    </div>
                                </div>
                                <div className='line-container'>
                                    <div className="line">
                                        {secondLine}
                                        {showSecondCursor && <span className="cursor">|</span>}
                                    </div>
                                </div>
                            </div>
                        )}
                    </Col>
                </Row>
                <Row justify={'start'} className="customer-onboarding-subheading-text fade-in">
                    <Col span={2}></Col>
                    <Col span={16}>
                        <div>
                            Make Your Product Team as Famous as Your Product
                        </div>
                        <EmptyLine height={40} />
                        <div>
                            Build a magnetic employer brand, without the high costs of ATS
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col span={2}></Col>
                    <Col span={12}>
                        <EmptyLine height={80} />
                        <div className="customer-onboarding-subheading-text desc-text fade-in">
                            <div>No one knows how incredible your team is - and that's costing you innovators joining your team.</div>
                            <EmptyLine height={20} />
                            <div>Our AI agent showcases your Engineering culture's unique principles and the tech.</div>
                        </div>
                    </Col>
                    <Col span={8} className='customer-onboarding-buttons fade-in'>
                        <button className="schedule-button">Get my demo!</button>
                        <button className="try-button"
                            onClick={
                                () => {
                                    loginStatus ? navigate('/saas/customer/select-principle') : navigate('/saas/customer')
                                }}>Try it now</button>
                    </Col>
                    <Col span={2}>
                    </Col>
                </Row>
            </div>
        </SaaSContainer>
    );
}

