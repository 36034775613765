import React, { use, useContext, useEffect } from "react";
import { Col, Row, Typography, Image } from 'antd';
import SaasStartLayout from "saas/component/SaasStartLayout";
import "./SaasPitchingPage.less";
import EmptyLine from "ui/EmptyLine";
import { useNavigate } from "react-router-dom";
import { SaasAuthContext } from "saas/context/SaasAuthProvider";

const { Paragraph } = Typography;

export default function SaasPitchingPage() {
  const imageUrl = 'https://storage.googleapis.com/genvalues-saas/22b425cb-1c5d-4224-b84f-5e5ef5820a01.png';
  const navigate = useNavigate();
  const authContext = useContext(SaasAuthContext);
  const handleTryAction = () => {
    if (authContext.isLoggedIn) {
      navigate("/saas/customer/profile");
    } else {
      navigate("/saas/onboarding");
    }
  };


  const benefitsList = [
    "Genvalues AI turns your team into a talent magnet—without extra effort",
    "No more invisible teams. AI automatically creates a dynamic showcase of your principles, way of working, and culture",
    "No more hiring friction. Engineers self-select based on alignment—reducing mismatched applications",
    "No more expensive ATS. Keep what works, remove what doesn’t—cut unnecessary hiring costs.",
    "Stay ahead of AI-driven hiring. As AI agents begin recommending teams based on real culture, Genvalues ensures your team is seen."
  ]

  const aiMatterReasons = [
    "AI-powered branding makes your team discoverable by the engineers you want.",
    "Instead of writing employer branding content, AI generates it for you.",
    "You get ahead of the AI-driven job market shift—before your competitors do.",
    "SAO is the future of hiring. Don’t let your team stay invisible."
  ]

  return (
    <SaasStartLayout>
      <div className="pitching-page">
        <Row>
          <Col span={24}>
            <Paragraph className="pitching-page-title">Make Your Product Team Discoverable – By AI, By Engineers, By the Future.</Paragraph>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Paragraph className="pitching-page-subheading">Your Product Team deserves to be seen. AI-powered branding that showcases your culture, way of working and principles—fully embedded on your site. No ATS required. As AI agents begin matching engineers with teams, visibility will define who gets discovered.</Paragraph>
          </Col>
        </Row>
        <EmptyLine height={66} />
        <Row className="image-row">
          <Col span={16}>
            <ul className="pitching-page-content">
              {benefitsList.map((benefit, index) => (
                <li key={index}>{benefit}</li>
              ))}
            </ul>
            <EmptyLine height={40} />
            <Image src={imageUrl} className="pitching-image" preview={false} />
          </Col>
          <Col span={2}></Col>
          <Col span={6}>
            <div className="cta-buttons">
              <button className="demo">Get my demo!</button>
              <button className="try" onClick={() => handleTryAction()}>Try it now</button>
            </div>
          </Col>
        </Row>
        <EmptyLine height={66} />
        <Row>
          <Col span={10}>
            <Paragraph className="pitching-page-ai-heading">Why Genvalues AI Matters in This Shift</Paragraph>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <ul className="pitching-page-content">
              {aiMatterReasons.map((benefit, index) => (
                <li key={index}>{benefit}</li>
              ))}
            </ul>
          </Col>
        </Row>
        <EmptyLine height={370} />
      </div>
    </SaasStartLayout>
  );
}