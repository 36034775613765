import React from 'react';
import { Col, Row } from "antd";
import EmptyLine from "../ui/EmptyLine";

export function arrTrans(arr, num) {
    const iconsArr = [];
    arr.forEach((item, index) => {
        const page = Math.floor(index / num);
        if (!iconsArr[page]) {
            iconsArr[page] = [];
        }
        iconsArr[page].push(item);
    });
    return iconsArr;
}

export function formatElements(elements, countPerRow, spaceHeight, render, justify) {
    countPerRow = countPerRow || 4
    spaceHeight = spaceHeight || 48
    justify = justify || 'center'

    render = render || function (element) {
        return (
            <div>{element}</div>
        )
    }
    const elementsArray = arrTrans(elements, countPerRow)
    return (
        <div>
            {
                elementsArray.map((values, index) => {
                    return (
                        <div key={index}>
                            <Row justify={justify} >
                                {
                                    values.map((element, index2) => {
                                        return (
                                            <Col span={Math.floor(24 / countPerRow)} key={index2}>
                                                {render(element, index, index2)}
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                            <EmptyLine height={spaceHeight} />
                        </div>

                    )
                })
            }

        </div>

    )
}

export function arrayContains(array, element) {
    if (!array) {
        return false;
    }
    var i = array.length;
    while (i--) {
        if (array[i] === element) {
            return true;
        }
    }
    return false;
}

export function isArrayEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) {
        return false;
    }

    const sortedArr1 = arr1.sort();
    const sortedArr2 = arr2.sort();

    return sortedArr1.every((element, index) => element === sortedArr2[index]);
}
