import React from "react";

import "./LoginCommon.less";
import { Col, Row } from "antd";
import AJButton3 from "../../ui/AJButton3";
import { useNavigate } from 'react-router-dom'
import AJCard from "../../ui/AJCard";

export default function CompanyConfigureContainer(props) {
    let { header, body, footer, height, width, borderRadius, hideClose } = props

    height = height || 969
    width = width || 623
    borderRadius = borderRadius || 44

    hideClose = hideClose || false

    return (
        <AJCard width={height} height={width} borderRadius={borderRadius} hideClose={hideClose}>
            <div className={'login-common-container'}>
                {
                    header ?
                        <div className={'text-font login-common-title'}>
                            {header}
                        </div>
                        : null
                }
                <div className={'login-common-container-body'}>
                    {body}
                </div>

                <div className={'login-common-container-footer'}>
                    {footer}
                </div>
            </div>
        </AJCard>
    )
}

function _FooterButtons(props) {
    let navigate = useNavigate();

    const backClick = props.onBack || (() => {
        navigate(-1)
    })

    const nextClick = props.onNext || (() => { })
    const nextName = props.nextName || 'Next'
    const nextType = props.nextType || 'primary'
    return (
        <Row>
            <Col span={7}>
                <div>
                    <AJButton3
                        title={props.backName || 'Back'}
                        titleStyle={props.titleStyle || {}}
                        type={'secondary'}
                        height={60}
                        width={135}
                        onClick={backClick}
                        disabled={props.disabledBack}
                    />
                </div>
            </Col>

            <Col span={12}>
                <div hidden={props.hiddenNext}>
                    <AJButton3
                        title={nextName}
                        titleStyle={{ ...props.titleStyle, color: 'white' } || {}}
                        type={nextType}
                        height={60}
                        width={props.nextWidth || 304}
                        onClick={nextClick}
                        disabled={props.disabledNext}
                        loading={props.loadingNext}
                    />
                </div>
            </Col>
        </Row>
    )
}

export function FooterButtons(props) {
    return (
        <Row style={{ display: "absolute", left: 0, bottom: 0 }}>
            <Col span={18}>
                {_FooterButtons(props)}
            </Col>
        </Row>
    )
}

export function FooterButtonsWithOK(props) {
    let onOK = props.onOK || (() => { })

    return (
        <Row>
            <Col span={18}>
                {_FooterButtons(props)}
            </Col>
            <Col span={6}>
                <Row justify="end">
                    <Col>
                        <AJButton3
                            title={props.OKName || 'OK'}
                            height={60}
                            width={186}
                            disabled={props.disabledOK}
                            loading={props.loadingOK}
                            onClick={onOK} />
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}