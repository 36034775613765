import { CheckOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import React from 'react';
import { OnFinishProps } from 'saas/model/SaasCommonModel';

interface AnimatedCheckmarkProps extends OnFinishProps {
    showImHappy: boolean;
}

const AnimatedCheckmark: React.FC<AnimatedCheckmarkProps> = ({ onFinish, showImHappy }) => {
    // const [clicked, setClicked] = useState(showImHappy);
    const [hovered, setHovered] = useState(false);

    // useEffect(() => {
    //     console.log("showImHappy in effect: ", showImHappy);
    //     setClicked(!showImHappy)
    // }, [showImHappy]);


    return (
        <button
            className='animated-checkmark'
            onClick={() => {
                // setClicked(true)
                onFinish();
                // setTimeout(() => {
                // onFinish();
                // }, 500);
            }}
            style={{
                fontSize: '24px',
                transition: 'transform 0.3s ease-in-out, color 0.3s ease-in-out',
                transform: !showImHappy ? 'scale(1.2)' : hovered ? 'scale(1.1)' : 'scale(1)',
                // color: hovered ? 'blue' : 'black',
            }}
        >
            {showImHappy ? "I'm Happy!" : <CheckOutlined style={{ color: 'black' }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />}
        </button>
    );
};

export default AnimatedCheckmark;