import React from "react";
import { Props } from "saas/model/SaasCommonModel";

interface OverlayLayoutProps extends Props {
    layoutWidth: string;
}
const OverlayLayout: React.FC<OverlayLayoutProps> = (props) => {
    return (
        <div className="overlay-layout" style={{ display: "flex", width: "100%" }}>
            <div style={{ flex: `0 0 ${props.layoutWidth}` }}>
                {props.children}
            </div>
        </div>
    );
};

export default OverlayLayout;
