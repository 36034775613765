import React, { useContext, useState } from "react";
import { Row, Col, Input, message } from "antd";

import "./DescribeValues.less";
import "./LoginCommon.less";
import EmptyLine from "../../ui/EmptyLine";
import CompanyConfigureContainer, { FooterButtons } from "./CompanyConfigureContainer";
import AJButton3 from "../../ui/AJButton3";
import { useNavigate } from "react-router-dom";
import PickValuesContext from "../../context/PickValuesContext";
import DescribeValuesContext from "../../context/DescribeValuesContext";
import fs from "less/lib/less-node/fs";
import { requirePickedValueCount } from "../../context/PickValuesProvider";
import { companyProfile, updateCompanyProfile } from "../../http/AJHttp";
import { currentCompanyName, currentUserName, saveCompanyRegisterData } from "../../storage/AJStorage";
import CompanyStructureContext from "../../context/CompanyStructureContext";
import JobOpeningsContext from "../../context/JobOpeningsContext";
import SocialLinksContext from "../../context/SocialLinksContext";
import CreateAccountContext from "../../context/CreateAccountContext";
import AppContext from "../../context/AppContext";
import queryString from 'query-string';
import ValueMediaUpload from "../ValueMediaUpload";
import CompanyRegisterTemplate from "./CompanyRegisterTemplate";
import { useParams } from "react-router-dom";
import { AJClickPopover } from "../../ui/AJPopover";
import { findValueConfig } from "../values/ValuesConfig";

const { TextArea } = Input;

export function UpdateDescribeValuesContainer(props) {
    // let valueIndex = parseInt(props.match.params.id)
    let companyId = parseInt(props.match.params.companyId)

    const [isLoading, setIsLoading] = useState(true)
    const [savedCompanyProfile, setSavedCompanyProfile] = useState(null)
    let newProps = {
        ...props,

    }
    console.log(`${companyId}`)

    if (isLoading) {
        companyProfile(companyId).then(response => {
            if (response) {
                setIsLoading(false)
                setSavedCompanyProfile(response.data)
                newProps = {
                    ...props,
                    update: true,
                    companyId: companyId,
                    savedDescribeValues: response.data.describeValues,
                }
            }
            // console.log(response.data)
        }).catch(e => {
            message.error("load company profile failed!")
        })
    }




    return (<>
        {
            isLoading ? <h1>Loading...</h1>
                : <DescribeValuesContainer {...props}
                    savedCompanyProfile={savedCompanyProfile}
                    savedDescribeValues={savedCompanyProfile && savedCompanyProfile.describeValues}
                    update={true}
                    companyId={companyId}
                />
        }
    </>)
}

export default function DescribeValuesContainer(props) {
    const navigate = useNavigate()


    const queryParams = queryString.parse(props.location.search)
    const startIndex = parseInt(queryParams.start)


    // let valueIndex = parseInt(props.match.params.id) || props.valueIndex
    const [valueIndex, setValueIndex] = useState(queryParams.start ? startIndex : 1)
    const savedDescribeValues = props.savedDescribeValues
    const savedCompanyProfile = props.savedCompanyProfile
    const { update, companyId } = props

    // console.log(`value index : ${valueIndex}`)
    // console.log(savedDescribeValues)

    if (valueIndex < 1 || valueIndex > 9) {
        setValueIndex(1)
    }
    const { pickedValues, orderedValues } = useContext(PickValuesContext)

    // TODO

    // let orderedValuesCopy;

    const currentOrderedValues = orderedValues()

    if (!currentOrderedValues || currentOrderedValues.length !== requirePickedValueCount) {
        // message.error("Miss values!")
        navigate('/')
        return null
    }

    const currentValue = currentOrderedValues[valueIndex - 1]
    // console.log(`value index : ${currentValue}`)


    return (
        <div>
            {
                currentOrderedValues.map((currentValue, index) => {
                    // console.log(currentValue)
                    return (
                        <div hidden={index + 1 !== valueIndex}>
                            <DescribeValues
                                currentValue={currentValue}
                                valueIndex={index + 1}
                                savedDescribeValues={props.savedDescribeValues} />

                        </div>)
                })

            }
        </div>
    )

    function DescribeValues(props) {
        const navigate = useNavigate()



        // console.log(savedDescribeValues)

        const { describeValues, updateDescribeValues } = useContext(DescribeValuesContext)

        const { pickedValues, orderedValues } = useContext(PickValuesContext)
        const { companyStructure, technologies } = useContext(CompanyStructureContext)
        const { jobOpenings } = useContext(JobOpeningsContext)
        const { socialLinks } = useContext(SocialLinksContext)
        const { accountInfo } = useContext(CreateAccountContext)
        const { currentAccount } = useContext(AppContext)
        const { jobOpeningsSource, careerPageUrl } = useContext(JobOpeningsContext)
        // const [currentValueDesc, setCurrentValueDesc] = useState(getCurrentValueDesc())

        // TODO
        const currentValue = props.currentValue
        const valueIndex = props.valueIndex
        // console.log(currentValue)
        // console.log(valueIndex)
        // let currentText = ''

        // let valueIndex = parseInt(props.match.params.id)

        const header = 'Describe your values'
        const describeValuesData = describeValues()
        const { savedDescribeValues } = props
        // console.log(describeValuesData)


        // console.log(describeValuesData)

        const currentValueDesc = //savedDescribeValues ? savedDescribeValues[valueIndex] :
            (describeValuesData[valueIndex] || {})

        const getSavedValueDescText = () => {
            const value = currentValue.text
            for (const index in savedDescribeValues) {
                if (savedDescribeValues[index].value === value) {
                    return savedDescribeValues[index].text
                }
            }

            return null
        }

        const getSavedValueDescImage = () => {
            const value = currentValue.text
            for (const index in savedDescribeValues) {
                if (savedDescribeValues[index].value === value) {
                    return savedDescribeValues[index].image
                }
            }

            return null
        }

        const getSavedValueDescVideo = () => {
            const value = currentValue.text
            for (const index in savedDescribeValues) {
                if (savedDescribeValues[index].value === value) {
                    return savedDescribeValues[index].video
                }
            }

            return null
        }

        let currentText = currentValueDesc.text || getSavedValueDescText()
        let defaultImage = "/img/login/upload-value-desc.png"

        let currentImage = currentValueDesc.image || getSavedValueDescImage()
        let currentVideo = currentValueDesc.video || getSavedValueDescVideo()

        // const [currentImage, setCurrentImage] = useState(currentValueDesc.image || getSavedValueDescImage())
        // const [currentVideo, setCurrentVideo] = useState(currentValueDesc.video || getSavedValueDescVideo())

        // currentImage = currentImage ? AJImageUrl(currentImage) : '' //TODO for common

        const [uploadVisible, setUploadVisible] = useState(false)
        const [linkVideoVisible, setLinkVideoVisible] = useState(false)



        if (!describeValuesData || describeValuesData.length !== requirePickedValueCount) {
            // message.error("Miss values!")
            // navigate('/register/filter-key-values')
            // return
        }

        const body = (
            <div className={'describe-value'}>
                <Row>
                    <Col span={15} className={'describe-value-container-left'}>
                        <div className={'describe-value-font-3'}>
                            <br />
                            Define your values in an authentic way. It is attractive.
                        </div>
                        <br />
                        <Row type="flex" align="middle" justify={'space-between'}>
                            <Col>
                                <div className={'describe-value-font-2'} >
                                    {`${valueIndex}. ${currentValue.text}`}
                                </div>
                            </Col>
                            <Col>
                                {/*<AJButton3 size={'mini'} title={'See examples'} type={'secondary'}/>*/}
                                <a style={{
                                    color: '#A0A0A0'
                                }}>
                                    <u></u>
                                </a>
                            </Col>
                        </Row>



                        <EmptyLine height={12} />
                        <TextArea
                            placeholder="We recommend a text length of around 200 words for each value."
                            autoSize={{ minRows: 10, maxRows: 10 }}
                            defaultValue={currentText}
                            onChange={(event => {
                                currentText = event.target.value
                                updateDescribeValues(valueIndex, {
                                    value: currentValue.text,
                                    text: currentText,
                                    image: currentImage,
                                    video: currentVideo,
                                })
                            })}
                        />
                        <EmptyLine height={22} />
                    </Col>
                    <Col span={2} />
                    <Col span={7} className={'describe-value-container-right'}>
                        <EmptyLine height={60} />
                        <div className={'describe-value-font-4'}>
                            Please upload a video or a image<br /> that are prefarable related to this<br /> specific value.
                        </div>
                        <br />
                        {/*<img src={currentImage ? AJImageUrl(currentImage) : '/img/login/upload-value-desc.png'} height={120} width={200}/>*/}
                        {
                            <>
                                <ValueMediaUpload
                                    readonly={false}
                                    value={currentValueDesc}
                                    height={127}
                                    width={215}
                                    placeholderImage={defaultImage}
                                    onVideoChange={() => { }}
                                    onVideoSave={(video) => {
                                        currentVideo = video
                                        currentImage = null
                                        updateDescribeValues(valueIndex, {
                                            value: currentValue.text,
                                            text: currentText,
                                            image: null,
                                            video: video,
                                        })

                                    }}
                                    onImageChange={(image) => {
                                        currentImage = image
                                        currentVideo = null
                                        updateDescribeValues(valueIndex, {
                                            value: currentValue.text,
                                            text: currentText,
                                            image: image,
                                            video: null,

                                        })
                                    }
                                    }
                                    footer={
                                        ({ openUploadingImage, showLinkVideoModel }) =>
                                            <>
                                                <div style={{ width: 127 }}>
                                                    <AJButton3 size={'mini'}
                                                        title={'Upload image'}
                                                        type={'secondary'}
                                                        onClick={() => {
                                                            openUploadingImage()
                                                        }} />
                                                </div>
                                                <EmptyLine height={12} />
                                                <div style={{ width: 127 }}>
                                                    <AJButton3 size={'mini'}
                                                        title={'Link video'}
                                                        width={127}
                                                        type={'secondary'}
                                                        onClick={() => {
                                                            showLinkVideoModel(true)
                                                        }} />
                                                </div>
                                            </>
                                    }

                                />
                                {/*<div hidden={!currentVideo}>*/}
                                {/*    <AJVideoPlayer height={127} width={215} url={currentVideo}/>*/}
                                {/*</div>*/}
                                {/*<div hidden={currentVideo}>*/}
                                {/*    <AJUploadAvatar*/}
                                {/*        imageUrl={currentImage ? AJImageUrl(currentImage) : defaultImage}*/}
                                {/*        id={`upload-image-${valueIndex}`}*/}
                                {/*        beforeUpload={() => {*/}
                                {/*            // TODO*/}
                                {/*            // restore video link*/}
                                {/*            setCurrentVideo(null)*/}
                                {/*        }}*/}
                                {/*        onUploaded={(file) => {*/}
                                {/*            console.log(file)*/}
                                {/*            //currentVideo = null*/}
                                {/*            setCurrentVideo(null)*/}
                                {/*            setCurrentImage(file.response.image)*/}
                                {/*/!*            // updateDescribeValues(valueIndex, {*!/*/}
                                {/*            //     value: currentValue.text,*/}
                                {/*            //     text : currentText,*/}
                                {/*            //     image : currentImage,*/}
                                {/*            //     video: currentVideo,*/}
                                {/*            })*/}
                                {/*        }}/>*/}
                                {/*</div>*/}
                            </>


                        }

                        <br />
                        {/*<div style={{width: 127}}>*/}
                        {/*    <AJButton3 size={'mini'}*/}
                        {/*               title={'Upload image'}*/}
                        {/*               type={'secondary'}*/}
                        {/*               onClick={() => {*/}
                        {/*                   //setUploadVisible(true)*/}
                        {/*                   const element = document.getElementById(`upload-image-${valueIndex}`)*/}
                        {/*                   if (element)*/}
                        {/*                       element.click()*/}
                        {/*                   // console.log(imageUploadRef.current)*/}
                        {/*                   // imageUploadRef.current.click()*/}
                        {/*               }}/>*/}
                        {/*</div>*/}
                        {/*<EmptyLine height={12}/>*/}
                        {/*<div style={{width: 127}}>*/}
                        {/*    <AJButton3 size={'mini'}*/}
                        {/*               title={'Link video'}*/}
                        {/*               width={127}*/}
                        {/*               type={'secondary'}*/}
                        {/*               onClick={() => {*/}
                        {/*                   setLinkVideoVisible(true)*/}
                        {/*               }}/>*/}
                        {/*</div>*/}

                        <EmptyLine height={32} />
                        <div className={'describe-value-font-4'} style={{ color: '#181414' }}>
                            To change the picture just<br /> upload again.
                        </div>
                    </Col>
                </Row>
                <div className={'describe-value-footer'}>
                    {`${valueIndex}/${9}`}
                </div>

                {/*{*/}
                {/*    UploadImageEml*/}
                {/*}*/}
                {/*{*/}
                {/*    LinkVideoEml()*/}
                {/*}*/}
            </div>
        )

        const [loadingNext, setLoadingNext] = useState(false)

        const footer = <FooterButtons
            nextName={(update && valueIndex === requirePickedValueCount) ? 'Save' : 'Next'}
            nextLoading={loadingNext}
            onNext={() => {
                const nextIndex = valueIndex + 1
                // console.log(`next index ${nextIndex}`)
                updateDescribeValues(valueIndex, {
                    value: currentValue.text,
                    text: currentText,
                    image: currentImage,
                    video: currentVideo,
                })
                if (nextIndex <= requirePickedValueCount) {
                    //navigate(`/register/describe-values/${nextIndex}`)
                    setValueIndex(nextIndex)
                }
                else {
                    if (update) {
                        const getCompanyRegister = () => {
                            savedCompanyProfile.describeValues = describeValues()
                            savedCompanyProfile.orderedValues = orderedValues()
                            savedCompanyProfile.pickedValues = pickedValues()
                            return savedCompanyProfile
                        }
                        setLoadingNext(true)
                        updateCompanyProfile(companyId, getCompanyRegister())
                            .then(response => {
                                saveCompanyRegisterData(null)
                                let companyName = currentCompanyName() || currentUserName() || ''
                                navigate(`/company-profile/${companyId}`)
                                setLoadingNext(false)
                            })
                            .catch(e => {
                                message.error('Save failed!')
                            })
                    }
                    else {
                        navigate(`/register/sync-job-openings`)
                    }

                }
            }}
            onBack={() => {
                const nextIndex = valueIndex - 1
                // console.log(`next index ${nextIndex}`)
                updateDescribeValues(valueIndex, {
                    value: currentValue.text,
                    text: currentText,
                    image: currentImage,
                    video: currentVideo,
                })
                if (nextIndex > 0) {
                    //navigate(`/register/describe-values/${nextIndex}`)
                    setValueIndex(nextIndex)
                }
                else {
                    navigate(-1)
                }
            }
            } />

        return (
            <div hidden={props.hidden}>
                <CompanyRegisterTemplate tips={'People want to be a part of something bigger than themselves'}>
                    <CompanyConfigureContainer header={header} body={body} footer={footer} hideClose={true} />
                </CompanyRegisterTemplate>
            </div>

        )
    }
}


export function CompanyDescribeValue(props) {
    const navigate = useNavigate()

    let { id } = useParams();

    // const queryParams = queryString.parse(props.location.search)
    const valueIndex = parseInt(id)
    const { pickedValues, orderedValues, totalValueCount } = useContext(PickValuesContext)
    const { describeValues, updateDescribeValues } = useContext(DescribeValuesContext)
    const [loadingNext, setLoadingNext] = useState(false)
    const [popupVisible, setPopupVisible] = useState(false)

    const describeValuesData = describeValues()
    // console.log(`${id} ${totalValueCount()}`)

    if (valueIndex < 1 || valueIndex > requirePickedValueCount) {
        message.error('Wrong value index, should be in [1,9]!')
        navigate('/register/filter-key-values')
        return null
    }

    if (totalValueCount() !== requirePickedValueCount) {
        message.error(`Wrong value number: ${totalValueCount()}, should be in ${requirePickedValueCount}!`)

        navigate('/register/filter-key-values')
        return null
    }



    const currentValue = orderedValues()[valueIndex - 1]
    // console.log(valueIndex)
    const currentValueConfig = findValueConfig(currentValue.text) || { questions: 'No Supported Questions' }

    const currentValueDesc = describeValuesData[valueIndex] || {}
    let currentText = currentValueDesc.text
    let defaultImage = "/img/login/upload-value-desc.png"

    let currentImage = currentValueDesc.image
    let currentVideo = currentValueDesc.video

    const header = 'Describe your values'

    const body = (
        <div className={'describe-value'}>
            <Row>
                <Col span={15} className={'describe-value-container-left'}>
                    <div className={'describe-value-font-3'}>
                        <br />
                        Define your values in an authentic way. It is<br /> attractive.
                    </div>
                    <br />
                    <Row type="flex" align="middle" justify={'space-between'}>
                        <Col>
                            <div className={'describe-value-font-2'} >
                                {`${valueIndex}. ${currentValue.text}`}
                            </div>
                        </Col>
                        <Col>
                            <AJClickPopover
                                content={<SupportQuestions
                                    questions={currentValueConfig.questions}
                                    onClose={() => setPopupVisible(false)}
                                />}
                                scheme={'dark-blue'}
                                placement={'right'}
                                visible={popupVisible}
                                onVisibleChange={(visible) => {
                                    setPopupVisible(visible)
                                }}>
                                <div style={{
                                    cursor: 'pointer'
                                }} onClick={() => setPopupVisible(!popupVisible)}>
                                    <span style={{ fontSize: 12, fontWeight: 600, color: '#6A35FF' }}><u>Help me write</u></span>
                                    &nbsp;
                                    <svg style={{ verticalAlign: 'middle' }} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM11 15H9V13H11V15ZM11 11H9V5H11V11Z" fill="#6A35FF" />
                                    </svg>
                                </div>
                            </AJClickPopover>

                        </Col>
                    </Row>



                    <EmptyLine height={12} />
                    <TextArea
                        placeholder="Here, you are talking to the talent about how your team practice this value. Give concrete examples of how it reflects the day-to-day work. Mention colleagues or CEO:s on how they practice it.
"
                        autoSize={{ minRows: 10, maxRows: 10 }}
                        defaultValue={currentText}
                        onChange={(event => {
                            currentText = event.target.value
                            updateDescribeValues(valueIndex, {
                                value: currentValue.text,
                                text: currentText,
                                image: currentImage,
                                video: currentVideo,
                            })
                        })}
                    />
                    <EmptyLine height={22} />
                </Col>
                <Col span={2} />
                <Col span={7} className={'describe-value-container-right'}>
                    <EmptyLine height={60} />
                    <div className={'describe-value-font-4'}>
                        Please upload a video or a image<br /> that are prefarable related to this<br /> specific value.
                    </div>
                    <br />
                    {
                        <>
                            <ValueMediaUpload
                                readonly={false}
                                value={currentValueDesc}
                                height={127}
                                width={215}
                                placeholderImage={defaultImage}
                                onVideoChange={() => { }}
                                onVideoSave={(video) => {
                                    currentVideo = video
                                    currentImage = null
                                    updateDescribeValues(valueIndex, {
                                        value: currentValue.text,
                                        text: currentText,
                                        image: null,
                                        video: video,
                                    })

                                }}
                                onImageChange={(image) => {
                                    currentImage = image
                                    currentVideo = null
                                    updateDescribeValues(valueIndex, {
                                        value: currentValue.text,
                                        text: currentText,
                                        image: image,
                                        video: null,

                                    })
                                }
                                }
                                footer={
                                    ({ openUploadingImage, showLinkVideoModel }) =>
                                        <>
                                            <div style={{ width: 127 }}>
                                                <AJButton3 size={'mini'}
                                                    title={'Upload image'}
                                                    type={'secondary'}
                                                    onClick={() => {
                                                        openUploadingImage()
                                                    }} />
                                            </div>
                                            <EmptyLine height={12} />
                                            <div style={{ width: 127 }}>
                                                <AJButton3 size={'mini'}
                                                    title={'Link video'}
                                                    width={127}
                                                    type={'secondary'}
                                                    onClick={() => {
                                                        showLinkVideoModel(true)
                                                    }} />
                                            </div>
                                        </>
                                }

                            />
                        </>


                    }

                    <br />

                    <EmptyLine height={32} />
                    <div className={'describe-value-font-4'} style={{ color: '#181414' }}>
                        To change the picture just<br /> upload again.
                    </div>
                </Col>
            </Row>
            <div className={'describe-value-footer'}>
                {`${valueIndex}/${9}`}
            </div>
        </div>
    )

    const footer = <FooterButtons
        // nextName={'Next'}
        nextLoading={loadingNext}
        onNext={() => {
            const nextIndex = valueIndex + 1
            // console.log(`next index ${nextIndex}`)
            updateDescribeValues(valueIndex, {
                value: currentValue.text,
                text: currentText,
                image: currentImage,
                video: currentVideo,
            })
            if (nextIndex <= requirePickedValueCount) {
                navigate(`/register/describe-values/${nextIndex}`)
            }
            else {
                navigate(`/register/sync-job-openings`)
            }
        }}
        onBack={() => {
            const nextIndex = valueIndex - 1
            // console.log(`next index ${nextIndex}`)
            updateDescribeValues(valueIndex, {
                value: currentValue.text,
                text: currentText,
                image: currentImage,
                video: currentVideo,
            })

            // const nextIndex = valueIndex + 1

            navigate(-1)

        }
        } />

    return (
        <div key={valueIndex}>
            <CompanyRegisterTemplate tips={'People want to be a part of something bigger than themselves'}>
                <CompanyConfigureContainer header={header} body={body} footer={footer} hideClose={true} />
            </CompanyRegisterTemplate>
        </div>
    )

}

function SupportQuestions(props) {

    const questions = props.questions || <>No Supported Questions</>

    //     || <>
    //     1. How is ‘inclusion & belonging’ defined in your organization? <br/><br/>
    //     2. What is the existing makeup of your workforce? What is the distribution of women and underrepresented groups across levels and functions?<br/><br/>
    //     3. How do you measure and evaluate the impact of diversity and inclusion on business performance?<br/><br/>
    //     4. How are the inclusion and belonging strategy supported in your organization? (e.g., accountability, percentage of the overall budget allocated)<br/><br/>
    //     5. How do top leaders and managers set the tone?<br/><br/>
    //     6. What are your current organizational priorities for inclusion & belonging?<br/><br/>
    //     7. How do you communicate your commitment to diversity and inclusion internally and externally?<br/><br/>
    //     8. What kind of flexible workplace policies and practices do you have to accommodate the needs of your diverse workforce?<br/><br/>
    //     9. What resources and support you provide to employees to develop their knowledge and skills to work effectively within a diverse environment?<br/><br/>
    //     10. How do your organization’s hiring and selection practices reflect inclusion and belonging values? <br/><br/>
    //     11. How are inclusion practices embedded into employee’s everyday responsibilities?<br/><br/>
    //     12. How have you integrated diversity into your succession planning?<br/><br/>
    //     13. What is employee involvement in developing inclusion and belonging strategies/initiatives in the organization?<br/><br/>
    //     14. What kind of formal developmental programs (e.g., mentoring, coaching) are in place to engage underrepresented groups?<br/><br/>
    //     15. How do you try to reach pay equity for employees of all genders and races performing similar work?<br/><br/>
    //     16. What do you see "on the horizon" for inclusion and belonging in your organization?<br/><br/>
    // </>

    return (
        <div className={'support-questions-container'}>
            <div className={'support-questions-close'}>
                <img src={'/img/login/close.svg'} width={'100%'} height={'100%'} onClick={props.onClose} />
            </div>
            <div className={'support-questions-title'}>
                Support questions
            </div>
            <br />
            <div className={'support-questions-body'}>
                {questions}
            </div>
        </div>
    )
}


