import React from 'react';

export default function SparkIcon({ fill = 'white' }: { fill?: string }) {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_12235_7504" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="40" height="40">
                <rect width="40" height="40" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_12235_7504)">
                <path d="M22.5002 22.9487C22.5002 20.6989 23.2947 18.7798 24.8835 17.1912C26.4722 15.6023 28.3913 14.8078 30.641 14.8078C28.3913 14.8078 26.4722 14.0134 24.8835 12.4245C23.2947 10.8359 22.5002 8.91671 22.5002 6.66699C22.5002 8.91671 21.7058 10.8359 20.1169 12.4245C18.5283 14.0134 16.6091 14.8078 14.3594 14.8078C16.6091 14.8078 18.5283 15.6023 20.1169 17.1912C21.7058 18.7798 22.5002 20.6989 22.5002 22.9487Z" fill={fill} />
                <path d="M15.416 33.9749C15.416 32.2477 16.0259 29.1077 17.2458 27.888C18.4654 26.6682 19.1055 26.0583 20.8327 26.0583C19.1055 26.0583 18.4654 25.4483 17.2458 24.2285C16.0259 23.0089 15.416 19.8688 15.416 18.1416C15.416 19.8688 14.8061 23.0089 13.5862 24.2285C12.3666 25.4483 10.8932 26.0583 9.16602 26.0583C10.8932 26.0583 12.3666 26.6682 13.5862 27.888C14.8061 29.1077 15.416 32.2477 15.416 33.9749Z" fill={fill} />
            </g>
        </svg>
    )
}