import React, { createContext, useState, ReactNode } from "react";
import { SaasUserInfo } from "saas/model/SaasCommonModel";


interface CreateSaaSAccountContextType {
    accountInfo: SaasUserInfo;
    updateAccountInfo: (key: string, value: any) => void;
    setAccountInfo: React.Dispatch<React.SetStateAction<SaasUserInfo>>;
    checkAccountInfoCompleted: (checkFun?: (accountInfo: SaasUserInfo, key: string) => boolean) => boolean;
}

const SaasCreateAccountContext = createContext<CreateSaaSAccountContextType | undefined>(undefined);

const SaasCreateAccountProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [accountInfo, setAccountInfo] = useState<SaasUserInfo>({} as SaasUserInfo);

    const updateAccountInfo = (key: string, value: any) => {
        setAccountInfo(prevState => ({
            ...prevState,
            [key]: value
        }));
    };

    const checkAccountInfoCompleted = (checkFun: (accountInfo: SaasUserInfo, key: string) => boolean = () => true) => {
        for (const k of Object.keys(accountInfo)) {
            if (k === 'phone') {
                continue;
            }
            const key = k as keyof SaasUserInfo;
            if (!accountInfo[key] || accountInfo[key] === '' || !checkFun(accountInfo, key)) {
                console.log(`${k} is not set`);
                return false;
            }
        }
        return true;
    };

    return (
        <SaasCreateAccountContext.Provider value={{
            accountInfo,
            updateAccountInfo,
            setAccountInfo,
            checkAccountInfoCompleted
        }}>
            {children}
        </SaasCreateAccountContext.Provider>
    );
};

export { SaasCreateAccountContext, SaasCreateAccountProvider };