import axios from "axios";
import { getSaasUserToken } from "../storage/SaasStorage";

const baseUrl = process.env.REACT_APP_BASE_URL;
if (process.env.NODE_ENV === 'production') {
    console.log('API URL:', process.env.REACT_APP_BASE_URL);
} else if (process.env.NODE_ENV === 'test') {
    console.log('API URL:', process.env.REACT_APP_BASE_URL);
} else {
    console.log('API URL:', process.env.REACT_APP_BASE_URL);
}
export const saasApiBaseUrl = `${baseUrl}/api/saas`;

// Unauthenticated API Client
const unauthenticatedApiClient = axios.create({
    baseURL: `${baseUrl}/api/saas`,
    headers: {
        "Content-Type": "application/json",
    },
});

// Authenticated API Client
const authenticatedApiClient = axios.create({
    baseURL: `${baseUrl}/api/saas`,
    headers: {
        "Content-Type": "application/json",
    },
});

authenticatedApiClient.interceptors.request.use(
    (config) => {
        const token = getSaasUserToken(); // Retrieve the token from storage
        console.log('token: ', token);
        if (token) {
            config.headers["Authorization"] = `Token ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

authenticatedApiClient.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401) {
            window.location.href = '/saas/customer';
        }
        return Promise.reject(error);
    }
);

export { unauthenticatedApiClient, authenticatedApiClient };
