import React, {useState} from "react";
import {Modal, Button, Row, Col} from 'antd';

import './AJModal.css'
import EmptyLine from "./EmptyLine";

function AJModal(props) {
    let {top, onClose, onOk} = props
    top = top || 0
    onClose = onClose || function () {

    }
    onOk = onOk || function () {

    }

    return (
            <div>
                <div className={'ajmodal-default'} onClick={() => {onClose()}}
                     style={{width: '100%', height: '100%'}}>
                    <div className={'ajmodal-default-mask'}>
                        <EmptyLine height={top}/>
                        <Row type="flex" justify="center">
                            <Col>
                                {props.children}
                            </Col>
                        </Row>
                    </div>

                </div>

        </div>

    )
}

export default AJModal
