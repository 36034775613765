import { TechStack } from 'component/tech-stack/TechStackEditing';
import React from 'react';
import { OurTechnology } from 'saas/model/SaasCommonModel';

interface SaasProfileTechStackProps {
    ourTechnology: OurTechnology;
}
const SaasProfileTechStack: React.FC<SaasProfileTechStackProps> = ({ ourTechnology }) => {
    const { technologies, freeText } = ourTechnology;
    return (
        <div>
            <TechStack selected={technologies} text={freeText} className={'tech-stack-editing2'} span={3} showCustomTech={false} />
        </div>
    );
}

export default SaasProfileTechStack;