import React, { useContext, useState } from "react";

import "./FilterKeyValues.less"
import EmptyLine from "../../ui/EmptyLine";
import { formatNoneSelectableKeyCards, formatKeyCards } from "../../component/KeyCard";
import AJModalV2 from "../../ui/AJModalV2";
import PickValuesContext from "../../context/PickValuesContext";
import { useNavigate } from "react-router-dom";
import { defaultValuesConfig } from "../../component/values/ValuesConfig";
import PickValueModals from "../../component/values/PickValueModals";
import AJButton3, { AJCenterButton } from "../../ui/AJButton3";
import CompanyRegisterContext from "../../context/CompanyRegisterContent";
import { HCenterContainer } from "../../ui/VCenterContainer";
import { Button, Col, Modal, Row, message } from "antd";
import { useParams } from "react-router-dom";
import { companyProfile, companyRegister } from "../../http/AJHttp";
import {
    currentCompanyName,
    currentUserId,
    currentUserName,
    saveCompanyRegisterData,
    setCurrentCompanyId
} from "../../storage/AJStorage";
import CompanyStructureContext from "../../context/CompanyStructureContext";
import DescribeValuesContext from "../../context/DescribeValuesContext";
import JobOpeningsContext from "../../context/JobOpeningsContext";
import SocialLinksContext from "../../context/SocialLinksContext";
import CreateAccountContext from "../../context/CreateAccountContext";
import AppContext from "../../context/AppContext";


export function UpdateFilterKeyValues(props) {

    const { id } = useParams()
    // console.log(`update filterKeyValue for ${id}`)
    // const [isLoading, setIsLoading] = useState(true)

    // companyProfile(id).then(response => {
    //     if (response) {
    //         setIsLoading(false)
    //
    //     }
    // }).catch( e => {
    //     message.error("load company profile failed!")
    // })

    const newProps = {
        ...props,
        update: true,
        companyId: id
    }

    return (<>
        {/*{*/}
        {/*    isLoading ? <h1>Loading....</h1>*/}
        {/*    :*/}
        <FilterKeyValues {...newProps} />
        {/*}*/}
    </>)
}

function FilterKeyValues(props) {
    const [modalsVisible, setModalsVisible] = useState(false)
    const [currentCard, setCurrentCard] = useState(null)
    const [alertVisible, setAlertVisible] = useState(false)
    const [saveLoading, setSaveLoading] = useState(false)

    const { clearAllValues, clearValuesByType, leftValueCount } = useContext(PickValuesContext)
    // const {companyRegister, updateCompanyRegister} = useContext(CompanyRegisterContext)
    const { companyStructure, technologies } = useContext(CompanyStructureContext)
    const { orderedValues, pickedValues } = useContext(PickValuesContext)
    const { describeValues } = useContext(DescribeValuesContext)
    let { jobOpenings } = useContext(JobOpeningsContext)
    const { socialLinks } = useContext(SocialLinksContext)
    const { accountInfo } = useContext(CreateAccountContext)
    const { currentAccount } = useContext(AppContext)
    const { jobOpeningsSource, careerPageUrl } = useContext(JobOpeningsContext)
    const [linkCount, setLinkCount] = useState(3)
    let keyValuesConfig = [...defaultValuesConfig]
    keyValuesConfig.pop()
    const [valuesConfig, setValueConfig] = useState(keyValuesConfig)

    const navigate = useNavigate()

    // console.log(pickedValues())

    // ~~~~~~
    // document.getElementsByTagName('body')[0].style.background =
    // const loginBackgroundColor = 'linear-gradient(180deg, rgba(168, 94, 187, 0.54) 2.6%, rgba(143, 195, 242, 0.138) 100%)'
    // document.getElementsByTagName('body')[0].style.backgroundImage = `url(/img/common/start.jpg)`

    // the same with start-page
    const cardContainerStyle = {
        background: 'linear-gradient(180deg, #000000 0%, #3E3E3E 100%)',
        /* Button Shadow 1 */

        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.15)',
        borderRadius: '42px',
        border: 0,
        height: 85,
        width: 270,
    }

    const cardTextStyle = {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '24px',
        lineHeight: '29px',
        paddingLeft: 20,
        /* Text White */

        color: '#FFFFFF'
    }

    function renderAlert() {
        return (
            <div className={'value-driven-talents-alert'}>
                <div className={'value-driven-talents-alert-container'}>
                    <div style={{ marginLeft: 'auto', marginRight: 0, width: '20px', height: '20px' }}>
                        <img src={'/img/login/close.svg'} onClick={() => setAlertVisible(false)} />
                    </div>
                    <EmptyLine height={8} />
                    <div className={'value-driven-talents-alert-title'}>
                        Great!<br />
                        9 out of 9 values
                    </div>
                    <EmptyLine height={18} />
                    <div className={'value-driven-talents-alert-prompt'}>
                        Are you ready for<br /> next step?
                    </div>
                    <EmptyLine height={40} />
                    <AJCenterButton title={'Yes'}
                        size={'small'}
                        height={38}
                        width={271}
                        onClick={() => {
                            setAlertVisible(false)
                            setTimeout(() => {
                                if (props.update) {
                                    navigate(`/register/rank-values/update/${props.companyId}`)
                                }
                                else {
                                    navigate("/register/rank-values")

                                }
                                // ~~~~~ TODO
                                // document.getElementsByTagName('body')[0].style.backgroundImage = 'none'
                                // document.getElementsByTagName('body')[0].style.background = loginBackgroundColor
                            }, 20)
                        }} />


                </div>

            </div>
        )
    }

    const [visible, setVisible] = useState(false)

    const finishPick = (card) => {
        card.showModal = false
        setValueConfig([...valuesConfig])
        // if (leftValueCount() === 0) {
        //     setTimeout(() => {
        //         setAlertVisible(true)
        //     }, 200)
        // }
    }

    const LeftPromotion = () =>
        <><div className={'value-driven-talents-small-text'} style={{ height: 26 }}>
            Remember, you have only
        </div>
            <br />
            <div className={'number-container'}>
                <Row type="flex" justify="space-around" align="middle" style={{ height: '100%' }}>
                    <Col span={24}>
                        {leftValueCount()}
                    </Col>
                </Row>
            </div>
            <br />
            <div className={'value-driven-talents-mid-text'}>
                values left
            </div>
        </>

    const NextStepPromotion = () =>
        <><div className={'value-driven-talents-small-text'} style={{ height: 26 }}>
            Great 9 out of 9 values!
        </div>
            <br />
            <>
                <Row type="flex" justify="space-around" align="middle" style={{ height: '100%' }}>
                    <AJButton3 title={'Next step'}
                        titleStyle={{ color: 'white' }}
                        scheme={'dark-purple'}
                        width={280}
                        height={60}
                        loading={saveLoading}
                        shadow={true} onClick={() => {
                            registerCompanyProfile()

                            // setTimeout(() => {
                            //     if (props.update) {
                            //         navigate(`/register/rank-values/update/${props.companyId}`)
                            //
                            //     }
                            //     else {
                            //         navigate("/register/rank-values")
                            //     }
                            //     // registerCompanyProfile(true)
                            // }, 20)
                        }} />
                </Row>

            </>

            <br />
            <div className={'value-driven-talents-mid-text'}>
                Ready?
            </div>
        </>

    const registerCompanyProfile = async () => {
        setSaveLoading(true)
        // console.log(getCompanyRegister())
        const response = await companyRegister(getCompanyRegister())

        setSaveLoading(false)

        if (response) {
            saveCompanyRegisterData(null)
            setCurrentCompanyId(response.data.id)
            let companyName = currentCompanyName() || currentUserName() || ''
            navigate(`/company-profile/${response.data.id}`)
        }
        else {
            message.error("Submit company profile error!")
        }
    }

    const getCompanyRegister = () => {
        const convertPickedToOrdered = (pickedValues) => {
            let orderedValues = []
            let id = 0
            for (const index in pickedValues) {

                const values = pickedValues[index]
                orderedValues = orderedValues.concat(values.map((v, index) => {
                    return { text: v, id: id++ }
                }))
            }

            return orderedValues
        }

        const convertPickedToDescription = (pickedValues) => {

            const descriptions = {}
            let id = 1
            for (const index in pickedValues) {
                const values = pickedValues[index]
                values.forEach(v => {
                    descriptions[id++] = {
                        value: v
                    }
                })
            }
            return descriptions
        }

        return {
            name: accountInfo.username || currentCompanyName() || currentUserName() || '',
            // username: accountInfo.username,
            email: accountInfo.email || currentUserName() || '',
            orgNr: accountInfo['org.Nr'] || 'org.Nr',
            companyStructure: {
                structure: companyStructure(),
                technologies: technologies()
            },
            pickedValues: pickedValues(),
            orderedValues: convertPickedToOrdered(pickedValues()),
            describeValues: convertPickedToDescription(pickedValues()),
            jobOpenings: [],
            socialLinks: {},
            logo: 1, // TODO for test
            owner: parseInt(currentUserId()),
            save: true,
        }
    }

    return (
        <HCenterContainer>
            <div className={'value-driven-talents'}>
                <div className={'value-driven-talents-big-text'}>
                    Pick 9 values in total that describe<br /> your tech team & company
                </div>
                <EmptyLine height={18} />

                <div className={'value-driven-talents-small-text'} style={{ height: 46 }}>
                    {/*Filter your most important keyvalues for that perfect match!*/}
                </div>
                <EmptyLine height={25} />

                {formatNoneSelectableKeyCards(valuesConfig, 4, 25, (card, selected) => {
                    card.showModal = true
                    setValueConfig([...valuesConfig])
                }, cardContainerStyle, cardTextStyle, (card) => {
                    return pickedValues()[card.index] && pickedValues()[card.index].length > 0
                })}

                <AJModalV2 visible={alertVisible} width={338}
                    centered={true}
                    onClose={() => setAlertVisible(false)}>
                    {renderAlert()}
                </AJModalV2>

                <EmptyLine height={70} />
                {leftValueCount() === 0 ? <NextStepPromotion /> : <LeftPromotion />}
                {/*<EmptyLine height={140}/>*/}

                <PickValueModals
                    valuesConfig={valuesConfig}
                    onCloseModal={finishPick}
                    onCancel={finishPick} />

            </div>
        </HCenterContainer>
    )
}

export default FilterKeyValues

