import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { currentUserId } from 'saas/utils/storage/SaasStorage';
import { checkDns } from 'saas/utils/http/SaasHttpClient';
const baseUrl = process.env.REACT_APP_BASE_URL;
const DomainSetup: React.FC = () => {
    const [domain, setDomain] = useState<string>('');
    const [dnsStatus, setDnsStatus] = useState<string>('');
    const [dnsCheckPassed, setDnsCheckPassed] = useState<boolean>(false);
    const [certificateStatus, setCertificateStatus] = useState<string>('');
    const [isChecking, setIsChecking] = useState<boolean>(false);
    const [isRequesting, setIsRequesting] = useState<boolean>(false);
    const [serverName, setServerName] = useState<string>('');

    useEffect(() => {
        const genvaluesDomainName = baseUrl?.split('://')[1] || 'genvalues.com';
        const userId = currentUserId();
        setServerName(`customer${userId}.${genvaluesDomainName}`);
    }, [])

    useEffect(() => {
        console.log('serverName: ', serverName)
    }, [serverName])

    // Server IP or CNAME for DNS instructions 
    const serverIp = 'customer1.dev.genvalues.com';

    // Check DNS resolution
    const handleCheckDns = async () => {
        if (!domain) {
            setDnsStatus('Please enter a domain.');
            return;
        }

        setIsChecking(true);
        setDnsStatus('Checking DNS...');

        try {
            const req = { 'domainName': domain };
            const response = await checkDns(req);
            setDnsStatus(response.message);
            setIsChecking(false);
            setDnsCheckPassed(true);
        } catch (error: any) {
            setDnsCheckPassed(false);
            setIsChecking(false);
            if (error instanceof Error) {
                setDnsStatus(error.message);
            } else {
                setDnsStatus('Network error, please try again later.');
            }
        }
    };

    // Request certificate
    const handleRequestCertificate = async () => {
        setIsRequesting(true);
        setCertificateStatus('Requesting certificate...');

        try {
            const response = await axios.post('/api/request-certificate', { domain });
            setCertificateStatus(response.data.message);
        } catch (error: any) {
            setCertificateStatus(error.response?.data?.message || 'Failed to request certificate. Please try again.');
        } finally {
            setIsRequesting(false);
        }
    };

    return (
        <div style={{ maxWidth: '600px', margin: '0 auto', padding: '20px' }}>
            <h1>Set Up Your Custom Domain</h1>

            {/* Step 1: Instructions */}
            <section>
                <h2>Step 1: Choose Your Domain and Set Up DNS</h2>
                <p>
                    To use your own domain with our SaaS platform, follow these steps:
                </p>
                <ol>
                    <li>
                        Decide on a subdomain, e.g., <code>careers.youdomain.com</code>.
                    </li>
                    <li>
                        Log in to your DNS provider (e.g., Cloudflare, GoDaddy) and add an A record:
                        <ul>
                            <li><strong>Name</strong>: Your subdomain (e.g., <code>values.customer1.com</code>)</li>
                            <li><strong>Type</strong>: CNAME</li>
                            <li><strong>Value</strong>: Our domain name: <code>{serverName}</code></li>
                            <li><strong>TTL</strong>: Default or 300 seconds</li>
                        </ul>
                        Alternatively, use a CNAME record pointing to <code>customer1.ourvalues.com</code>.
                    </li>
                    <li>Save the changes and wait for DNS propagation (may take up to 24 hours).</li>
                </ol>
            </section>

            {/* Step 2: Input and Check DNS */}
            <section>
                <h2>Step 2: Verify Your Domain</h2>
                <p>Enter your domain below and click "Check" to verify DNS setup.</p>
                <div>
                    <input
                        type="text"
                        value={domain}
                        onChange={(e) => setDomain(e.target.value)}
                        placeholder="e.g., values.customer1.com"
                        style={{ width: '70%', padding: '8px', marginRight: '10px' }}
                        disabled={isChecking || isRequesting}
                    />
                    <button
                        onClick={handleCheckDns}
                        disabled={isChecking || isRequesting || !domain}
                        style={{ padding: '8px 16px' }}
                    >
                        {isChecking ? 'Checking...' : 'Check'}
                    </button>
                </div>
                {dnsStatus && (
                    <p style={{ color: dnsStatus.includes('successfully') ? 'green' : 'red', marginTop: '10px' }}>
                        {dnsStatus}
                    </p>
                )}
            </section>

            {/* Step 3: Request Certificate */}
            {dnsCheckPassed && (
                <section>
                    <h2>Step 3: Request SSL Certificate</h2>
                    <p>Your DNS is set up correctly! Click below to secure your domain with an SSL certificate.</p>
                    <button
                        onClick={handleRequestCertificate}
                        disabled={isRequesting}
                        style={{ padding: '10px 20px', backgroundColor: '#007bff', color: 'white', border: 'none' }}
                    >
                        {isRequesting ? 'Requesting...' : 'Request Certificate'}
                    </button>
                    {certificateStatus && (
                        <p style={{ color: certificateStatus.includes('success') ? 'green' : 'red', marginTop: '10px' }}>
                            {certificateStatus}
                        </p>
                    )}
                </section>
            )}

            {/* Completion */}
            {certificateStatus.includes('success') && (
                <section>
                    <h2>All Set!</h2>
                    <p>
                        Congratulations! You can now access your SaaS page at{' '}
                        <a href={`https://${domain}`} target="_blank" rel="noopener noreferrer">
                            {domain}
                        </a>.
                    </p>
                </section>
            )}
        </div>
    );
};

export default DomainSetup;