import React from "react";
import EmptyLine from "ui/EmptyLine";

export default function SaaSContainer(props: any) {
    return (
        <div className="saas-page-container">
            {props.children}
            <EmptyLine height={100} />
        </div>
    );
}