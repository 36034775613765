import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import "./stepDescriptionCard.less";
import { gotoLink } from "../start/StartFooter";
import EmptyLine from "../../ui/EmptyLine";


export default function StepDescriptionCard({ content, textOne, textTwo, hasLink }) {
    const navigate = useNavigate();
    const [isHovered, setIsHovered] = useState(false);

    return (
        <div className={'step-description-card'}>
            <div className={'contents'}>
                <img src={content}></img>
                <span>{textOne}</span>
                <EmptyLine height={13} />
                <span>{textTwo}</span>
            </div>
            {hasLink ?
                <div class={'step-link'} onClick={() => { gotoLink('/talent', navigate) }}
                    onMouseEnter={() => { setIsHovered(true) }}
                    onMouseLeave={() => { setIsHovered(false) }}>
                    {isHovered ? <img src={'/img/home/talentLinkBlack.svg'}></img> : <img src={'/img/home/talentLink.svg'}></img>}
                </div> : <div></div>
            }
        </div>
    );
}