import React from "react";
import "./AJLoading.less"
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Col, Row } from "antd";


export default function AJLoading(props) {
    let {width, height, size, background} = props
    width = width || '100%'
    height = height || '100%'
    size = size || Math.floor(width/8)

    return (
        <Row type={'flex'} align={'middle'} justify={'center'} style={{height, width, background}}>
            <Col>
                <LegacyIcon type={'loading'} style={{fontSize: size}}/>
            </Col>
        </Row>
    );
}

export function AJFullScreenLoading(props) {
    return <AJLoading
        width={'100vw'}
        height={'100vh'}
        size={150}
    background={'rgb(0,0,0,0.45)'}/>
}