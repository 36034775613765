import React from "react";
import './PublishWithDNS.less'
import SaasProfileLayout from "saas/component/SaasProfileLayout";
import EmptyLine from "ui/EmptyLine";

const PublishWithDNS: React.FC = () => {

    return (
        <SaasProfileLayout>
            <div className="publish-with-dns-title">Embed Genvalues & Supercharge Your Employer Brand</div>
            <div className="publish-with-dns-sub-title">Showcase your product team seamlessly on your own website! With just a simple iframe, you can highlight your team's values, culture, and impact—without any coding headaches.</div>
            <EmptyLine height={80} />

            <div className="publish-with-dns-container">
                <div className="publish-with-dns-step1">
                    <p>
                        Start by selecting the domain you want to use for your SaaS site. This could for eg. be careers.yourcompany.com or jobs.yourcompany.com, but remember only subdomains are supported.
                    </p>
                    <EmptyLine height={20} />
                    <p>
                        Once you've decided, head to your DNS provider (where your domain settings are managed). Set up a CNAME record for your chosen subdomain and point it to the correct domain, which you can find in Settings → Content → Domain & SSL.
                    </p>

                </div>
            </div>


        </SaasProfileLayout>
    );
}

export default PublishWithDNS;