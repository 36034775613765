import React, {useEffect, useState} from "react";

function guid2() {
    function S4() {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    }
    return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
}

export default function AJVideo(props) {
    const {width, height, sources} = props

    const uuid = guid2()
    const [currentIndex, setCurrentId] = useState(0)
    const length = sources.length
    useEffect(() => {
        let videoDom = document.getElementById(uuid);
        videoDom.play()

        videoDom.addEventListener('ended', function(event) {
            if (currentIndex === length-1) {
                videoDom.src = sources[0];
                // videoDom.play();
                setCurrentId(0)
            } else {

                videoDom.src = sources[currentIndex + 1];
                // videoDom.play();
                setCurrentId(currentIndex + 1)
            }
        })
    })



    return (
        <video id={uuid} width={'100%'} muted>
            <source src={sources[currentIndex]} type="video/mp4"/>
        </video>
    )

}