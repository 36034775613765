import * as React from "react"
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useEffect } from "react";
import { setJoinedCommunity } from "../storage/AJStorage";
import { jobOpeningVisitor, sendCommunityMail } from "../http/AJHttp";

export default function OAuthCallback() {
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const email = params.get('email');
    const name = params.get('name');
    const state = params.get('state');
    const { redirectUrl, company } = JSON.parse(decodeURIComponent(state));

    useEffect(() => {
        const handleAuth = async () => {
            if (email) {
                await sendCommunityMail(email, name);
                await jobOpeningVisitor(email, name, redirectUrl, company);
                setJoinedCommunity(email);

                // For external URLs, use window.location
                // For internal navigation, use navigate()
                if (redirectUrl.startsWith(window.location.origin)) {
                    navigate(redirectUrl.replace(window.location.origin, ''), { replace: true });
                } else {
                    window.location.href = redirectUrl;
                }
            }
        };
        handleAuth();
    }, [email, name, redirectUrl, company, navigate]);

    return <div />;
}