import React, { useEffect, useState } from "react";
import { debounce } from 'lodash';
import "./AboutPage.less";
import EmptyLine from "../ui/EmptyLine";
import EmptySpan from "../ui/EmptySpan";
import { Avatar, Col, Progress, Row } from "antd";
import AJButton3, { AJButton4, AJCenterButton } from "../ui/AJButton3";
import StartFooter from "../component/start/StartFooter";
import { HCenterContainer } from "../ui/VCenterContainer";
import { useNavigate } from "react-router-dom";
import VisibilitySensor from "react-visibility-sensor";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

export default function AboutPage(props) {

    // useEffect(()=>{
    //     // window.scrollTo(0, 0)
    // }, [])

    const [percent1, setPercent1] = useState(0);
    const navigate = useNavigate();
    const [emptyLineHeight, setEmptyLineHeight] = useState(0);

    useEffect(() => {
        setEmptyLineHeight(calEmptyLineHeight());
    }, []);

    const redrawWithWindowChange = debounce(() => {
        setEmptyLineHeight(calEmptyLineHeight());
    }, 1000); // debounce time is 1 seconds

    useEffect(() => {
        const handleWindowResize = () => {
            redrawWithWindowChange();
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    function calEmptyLineHeight() {
        const width = window.innerWidth;
        return 0.4 * width - 440;
    }

    function Percent(props) {
        return (<div style={{ display: "inline-box" }}>
            {/*<Progress type="circle" percent={props.percent} strokeColor={{*/}
            {/*    '0%': '#181414',*/}
            {/*    '100%': '#181414',*/}
            {/*}} width={303}/>*/}
            <CircularProgressbar
                value={props.percent}
            />
            <EmptyLine height={29} />
            <Row type="flex" justify="center">
                <Col>
                    {props.children}
                </Col>
            </Row>
        </div>)

    }

    function formatAvatar(avatars) {
        avatars = avatars || [
            {
                url: '/img/about/carwan.png',
                info: {
                    name: 'Carwan Rasoal',
                    // location : 'Stockholm',
                    position: 'Founder & CEO'
                }
            },
            {
                url: '/img/about/lv2.png',
                info: {
                    name: 'Michael Lee',
                    // location : 'Stockholm',
                    position: 'CTO'
                }
            },
            {
                url: '/img/about/jonas2.png',
                info: {
                    name: 'Jonas Enqvist',
                    // location : 'Stockholm',
                    position: 'Creative Lead'
                }
            },
            // {
            //     url : '',
            //     info: {
            //         name : 'Unannounced',
            //         location : 'Berlin ',
            //         position : 'Role/title here '
            //     }
            // },
        ]

        const progressStyle = {
            // Colors
            pathColor: '#181414',
            textColor: '#181414',
            trailColor: '#181414',
            backgroundColor: '#181414',
        }


        return (<Row type="flex" justify="center" align={"top"}>
            {
                avatars.map((avatar) => {
                    return <Col span={7} style={{ textAlign: 'center' }}>
                        <HCenterContainer>
                            <Avatar size={270} src={avatar.url} />
                            <br /><br />
                            <div className={'about-page-team-desc'}>
                                <b>{avatar.info.name}</b>
                            </div>
                            {avatar.info.location ? <div className={'about-page-team-desc-2'} style={{ fontWeight: 400, fontSize: 20 }}>
                                {avatar.info.location}
                            </div> : null}


                            <div className={'about-page-team-position'} style={{ fontWeight: 400, fontSize: 22 }}>
                                {avatar.info.position}
                            </div>
                        </HCenterContainer>

                    </Col>
                })
            }
        </Row>)
    }

    return (
        <div className={'about-page'}>
            <EmptyLine height={350} />
            <div className={'about-page-title'}>
                <div style={{ paddingLeft: 160 }}>
                    Crafting careers that<br /> make a difference
                </div>
            </div>
            {/*<HCenterContainer>*/}
            <div className={'about-page-container'}>
                <EmptyLine height={emptyLineHeight} />
                <Row type={'flex'}>
                    <Col span={1} />
                    <Col span={11} style={{ paddingRight: 60 }}>
                        <div className={'about-page-intro-title'}>
                            A new era of<br /> value based hiring
                        </div>
                    </Col>
                    <Col span={11}>
                        <EmptyLine height={17} />
                        <div className={'about-page-contact-text'} style={{ fontFamily: 'InterRegular', fontSize: 22, color: '#181414' }}>
                            Our Values-Based Matching approach sets us apart in today's dynamic impact tech landscape. As the demands for skills continue to evolve, we understand that values and attitudes remain constant and crucial to performance and success. That's why we strongly emphasise aligning modern tech talent's professional and personal values with those of the team and workplace. This initial step ensures a meaningful fit and positions the organisation for sustained success in the long term.<br /><br />

                            By prioritising team-values first, we create a more efficient and effective hiring process beyond traditional methods. We carefully consider not only the candidate's technical abilities but also their beliefs, motivations, and cultural fit to ensure a comprehensive match. Our approach results in successful integration and contributes to a positive and productive work environment, driving business performance to new heights.<br /><br />

                            As technology continues to shape the future, our Values-Based Matching approach provides a smart and innovative solution for attracting top talent in the tech industry. Join us in revolutionising the hiring process and creating a brighter future for your organisation."
                        </div>
                    </Col>
                </Row>
            </div>
            {/*</HCenterContainer>*/}
            <EmptyLine height={150} />
            <div className={'about-page-container'}>
                <Row type={'flex'} style={{ minHeight: 466, background: '#F3F2EF', borderRadius: 90, flexWrap: 'nowrap' }}>
                    <div>
                        <img src={'/img/about/main-founding.png'} alt={'main'} height={"480"} />
                    </div>
                    <div>
                        <div style={{ padding: '60px 0 0 8vw', display: 'flex' }}>
                            <div>
                                <svg width="38" height="28" viewBox="0 0 38 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M23.7312 17.4875C23.7312 12.4389 24.9495 8.48636 27.386 5.62989C29.856 2.77343 33.3105 0.896797 37.7497 0V5.18149C34.3786 6.21115 32.1089 8.07117 30.9407 10.7616C30.2732 12.1898 30.0061 13.5682 30.1397 14.8968H38V28H23.7312V17.4875ZM0 17.4875C0 12.5053 1.18489 8.5694 3.55468 5.67972C5.95784 2.79004 9.44576 0.896797 14.0184 0V5.18149C10.614 6.24437 8.32762 8.07117 7.15942 10.6619C6.55863 12.0237 6.3083 13.4353 6.40843 14.8968H14.2688V28H0V17.4875Z" fill="#181414" />
                                </svg>
                            </div>
                            <div style={{ width: 19, height: 1 }} />
                            <div className={"about-page-founder-text"}>
                                There's a big movement happening right now that goes beyond traditional recruiting. It's about connecting the brightest individuals with impact-driven companies that share their values and mission. Every day, I'm inspired to create meaningful connections that drive positive change.”
                            </div>
                        </div>

                    </div>
                </Row>

            </div>
            <EmptyLine height={150} />

            {/*<HCenterContainer>*/}
            <div className={'about-page-container'}>
                <Row type={'flex'}>
                    <Col span={9} style={{ textAlign: "left" }}>
                        <div className={'about-page-reason-text-container'} style={{ paddingLeft: 40 }}>
                            <div className={'about-page-reason-text-title'}>
                                What we do
                            </div>
                            <br />
                            <div className={'about-page-reason-text-body-1'}>
                                Our value-matching platform help tech talents join
                                the impact wave and find teams that share the
                                same values.
                            </div>
                            <EmptyLine height={26} />
                            <div className={'about-page-reason-text-body-2'} style={{ lineHeight: '30px' }}>
                                We are transforming the traditional way of hiring to a modern
                                approach that aligns with the new generation of talent pool
                                that have a wider lens about a job and companies
                                responsibility in the society.
                            </div>
                            <EmptyLine height={20} />
                            <ul className={'about-page-reason-text-body-2'} style={{ marginLeft: -24, lineHeight: '30px' }}>
                                <li>
                                    We put values and purpose before skills. We believe
                                    purpose is a commitment, and commitment is
                                    performance.
                                </li>
                                <EmptyLine height={15} />
                                <li>
                                    We put values before qualifications. We believe values are teamwork, and teamwork leads to collective intelligence.
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col span={1} />
                    <Col span={14} style={{ textAlign: "right" }}>
                        <img src={'/img/about/video.png'} height={503} alt={"img"} />
                    </Col>

                </Row>
            </div>
            {/*</HCenterContainer>*/}

            <EmptyLine height={184} />
            <div className={'about-page-percent-container'} style={{ position: 'relative' }}>
                <EmptyLine height={90} />
                <div className={'about-page-percent-title'}>
                    Numbers that matters
                </div>


                <EmptyLine height={90} />

                <Row type="flex" justify="center">
                    <div style={{ width: "303px" }}>
                        <VisibilitySensor>
                            {({ isVisible }) => {
                                const percentage = isVisible ? 98 : 0;
                                return (
                                    <CircularProgressbar
                                        value={percentage}
                                        text={`${percentage}%`}
                                        styles={buildStyles({
                                            // Colors
                                            pathColor: '#181414',
                                            textColor: '#181414',
                                            // trailColor: '#181414',
                                            // backgroundColor: '#181414',
                                        })}
                                    />
                                );
                            }}
                        </VisibilitySensor>
                        <EmptyLine height={18} />
                        <div className={'about-page-percent-text'}>
                            Of employees wouldn’t<br />
                            work somewhere that<br />
                            does not fit their values
                        </div>
                    </div>
                    <EmptySpan width={74} />
                    <div style={{ width: "303px" }}>
                        <VisibilitySensor>
                            {({ isVisible }) => {
                                const percentage = isVisible ? 82 : 0;
                                return (
                                    <CircularProgressbar
                                        value={percentage}
                                        text={`${percentage}%`}
                                        styles={buildStyles({
                                            // Colors
                                            pathColor: '#181414',
                                            textColor: '#181414',
                                            // trailColor: '#181414',
                                            // backgroundColor: '#181414',
                                        })}
                                    />
                                );
                            }}
                        </VisibilitySensor>
                        <EmptyLine height={18} />

                        <div className={'about-page-percent-text'}>
                            Place importance on linking<br />
                            values and purpose with<br />
                            their employer
                        </div>
                    </div>
                    <EmptySpan width={74} />
                    <div style={{ width: "303px" }}>
                        <VisibilitySensor>
                            {({ isVisible }) => {
                                const percentage = isVisible ? 14 : 0;
                                return (
                                    <CircularProgressbar
                                        value={percentage}
                                        text={`${percentage}%`}
                                        styles={buildStyles({
                                            // Colors
                                            pathColor: '#181414',
                                            textColor: '#181414',
                                            // trailColor: '#181414',
                                        })}
                                    />
                                );
                            }}
                        </VisibilitySensor>
                        <EmptyLine height={18} />

                        <div className={'about-page-percent-text'}>
                            Engagement rate at<br />
                            workplace in Sweden
                        </div>
                    </div>
                </Row>

                <EmptyLine height={90} />
                <div style={{ textAlign: 'center', width: '100%' }}>
                    <AJButton4 title={'More insights'}
                        size={'mini'}
                        width={197}
                        type={'secondary'}
                        height={60}
                        titleStyle={{
                            fontSize: 22,
                            fontWeight: 400,
                            fontFamily: 'ManropeRegular'
                        }}
                        customStyle={{
                            borderRadius: 0
                        }}
                        onClick={() => {
                            navigate('/latest')
                        }} />
                </div>
                <EmptyLine height={90} />
                <div style={{ position: 'absolute', right: 250, bottom: 120, fontFamily: 'InterLight' }}>
                    <a href={'https://kpmg.com/uk/en/home/media/press-releases/2023/01/climate-quitting-younger-workers-voting-esg.html'} target="_blank">Source: KPMG</a>
                </div>
            </div>


            <EmptyLine height={150} />

            <HCenterContainer>
                <div className={'about-page-container'}>
                    <Row>
                        <Col span={1} />
                        <Col span={10} style={{ textAlign: "right" }}>
                            <div style={{ float: "right" }}>
                                <div className={'about-page-reason-text-container'} style={{ paddingRight: 40, }}>
                                    <div className={'about-page-reason-text-title'} style={{ textAlign: "left" }}>
                                        How it works
                                        <br />
                                    </div>
                                    <br /><br />
                                    <div className={'about-page-reason-text-body'} style={{ textAlign: "left", lineHeight: '30px' }}>


                                        We use cutting-edge technology and science to provide a unique platform. Genvalues elevate your employer brand, aligning with a modern generation with a wider lens on what a job is and a company's role in society. 82% of office workers consider it important to connect their values and purpose with their employer.<br /><br />

                                        We provide tech companies with a unique opportunity to stand out from the crowd, with over 60 attractive and modern professional values to choose from.<br /><br />

                                        Companies can select the top nine values that best represent their engineering team and their SDGs, and receive professional help to create a modern profile.<br /><br />




                                    </div>
                                </div>
                            </div>

                        </Col>
                        <Col span={13} style={{ textAlign: "left" }}>
                            <img src={'/img/about/about-how2.png'} height={517} width={770} style={{ paddingLeft: 40 }} />
                        </Col>
                    </Row>
                </div>
            </HCenterContainer>
            {/*<EmptyLine height={56}/>*/}
            {/*<AJCenterButton title={'Toolbox'} width={260} height={60} shadow={'0px 10px 20px rgba(0, 0, 0, 0.15)'}/>*/}


            <EmptyLine height={150} />

            <HCenterContainer>
                <div className={'about-page-container'}>
                    <Row>
                        <Col span={11} style={{ textAlign: "left" }}>
                            <div className={'about-page-reason-text-container'} style={{ paddingLeft: 40 }}>

                                <div className={'about-page-reason-text-title'}>
                                    Why Genvalues
                                </div>

                                <div className={'about-page-reason-text-body'} style={{ lineHeight: '28px' }}>
                                    <br />
                                    At Genvalues, our mission is to empower the impact tech wave with successful teams driven by innovation and a sustainable future for all. A match based on shared team values and purpose is key to fostering growth and success for impact tech companies and modern engineering talent.<br /><br /><br />

                                    <div className={'bold'}>We help employers build successful tech teams</div>

                                    For companies, we offer a unique solution to building successful tech teams. We understand that attracting and retaining top talent goes beyond skills and experience; it's about finding individuals who share the company's purpose and team’s values. Recent studies show that 82% of office workers place importance on linking values and purpose with their employer.<br /><br /><br />

                                    <div className={'bold'}>We help talents create meaningful working experience</div>

                                    For tech talents, we provide a platform for meaningful career growth. Career success goes beyond just ideas and experience; it's about finding a workplace that aligns with your purpose and professional values. With Genvalues, you can discover opportunities that offer career growth and match your values and purpose, creating a fulfilling work experience.
                                    Join us in revolutionising the tech industry with value-based matches that drive innovation, success, and a sustainable future for all. Discover the power of shared values and purpose with Genvalues.

                                </div>

                            </div>
                        </Col>
                        <Col span={13} style={{ textAlign: "right" }}>
                            <img src={'/img/about/why.png'} height={678} width={586} style={{ paddingRight: 0 }} />
                        </Col>

                    </Row>
                </div>
            </HCenterContainer>


            <EmptyLine height={150} />


            <div className={'about-page-team-container'}>
                <HCenterContainer>
                    <div className={'about-page-team-title'}>
                        Team
                    </div>
                    <div className={'about-page-team-content'}>

                        <EmptyLine height={58} />
                        {formatAvatar()}

                        <EmptyLine height={150} />
                        <div className={'about-page-team-title'}>
                            Advisors
                        </div>
                        <EmptyLine height={58} />

                        {formatAvatar([
                            // {
                            //     url : '',
                            //     info: {
                            //         name : 'Karin Björken',
                            //         location : 'Stockholm',
                            //         position : 'Senior Advisor'
                            //     }
                            // },
                            {
                                url: '/img/about/dave2.png',
                                info: {
                                    name: <>Dave Edwards<br /><br /></>,
                                    location: 'San Francisco ',
                                    position: <>AI & Future workforce expert<br /> with experience from Apple<br /> & Morgan Stanley</>
                                }
                            },
                            {
                                url: '/img/about/Jessica.png',
                                info: {
                                    name: <>Jessica Cederberg<br /> Wodmar</>,
                                    location: 'Stockholm',
                                    position: <>
                                        Sustainability expert with +30<br /> years of knowledge and<br />  experience & Author
                                    </>
                                }
                            },
                            {
                                url: '/img/about/chato2.png',
                                info: {
                                    name: <>Chato Rasoal<br /><br /></>,
                                    location: 'Stockholm',
                                    position: <>Ph.D in Psychology & Scientist


                                    </>
                                }
                            },
                        ])}


                        <EmptyLine height={352} />

                    </div>
                </HCenterContainer>
            </div>

            <StartFooter />
        </div>
    )
}
