import React, { useState } from "react";
import { requestCertificate } from "saas/utils/http/SaasHttpClient";
import EmptyLine from "ui/EmptyLine";
import PublishWithDNS from "./PublishWithDNS";


const Publication: React.FC = () => {
    const [domainName, setDomainName] = useState<string>('');
    const [message, setMessage] = useState<string>('');

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const req = { domainName: domainName };
        try {
            const data = await requestCertificate(req);
            setMessage(data.message);
        } catch (error) {
            if (error instanceof Error) {
                setMessage(error.message);
            } else {
                setMessage('Network error, please try again later.');
            }
        }
    };

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <label>
                    输入您的子域名（如 ourvalues.customer1.com）：
                    <input
                        type="text"
                        value={domainName}
                        onChange={(e) => setDomainName(e.target.value)}
                    />
                </label>
                <button type="submit">提交</button>
            </form>
            {message && <p>{message}</p>}

            <EmptyLine height={100} />
            <PublishWithDNS />
        </div>
    )
}
export default Publication;