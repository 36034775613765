import React, { useState } from "react";
import { defaultValuesConfig } from "../values/ValuesConfig";
import "./ChangeValue.less"
import { Collapse, Tag, message } from "antd";
import { arrayContains } from "../../utils/arrayUtil";
const { CheckableTag } = Tag;
const { Panel } = Collapse;

export default function ChangeValue(props) {
    const valuesConfig = [...defaultValuesConfig]
    valuesConfig.pop()
    let { value, onFinish, onChanged, width, disabledSet, excludeSet, selectedValue } = props
    disabledSet = disabledSet || []
    excludeSet = excludeSet || []

    // console.log(`${value} ${selectedValue}`)

    let defaultActiveKey = null
    valuesConfig.forEach((config, index) => {
        // console.log(config.values)

        const found = config.values.filter((item) => {
            // console.log(`${item.value}  === ${selectedValue}`)
            return item.value === selectedValue
        })
        if (found && found.length > 0) {
            defaultActiveKey = [index]
        }
    })



    return (
        <div className={'change-value-container'} style={{ width }}>
            <h1>Change Value</h1>
            {
                valuesConfig.map((config, index) => {
                    return (
                        <>
                            <Collapse defaultActiveKey={defaultActiveKey} >
                                <Panel key={index} header={config.textLines.join(" ")}>
                                    {
                                        config.values.map((v, i) => {
                                            if (arrayContains(excludeSet, v.value) && v.value !== value) {
                                                // console.log(`${v.value} is excluded!`)
                                                return null
                                            }
                                            return (
                                                <CheckableTag
                                                    checked={v.value === selectedValue}
                                                    onChange={() => {
                                                        if (arrayContains(disabledSet, v.value)) {
                                                            message.error(`${v.value} has been selected!`)
                                                            return
                                                        }
                                                        // setCurrentValue(v.value)
                                                        onChanged(v.value)
                                                    }}>

                                                    <div className={'change-value-tag'}>
                                                        {v.value}
                                                    </div>
                                                </CheckableTag >
                                            )
                                        })
                                    }

                                </Panel>

                            </Collapse>
                            <br />
                        </>
                    )
                })
            }
        </div>
    )

}
