import { currentUserId, getSaasUserToken } from "../storage/SaasStorage";
import { authenticatedApiClient, saasApiBaseUrl, unauthenticatedApiClient } from "./apiClient";


export const customerRegister = async (req: any) => {
    const response = await unauthenticatedApiClient.post("/register", req);
    return response.data;
};

export const customerLogin = async (req: any) => {
    const response = await unauthenticatedApiClient.post("/login", req, {});
    console.log("User logged in:", response.data);
    return response.data;
};


export const checkToken = async (token: string) => {
    const headers = {
        ...unauthenticatedApiClient.defaults.headers.common,
        Authorization: `Token ${token}`
    };
    const response = await unauthenticatedApiClient.post("/check-token", {}, { headers });
    return response.data;
};

export const uploadImageFile = async (formData: FormData) => {
    try {
        const headers = {
            ...authenticatedApiClient.defaults.headers.common,
            'Content-Type': 'multipart/form-data'
        };
        const response = await authenticatedApiClient.post("/uploadImageFile", formData, { headers });
        return response.data;
    } catch (error) {
        console.error("Error:", error);
    }
};

export const getSystemPrinciples = async () => {
    try {
        const response = await authenticatedApiClient.get("/systemPrinciples");
        return response.data;
    } catch (error) {
        console.error("Error:", error);
    }
};

export const saveUserPrinciples = async (req: any) => {
    try {
        const response = await authenticatedApiClient.post("/userPrinciples", req);
        return response.data;
    } catch (error) {
        console.error("Error:", error);
    }
};

export const getUserPrinciples = async (userId: number) => {
    try {
        const response = await authenticatedApiClient.get(`/userPrinciples?userId=${userId}`);
        return response.data;
    } catch (error) {
        console.error("Error:", error);
    }
};

export const saveProfile = async (req: any) => {
    try {
        const response = await authenticatedApiClient.post("/userProfile", req);
        return response.data;
    } catch (error) {
        console.error("Error:", error);
    }
};

export const getProfile = async (userId: number | null) => {
    try {
        const response = await authenticatedApiClient.get(`/userProfile?userId=${userId}`);
        return response.data;
    } catch (error) {
        console.error("Error:", error);
    }
};


export const getAiStreamReader = async (principle: string, inputMessage: string) => {
    const token = getSaasUserToken();
    const response = await fetch(`${saasApiBaseUrl}/stream`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Token ${token}`,
            "Accept": "text/event-stream", // Accept SSE
        },
        body: JSON.stringify({ message: inputMessage, principle: principle, userId: currentUserId() }),
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    const reader = response.body?.getReader();
    if (!reader) {
        console.error("Get response stream failed");
        return;
    }
    return reader;
}

export const getQuestionsByPrinciple = async (principles: string[], userId = currentUserId()) => {
    try {
        const response = await authenticatedApiClient.post("/saas-questions", { principles, userId });
        return response.data;
    } catch (error) {
        console.error("Error:", error);
    }
}

export const saveCustomValues = async (customValues: any, userId = currentUserId()) => {
    try {
        const response = await authenticatedApiClient.post("/custom-values", { customValues, userId });
        return response.data;
    } catch (error) {
        console.error("Error:", error);
    }
};

export const getCustomValues = async (userId = currentUserId()) => {
    try {
        const response = await authenticatedApiClient.get(`/custom-values?userId=${userId}`);
        return response.data;
    } catch (error) {
        console.error("Error:", error);
    }
};

export const deleteCustomValue = async (valueCategoryName: string, customValue: string, userId = currentUserId()) => {
    try {
        const response = await authenticatedApiClient.delete("/custom-values", {
            params: {
                userId,
                valueCategoryName,
                customValue
            }
        });
        return response.data;
    } catch (error) {
        console.error("Error:", error);
    }
};


export const checkDns = async (req: any) => {
    try {
        const response = await authenticatedApiClient.post("/check-dns", req);
        return response.data;
    } catch (error: any) {
        if (error.response && error.response.status === 400) {
            throw new Error(error.response.data.message);
        }
        console.error("Error:", error);
        throw error;
    }
};

export const requestCertificate = async (req: any) => {
    try {
        const response = await authenticatedApiClient.post("/request-certificate", req);
        return response.data;
    } catch (error: any) {
        if (error.response && error.response.status === 400) {
            throw new Error(error.response.data.message);
        }
        console.error("Error:", error);
        throw error;
    }
};