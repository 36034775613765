import React, { useContext, useState, ReactNode, useEffect } from "react";
import {
    currentUserId,
    getOrderedValues,
    saveOrderedValues,
    savePickedValues
} from "../utils/storage/SaasStorage";
import { arrayContains } from "utils/arrayUtil";
import { Props } from "saas/model/SaasCommonModel";
import { getUserPrinciples } from "saas/utils/http/SaasHttpClient";
import { SaasAuthContext } from "./SaasAuthProvider";

export const requirePickedValueCount: number = 9;

interface Card {
    index: number;
    title: string;
    tags?: Tag[];
}

interface Tag {
    selected: boolean;
}

export interface PickValuesContextProps {
    pickedValues: () => Record<string, string[]>;
    orderedValues: () => string[] | null;
    updatePickedValues: (pickedValues: any) => void;
    containsValue: (card: Card, title: string) => boolean;
    totalValueCount: () => number;
    leftValueCount: () => number;
    setOrderedValues: (orderedValues: string[] | null) => void;
    clearAllPickedValues: () => void;
    clearAllOrderedValues: () => void;
    clearValuesByType: (card: Card) => void;
}

const SaasPickValuesContext = React.createContext<PickValuesContextProps | undefined>(undefined);

const SaasPickValuesProvider: React.FC<Props> = (props) => {
    const maxValueCount: number = requirePickedValueCount;
    const [pickedValues, setPickedValues] = useState<Record<string, string[]>>({});
    const userId = currentUserId() || 0;
    const authContext = useContext(SaasAuthContext);

    useEffect(() => {
        if (authContext.isLoggedIn) {
            getUserPrinciples(userId).then((principles) => {
                setPickedValues(principles.principles);
            });
        }
    }, [authContext.isLoggedIn, userId]);

    return (
        <SaasPickValuesContext.Provider
            value={{
                pickedValues: (): Record<string, string[]> => {
                    return pickedValues;
                },
                orderedValues: (): string[] | null => {
                    return getOrderedValues();
                },
                updatePickedValues: (pickedValues: any): void => {
                    setPickedValues(pickedValues)
                    savePickedValues(pickedValues);
                    saveOrderedValues(null);
                },
                containsValue: (card: Card, title: string): boolean => {
                    const valueC = pickedValues[card.index] || [];
                    return arrayContains(valueC, title);
                },
                totalValueCount: (): number => {
                    let total = 0;
                    for (const index in pickedValues) {
                        total += pickedValues[parseInt(index)].length;
                    }
                    return total;
                },
                leftValueCount: (): number => {
                    return maxValueCount - Object.values(pickedValues).reduce((sum, arr) => sum + arr.length, 0);
                },
                setOrderedValues: (orderedValues: string[] | null): void => {
                    saveOrderedValues(orderedValues);
                },
                clearAllPickedValues: (): void => {
                    savePickedValues(null);
                },
                clearAllOrderedValues: (): void => {
                    saveOrderedValues(null);
                },
                clearValuesByType: (card: Card): void => {
                    pickedValues[card.index] = [];
                    card.tags?.forEach(tag => tag.selected = false);
                    savePickedValues(pickedValues);
                }
            }}
        >
            {props.children}
        </SaasPickValuesContext.Provider>
    );
};

export { SaasPickValuesProvider, SaasPickValuesContext };