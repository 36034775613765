import React from "react";
import "./NotFound404.less"
import VCenterContainer, { HCenterContainer } from "../ui/VCenterContainer";
import EmptyLine from "../ui/EmptyLine";
import { AJCenterButton } from "../ui/AJButton3";
import { useNavigate } from 'react-router-dom'
import AJImg from "../ui/AJImg";

export default function NotFound404() {
    // return (<div className={'not-found-404'}>
    //     <div className={'not-found-404-title'}>
    //         {/*<HCenterContainer>*/}
    //         404
    //         {/*</HCenterContainer>*/}
    //     </div>
    // </div>)

    const navigate = useNavigate()

    return (
        <div>
            {/*<EmptyLine height={138}/>*/}
            <div className={'page-not-found-title'}>
                Page not found
            </div>
            <div className={'page-not-found-404'}>
                <AJImg src={'/img/common/404.gif'} width={1207} height={527} alt={'Page not found'} />
            </div>
            <AJCenterButton title={'Take me home'} titleStyle={{ 'color': 'white' }}
                width={260} height={60} onClick={() => {
                    navigate('/')
                }} />

        </div>
    )
}