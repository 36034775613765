import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { checkToken } from 'saas/utils/http/SaasHttpClient';
import { getSaasUserToken } from 'saas/utils/storage/SaasStorage';

interface AuthContextType {
    isLoggedIn: boolean;
    login: (token: string) => void;
    logout: () => void;
    setLoginStatus: (isLoggedIn: boolean) => void;
}

const SaasAuthContext = createContext<AuthContextType>({
    isLoggedIn: false,
    login: () => { },
    logout: () => { },
    setLoginStatus: () => { }
});

const SaasAuthProvider = ({ children }: { children: ReactNode }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    // check the login status
    useEffect(() => {
        const token = getSaasUserToken();
        if (token) {
            checkToken(token).then(res => {
                if (res?.token) {
                    setIsLoggedIn(true);
                }
            }).catch(err => {
                setIsLoggedIn(false);
            });
        } else {
            setIsLoggedIn(false);
        }
    }, []);

    const login = (token: string) => {
        localStorage.setItem('token', token);
        setIsLoggedIn(true);
    };

    // logout function to remove token from local storage and set isLoggedIn to false
    const logout = () => {
        localStorage.removeItem('token');
        setIsLoggedIn(false);
    };

    const setLoginStatus = (isLoggedIn: boolean) => {
        setIsLoggedIn(isLoggedIn);
    };




    return (
        <SaasAuthContext.Provider value={{ isLoggedIn, login, logout, setLoginStatus }}>
            {children}
        </SaasAuthContext.Provider>
    );
};

export { SaasAuthProvider, SaasAuthContext };