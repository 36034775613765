import React, { forwardRef, useImperativeHandle, useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './bubbleLogo.less';
import { gotoLink } from "../../component/start/StartFooter";
import AJModalV2 from "../../ui/AJModalV2";
import EmptySpan from "../../ui/EmptySpan";

const BubbleLogoButton = forwardRef(({ img, containerWidthHeight, from, to, companyId, companyName, currentIndex }, ref) => {
  useImperativeHandle(ref, () => {
    return {
      updatePosition: updatePosition
    }
  });

  let [sx, setSx] = useState({ width: `170px`, height: `170px`, position: 'absolute', display: 'none' });
  const boxRef = useRef(null);
  const navigate = useNavigate();
  let [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    if (from || to || img || containerWidthHeight) {
      updatePosition(from, to);
    }
  }, [from, to, img, containerWidthHeight]);

  useEffect(() => {
    const handleAnimationEnd = (event) => {
      if (boxRef.current) {
        boxRef.current.style.left = `${boxRef.current.getBoundingClientRect().left}px`;
        boxRef.current.style.top = `${boxRef.current.getBoundingClientRect().top}px`;
        boxRef.current.style.animation = getBubbleFloatingAnimation();
      }
    };

    if (boxRef.current) {
      boxRef.current.addEventListener('animationend', handleAnimationEnd);
    }

    return () => {
      if (boxRef.current) {
        boxRef.current.removeEventListener('animationend', handleAnimationEnd);
      }
    };
  }, []);

  function getKeyframes(keyframesName) {
    try {
      let styleSheet = document.styleSheets;
      let animation = {};
      for (let i = 0; i < styleSheet.length; i++) {
        for (let j = 0; j < styleSheet[i].cssRules.length; j++) {
          if (styleSheet[i].cssRules[j].constructor.name === 'CSSKeyframesRule') {
            if (keyframesName === styleSheet[i].cssRules[j].name) {
              animation.cssRules = styleSheet[i].cssRules[j];
              animation.index = j;
              animation.styleSheet = styleSheet[i];
              return animation;
            }
          }
        }
      }
      return undefined;
    } catch {
      return undefined;
    }
  }

  function insertKeyFrames(keyframesName) {
    let styleSheet = document.styleSheets[0];
    styleSheet.insertRule(keyframesName, 0);
  }

  function updatePosition(fromPositionIndex, toPositionIndex) {
    boxRef.current.style.left = '0px';
    boxRef.current.style.top = '0px';

    // get the position of from and to positions
    let fromPosition, toPosition;
    if (fromPositionIndex === 0) {
      // if it is a new bubble, it should fly in from the edge of the page
      fromPosition = getOriginStartPosition(toPositionIndex, containerWidthHeight[0], containerWidthHeight[1]);
    } else {
      fromPosition = getPositionCoordinate(fromPositionIndex, containerWidthHeight[0], containerWidthHeight[1]);
    }
    toPosition = getPositionCoordinate(toPositionIndex, containerWidthHeight[0], containerWidthHeight[1]);

    setSx({ ...sx, width: `${getBubbleSize(toPositionIndex)}px`, height: `${getBubbleSize(toPositionIndex)}px`, display: 'block' });

    const keyFrames = getKeyframes(`slide-from-${fromPositionIndex}-${containerWidthHeight[0]}-to-${toPositionIndex}-${containerWidthHeight[1]}`);
    if (keyFrames === undefined) {
      let newKeyframes = `@keyframes slide-from-${fromPositionIndex}-${containerWidthHeight[0]}-to-${toPositionIndex}-${containerWidthHeight[1]} {\n` +
        '            from {\n' +
        `                -webkit-transform: translate(${fromPosition[0]}px, ${fromPosition[1]}px);\n` +
        `                        transform: translate(${fromPosition[0]}px, ${fromPosition[1]}px);\n` +
        '            }\n' +
        '            to {\n' +
        `                -webkit-transform: translate(${toPosition[0]}px, ${toPosition[1]}px);\n` +
        `                        transform: translate(${toPosition[0]}px, ${toPosition[1]}px);\n` +
        '            }\n' +
        '        }';
      insertKeyFrames(newKeyframes);
    }
    boxRef.current.style.animation = `slide-from-${fromPositionIndex}-${containerWidthHeight[0]}-to-${toPositionIndex}-${containerWidthHeight[1]} 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards`;
  }

  function getBubbleFloatingAnimation() {
    const randomNum = Math.floor(Math.random() * 4) + 1;
    const isDelay = randomNum % 2 === 0 ? '1s' : '';
    return `floating${randomNum} 10s ease-in-out ${isDelay} infinite both`;
  }

  function routeToCompanyPage() {
    if (currentIndex !== 6) {
      setModalVisible(true);
    } else {
      gotoLink(`/company-profile/${companyId}`, navigate);
    }
  }

  function sin(angle) {
    const radians = angle * (Math.PI / 180);
    return Math.sin(radians);
  }

  function cos(angle) {
    const radians = angle * (Math.PI / 180);
    return Math.cos(radians);
  }

  function getPositionCoordinate(positionIndex, width, height) {
    const centralX = Number((width / 2).toFixed(0));
    const centralY = Number((height / 2).toFixed(0));
    const marginRight = 0.34 * centralX - 158;
    const scale = Number(((-0.15 / 294) * centralX + (0.85 - 1024 * (-0.15 / 294))).toFixed(2));
    const radiusOne = Number((((centralX - marginRight) / 3) * scale).toFixed(0));
    const radiusTwo = 2 * radiusOne;
    const radiusThree = 3 * radiusOne;
    const halfBubbleSize = getBubbleSize(positionIndex) / 2;

    switch (positionIndex) {
      case 1: {
        return [(centralX - halfBubbleSize).toFixed(0), (centralY - radiusOne - halfBubbleSize).toFixed(0)];
      }
      case 2: {
        return [(centralX - cos(30) * radiusOne - halfBubbleSize + 40).toFixed(0), (centralY + sin(30) * radiusOne - halfBubbleSize - 50).toFixed(0)];
      }
      case 3: {
        return [(centralX + cos(30) * radiusOne - halfBubbleSize - 40).toFixed(0), (centralY + sin(30) * radiusOne - halfBubbleSize - 50).toFixed(0)];;
      }
      case 4: {
        return [(centralX + sin(19) * radiusTwo - halfBubbleSize).toFixed(0), (centralY - cos(19) * radiusTwo - halfBubbleSize).toFixed(0)];
      }
      case 5: {
        return [(centralX + sin(54) * radiusTwo - halfBubbleSize).toFixed(0), (centralY - cos(54) * radiusTwo - halfBubbleSize).toFixed(0)];
      }
      case 6: {
        return [(centralX + radiusTwo - halfBubbleSize).toFixed(0), (centralY - halfBubbleSize).toFixed(0)];
      }
      case 7: {
        return [(centralX + sin(54) * radiusTwo - halfBubbleSize).toFixed(0), (centralY + cos(54) * radiusTwo - halfBubbleSize).toFixed(0)];
      }
      case 8: {
        return [(centralX + sin(19) * radiusTwo - halfBubbleSize).toFixed(0), (centralY + cos(19) * radiusTwo - halfBubbleSize - 80).toFixed(0)];
      }
      case 9: {
        return [(centralX - sin(19) * radiusTwo - halfBubbleSize).toFixed(0), (centralY + cos(19) * radiusTwo - halfBubbleSize - 80).toFixed(0)];
      }
      case 10: {
        return [(centralX - sin(54) * radiusTwo - halfBubbleSize).toFixed(0), (centralY + cos(54) * radiusTwo - halfBubbleSize).toFixed(0)];
      }
      case 11: {
        return [(centralX - radiusTwo - halfBubbleSize).toFixed(0), (centralY - halfBubbleSize).toFixed(0)];
      }
      case 12: {
        return [(centralX - sin(54) * radiusTwo - halfBubbleSize).toFixed(0), (centralY - cos(54) * radiusTwo - halfBubbleSize).toFixed(0)];
      }
      case 13: {
        return [(centralX - sin(19) * radiusTwo - halfBubbleSize).toFixed(0), (centralY - cos(19) * radiusTwo - halfBubbleSize).toFixed(0)];
      }
      case 14: {
        return [(centralX + sin(50) * radiusThree - halfBubbleSize).toFixed(0), (centralY - cos(50) * radiusThree - halfBubbleSize - 20).toFixed(0)];
      }
      case 15: {
        return [(centralX + sin(75) * radiusThree - halfBubbleSize + 50).toFixed(0), (centralY - cos(75) * radiusThree - halfBubbleSize).toFixed(0)];
      }
      case 16: {
        return [(centralX + sin(75) * radiusThree - halfBubbleSize + 50).toFixed(0), (centralY + cos(75) * radiusThree - halfBubbleSize).toFixed(0)];
      }
      case 17: {
        return [(centralX + sin(54) * radiusThree - halfBubbleSize).toFixed(0), (centralY + cos(54) * radiusThree - halfBubbleSize - 20).toFixed(0)];
      }
      case 18: {
        return [(centralX - sin(54) * radiusThree - halfBubbleSize).toFixed(0), (centralY + cos(54) * radiusThree - halfBubbleSize - 20).toFixed(0)];
      }
      case 19: {
        return [(centralX - sin(75) * radiusThree - halfBubbleSize - 50).toFixed(0), (centralY + cos(75) * radiusThree - halfBubbleSize).toFixed(0)];
      }
      case 20: {
        return [(centralX - sin(75) * radiusThree - halfBubbleSize - 50).toFixed(0), (centralY - cos(75) * radiusThree - halfBubbleSize).toFixed(0)];
      }
      case 21: {
        return [(centralX - sin(50) * radiusThree - halfBubbleSize).toFixed(0), (centralY - cos(50) * radiusThree - halfBubbleSize - 20).toFixed(0)];
      }
      default: {
        return [0, 0];
      }
    }
  }

  const bubbleSize = {
    1: 170,
    2: 170,
    3: 170,
    4: 100,
    5: 100,
    6: 100,
    7: 100,
    8: 100,
    9: 100,
    10: 100,
    11: 100,
    12: 100,
    13: 100,
    14: 60,
    15: 60,
    16: 60,
    17: 60,
    18: 60,
    19: 60,
    20: 60,
    21: 60
  }

  function getBubbleSize(positionIndex) {
    return Object.keys(bubbleSize).includes(positionIndex.toString()) ? bubbleSize[positionIndex] : 0;
  }

  function getOriginStartPosition(positionIndex, width, height) {
    const centralX = Number((width / 2).toFixed(0));
    const centralY = Number((height / 2).toFixed(0));
    const marginRight = 0.34 * centralX - 158;
    const scale = Number(((-0.15 / 294) * centralX + (0.85 - 1024 * (-0.15 / 294))).toFixed(2));
    const radiusOne = Number((((centralX - marginRight) / 3) * scale).toFixed(0));
    const radiusTwo = 2 * radiusOne;
    const radiusThree = 3 * radiusOne;
    const halfBubbleSize = getBubbleSize(positionIndex) / 2;

    switch (positionIndex) {
      case 1: {
        return [0, (centralY - radiusOne - halfBubbleSize).toFixed(0)];
      }
      case 2: {
        return [0, (centralY + sin(30) * radiusOne - halfBubbleSize - 50).toFixed(0)];
      }
      case 3: {
        return [2 * centralX, (centralY + sin(30) * radiusOne - halfBubbleSize - 50).toFixed(0)];;
      }
      case 4: {
        return [(centralX + sin(19) * radiusTwo - halfBubbleSize).toFixed(0), 0 - 2 * halfBubbleSize];
      }
      case 5: {
        return [(centralX + sin(54) * radiusTwo - halfBubbleSize).toFixed(0), 0];
      }
      case 6: {
        return [2 * centralX, (centralY - halfBubbleSize).toFixed(0)];
      }
      case 7: {
        return [2 * centralX, (centralY + cos(54) * radiusTwo - halfBubbleSize).toFixed(0)];
      }
      case 8: {
        return [(centralX + sin(19) * radiusTwo - halfBubbleSize).toFixed(0), 2 * centralY];
      }
      case 9: {
        return [(centralX - sin(19) * radiusTwo - 4 * halfBubbleSize).toFixed(0), 2 * centralY];
      }
      case 10: {
        return [(centralX - sin(54) * radiusTwo - 4 * halfBubbleSize).toFixed(0), 2 * centralY];
      }
      case 11: {
        return [0, (centralY - halfBubbleSize).toFixed(0)];
      }
      case 12: {
        return [(centralX - sin(54) * radiusTwo - 4 * halfBubbleSize).toFixed(0), 0];
      }
      case 13: {
        return [(centralX - sin(19) * radiusTwo - halfBubbleSize).toFixed(0), 0 - 2 * halfBubbleSize];
      }
      case 14: {
        return [(centralX + sin(50) * radiusThree - halfBubbleSize).toFixed(0), 0 - 2 * halfBubbleSize];
      }
      case 15: {
        return [2 * centralX, (centralY - cos(75) * radiusThree - 4 * halfBubbleSize).toFixed(0)];
      }
      case 16: {
        return [2 * centralX, (centralY + cos(75) * radiusThree - halfBubbleSize).toFixed(0)];
      }
      case 17: {
        return [(centralX + sin(54) * radiusThree - halfBubbleSize).toFixed(0), 2 * centralY];
      }
      case 18: {
        return [0, 2 * centralY];
      }
      case 19: {
        return [0, (centralY + cos(75) * radiusThree - halfBubbleSize).toFixed(0)];
      }
      case 20: {
        return [0, (centralY - cos(75) * radiusThree - halfBubbleSize).toFixed(0)];
      }
      case 21: {
        return [(centralX - sin(50) * radiusThree - halfBubbleSize).toFixed(0), 0 - 2 * halfBubbleSize];
      }
      default: {
        return [0, 0];
      }
    }
  }

  return (
    <div>
      <div ref={boxRef} className={'logo-button'} style={sx}
        onClick={() => { routeToCompanyPage() }}
        onMouseEnter={() => {
          if (boxRef.current.style.animation && boxRef.current.style.animation.includes('slide-from')) {
            return;
          } else {
            boxRef.current.style.animation = '';
            boxRef.current.style.transform = 'scale(1.1)';
          }
        }}
        onMouseLeave={() => {
          if (boxRef.current.style.animation && boxRef.current.style.animation.includes('slide-from')) {
            return;
          } else {
            boxRef.current.style.transform = 'scale(1)';
            boxRef.current.style.animation = getBubbleFloatingAnimation();
          }
        }}
      >
        <img src={img} alt='company logo'></img>
      </div>

      <AJModalV2 visible={modalVisible}
        width={565}
        centered={true}
        destroyOnClose={true}
        onClose={() => setModalVisible(false)}
      >
        <div className={"modal-content-container"}>
          <div className={"modal-title"}>
            You are not yet done with the value selection. Do you still want to visit <span style={{ 'fontWeight': '700' }}>{companyName}</span>'s profile?
          </div>

          <div className={"button-container"}>
            <button className={'modal-button'} onClick={() => gotoLink(`/company-profile/${companyId}`, navigate)}>Visit the profile</button>
            <EmptySpan width={50} />
            <button className={'modal-button'} style={{ 'background': '#FFD865', 'border': '1px solid #FFD865' }}
              onClick={() => setModalVisible(false)}
            >Continue selection</button>
          </div>
        </div>
      </AJModalV2>
    </div>
  )
});

export default BubbleLogoButton;
