import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCustomValues, getSystemPrinciples, saveCustomValues, saveUserPrinciples, deleteCustomValue } from "saas/utils/http/SaasHttpClient";
import "./PrincipleSelection.less"
import SaaSContainer from "saas/SaaSContainer";
import EmptyLine from "ui/EmptyLine";
import { SystemPrincipleModel, ValueModel } from "saas/model/SystemPrincipleModel";
import AJPopover from "ui/AJPopover";
import SelectableIcon from "component/SelectableIcon";
import { PickValuesContextProps, SaasPickValuesContext } from "saas/context/SaasPickValuesProvider";
import { currentUserId } from "saas/utils/storage/SaasStorage";
import { message } from "antd";
import { CloseOutlined } from "@ant-design/icons";

const PrinciplesSelection: React.FC = () => {
    const navigate = useNavigate();

    const pickValueContext: PickValuesContextProps = useContext(SaasPickValuesContext) || {} as PickValuesContextProps;
    const pickedValues = pickValueContext.pickedValues;
    const updatePickedPrinciples = pickValueContext?.updatePickedValues;
    const leftValueCount = pickValueContext?.leftValueCount || (() => { });

    const [selectedPrinciple, setSelectedPrinciple] = useState<string | null>(null);
    const [selectedPrincipleType, setSelectedPrincipleType] = useState<string | null>(null);
    const [btnDisabled, setBtnDisabled] = useState<boolean>(false);
    // const [pickedValues, setPickedValues] = useState({} as any);

    const [valuesConfig, setValueConfig] = useState<SystemPrincipleModel[] | null>(null);

    useEffect(() => {
        Promise.all([getSystemPrinciples(), getCustomValues()]).then(res => {
            const systemPrinciples = res[0];
            const customValues = res[1];
            const updatedSystemPrinciples = systemPrinciples.map((principle: SystemPrincipleModel) => {
                const addedCustomValues = customValues
                    .filter((item: any) => item.valueCategoryName === principle.valueCategoryName)
                    .map((item: any) => ({
                        value: item.customValue,
                        image: '',
                        order: Math.max(...principle.values.map(v => v.order), -1) + 1,
                        tips: '',
                        isCustom: true,
                    }));

                return {
                    ...principle,
                    values: [...principle.values, ...addedCustomValues]
                };
            });

            setValueConfig(updatedSystemPrinciples);
        });
    }, []);

    useEffect(() => { setBtnDisabled(() => { return 9 - leftValueCount() === 0 }) }, [leftValueCount()]);

    // Handle selecting a principle (toggle values display)
    const handleSelectPrinciple = (principle: SystemPrincipleModel) => {
        setSelectedPrinciple(principle.valueCategoryName);
        setSelectedPrincipleType(principle.type);
    };

    const handlePickedValues = (principle: string, value: string) => {
        const currentValues = pickedValues()[principle] || [];

        if (currentValues.includes(value)) {
            // unselect value, remove it from the list
            const newValues = { ...pickedValues() };
            newValues[principle] = currentValues.filter(v => v !== value);
            updatePickedPrinciples(newValues);
        } else {
            const remaining = leftValueCount();
            if (remaining <= 0) {
                message.warning("You have selected the maximum number of values.");
                return;
            }

            // select value, add it to the list
            const newValues = { ...pickedValues() };
            newValues[principle] = [...currentValues, value];
            updatePickedPrinciples(newValues);
        }
    };

    // Handle adding a custom value
    const handleAddValue = (principle: string, newValue: string) => {
        if (!newValue.trim()) return;
        if (newValue.length > 50) {
            message.warning("Custom value should be less than 50 characters.");
            return;
        }

        const exists = valuesConfig?.find(p =>
            p.valueCategoryName === principle
        )?.values.some(v => v.value === newValue);

        if (exists) {
            message.warning("This value already exists.");
            return;
        }

        const request = { "valueCategoryName": principle, "value": newValue };
        saveCustomValues(request);

        setValueConfig((prev: SystemPrincipleModel[] | null) =>
            (prev || []).map((p) =>
                p.valueCategoryName === principle
                    ? {
                        ...p,
                        values: [
                            ...p.values,
                            {
                                image: '',
                                order: Math.max(...p.values.map((v) => v.order), -1) + 1,
                                tips: '',
                                value: newValue,
                                isCustom: true,
                            },
                        ],
                    }
                    : p
            )
        );
    };

    const handleDeleteCustomValue = (principle: string, valueToDelete: string) => {
        const newPickedValues = { ...pickedValues() };
        newPickedValues[principle] = newPickedValues[principle]?.filter(v => v !== valueToDelete);
        updatePickedPrinciples(newPickedValues);

        setValueConfig(prev => {
            if (!prev) return null;
            return prev.map(p => {
                if (p.valueCategoryName === principle) {
                    return {
                        ...p,
                        values: p.values.filter(v => v.value !== valueToDelete)
                    };
                }
                return p;
            });
        });

        deleteCustomValue(principle, valueToDelete).catch(error => {
            console.error("Failed to delete custom value:", error);
            message.error("Failed to delete custom value");
        });
    };

    const getPrincipleIsSelected = (principle: string) => {
        return selectedPrinciple === principle;
    }

    const getTagClassName = (value: string) => {
        return pickedValues()[selectedPrinciple || '']?.includes(value) ? "ajtag-selected-light" : "ajtag-light";
    }

    const generatePage = () => {
        const userPickedValues = pickedValues();
        // const userPickedValues: any = [];
        const req = {
            principles: userPickedValues,
            userId: currentUserId(),
        }
        saveUserPrinciples(req).then(() => {
            navigate('/saas/customer/profile');
        });
    }

    const renderIcons = (selectedPrinciple: string, value: ValueModel) => {
        const tips = value.tips
        return (
            <AJPopover content={tips} placement={'right'} width={140} scheme={'dark'}>
                <div style={{ display: "inline-block", marginRight: "16px", marginBottom: "8px" }}>
                    <SelectableIcon
                        icon={`/img/value/${value.image}.svg`}
                        data={value}
                        onClick={(selected: string, title: string) => {
                            handlePickedValues(selectedPrinciple, value.value);
                        }}
                        checkSelected={(title: string) => {
                            const v = title.replace(/-/g, ' ')
                            return pickedValues()[selectedPrinciple]?.includes(v);
                        }}
                        allowSelect={() => {
                            return leftValueCount() > 0
                        }}
                    />
                </div>
            </AJPopover>
        )
    }

    return (
        <SaaSContainer>
            <div className='select-principle-container'>
                <div className='left-col'>
                    <div className='select-principle-heading'>Select Principles that define your team to build cohesion and attract aligned talent.</div>
                    <div>
                        <div className='subheading'>Principles</div>
                        <div className='select-principle-card-container'>
                            {
                                valuesConfig?.map((principle: SystemPrincipleModel, index: number) => {
                                    return (
                                        <div key={index} className={`principle-card ${getPrincipleIsSelected(principle.valueCategoryName) ? 'is-selected' : ''}`} onClick={() => handleSelectPrinciple(principle)}>
                                            <div className="text-lines">
                                                {principle.valueCategoryName}
                                            </div>
                                        </div>
                                    )
                                })
                            }

                        </div>
                        <div className="values-container">
                            {selectedPrinciple && (
                                <>
                                    <div className='subheading'>Select</div>
                                    <div className="pick-values">
                                        <div className="pick-values-container">
                                            {
                                                selectedPrincipleType !== 'icon' && valuesConfig?.find((p: any) => p.valueCategoryName === selectedPrinciple)
                                                    ?.values.map((value: ValueModel, index: number) => (
                                                        <div key={index} className="tag-container">
                                                            <span className="tag-span">
                                                                <div className={getTagClassName(value.value)} onClick={() => handlePickedValues(selectedPrinciple, value.value)}>
                                                                    {value.value}
                                                                    {value.isCustom && <span className="custom-delete-icon" title="Delete Custom Value" onClick={(e) => {
                                                                        e.stopPropagation(); // Prevent parent's onClick
                                                                        handleDeleteCustomValue(selectedPrinciple, value.value);
                                                                    }}><CloseOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} /></span>}
                                                                </div>
                                                            </span>
                                                        </div>
                                                    ))
                                            }
                                            {
                                                selectedPrincipleType !== 'icon' && <div className="tag-container">
                                                    <span className="tag-span">
                                                        <input className="ajtag-light" placeholder="Add your own value"
                                                            onKeyDown={(e) => {
                                                                if (e.key === "Enter") {
                                                                    handleAddValue(selectedPrinciple, e.currentTarget.value);
                                                                    e.currentTarget.value = "";
                                                                }
                                                            }}
                                                        />
                                                    </span>
                                                </div>
                                            }
                                            {
                                                selectedPrincipleType === 'icon' && valuesConfig?.find((p: any) => p.valueCategoryName === selectedPrinciple)
                                                    ?.values.map((value: ValueModel, index: number) => (
                                                        renderIcons(selectedPrinciple, value)
                                                    ))
                                            }
                                        </div>
                                        <div className="pick-values-foot">
                                            Total values left {leftValueCount()}
                                        </div>
                                    </div>
                                </>
                            )}

                        </div>

                    </div>

                </div>
                <div className='right-col'>
                    <div className='generate-page-button-tips'>
                        Done with value selection?<br />
                        Generate page when you are ready
                    </div>
                    <EmptyLine height={20} />
                    <button disabled={btnDisabled} className="generate-page-button" onClick={() => generatePage()}>Generate Page</button>
                </div>
            </div>
        </SaaSContainer>
    );
};

export default PrinciplesSelection;
