import React from "react";

import "./TermsConditionsPage.less"
import EmptyLine from "../ui/EmptyLine";
import {HCenterContainer} from "../ui/VCenterContainer";
import StartFooter from "../component/start/StartFooter";

export default function TermsConditionsPage(props) {
    return (
        <>
        <HCenterContainer>
        <div className={'terms-conditions-page'}>
            <div className={'terms-conditions-page-container'}>
                <EmptyLine height={666}/>
                <div className={'terms-conditions-title'}>
                    Terms &<br/> Conditions
                </div>
                <EmptyLine height={200}/>
                <HCenterContainer>
                <div className={'terms-conditions-container'}>
                    <img src={'/img/common/terms.svg'}/>
                </div>
                </HCenterContainer>
                <EmptyLine height={200}/>
            </div>
        </div>
        </HCenterContainer>
        <StartFooter/>
        </>
    )
}
