import React from "react";

import { Props } from "saas/model/SaasCommonModel";

const SaasProfileLayout: React.FC<Props> = (props) => {
    return (
        <div className="saas-profile-layout" style={{ display: 'flex', width: '100%', height: '100%' }}>
            <div style={{ flex: 1 }}></div>
            <div style={{ flex: 22 }}>
                {props.children}
            </div>
            <div style={{ flex: 1 }}></div>
        </div>
    )
};

export default SaasProfileLayout
