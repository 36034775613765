import React from "react";
import { Props } from "saas/model/SaasCommonModel";

const SaasStartLayout: React.FC<Props> = (props) => {
  return (
    <div className="saas-start-layout" style={{ display: 'flex', width: '100%' }}>
      <div style={{ flex: 2 }}></div>
      <div style={{ flex: 20 }}>
        {props.children}
      </div>
      <div style={{ flex: 2 }}></div>
    </div>
  )
};

export default SaasStartLayout
