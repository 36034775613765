import React, { useState } from "react";
import { useDrag, useDrop } from 'react-dnd'

import "./AJTag.less"

function AJTag(props) {
    let { title,
        onTagClick,
        data,
        popover,
        toggleHover,
        colorStyle,
        checkSelected,
        allowedSelect } = props
    colorStyle = colorStyle || 'dark'
    allowedSelect = allowedSelect || (() => true)
    toggleHover = props.toggleHover || (() => { })
    let toggleHoverWrapper = (on, title) => {
        toggleHover(on, title)
    }

    let selectedStyle, style
    if (colorStyle === 'light') {
        selectedStyle = 'ajtag-selected-light'
        style = 'ajtag-light'
    }
    else {
        selectedStyle = 'ajtag-selected'
        style = 'ajtag'
    }
    checkSelected = checkSelected || (() => false)
    let [selected, setSelected] = useState(checkSelected(title))
    onTagClick = onTagClick || function (selected, title) { }
    const className = checkSelected(title) ? selectedStyle : style
    selected = checkSelected(title)
    // console.log(`${title} current is ${selected}`)

    return (
        <div className={className} onClick={onClickTag} onMouseOver={() => {
            toggleHoverWrapper(true, title)
        }} onMouseLeave={() => {
            toggleHoverWrapper(false, title)
        }}>
            {props.children ? props.children : (<div className={'disable-user-select'}> {title} </div>)}
        </div>
    )

    function onClickTag() {
        // not selected and do not allow to select
        // console.log(selected)
        if (selected === false) {
            if (!allowedSelect()) {
                // donothing
                return
            }
        }

        onTagClick(!selected, title)
        setSelected(!selected)

    }
}

export default AJTag


