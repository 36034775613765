import React, { useState, useEffect, useMemo, useRef, useLayoutEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { debounce } from 'lodash';
import './valueSelect.less';
import BubbleLogoButton from './bubbleLogo';
import SliderContainer from './sliderContainer';
import ScrollTab from './scrollTab';
import { defaultValuesConfig3, valueListWithIndex, valuePatch, valuePatchReverse } from '../../component/values/ValuesConfig';
import { talentValueMatch, getAllCompanies } from "../../http/AJHttp";
import AJLogo from "../../ui/AJLogo";
import { calcGap } from "../../ui/AJButton3";

export default function ValueSelectWrapper(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [containerWidthHeight, setContainerWidthHeight] = useState([0, 0]);
  let [selectIndex, setSelectIndex] = useState(0);
  let [isExpand, setIsExpand] = useState(false);
  let [selectedValues, setSelectedValues] = useState(defaultValuesConfig3);
  let [neededFetchMatched, setNeededFetchMatched] = useState();
  let [showInitialPage, setShowInitialPage] = useState(false);
  let [showLoadingPage, setShowLoadingPage] = useState(false);
  let [initialPageSx, setInitialPageSx] = useState({});
  const [isExpandHovered, setIsExpandHovered] = useState(false);
  const [canViewAllMatches, setCanViewAllMatches] = useState(false);
  const [isMounted, setIsMounted] = useState(true);
  const [hintShow, setHintShow] = useState({
    'value1': Array(12).fill(0),
    'value2': Array(9).fill(0),
    'value3': Array(6).fill(0),
    'value4': Array(6).fill(0),
    'value5': Array(6).fill(0),
    'value6': Array(7).fill(0),
    'value7': Array(10).fill(0),
  });
  const [hintShowExpand, setHintShowExpand] = useState(hintShow);
  let [valueHintSx, setValueHintSx] = useState({});
  let [valueSelectContainerBorderWidth, setValueSelectContainerBorderWidth] = useState(undefined);
  const [scrollX, setScrollX] = useState(0);
  const buttonAnimationGap = useMemo(() => { return calcGap('View all matches') }, ['View all matches']);
  const myElementRef = useRef(null);

  useEffect(() => {
    const addFilterPropInSelectedValues = (filterValues) => {
      setSelectedValues(prevSelectedValues => {
        prevSelectedValues.forEach((item, index) => {
          item.values.forEach(subItem => {
            if (!filterValues[index].includes(subItem?.value)) {
              subItem['isFiltered'] = true;
            }
          })
        });
        return [...prevSelectedValues];
      });
    };

    const getShouldFilterValues = (companyInfo) => {
      let filterValues = Array.from({ length: 7 }, (_, index) => index).reduce((obj, key) => ({ ...obj, [key]: [] }), {});
      companyInfo.forEach(item => {
        if (item?.published) {
          Object.values(item.describeValues).forEach(value => {
            const valueText = value.value;
            const valueTextPatch1 = valuePatch(valueText);
            const valueTextPatch2 = valuePatchReverse(valueText);
            const allValues = [...new Set([valueText, valueTextPatch1, valueTextPatch2])];

            Object.keys(valueListWithIndex).forEach(index => {
              let tmpList = valueListWithIndex[index];
              let newIndex = index;
              if (index == 1) {
                newIndex = 6;
              } else if (index == 0) {
                newIndex = index;
              } else {
                newIndex = index - 1;
              }
              if (tmpList.includes(valueText.toLowerCase()) || tmpList.includes(valueTextPatch1.toLowerCase())
                || tmpList.includes(valueTextPatch2.toLowerCase())) {
                filterValues[newIndex] = filterValues[newIndex].concat(allValues);
              }
            })
          });
        }
      });
      sessionStorage.setItem('cachedFilteredValues', JSON.stringify(filterValues));
      return filterValues;
    };

    const fetchData = async () => {
      try {
        let shouldFilterValues = JSON.parse(sessionStorage.getItem('cachedFilteredValues'));
        if (!shouldFilterValues) {
          let cachedCompanyInfo = JSON.parse(sessionStorage.getItem('cachedCompanyInfo'));
          if (!cachedCompanyInfo) {
            const response = await getAllCompanies();
            if (response?.data && response.data.length > 0) {
              cachedCompanyInfo = response.data;
            }

            shouldFilterValues = getShouldFilterValues(cachedCompanyInfo);
            addFilterPropInSelectedValues(shouldFilterValues);
          } else {
            shouldFilterValues = getShouldFilterValues(cachedCompanyInfo);
            addFilterPropInSelectedValues(shouldFilterValues);
          }
        } else {
          addFilterPropInSelectedValues(shouldFilterValues);
        }
      } catch (error) { }
    }

    fetchData();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPositionX = window.scrollX || document.documentElement.scrollLeft;
      setScrollX(scrollPositionX);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    return () => {
      // When component destorys, set isMounted: false
      setIsMounted(false);
    };
  }, []);

  useEffect(() => {
    const border = document.getElementById('value-select-border');
    setValueSelectContainerBorderWidth(border?.offsetWidth);

    const element = myElementRef.current.getBoundingClientRect();
    if (element) setContainerWidthHeight([Math.floor(element.width), Math.floor(element.height)]);
    setInitialPageSx({ ...initialPageSx, 'minHeight': `${window.innerHeight - 300}px` });
  }, []);

  useEffect(() => {
    function solveBrowserBack(values) {
      if (selectedValuesNum(values)) {
        setCanViewAllMatches(true);
        setSelectedValues(values);
        setShowInitialPage(false);
        setNeededFetchMatched(true);
      } else {
        setCanViewAllMatches(false);
        setShowInitialPage(true);
      }
    }

    const cachedSelectedValues = JSON.parse(sessionStorage.getItem('cachedSelectedValues'))?.selectedValues;
    const cachedBubbleData = JSON.parse(sessionStorage.getItem('cachedSelectedValues'))?.bubbleData;
    const cachedOnlySelectedValues = JSON.parse(sessionStorage.getItem('cachedOnlySelectedValues'));

    let isSelectedValueChanged = location.state?.isChanged;
    let newSelectedValue = location.state?.selectedValues;

    if (isBool(isSelectedValueChanged) && isSelectedValueChanged && newSelectedValue) {
      // selected values has been changed and the number is not zero
      solveBrowserBack(newSelectedValue);
      return;
    } else {
      if (cachedOnlySelectedValues && cachedSelectedValues && cachedOnlySelectedValues &&
        !compareSelectedValues(cachedOnlySelectedValues, cachedSelectedValues)) {
        // select values passed is different with cache
        solveBrowserBack(cachedOnlySelectedValues);
        return;
      }
      if (cachedSelectedValues && cachedBubbleData) {
        setCanViewAllMatches(true);
        setShowInitialPage(false);
        setSelectedValues(cachedSelectedValues);
        setBubbleLogoData(cachedBubbleData);
        return;
      }
    }
    setShowInitialPage(true);
  }, [location]);


  // debounce user's select input
  const fetchMatchedCompanies = debounce(() => {
    setNeededFetchMatched(!neededFetchMatched);
  }, 800); // debounce time is 0.8 seconds

  // debounce browser window change behavior
  const redrawWithWindowChange = debounce(() => {
    const parentElement = document.getElementById('bubble-logo-container');
    if (parentElement) {
      const width = parentElement.offsetWidth;
      const height = parentElement.offsetHeight;
      if (width === containerWidthHeight[0] && height === containerWidthHeight[1]) return;
      setContainerWidthHeight([width, height]);
    } else {
      setInitialPageSx({ ...initialPageSx, 'minHeight': `${window.innerHeight - 300}px` });
    }
  }, 1000); // debounce time is 1 seconds

  // redraw bubble logos due to zoom in and out behavior
  function handleZoom() {
    redrawWithWindowChange();
  }

  // redraw bubble logos due to browser window size change behavior
  useEffect(() => {
    const handleWindowResize = () => {
      const border = document.getElementById('value-select-border');
      setValueSelectContainerBorderWidth(border?.offsetWidth);
      redrawWithWindowChange();
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (neededFetchMatched == undefined) {
      return;
    }

    // get selected values
    let selectedValuesList = [];
    for (let valueType of selectedValues) {
      for (let value of valueType['values']) {
        if (value['isSelected']) {
          selectedValuesList.push(value['value']);
        }
      }
    }
    if (isMounted) {
      fetchTopMostCompanies(selectedValuesList, 1);
    }
  }, [neededFetchMatched]);

  let [bubbleLogoData, setBubbleLogoData] = useState([]);
  let lastData = [];
  const fetchTopMostCompanies = async (values, page) => {
    if (!showLoadingPage) setShowLoadingPage(true);

    values = values || [];
    page = page || 1;
    let originalDataList = [];
    let hasNext = true;
    let matchedNumber = 0;
    while (hasNext && page <= 1) {
      const response = await talentValueMatch(values, page, 21);
      if (response) {
        const tmpList = response.data.results;
        if (tmpList && tmpList.length > 0) {
          matchedNumber = Number(tmpList[0]['matched']);
          originalDataList = originalDataList.concat(tmpList);
        }
        if (originalDataList.length >= matchedNumber) {
          break;
        }
        if (!response.data.next) {
          hasNext = false;
        } else {
          page += 1;
        }
      }
    }

    let result = [];
    if (originalDataList && originalDataList.length > 0) {
      for (let index in originalDataList) {
        if (index >= matchedNumber) {
          break;
        }
        const item = originalDataList[index];
        const logoUrl = item?.logoUrl ? item?.logoUrl : '';
        result.push({ company_name: item['company_name'], company_id: Number(item['company_id']), logoUrl: 'https://genvalues.com/' + logoUrl });
      }
    }

    lastData = bubbleLogoData;
    const tmp = [];
    for (let index in result) {
      const companyId = result[index].company_id.toString();
      let isExistCompany = false;
      let positionOfExistCompany = Number(index) + 1;
      for (let company of lastData) {
        if (company['companyId'] === companyId) {
          isExistCompany = true;
          positionOfExistCompany = Number(company['to']);
          break;
        }
      }
      if (!isExistCompany) {
        tmp.push({ imgUrl: result[index]['logoUrl'], companyId: companyId, companyName: result[index]['company_name'], from: 0, to: Number(index) + 1 });
      } else {
        tmp.push({ imgUrl: result[index]['logoUrl'], companyId: companyId, companyName: result[index]['company_name'], from: positionOfExistCompany, to: Number(index) + 1 });
      }
    }
    const tmpObj = {
      selectedValues: selectedValues,
      bubbleData: tmp
    };
    sessionStorage.setItem('cachedSelectedValues', JSON.stringify(tmpObj));
    setShowLoadingPage(false);
    setBubbleLogoData(tmp);
  }

  function indexChange(index) {
    setSelectIndex(index);
  };

  function handleExpand() {
    if (setIsExpandHovered) {
      setIsExpandHovered(false);
    }
    setIsExpand(!isExpand);
  }

  const handleValueSelect = (event, valueTypeIndex, valuesIndex) => {
    if (showInitialPage) {
      setShowInitialPage(false);
    }
    let tmp = selectedValues;
    tmp[valueTypeIndex]["values"][valuesIndex]["isSelected"] = !tmp[valueTypeIndex]["values"][valuesIndex]["isSelected"];
    event.target.className = tmp[valueTypeIndex]["values"][valuesIndex]["isSelected"] ? 'value-item is-selected' : 'value-item';
    selectedValues = tmp;
    fetchMatchedCompanies();
  };

  function jumpToMatchCompanyPage() {
    if ((selectIndex === 6 || canViewAllMatches) && selectedValuesNum(selectedValues)) {
      sessionStorage.setItem('cachedOnlySelectedValues', JSON.stringify(selectedValues));
      navigate('/companies-matched', { state: { selectedValues: selectedValues } });
    }
  }

  function isBool(value) {
    return typeof value === 'boolean';
  }

  function selectedValuesNum(list) {
    let num = 0;
    for (let valueType of list) {
      for (let value of valueType['values']) {
        if (value?.isSelected) {
          num += 1;
        }
      }
    }
    return num;
  }

  function compareSelectedValues(source, target) {
    for (let i = 0; i < source.length; i++) {
      for (let j = 0; j < source[i]['values'].length; j++) {
        const srcIsSelcted = source[i]['values'][j]?.isSelected ? true : false;
        const tagIsSelected = target[i]['values'][j]?.isSelected ? true : false;
        if (srcIsSelcted !== tagIsSelected) {
          return false;
        }
      }
    }
    return true;
  }

  function replaceAll(input, search, replacement) {
    const regex = new RegExp(search, 'g');
    return input.replace(regex, replacement);
  }

  return (
    <div className='total-container' onWheel={handleZoom}>

      <div className={'navigator-title'} onClick={() => { navigate("/") }}>
        {<AJLogo schema={'black'} />}
      </div>

      <div className='value-select-container'>
        <div className='border' id='value-select-border'>
          <div className="select-tab">
            <ScrollTab onIndexChange={indexChange}></ScrollTab>
          </div>

          {
            isExpand ? <img src={"/img/value-select/not-expand.svg"}
              className="expand-button"
              onClick={() => handleExpand()} /> :
              <>
                {
                  showInitialPage || isExpandHovered ?
                    <div className={`${showInitialPage || isExpandHovered ? 'has-hint' : ''}`}>
                      <div className={'border'}></div>
                      <img src={"/img/value-select/expand-hint.svg"} />
                    </div> : <></>
                }
                <img src={"/img/value-select/expand.svg"}
                  className="expand-button"
                  onClick={() => handleExpand()}
                  onMouseEnter={() => setIsExpandHovered(true)}
                  onMouseLeave={() => setIsExpandHovered(false)} />
              </>
          }

          {
            <button className={`matched-company-button ${(((selectIndex === 6) || canViewAllMatches) && selectedValuesNum(selectedValues)) ? 'active' : ''}`}
              onClick={() => { jumpToMatchCompanyPage() }}>
              {'View all matches'.split(' ').map((item, index) => {
                const gap = buttonAnimationGap[index];
                return [...Array.from(item).map((letter, index1) => {
                  return <span key={index1} style={{ animationDelay: `${(index1 + gap) * 0.02}s`, display: 'inline-block' }} className='title-letter'>{letter}</span>
                }), index !== 2 ? <span>{' '}</span> : <></>]
              })}
            </button>
          }

          {
            !isExpand ?
              selectedValues.map((values, index) => {
                return (
                  <div className={`${selectIndex !== index ? 'not-show' : ''}`}>
                    {values['values'].map((item, index1) => {
                      let tmp = 'value' + (index + 1);
                      return <img src={`/img/value-select/value-item-hint-${index + 1}-${index1 + 1}.svg`} className={`hint-img ${!hintShow[tmp][index1] ? 'not-show' : ''}`} style={{ ...valueHintSx, 'position': 'absolute' }}></img>
                    })}

                    <SliderContainer>
                      <>
                        {values['values'].map((item, index1) => (
                          <button className={`value-item ${item["isSelected"] ? 'is-selected' : ''} ${item["isFiltered"] ? 'is-filtered' : ''}`} key={index1} id={`button-${index}-${index1}`}
                            onClick={(event) => { handleValueSelect(event, index, index1) }}
                            onMouseEnter={(event) => {
                              const button = document.getElementById(`button-${index}-${index1}`).getBoundingClientRect();
                              hintShow['value' + (index + 1)][index1] = 1;
                              const tmp1 = { ...hintShow };
                              const leftBoundary = button.left > 0 ? button.left : 0;
                              const left = (button.right - leftBoundary) / 2 + leftBoundary - 104;
                              if (left + scrollX + 104 * 2 <= valueSelectContainerBorderWidth) {
                                setValueHintSx({ ...valueHintSx, 'bottom': '93px', 'left': `${left + scrollX}px` });
                                setHintShow(tmp1);
                              }
                              event.target.className = event.target.className.includes('is-hovered') ? event.target.className : event.target.className + ' is-hovered';
                            }}
                            onMouseLeave={(event) => {
                              hintShow['value' + (index + 1)][index1] = 0;
                              const tmp2 = { ...hintShow };
                              setHintShow(tmp2);
                              event.target.className = replaceAll(event.target.className, ' is-hovered', '');
                            }}
                          >
                            {item.image ? <img src={`/img/value/${item.image}.svg`} /> : <></>}
                            {item.displayValue ? item.displayValue : item.value}
                          </button>
                        ))}
                      </>
                    </SliderContainer>
                  </div>
                )
              }) :
              selectedValues.map((values, index) => {
                return (
                  <div className={`${selectIndex !== index ? 'not-show' : ''}`}>
                    {values['values'].map((item, index1) => {
                      let tmp = 'value' + (index + 1);
                      return <img src={`/img/value-select/value-item-hint-${index + 1}-${index1 + 1}.svg`} className={`hint-img ${!hintShowExpand[tmp][index1] ? 'not-show' : ''}`} style={{ ...valueHintSx, 'position': 'absolute', 'z-index': '1' }}></img>
                    })}

                    {values['values'].map((item, index1) => (
                      <button className={`value-item ${item["isSelected"] ? 'is-selected' : ''} ${item["isFiltered"] ? 'is-filtered' : ''}`} style={{ 'marginBottom': '18px' }} key={index1} id={`button-expand-${index}-${index1}`}
                        onClick={(event) => { handleValueSelect(event, index, index1) }}
                        onMouseEnter={(event) => {
                          const button = document.getElementById(`button-expand-${index}-${index1}`).getBoundingClientRect();
                          hintShowExpand['value' + (index + 1)][index1] = 1;
                          const tmp1 = { ...hintShowExpand };
                          const leftBoundary = button.left > 0 ? button.left : 0;
                          const left = (button.right - leftBoundary) / 2 + leftBoundary - 104;
                          const distanceToBottom = document.documentElement.clientHeight - button.bottom;
                          setValueHintSx({ ...valueHintSx, 'bottom': `${distanceToBottom + 27}px`, 'left': `${left + scrollX}px` });
                          setHintShowExpand(tmp1);
                          event.target.className = event.target.className + ' is-hovered';
                        }}
                        onMouseLeave={(event) => {
                          hintShowExpand['value' + (index + 1)][index1] = 0;
                          const tmp2 = { ...hintShowExpand };
                          setHintShowExpand(tmp2);
                          event.target.className = event.target.className.replace(' is-hovered', '');
                        }}
                      >
                        {item.image ? <img src={`/img/value/${item.image}.svg`} /> : <></>}
                        {item.displayValue ? item.displayValue : item.value}
                      </button>
                    ))}
                  </div>
                )
              })
          }
        </div>

      </div>


      <div style={{ width: '100%', height: '100%' }} ref={myElementRef}>
        {
          showInitialPage ?
            <div className={"initial-page-container"} style={initialPageSx}>
              <div className={"initial-page has-background"}>
                <img src={"/img/value-select/select-hint.svg"}></img>
              </div>
            </div> : showLoadingPage ?
              <div className={"initial-page-container"} style={initialPageSx}>
                <div className={"initial-page"}>
                  <img src={"/img/value-select/select-loading.svg"} style={{ width: '90px', animation: 'rotate 1s linear infinite' }} />
                </div>
              </div> :
              <div className={"bubble-logo-container"} id={'bubble-logo-container'}>
                {
                  bubbleLogoData.map((item, index) => {
                    return <BubbleLogoButton key={index} img={item.imgUrl} containerWidthHeight={containerWidthHeight} from={item.from} to={item.to} companyId={item.companyId} companyName={item.companyName} currentIndex={selectIndex} />
                  })
                }
              </div>
        }
      </div>
    </div>
  )
}
