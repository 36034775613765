import React from "react";

function SvgOutlineArrowRight(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path d="M10 17l5-5-5-5v10z" fill="#000" />
    </svg>
  );
}

export default SvgOutlineArrowRight;
