import React, { useMemo } from 'react';
import { Modal, Typography, Input } from 'antd';
import SaasImageUploadButton from './SaasImageUploadButton';
import './Overlay01.less';
import SaasProfileLayout from './SaasProfileLayout';
import EmptyLine from 'ui/EmptyLine';
import { OverlayProps, SaasUserProfileInfo } from 'saas/model/SaasCommonModel';
import OverlayLayout from './OverlayLayout';

const { Paragraph, Text } = Typography;
const { TextArea } = Input;

const Overlay01: React.FC<OverlayProps> = ({ isOpen, profileInfo, onSave, onClose }) => {
  const closeModal = () => onClose();

  const renderModalContent = useMemo(() => () => (
    <div className='overlay-01-modal-content'>
      <div className="overlay-head-1">Edit Section</div>
      <EmptyLine height={20} />
      <div className="overlay-description">
        Here You can add content and customize your page
      </div>
      <EmptyLine height={55} />

      <div className="overlay-head-2">Add Your Logotype</div>
      <EmptyLine height={2} />
      <div className="overlay-format-desc">Format should be SVG, JPG, PNG</div>

      <EmptyLine height={30} />
      <SaasImageUploadButton
        image={profileInfo.logotype}
        onImageChange={(url) =>
          profileInfo && onSave({ ...profileInfo, logotype: url })
        }
      />

      <EmptyLine height={70} />
      <div className="overlay-head-2">Headline</div>
      <EmptyLine height={15} />
      <Input className="overlay-one-line-input"
        value={profileInfo.profileHeadline}
        onChange={(e) =>
          profileInfo && onSave({ ...profileInfo, profileHeadline: e.target.value })
        }
      />

      <EmptyLine height={70} />
      <div className="overlay-head-2">Add your hero image here</div>
      <EmptyLine height={2} />
      <div className="overlay-format-desc">Format should be JPG, PNG</div>

      <EmptyLine height={25} />
      <SaasImageUploadButton
        image={profileInfo.profileImage}
        onImageChange={(url) =>
          profileInfo && onSave({ ...profileInfo, profileImage: url })
        }
      />

      <EmptyLine height={70} />
      <div className="overlay-head-2">Engineering Culture Title</div>
      <EmptyLine height={15} />
      <Input className="overlay-one-line-input"
        value={profileInfo.engineeringCultureTitle}
        onChange={(e) =>
          profileInfo && onSave({ ...profileInfo, engineeringCultureTitle: e.target.value })
        }
      />

      <EmptyLine height={70} />
      <div className="overlay-head-2">Engineering Culture Content</div>
      <EmptyLine height={15} />
      <TextArea className="overlay-multi-line-input" rows={4}
        value={profileInfo.engineeringCultureContent}
        onChange={(e) =>
          profileInfo && onSave({ ...profileInfo, engineeringCultureContent: e.target.value })
        }
      />
    </div>
  ), [profileInfo, onSave]);

  return (
    <Modal
      open={isOpen}
      width="100%"
      onCancel={closeModal}
      footer={null}
      className="overlay-01-modal"
    >
      <SaasProfileLayout>
        <OverlayLayout layoutWidth="62%">
          {renderModalContent()}
        </OverlayLayout>
      </SaasProfileLayout>
    </Modal>
  );
};

export default Overlay01;