import React from "react";
export const defaultValuesConfig = [
    {
        index: 0,
        key: "Team value",
        textLines: ['Team Values', ''],
        cardTitle: "Pick your most important values",
        hPadding: 99,
        layout: [5, 4, 4],
        values: [
            {
                "value": "Diversity",
                "tips": "Members of your team are diverse in gender, ethnicity, age, and professional background.",
                questions: <>

                    1. What does diversity mean to your company? Do you have engineers from diverse age groups, nationalities, professional backgrounds, stages of life, or something else?<br /><br />

                    2. What percentage of your workforce are women, ethnic minorities, differently-abled, or people of color?<br /><br />

                    3. What percentage of your leadership team consists of women, ethnic minorities, differently-abled, or people of color?<br /><br />

                    4. How do you work to promote diversity within your team and the broader tech ecosystem?<br /><br />

                    5. What efforts do you make to become more diverse and accepting of all cultures and backgrounds?<br /><br />

                    6. How do you align your internal HR policies (e.g., hiring, promotion, and evaluation, etc.) to create more diversity?<br /><br />

                    7. How do you educate and sensitize engineers towards the benefits of diversity?
                </>,
            },
            {
                "value": "EQ>IQ",
                "tips": "Excellent technical skills are a must but the ability to get along with others’ in the team, show empathy, and be self-motivated makes for a great team member.",
                questions: <>
                    1. What are the most valuable traits (other than intelligence) of engineers working at your company? E.g. empathy, thoughtfulness, helpful, kind and considerate, etc?<br /><br />

                    2. How important it is to put team goals before personal ambitions?<br /><br />

                    3. Ask an engineer to share his or her view on the traits of the coworkers that they like.<br /><br />

                    4. How do engineers demonstrate empathy at work? For example, blameless postmortems of issues, helping each other, understanding different perspectives, showing respect towards opinions different from theirs, etc.<br /><br />

                    5. During idea generation, how are engineers able to voice their ideas and opinions, without yelling, aggression, and harassment?
                </>
            },
            {
                "value": "Impressive Team Members",
                "tips": "Work alongside some of the best talent in the industry.",
                questions: <>

                    1. What are the outside-work interests of your engineers? Is there a baker, guitarist, fiction writer, or sketch artist among your engineers?<br /><br />

                    2. Share a story about a small idea or effort by an engineer turned into sweeping innovation.<br /><br />

                    3. How do you give employees space to pursue their interests and become their best selves?<br /><br />

                    4. Share any inspiring stories about your engineers’ personal interests that got them to winning a competition, learning a new skill, or making an impact on society.<br /><br />

                    5. Do you have any kind of clubs like a photographers club, a coding club, a bikers’ club, or a toastmasters club to bring together engineers with similar interests?
                </>
            },
            {
                "value": "Love for product",
                "tips": "Engineers are constantly seeking meaningful product improvements that result in new customers and positive impact.",
                questions: <>

                    1. What was the inspiration behind your core product?<br /><br />

                    2. What critical problem is solved by your product?<br /><br />

                    3. How do you constantly improve your product features to make it better for your customers?<br /><br />

                    4. What real impact in the world do you wish to create with your product?<br /><br />

                    5. Who are the users of your product?<br /><br />

                    6. How does it empower users to achieve greater things or make their life easier?<br /><br />

                    7. Are you working on any new products or features?
                </>
            },
            {
                "value": "Cross-functional collaboration",
                "tips": "Engineers with different functional expertise coming together to work toward a common goal.",
                questions: <>
                    1. Who will be the other team members (e.g. sales, business development, designers, etc.) that an engineer will interact with on a daily basis?<br /><br />

                    2. How does the office seating plan encourage cross-functional collaboration?<br /><br />

                    3. Are there any informal settings like a coffee or cookie break where teammates who don’t usually work together get to interact with each other?<br /><br />

                    4. What communication tools or forums are used for interactions among teams?<br /><br />

                    5. Are there any company-wide events to foster social interaction among different departments?<br /><br />

                    6. Cite specific instances when a cross-functional team solved a problem or achieved exceptional results?
                </>
            },
            {
                "value": "Creative and Innovative",
                "tips": "Challenging the way things have been traditionally done by harnessing unique ideas.",
                questions: <>
                    1. How do you focus on solving problems and come up with creative solutions?<br /><br />

                    2. Does your product portfolio reflect creativity and innovation to create a real-world change or impact?<br /><br />

                    3. Do your workplace practices like recruitment & interviewing, rewards value creative thinking, and problem-solving?<br /><br />

                    4. How do you encourage engineers to think beyond the boundaries of their job? How do you integrate creativity in day-to-day work?<br /><br />

                    5. How do your leaders act as role models to foster and nurture a creative and innovative mindset?<br /><br />

                    6. How do you acknowledge the creative talent with incentives and rewards?<br /><br />

                    7. How do you capture and share best practices and mistakes to learn from?<br /><br />

                    8. What kind of metrics tools are used to track the success of your creative and innovative processes?
                </>
            },
            {
                "value": "Open communication",
                "tips": "Engineers can freely express their ideas and everyone is involved in decision-making.",
                questions: <>
                    1. What’s your “communication” mantra? E.g. overcommunicate, don’t stop yourself, say it as it is, etc.<br /><br />

                    2. Are there any company-wide meetings that are attended by all engineers across locations? E.g. weekly standups, quarterly town halls, etc.<br /><br />

                    3. What communication tools and platforms (e.g. Slack, Zoom) are used on a daily basis by different tech teams?<br /><br />

                    4. What forums are in place for employees to exchange information and opinion with the leadership?<br /><br />

                    5. What kind of formal/informal events do you conduct to allow employees to express their views?
                </>
            },
            {
                "value": "Flat Organization",
                "tips": "Few middle managements between employees and executives.",
                questions: <>1. How flat is your organisation?<br /><br />

                    2. How does your company’s organizational structure empower employees and unite them?<br /><br />

                    3. How do the leaders communicate the goings-on of the company?<br /><br />

                    4. How do you ensure there is transparency around how decisions are made?<br /><br />

                    5. Describe the freedom enjoyed by your workforce to take new initiatives.<br /><br />

                    6. How do you encourage employees to take ownership for success?</>
            },
            {
                "value": "Risk-taking > Stability",
                "tips": "Step away from the safety of the familiar, show a higher level of curiosity, think out-of-the-box, take chances, and try new ideas.",
                questions: <>
                    1. Why is risk-taking important to you?<br /><br />

                    2. How do you encourage ‘action’ over ‘waiting for perfection’?<br /><br />

                    3. Do you encourage engineers to take risks, fail fast, learn fast, and deliver results fast?<br /><br />

                    4. How do you regularly encourage engineers to take risks and experiment?<br /><br />

                    5. How do leaders challenge the status quo and model risk-taking behavior?<br /><br />

                    6. How do you create a supportive environment where engineers feel trusted and know they won’t be judged or punished if they fail?
                </>
            },
            {
                "value": "wears many hats",
                "tips": "Team members accomplish different kinds of work in addition to writing codes.",
                questions: <>
                    1. In the early stages of a business, engineers have to play multiple roles. Describe the kind of initiatives and projects that engineers do outside of their traditional “software engineering” scope.<br /><br />

                    2. Do you prefer engineers with knowledge of a specific language or experience in a particular platform, or generalists with knowledge and experience of multiple languages and platforms?<br /><br />

                    3. What is the breadth of roles that engineers get to experience? For example, technical leadership, interviewing & selection, engineering support, product management, to name a few.<br /><br />

                    4. How do you encourage engineers to cross technical boundaries and work with product, backend, frontend, and UI/UX teams to build the best product?<br /><br />

                    5. Are there any formal job rotation programs in place to rotate engineers between different teams or roles?<br /><br />
                </>
            },
            {
                "value": "Team Oriented",
                "tips": "An environment where everyone wins because it plays on everyone’s strengths.",
                questions: <>
                    1. Is pair programming, job shadowing practiced among physically located and remote engineers?<br /><br />

                    2. How do you celebrate together when teams achieve a milestone towards a common goal?<br /><br />

                    3. Are there information sharing sessions to prevent knowledge silos and encourage collaboration?<br /><br />

                    4.  How do the teams share accountability, solve common problems, and address shared concerns?<br /><br />

                    5. Share some fun occasions such as sponsoring sports teams or organizing lunchtime potlucks that help lighten things up and bring your teams even closer together.<br /><br />
                </>
            },
            {
                "value": "Continuous Feedback",
                "tips": "You receive ongoing feedback through open dialog. This energizes you to deliver superior performance.",
                questions: <>

                    1. Do your employees have to wait until the annual reviews to get feedback or there are built-in opportunities to allow for that kind of dialogue on a regular basis?<br /><br />

                    2. To remove any bias from feedback, how are facts, statistics, and relevant attributes tracked to provide continuous feedback?<br /><br />

                    3. What opportunities are provided to engineers to share their ideas on how the company and team can improve performance?<br /><br />

                    4. Do you have in place any performance management tools to track continuous conversations and feedback?<br /><br />

                    5. How do managers coach their team members to perform better at their job?<br /><br />

                    6. How are team members’ goals adjusted and revisited as organizational strategies evolve?
                </>
            },
        ]
    },
    {
        index: 1,
        textLines: ['Sustainability', ''],
        cardTitle: "Pick your favorite sustainability goals you want to work with.",
        type: "icon",
        hPadding: 100,
        layout: [13, 4],
        values: [
            {
                value: "No Poverty",
                image: 'no-poverty',
                tips: "End poverty in all its forms everywhere. All people everywhere, including the poorest and most vulnerable, should enjoy a basic standard of living and social protection benefits.",
                questions: <>
                    Try to explain on how your product or service contribute to this SDG.<br /> And why you are so passionate about it.
                </>
            },
            {
                value: "Zero Hunger",
                image: "zero-hunger",
                tips: "End hunger and all forms of malnutrition, achieve food security to sufficient nutritious food, and promote sustainable food production",
                questions: <>
                    Try to explain on how your product or service contribute to this SDG.<br /> And why you are so passionate about it.
                </>
            },
            {
                value: "Good Health And Well being",
                image: "good-health-and-well-being",
                tips: "Ensure healthy lives and promote well-being for all at all ages by ending the epidemics; reducing non-communicable and environmental diseases as well as achieving universal health coverage; and ensuring access to safe, affordable and effective medicines and vaccines for all.",
                questions: <>
                    Try to explain on how your product or service contribute to this SDG.<br /> And why you are so passionate about it.
                </>
            },
            {
                value: "Quality Education",
                image: "qualtity-education",
                tips: "Ensure inclusive and equitable quality education and promote lifelong learning opportunities for all.",
                questions: <>
                    Try to explain on how your product or service contribute to this SDG.<br /> And why you are so passionate about it.
                </>
            },
            {
                value: "Gender Equality",
                image: "gender-equality",
                tips: "Achieve gender equality and empower all women and girls to reach their full potential, which requires eliminating all forms of discrimination and violence against them, including harmful practices.\n",
                questions: <>
                    Try to explain on how your product or service contribute to this SDG.<br /> And why you are so passionate about it.
                </>
            },
            {
                value: "Clean Water and Sanitation",
                image: "clean-water-and-sanitation",
                tips: "Ensure availability and sustainable management of water and sanitation for all including addressing the quality and sustainability of water resources.",
                questions: <>
                    Try to explain on how your product or service contribute to this SDG.<br /> And why you are so passionate about it.
                </>
            },
            {
                value: "Affordable and Clean Energy",
                image: "affordable-and-clean-energy",
                tips: "Ensure access to affordable, reliable, sustainable and modern energy for all. This includes promoting broader energy access and increased use of renewable energy.\n",
                questions: <>
                    Try to explain on how your product or service contribute to this SDG.<br /> And why you are so passionate about it.
                </>
            },
            {
                value: "Decent Work and Economic Growth",
                image: "eecent-work-and-wconomic-growth",
                tips: "Promote sustained, inclusive and sustainable economic growth, full and productive employment and decent work for all. This includes eradicating forced labour, human trafficking and child labour.",
                questions: <>
                    Try to explain on how your product or service contribute to this SDG.<br /> And why you are so passionate about it.
                </>
            },
            {
                value: "Industry, Innovation and Infrastructure",
                image: "industry-innovation-and-infrastructure",
                tips: "Build resilient infrastructure, promote inclusive and sustainable industrialization and foster innovation.",
                questions: <>
                    Try to explain on how your product or service contribute to this SDG.<br /> And why you are so passionate about it.
                </>
            },
            {
                value: "Reduced Inequality",
                image: "reduced-inequalities",
                tips: "Reduce inequality in income, as well as those based on sex, age, disability, race, class, ethnicity, religion and opportunity—both within and among countries.",
                questions: <>
                    Try to explain on how your product or service contribute to this SDG.<br /> And why you are so passionate about it.
                </>
            },
            {
                value: "Sustainable Cities and Communities",
                image: "sustainable-cities-and-communities",
                tips: "Aims to make cities and human settlements inclusive, safe, resilient and sustainable by renewing and planning cities and other human settlements in a way that fosters community cohesion and personal security while stimulating innovation and employment.",
                questions: <>
                    Try to explain on how your product or service contribute to this SDG.<br /> And why you are so passionate about it.
                </>
            },
            {
                value: "Responsible Consumption and Production",
                image: "responsible-consumption-and-production",
                tips: "Promote sustainable consumption and production patterns through measures such as specific policies and international agreements on the management of materials that are toxic to the environment.",
                questions: <>
                    Try to explain on how your product or service contribute to this SDG.<br /> And why you are so passionate about it.
                </>
            },
            {
                value: "Climate Action",
                image: "climate-action",
                tips: "Take urgent action to combat climate change and its widespread, unprecedented effects which disproportionately burden the poorest and the most vulnerable.",
                questions: <>
                    Try to explain on how your product or service contribute to this SDG.<br /> And why you are so passionate about it.
                </>
            },
            {
                value: "Life Below Water",
                image: "life-belwo-water",

                tips: "Promote the conservation and sustainable use of marine and coastal ecosystems, prevent marine pollution and increase the economic benefits from the sustainable use of marine resources.",
                questions: <>
                    Try to explain on how your product or service contribute to this SDG.<br /> And why you are so passionate about it.
                </>
            },
            {
                value: "Life on Land",
                image: "life-on-land",
                tips: "Protect, restore and promote sustainable use of terrestrial ecosystems, sustainably manage forests, combat desertification, and halt and reverse land degradation and halt biodiversity loss",
                questions: <>
                    Try to explain on how your product or service contribute to this SDG.<br /> And why you are so passionate about it.
                </>
            },
            {
                value: "Peace and Justice Strong Institutions",
                image: "peace-and-justice-strong-instutuions",
                tips: "Promote peaceful and inclusive societies for sustainable development, provide access to justice for all and build effective, accountable and inclusive institutions at all levels",
                questions: <>
                    Try to explain on how your product or service contribute to this SDG.<br /> And why you are so passionate about it.
                </>
            },
            {
                value: "Partnerships to achieve the Goal",
                image: "partnerships-for-the-goals",
                tips: "The 2030 Agenda requires a revitalized and enhanced global partnership that mobilizes all available resources from Governments, civil society, the private sector, the United Nations system and other actors in order to strengthen the means of implementation.",
                questions: <>
                    Try to explain on how your product or service contribute to this SDG.<br /> And why you are so passionate about it.
                </>
            },
        ]
    },
    {
        index: 2,
        textLines: ['Guiding', 'Principles'],
        cardTitle: "Pick your most important values",
        layout: [4, 4, 4],
        values: [
            {
                "value": "Agile methods",
                "tips": "The focus is on quality, speed, and communication to embrace change.",
                questions: <>
                    1. What are the agile practices your development team uses?<br /><br />

                    2. How do the teams get a perspective on new features that need to be added to the final product or service?<br /><br />

                    3. What helps you achieve more flexible and faster product development?<br /><br />

                    4. Are there any specific project management tools used for the implementation of agile methodologies to help teams to better structure their workflows and improve team collaboration?<br /><br />

                    5. What is your approach to accelerate the iteration cycle?<br /><br />

                    6. How do you help team members to understand and embrace agile methods within the company?<br /><br />

                    7. Give a snapshot of the daily meetings to resolve issues.

                </>
            },
            {
                "value": "Safety & Reliability",
                "tips": "Creating robust, secure technologies that function in challenging environments.",
                questions: <>
                    1. What strategies and processes does your company try employ to ensure the safety and reliability of your technology products or services?<br /><br />

                    2. How do you assess and manage potential risks associated with the deployment of new technologies, particularly those that might have a significant impact on user safety or data security?<br /><br />

                    3. Can you provide examples of how your company has responded to past challenges or incidents related to safety and reliability in your products or services?<br /><br />

                    4. In what ways does your company engage with users, regulatory bodies, or industry groups to continuously improve the safety and reliability standards of your technology?

                </>
            },
            {
                "value": "Ethical AI",
                "tips": "Prioritising AI development that upholds privacy, fairness, and transparency in alignment with moral and societal standards.",
                questions: <>
                    1. How does your company define ethical AI, and can you provide examples of how this definition guides your AI development and deployment?<br /><br />

                    2. In what ways do you ensure transparency and accountability in your AI systems, particularly in decision-making processes that significantly impact users?<br /><br />

                    3. Can you describe the measures you may have in place to prevent biases in your AI systems?<br /><br />

                    4. How does your company handle the ethical implications of AI in terms of data privacy and user consent?

                </>
            },
            {
                "value": "Start-to-Finish Ownership",
                "tips": "You take pride in your work and make it your mission to see it through.",
                questions: <>
                    1. How do you bring together both freedom and responsibility to encourage engineers to own their projects?<br /><br />

                    2. How does the ownership mindset makes employees at your company feel part of the bigger picture?<br /><br />


                    3.Share some examples of how engineers are encouraged and supported in owning projects every step of the way - from ideation and building to shipping the product.<br /><br />


                    4. What tools or venues are you providing to engineers so that they can think and act like owners of projects and collaborate across teams to build and ship the product?<br /><br />


                    5. If possible, share some examples of products that were built start-to-finish by smaller engineering teams.
                </>
            },
            {
                "value": "Cutting-Edge Technologies",
                "tips": "Leverage disruptive technologies as well as the latest technological advancements.",
                questions: <>
                    1. What technologies you have in your stack for making your software development process faster and easier?<br /><br />

                    2. Are there any new technologies you’ve adopted for solving problems, adding value to the customer experience, or adapting to the industry needs?<br /><br />

                    3. Are you seeking unique applications of any cutting-edge technologies to your advantage?<br /><br />

                    4. Do new hires need to be an expert in these technologies for joining your organization?

                </>
            },
            {
                "value": "High Quality Code Base",
                "tips": "A work environment that is best suited for engineers who want to learn a broad set of skills in a short period of time, enjoy working under a deadline, and/or are energized by changing requirements and technological challenges.",
                questions: <>
                    1. How do you define code quality?<br /><br />

                    2. Why do you think code quality matters?<br /><br />

                    3. What practices do you adopt to improve code quality?<br /><br />

                    4. How do you monitor the quality of your codebase over time?<br /><br />

                    5. Do you use any code quality tools to ensure your code is high quality?<br /><br />

                    6. How do you train your engineers to comply with the coding standard?<br /><br />

                    7. At what stage of the development process, do code analysis take place?<br /><br />

                    8. Do you follow automated or manual code reviews?

                </>
            },
            {
                "value": "Fast-Paced Environment",
                "tips": "You may find yourself moving from one task to another without much time in between.",
                questions: <>
                    1. What does being fast-paced mean to you, your customers, and your industry?<br /><br />

                    2. How do engineers keep up with a fast-paced environment? Do they move between teams to acquire product knowledge, work closely with users to identify mistakes early or make decisions without red tape?<br /><br />

                    3. How do the engineers feel about working in a fast-paced environment?<br /><br />

                    4. How do you achieve speed without sacrificing quality and accuracy?<br /><br />

                    5. What helps maintain engineers’ motivation in times of high expectation without causing anxiety and potential burnout?<br /><br />

                    6. What traits in engineers are greatly valued in this fast-paced environment?
                </>
            },
            {
                "value": "Open Source Contributer",
                "tips": "",
                questions: <>
                    1. Why do you think open source contribution is important?<br /><br />

                    2. How do you support the open-source landscape?<br /><br />

                    3. How do you balance employee’s time and effort between participation in open source projects and paid projects?
                </>
            },
            {
                "value": "Pair Programs",
                "tips": "Two programmers on one computer because the best programs are done by pairs.",
                questions: <>
                    1. Why did you decide to introduce pair programs in the first place?<br /><br />

                    2. What benefits of pair programs have you experienced?<br /><br />

                    3. How often do teams pair program? For instance, is it only when developers have to quickly train new team members, or do you encourage your engineers to pair up regularly as a way to distribute knowledge of the codebase or solve a problem, etc?<br /><br />

                    5. What resources and tools do you have in place for pair programming?<br /><br />

                    6. How do teams that work remotely manage pair programming?<br /><br />

                    7. What are your strategies to implement successful pair programs? For instance, instant feedback loops, communal machines, etc.
                </>
            },
            {
                "value": "Continuous Delivery",
                "tips": "Engineers deploy code in short cycles and ensuring that their software can be released at any time.",
                questions: <>
                    1. What does ‘continuous delivery’ mean in your organization?<br /><br />

                    2. How do you achieve an efficient deployment pipeline?<br /><br />

                    3. How do you nurture the continuous delivery mindset among your development teams?<br /><br />

                    4. What is the overall architecture of your services and products?<br /><br />

                    5. How is your build pipeline constructed and how your teams interact with it?<br /><br />

                    6. How do you optimize Mean Time to Recovery (MTTR)?<br /><br />

                    7. What are the key metrics (e.g., deployment frequency, change lead time, mean time to recovery, change fail rate, etc.) you track across your pipeline?
                </>
            },
            {
                "value": "Team Practice DevOps",
                "tips": "A firm handshake between development and operations that unites agile, git, continuous delivery, automation.",
                questions: <>
                    1. What does DevOps mean to your organization?<br /><br />

                    2. What methods do you apply for agile planning?<br /><br />

                    3. How do you ensure active stakeholder participation?<br /><br />

                    4. To achieve an automated release pipeline, do you use any specific DevOps tools or build them from scratch?<br /><br />

                    5. How do you monitor application performance once it goes live?<br /><br />

                    6. Do you use any open-source products?
                </>
            },
            {
                "value": "Driven by Excellence",
                "tips": "Relentless in pursuit of excellence, the team pushes boundaries for innovation, delivering top results through hard work.",
                questions: <>
                    1. How do you encourage and support your employees to pursue excellence in their daily tasks and long-term goals?<br /><br />

                    2. What role does innovation play in your company's pursuit of excellence, and how do you integrate it into your operations?<br /><br />

                    3. How does your company define excellence in terms of product quality, customer service, and overall operations?
                </>
            }
        ]
    },
    {
        index: 3,
        textLines: ['Career', 'Development'],
        cardTitle: "Pick your most important values",
        hPadding: 100,
        layout: [3, 2, 1],

        values: [
            {
                "value": "Promotes from within",
                "tips": "Internal talent is preferred over external hires.",
                questions: <>
                    1. How do founders, company leaders, and team managers work consciously to nurture engineers’ drive to grow in their careers?<br /><br />

                    2. What opportunities like hackathons are provided to stretch and showcase your engineers’ passions and talents?<br /><br />

                    3. What are some innovative projects pursued by the engineers at your company?<br /><br />

                    4. How do you foster curiosity and a desire to discover and address the users’ unsaid and unmet needs?<br /><br />

                    5. Do engineers collaborate across roles and advocate for ideas which the company could develop?<br /><br />

                    6. Share any experience when an opposing viewpoint of an engineer was considered and it proved to be a game-changer for the business.
                </>
            },
            {
                "value": "Internal mobility",
                "tips": "Engineers can move from role to role.",
                questions: <>
                    1. How do you align your engineers’ career interests with the firm’s goals?<br /><br />

                    2. Do your engineers have easy access to opportunities for lateral moves?<br /><br />

                    3. Do you have mentoring & coaching programs that help engineers broaden their skills and experiences to move across functions, projects, jobs, etc?<br /><br />

                    4. Share some real-life stories of how your engineers successfully transitioned into different roles within the organization.
                </>
            },
            {
                "value": "Good for junior engnineers",
                "tips": "Quick learners with <1 of experience are encouraged to apply and can expect mentorship.",
                questions: <>
                    1. What is the proportion of junior engineers to senior engineers in the company?<br /><br />

                    2. What are the coaching and mentoring opportunities offered to junior engineers for building a successful engineering career in the company?<br /><br />

                    3.  How have you hired and trained people from other professions to become successful engineers at your company?<br /><br />

                    4. Share the success stories of junior engineers in your company.
                </>
            },
            {
                "value": "Has Internship programs",
                "tips": "A wide range of internship opportunities for graduates and postgraduates.",
                questions: <>
                    1. What’s the goal of your internship program?<br /><br />

                    2. What kind of exposure and learning is gained by the interns?<br /><br />

                    3. What type of projects are assigned to interns?<br /><br />

                    4. What are the opportunities available to interns to interact with the leadership team?<br /><br />

                    5. How does the internship at your company provide real-world experience to the interns?<br /><br />

                    6. How closely do the interns work with the managers and technical experts in the company?
                </>
            },
            {
                "value": "High employee retention",
                "tips": "Engineers join and then they stay for their trust and loyalty.",
                questions: <>
                    1. What is the average tenure of engineers at your company?<br /><br />

                    2. What keeps them happy and engaged at your company?<br /><br />

                    3. What makes engineers feel connected with your company that they don’t leave too soon?<br /><br />

                    4. Share the experiences and testimonials by long-tenured engineers about why do they like working at your company.
                </>
            },
            {
                "value": "Promote growth mindset",
                "tips": "Well-thought-out learning & development opportunities to grow.",
                questions: <>

                    1. What are the different career development opportunities available within your organization?<br /><br />

                    2. Apart from the traditional vertical career ladder, do you offer any other career paths?<br /><br />

                    3. Is climbing into the managerial ranks the only path to growth in your organization?<br /><br />

                    4. How do you support both management and individual contributor career paths?<br /><br />

                    5. What resources are provided to engineers so they can improve their skills and reach their career development goals?<br /><br />

                    6. Share a few engineers’ success stories from your team.
                </>
            }
        ]
    },
    {
        index: 4,
        textLines: ['Workplace', 'Culture'],
        cardTitle: "Pick your most important values",
        hPadding: 100,
        layout: [3, 2, 1],

        values: [
            {
                "value": "Lunch together",
                "tips": "Team members enjoy eating lunch together while exchanging ideas.",
                questions: <>

                    1. How lunchtime is enjoyed by everyone at your company?<br /><br />

                    2. Describe the friendly vibes, connections, and creativity sparked among teams over lunch.<br /><br />

                    3. Do you buy lunch for your team or have a budget to host offsite budgets?<br /><br />

                    4. What are the popular lunchtime conversations?
                </>
            },
            {
                "value": "Flexible work",
                "tips": "The team has flexibility in their work hours and/or locations.",
                questions: <>
                    1.  Describe the geographical spread of your teams and team members.<br /><br />

                    2. What’s your underlying philosophy behind flexible working?<br /><br />

                    3. Do you offer telecommuting, condensed workweek, or any other flexible work schedules?<br /><br />

                    4. What communication tools do you use to collaborate with geographically dispersed teams?<br /><br />

                    5. Are there opportunities to bring everyone under one roof to spend some in-person time together for work, play, and laugh?<br /><br />

                    6. Do any engineers who are parents leverage the flexible work schedules to bond with their children while delivering results at work?<br /><br />

                    7. Share some employee stories about how flexible work schedules helped them to balance their personal goals with professional responsibilities. For instance, someone learned a musical instrument or a new language; took a time off to explore a new country, etc.
                </>
            },
            {
                "value": "Light meetings",
                "tips": "Fewer, shorter meetings with focused agenda.",
                questions: <>

                    1. How many regular meetings you have every month? What are these regular meetings? For example, 2 hours minutes all-hands, 10 minute daily stand-up, 1-hour weekly huddle, and scrums, etc.<br /><br />

                    2. What asynchronous communication platforms and tools are used to share information and make ad-hoc work discussions?<br /><br />

                    3. How are decisions made during meetings?
                </>
            },
            {
                "value": "Colleagues are friends",
                "tips": "Working with colleagues that you genuinely love to be around.",
                questions: <>
                    1. On a daily basis, how do engineers like to hang out with each other outside work? For example, do they enjoy playing a video game or go for a stroll after lunch, play chess or share a joke before leaving for home in the evening?<br /><br />

                    2. What are the different indoor and outdoor social events organized where employees get to spend time together outside work?<br /><br />

                    3. Are there any resources in the office to build these friendly moments among colleagues? For example, a snooker table or a chessboard.<br /><br />

                    4. How do these personal connections help fuel enthusiasm and liveliness in the workplace?<br /><br />

                    5. How do you ensure that a new hire brings the same friendly vibes to the group?
                </>
            },
            {
                "value": "Innovative Office Design",
                "tips": "Thoughtful office space where engineers explore, collaborate, and connect.",
                questions: <>
                    1. What does your office design reveal about your company?<br /><br />

                    2. How does your office design balance both collaboration and concentration?<br /><br />

                    3. How does the office design (e.g., natural lighting, bright colors, artwork on walls) spark creativity, friendliness, and a cool quotient?<br /><br />

                    4. Do you have large, open-plan spaces to encourage staff to work collaboratively and creatively in an inclusive environment?<br /><br />

                    5. Do you have spaces that maximize natural light through large windows to make people feel happier?
                </>
            },
            {
                "value": "Flexible public holidays",
                "tips": "Employees are allowed to take holidays on which they usually have off for days that have meaning to their culture, religion or lifestyle.",
                questions: <>
                    1. How does your holiday calendar take into consideration diverse personal beliefs, observations, or lifestyles?<br /><br />

                    2. Do you let employees swap holidays on which they usually have off for days that have meaning to their culture, religion, or lifestyle?<br /><br />

                    3. Briefly talk about this policy-in-action by sharing the experience of any employee who leverages this policy. For example, an employee who doesn’t celebrate Christmas may work that day and take off another religious day, such as Yom Kippur or Diwali; or an employee who swaps a nationally observed day for a cause they support.
                </>
            },

        ]
    },
    {
        index: 5,
        cardTitle: "Pick your most important values",
        textLines: ['Strategy', ''],
        hPadding: 300,
        layout: [3, 3],

        values: [
            {
                "value": "Growing Team",
                "tips": "Onboarding new members and recognizing current ones to make the necessary strides focused on growth.",
                questions: <>
                    1. By what number has your engineering team grown in the last 12 months?<br /><br />

                    2. How has your engineering team evolved over the years?<br /><br />

                    3. What’s fueling your growth?<br /><br />

                    4. By what number do you plan to increase your engineering team in the times ahead?<br /><br />

                    5. What are the potential market needs and gaps you are looking to serve with your products and services?<br /><br />

                    6. What talent will play an important role in driving your current and future business growth?

                </>
            },
            {
                "value": "Customer First",
                "tips": "Solve the right problems, detect issues quickly, and predict future customer needs.",
                questions: <>
                    1. What are your customer demographics?<br /><br />

                    2. How do you bring together developers and UX/UI designers to deliver products that are useful for customers?<br /><br />

                    3. How are customers involved in different stages of product development?<br /><br />

                    4. What are the different forums and opportunities presented for customers to share their feedback?<br /><br />

                    5. Share an experience/story when you had put customer satisfaction ahead of everything else.<br /><br />

                    6. How do you build your product and test it at the same time?
                </>
            },

            {
                "value": "Engineering-Driven",
                "tips": "Develop high-tech products based on sophisticated technology.",
                questions: <>
                    2. How does the engineering function drive your company’s decisions?<br /><br />

                    3. What percentage of your workforce are engineers?<br /><br />

                    4. Who are the majority of your clients? Are they mostly engineers?<br /><br />

                    5. How vital are sophisticated software, data, and analytics for building your products?<br /><br />

                    8. What role is played by the engineering team to determine the overall direction and priorities for the company?<br /><br />

                    12. Share some engineering challenges overcome by your team.
                </>
            },
            {
                "value": "Product-Driven",
                "tips": "Product is our life, our passion.",
                questions: <>
                    1. Describe your product-driven mindset.<br /><br />

                    2. How does your product drive end-user product adoption and create a market for itself?<br /><br />

                    3. Share some personal stories of how engineers have been in situations you try to solve, and how they’re passionate and confident about solving them.<br /><br />

                    4. What is your product’s contribution to business growth?<br /><br />

                    5. How do you monitor and communicate with your customers to take their feedback to shape your product(s)?
                </>
            },
            {
                "value": "Design-Driven",
                "tips": "Fixated on the customer experience.",
                questions: <>
                    1. Share your eye for and love of design.<br /><br />

                    2. How are UX design tools and practices integrated into the
                    business model?<br /><br />

                    3. What is the focus on ‘user experience’ in your product development and upgrades?<br /><br />

                    3. What makes your product ‘design-centric’ or ‘user-centric’?<br /><br />

                    4. How does everyone share ownership of the user experience?
                </>
            },
            {
                "value": "Data-Driven",
                "tips": "Using the right data at the right time for conclusive decision making.",
                questions: <>
                    1. How do you use data to drive business decisions?<br /><br />

                    2. How do you source actionable data for system and process improvements?<br /><br />

                    3. How do you leverage data to build and upgrade applications to solve customers’ problems?<br /><br />

                    4. How do your data scientists collaborate with engineers to build the applications?<br /><br />

                    5. How do you combine data science expertise with a deep understanding of business problems?<br /><br />

                    6. Does your solution use algorithmic data mining to make predictions and suggestions or to take action autonomously?
                </>
            }
        ]
    },
    {
        index: 6,
        textLines: ['Health', 'and Wellbeing'],

        cardTitle: "Pick your most important values",
        layout: [3, 3, 1],

        values: [
            {
                "value": "Practices Inclusion & belonging",
                "tips": "Executives are committed to building a diverse, equal, and inclusive workplace. From recruiting to training and advancement, the emphasis is on providing a supportive environment for all individuals, regardless of sexual orientation, gender, race, religion, disability, or ethnicity.",
                questions: <>
                    1. How is ‘inclusion & belonging’ defined in your organization222?<br /><br />

                    2. What is the existing makeup of your workforce? What is the distribution of women and underrepresented groups across levels and functions?<br /><br />

                    3. How do you measure and evaluate the impact of diversity and inclusion on business performance?<br /><br />

                    4. How do top leaders and managers set the tone?<br /><br />

                    5. What are your current organizational priorities for inclusion & belonging?<br /><br />

                    6. How do you communicate your commitment to diversity and inclusion internally and externally?<br /><br />

                    7. What resources and support you provide to employees to develop their knowledge and skills to work effectively within a diverse environment?<br /><br />

                    8. How do your organization’s hiring and selection practices reflect inclusion and belonging values?
                </>
            },
            {
                "value": "Work/Family Balance",
                "tips": "Engineers have the flexibility, freedom, and support to achieve fulfillment and enjoyment in both their personal and professional lives.",
                questions: <>
                    1. No matter how we love our jobs, it’s not the only thing in our life, right? How do you let your engineers thrive in and out of the office?<br /><br />

                    2. Why do you think it is important for engineers to strike a work/family balance?<br /><br />

                    3. What opportunities do you provide for engineers to recharge and reenergize themselves? For example, compressed workweek, sabbaticals, etc.<br /><br />

                    4. Do you give any paid time off so engineers can spend time doing the things that they love outside of work, like travel, hobbies, and family time?<br /><br />

                    5. Is there any flexibility of hours or days worked?<br /><br />

                    6. How do engineers make use of these opportunities to grow both professionally and personally?
                </>
            },
            {
                "value": "Personal Growth Strategy",
                "tips": "Each employee’s best interest matters whether it’s learning a new skill or pursuing a passion.",
                questions: <>

                    1. How do you demonstrate your commitment to the personal growth of your engineers?<br /><br />

                    2. Do you have individual and group coaching programs to help engineers enhance their skills?<br /><br />

                    3. Are there any formal mentorship programs where you connect new and less experienced engineers with experienced, knowledgeable engineers to help the new employee quickly absorb the organization’s norms?<br /><br />

                    4. Are there any mentoring opportunities to help the continuing engineers grow in their current position and become ready for new technology and roles?<br /><br />

                    5. Do you assign mentors to a new engineer for orientation and onboarding?<br /><br />

                    6. Are there peer-to-peer learning and feedback to help engineers learn from each other and grow?<br /><br />

                    7. What training programs (e.g. managerial training, leadership training) are offered to engineers to improve their areas of performance?
                </>
            },
            {
                "value": "Safe Environment To Fail",
                "tips": "Be fearless to explore unknown, try new ideas, and never stop learning.",
                questions: <>

                    1. Do you have safety nets to recover from failures? You may be leveraging technology to minimize the consequences of failing. E.g. automated software testing to recognize code failures and fix it, machine learning to reduce the chances of costly wrong decisions, etc.<br /><br />

                    2. Do you talk about failures and learnings from them to make people accept failures and not fear them?<br /><br />

                    3. How do you ensure engineers feel confident to take risks without being shamed or criticized for failure?<br /><br />

                    4. Share an incidence when your team learned from failure and acted on that learning which turned out to be a success.<br /><br />

                    5. When something fails, how do you analyze the root cause of the problem without blaming anyone?<br /><br />

                    6. Do you nurture the mindset that it is more important to keep pushing, keep failing, and iterating (fail ‘fast’) rather than trying to play safe and be left behind?
                </>
            },
            {
                "value": "Supports Physical Wellness",
                "tips": "The team promotes physical activity to get a strong mind and body. Team members are provided with fitness memberships.",
                questions: <>

                    1. Do your engineers engage in any kind of outdoor sports activities like hiking, biking, golf, marathon, rafting, etc?<br /><br />

                    2. Do you give any allowance to encourage participation in races, marathons, triathlons, or other such events?<br /><br />

                    3.  Do you provide any reimbursements for gym memberships, club memberships, or buying equipment?<br /><br />

                    4. Does your cafeteria offer healthy snacks and lunches or have you partnered with any healthy snack delivery service to deliver healthy snacks to the engineers instead of them having to go out looking for fast food?<br /><br />

                    5. Do you provide ergonomic equipment such as sit-stand desks, chairs, etc, to ensure efficiency and comfort to the engineers?
                </>
            },
            {
                "value": "Fosters Psychological Safety",
                "tips": "A strong belief that one will not be punished or humiliated for speaking up with ideas, questions, taking risks, concerns, or mistakes.",
                questions: <>

                    1. How do you promote a speak-up culture to encourage everyone to share their ideas and opinions without any fear of penalty?<br /><br />

                    2. How do you promote constructive conflict where ideas are debated rather than judged, and engineers don’t hesitate to bring even their whacky ideas?<br /><br />

                    3. Do managers hold conversations asking team members their preferred work, communication, and feedback styles?<br /><br />

                    4. Do you conduct employee surveys to understand how the team is feeling about taking risks, asking for help, and making mistakes?<br /><br />

                    5.Do engineers feel safe for interpersonal risk-taking and make product innovation possible?
                </>,
                order: 6
            },
            {
                "value": "Remote won't feel lonely",
                "tips": "Team members are provided with proven guidelines and routines to stay engaged and not get burned-out.",
                questions: <>

                    1. What are the opportunities available for remote engineers to make meaningful connections with their teams?<br /><br />

                    2. Are there any “in-the-office” day, when remote employees are encouraged to come in?<br /><br />

                    3. What is your onboarding process for new remote hires?<br /><br />

                    4. How do you keep the remote engineers in the communication loop to reinforce their sense of belonging?<br /><br />

                    5. How do you motivate remote engineers to participate in proposing ideas and brainstorming sessions actively?<br /><br />

                    6. Are there any guidelines and routine kit to help remote engineers stay productive and focus and not get burned-out or lose engagement?<br /><br />

                    7. Do you coach or support remote engineers to engage in self-care activities (e.g. yoga, meditation, healthy eating)?<br /><br />

                    8. Are there any work practices (e.g. strict no emails/Slack chats/text message after hours rule, pay for coworking, or office space) that help remote engineers maintain work/life balance?
                </>
            }
        ]
    },
    {
        index: 7,
        textLines: ['Company', 'Structure'],
        cardTitle: "Pick your most important values",
        hPadding: 250,
        layout: [4, 2],

        values: [
            {
                "value": "B2B",
                "tips": ""
            },
            {
                "value": "B2C",
                "tips": ""
            },
            {
                "value": "Self-Funded",
                "tips": ""
            },
            {
                "value": "Technical Founder(s)",
                "tips": ""
            },
            {
                "value": "Remote-OK",
                "tips": ""
            }
        ]
    }
]

export function valueCompare(v1, v2) {
    if (v1 === v2) {
        return true
    }
    if (!v1 || !v2) {
        return false
    }
    return (v1.toLowerCase() === v2.toLowerCase())
}

export const valuePatch = function (value) {
    const patch = {
        'Cross-functional collaboration': 'Cross-functional Collaboration',
        'wears many hats': 'Wears Many Hats',
        'Strong community': 'Strong Community',
        'Love for product': 'Love for Product',
        'Open communication': 'Open Communication',
        'Lunch together': 'Lunch Together',
        'Flexible work': 'Flexible Work',
        'Light meetings': 'Light Meetings',
        'Colleagues are friends': 'Colleagues are Friends',
        'Flexible public holidays': 'Flexible Public Holidays',
        'Agile methods': 'Agile Methods',
        'Promotes from within': 'Promotes From Within',
        'Internal mobility': 'Internal Mobility',
        'Good for junior engnineers': 'Good for Junior Engineers',
        'Has Intership programs': 'Has Internship programs',
        'High employee retention': 'High Employee Retention',
        'Promote growth mindset': 'Promote Growth Mindset',
        'Practices Inclusion & belonging': 'Practices Inclusion & Belonging',
        "Remote won't feel lonely": "Remote Won't Feel Lonely",
        "Remote won't feel lonley": "Remote Won't Feel Lonely",
        "Team Oriented": "Heavily Team Oriented"
    }

    return patch[value] || value
}

export const valuePatchReverse = function (value) {
    const patch = {
        'Cross-functional Collaboration': 'Cross-functional collaboration',
        'Wears Many Hats': 'wears many hats',
        'Strong Community': 'Strong community',
        'Love for Product': 'Love for product',
        'Open Communication': 'Open communication',
        'Lunch Together': 'Lunch together',
        'Flexible Work': 'Flexible work',
        'Light Meetings': 'Light meetings',
        'Colleagues are Friends': 'Colleagues are friends',
        'Flexible Public Holidays': 'Flexible public holidays',
        'Agile Methods': 'Agile methods',
        'Promotes From Within': 'Promotes from within',
        'Internal Mobility': 'Internal mobility',
        'Good for Junior Engineers': 'Good for junior engnineers',
        'Has Internship programs': 'Has Intership programs',
        'High Employee Retention': 'High employee retention',
        'Promote Growth Mindset': 'Promote growth mindset',
        'Practices Inclusion & Belonging': 'Practices Inclusion & belonging',
        "Remote Won't Feel Lonely": "Remote won't feel lonely",
        "Remote Won't Feel Lonely": "Remote won't feel lonley",
        "Heavily Team Oriented": "Team Oriented"
    }

    return patch[value] || value
}

export function findValueConfig(value) {
    let allValues = []
    defaultValuesConfig.forEach(valueConfig => {
        allValues = allValues.concat(valueConfig.values)
    })
    for (const v of allValues) {
        if (v.value === value || valuePatch(v.value) === value) {
            console.log(v)
            return v
        }
    }

    return null
}

export function generateValueListWithIndex() {
    let result = Array.from({ length: 7 }, (_, index) => index).reduce((obj, key) => ({ ...obj, [key]: [] }), {});
    defaultValuesConfig.forEach(valueConfig => {
        if (String(valueConfig.index) !== '7') {
            let tmp = valueConfig.values.map(item => item.value.toLowerCase());
            result[String(valueConfig.index)] = result[String(valueConfig.index)].concat(tmp);
        }
    });
    return result;
}

export const valueListWithIndex = generateValueListWithIndex();

export const defaultValuesConfig2 = [
    {
        index: 0,
        key: "Team value",
        textLines: ['Team Values', ''],
        cardTitle: "Pick your most important values",
        hPadding: 99,
        layout: [5, 4, 4],
        values: [
            {
                "value": "Continuous Feedback",
                "tips": "",
                questions: [
                    "Do your employees have to wait until the annual reviews to get feedback or are there built-in opportunities to regularly allow for that kind of dialogue?",
                    "What opportunities are provided to engineers to share their ideas on how the company and team can improve performance?",
                    "Do you have performance management tools to track continuous conversations and feedback?",
                    "How do managers coach their team members to perform better at their job?"
                ],
            },
            {
                "value": "EQ>IQ",
                "tips": "An individual's emotional quotient (ability to identify, evaluate, control, and express emotions) is of greater importance than their intelligence quotient (IQ)",
                questions: [
                    "What are the most valuable traits (other than intelligence) of engineers working at your company? E.g. empathy, thoughtfulness, help, kind and considerate, etc?",
                    "How important is it to put team goals before personal ambitions?",
                    "How do engineers demonstrate empathy at work? For example, blameless postmortems of issues, helping each other, understanding different perspectives, showing respect towards opinions different from theirs, etc.",
                    "During idea generation, how can engineers voice their ideas and opinions, without yelling, aggression, and harassment?"
                ]
            },
            {
                "value": "Cross-functional collaboration",
                "tips": "Engineers work directly alongside peers from other departments.",
                questions: [
                    "Who will be the other team members (e.g. sales, business development, designers, etc.) that an engineer will interact with daily?",
                    "How does the office seating plan encourage cross-functional collaboration?",
                    "What communication tools or forums are used for interactions among teams?",
                    "Are there any company-wide events to foster social interaction among different departments?"
                ]
            },
            {
                "value": "Risk-taking > Stability",
                "tips": "",
                questions: [
                    "Why is risk-taking important to you?",
                    "How do you encourage 'action' over waiting for perfection?",
                    "Do you encourage engineers to take risks, fail fast, learn fast, and deliver results fast?",
                    "How do you regularly encourage engineers to take risks and experiment?",
                    "How do you create a supportive environment where engineers feel trusted and know they won’t be judged or punished if they fail?"
                ]
            },
            {
                "value": "Love for product",
                "tips": "",
                questions: [
                    "What was the inspiration behind your core product?",
                    "What critical problem is solved by your product?",
                    "How do you constantly improve your product features to make it better for your customers? What real impact in the world do you wish to create with your product?"
                ]
            },
            {
                "value": "Diversity",
                "tips": "Members of your team are diverse in gender, race, age, or professional background.",
                questions: [
                    "What does diversity mean to your company? Do you have engineers from diverse age groups, nationalities, professional backgrounds, stages of life, or something else?",
                    "What percentage of your workforce are women, ethnic minorities, differently-abled, or people of color?",
                    "How do you work to promote diversity within your team and the broader tech ecosystem?",
                ]
            },
            {
                "value": "Creative and Innovative",
                "tips": "Engineers have a broad set of responsibilities and can take on additional roles in addition to writing and pushing code.",
                questions: [
                    "How do you focus on solving problems and come up with creative solutions?",
                    "How do you encourage engineers to think beyond their job? How do you integrate creativity in day-to-day work?",
                    "How do you acknowledge creative talent with incentives and rewards?",
                    "How do you capture and share best practices and mistakes to learn from?",
                    "What metrics tools are used to track the success of your creative and innovative processes?"
                ]
            },
            {
                "value": "wears many hats",
                "tips": "Engineers have a broad set of responsibilities and can take on additional roles.",
                questions: [
                    'In the early stages of a business, engineers have to play multiple roles. Describe the initiatives and projects engineers do outside of their traditional "software engineering" scope.',
                    "How do you encourage engineers to cross technical boundaries and work with product, backend, frontend, and UI/UX teams to build the best product?",
                    "Are there any formal job rotation programs to rotate engineers between different teams or roles?",
                ]
            },
            {
                "value": "Team Oriented",
                "tips": "",
                questions: [
                    'Is pair programming, and job shadowing practiced among physically located and remote engineers?',
                    'How do you celebrate together when teams achieve a milestone towards a common goal?',
                    'Are there information-sharing sessions to prevent knowledge silos and encourage collaboration?',
                    'How do the teams share accountability, solve common problems, and address shared concerns?'
                ]
            },
            {
                "value": "Open communication",
                "tips": "",
                questions: [
                    "What’s your “communication” mantra? E.g. overcommunicate, don’t stop yourself, etc.",
                    "Are there any company-wide meetings that are attended by all engineers across locations? E.g. weekly standups, quarterly town halls, etc.",
                    "What communication tools and platforms (e.g. Slack, Zoom) are used daily by different tech teams?",
                    "What forums are in place for employees to exchange information and opinion with the leadership?"
                ]
            },
            {
                "value": "Flat Organization",
                "tips": "The company is structured with few or no levels of middle management between employees and executives.",
                questions: [
                    "Can you describe your organization’s reporting structure or hierarchy?",
                    "How easy can new engineers walk up to the CEO and share their ideas and concerns?",
                    "Do managers set the expectation and goals and then let the engineers decide their course of action instead of seeking their approval at each step?",
                    "How do the leaders communicate the goings-on of the company?"
                ]
            },
            {
                "value": "Impressive Team Members",
                "tips": "",
                questions: [
                    "Can you share a story about a small idea or effort by an engineer turned into sweeping innovation?",
                    "How do you give employees space to pursue their interests and become their best selves?",
                    "Share any inspiring stories about your engineers’ interests that got them to win a competition, learn a new skill, or make an impact on society."
                ]
            },
        ]
    },
    {
        index: 1,
        textLines: ['Sustainability', ''],
        cardTitle: "Pick your most important values",
        type: "icon",
        hPadding: 100,
        layout: [13, 4],
        values: [
            {
                value: "No Poverty",
                image: 'no-poverty',
                tips: "End poverty in all its forms everywhere. All people everywhere, including the poorest and most vulnerable, should enjoy a basic standard of living and social protection benefits."
            },
            {
                value: "Zero Hunger",
                image: "zero-hunger",
                tips: "End hunger and all forms of malnutrition, achieve food security to sufficient nutritious food, and promote sustainable food production"
            },
            {
                value: "Good Health And Well being",
                image: "good-health-and-well-being",
                tips: "Ensure healthy lives and promote well-being for all at all ages by ending the epidemics; reducing non-communicable and environmental diseases as well as achieving universal health coverage; and ensuring access to safe, affordable and effective medicines and vaccines for all."
            },
            {
                value: "Quality Education",
                image: "qualtity-education",
                tips: "Ensure inclusive and equitable quality education and promote lifelong learning opportunities for all."
            },
            {
                value: "Gender Equality",
                image: "gender-equality",
                tips: "Achieve gender equality and empower all women and girls to reach their full potential, which requires eliminating all forms of discrimination and violence against them, including harmful practices."
            },
            {
                value: "Clean Water and Sanitation",
                image: "clean-water-and-sanitation",
                tips: "Ensure availability and sustainable management of water and sanitation for all including addressing the quality and sustainability of water resources."
            },
            {
                value: "Affordable and Clean Energy",
                image: "affordable-and-clean-energy",
                tips: "Ensure access to affordable, reliable, sustainable and modern energy for all. This includes promoting broader energy access and increased use of renewable energy."
            },
            {
                value: "Decent Work and Economic Growth",
                image: "eecent-work-and-wconomic-growth",
                tips: "Promote sustained, inclusive and sustainable economic growth, full and productive employment and decent work for all. This includes eradicating forced labour, human trafficking and child labour."
            },
            {
                value: "Industry, Innovation and Infrastructure",
                image: "industry-innovation-and-infrastructure",
                tips: "Build resilient infrastructure, promote inclusive and sustainable industrialization and foster innovation."
            },
            {
                value: "Reduced Inequality",
                image: "reduced-inequalities",
                tips: "Reduce inequality in income, as well as those based on sex, age, disability, race, class, ethnicity, religion and opportunity—both within and among countries."
            },
            {
                value: "Sustainable Cities and Communities",
                image: "sustainable-cities-and-communities",
                tips: "Aims to make cities and human settlements inclusive, safe, resilient and sustainable by renewing and planning cities and other human settlements in a way that fosters community cohesion and personal security while stimulating innovation and employment."
            },
            {
                value: "Responsible Consumption and Production",
                image: "responsible-consumption-and-production",
                tips: "Promote sustainable consumption and production patterns through measures such as specific policies and international agreements on the management of materials that are toxic to the environment."
            },
            {
                value: "Climate Action",
                image: "climate-action",
                tips: "Take urgent action to combat climate change and its widespread, unprecedented effects which disproportionately burden the poorest and the most vulnerable."
            },
            {
                value: "Life Below Water",
                image: "life-belwo-water",
                tips: "Promote the conservation and sustainable use of marine and coastal ecosystems, prevent marine pollution and increase the economic benefits from the sustainable use of marine resources."
            },
            {
                value: "Life on Land",
                image: "life-on-land",
                tips: "Protect, restore and promote sustainable use of terrestrial ecosystems, sustainably manage forests, combat desertification, and halt and reverse land degradation and halt biodiversity loss"
            },
            {
                value: "Peace and Justice Strong Institutions",
                image: "peace-and-justice-strong-instutuions",
                tips: "Promote peaceful and inclusive societies for sustainable development, provide access to justice for all and build effective, accountable and inclusive institutions at all levels"
            },
            {
                value: "Partnerships to achieve the Goal",
                image: "partnerships-for-the-goals",
                tips: "The 2030 Agenda requires a revitalized and enhanced global partnership that mobilizes all available resources from Governments, civil society, the private sector, the United Nations system and other actors in order to strengthen the means of implementation."
            },
        ]
    },
    {
        index: 2,
        textLines: ['Guiding', 'Principles'],
        cardTitle: "Pick your most important values",
        layout: [4, 4, 4],
        values: [
            {
                "value": "Agile methods",
                "tips": "",
                questions: [
                    "What are the agile practices your development team uses?",
                    "How do the teams get a perspective on new features that need to be added to the final product or service?",
                    "Are there any specific project management tools used for the implementation of agile methodologies to help teams to better structure their workflows and improve team collaboration?",
                    "Can you give a snapshot of the daily meetings to resolve issues?"
                ]
            },
            {
                "value": "Start-to-Finish Ownership",
                "tips": "",
                questions: [
                    "How do you bring freedom and responsibility together to encourage engineers to own their projects?",
                    "How does the ownership mindset makes employees at your company feel part of the bigger picture?",
                    "Can you share some examples of how engineers are encouraged and supported in owning projects every step - from ideation and building to shipping the product?",
                    "What tools or venues are you providing to engineers so that they can think and act like owners of projects and collaborate across teams to build and ship the product?"
                ]
            },
            {
                "value": "Cutting-Edge Technologies",
                "tips": "",
                questions: [
                    "What technologies do you have in your stack to make your software development process faster and easier?",
                    "Are there any new technologies you’ve adopted to solve problems, add value to the customer experience, or adapt to industry needs?",
                    "Are you seeking unique applications of cutting-edge technologies to your advantage? ",
                    "Do new hires need to be an expert in these technologies to join your organization?"
                ]
            },
            {
                "value": "High Quality Code Base",
                "tips": "A work environment that is best suited for engineers who want to learn a broad set of skills in a short period of time, enjoy working under a deadline, and/or are energized by changing requirements and technological challenges.",
                questions: [
                    "How do you define code quality?",
                    "What practices do you adopt to improve code quality?",
                    "How do you monitor the quality of your codebase over time?",
                    "Do you use any code quality tools to ensure your code is high quality?",
                    "At what stage of the development process does code analysis take place? ",
                    "Do you follow automated or manual code reviews?",
                ]
            },
            {
                "value": "Fast-Paced Environment",
                "tips": "A work environment that is best suited for engineers who want to learn a broad set of skills in a short period of time, enjoy working under a deadline, and/or are energized by changing requirements and technological challenges.",
                questions: [
                    "What does being fast-paced mean to you, your customers, and your industry?",
                    "How do engineers keep up with a fast-paced environment? Do they move between teams to acquire product knowledge, work closely with users to identify mistakes early or make decisions without red tape?",
                    "What helps maintain engineers’ motivation in times of high expectation without causing anxiety and potential burnout?",
                    "What traits in engineers are greatly valued in this fast-paced environment?",
                ]
            },
            {
                "value": "Open Source Contributer",
                "tips": "",
                questions: [
                    "Why do you think open source contribution is important?",
                    "How do you support the open-source landscape?",
                    "How do you balance employees’ time and effort between participation in open source projects and paid projects?"
                ]
            },
            {
                "value": "Pair Programs",
                "tips": "Members commonly program in pairs.",
                questions: [
                    "What benefits of pair programs have you experienced?",
                    "How often do teams pair program? For instance, is it only when developers have to quickly train new team members, or do you encourage your engineers to pair up regularly to distribute knowledge of the codebase or solve a problem, etc?",
                    "What resources and tools do you have for pair programming? ",
                    "How do teams that work remotely manage pair programming?"
                ]
            },
            {
                "value": "Continuous Delivery",
                "tips": "The team deploys code in short cycles, ensuring that their software can be reliably released at any time. They have designed a process that aims to build, test, and release code faster and more frequently.",
                questions: [
                    "What does ‘continuous delivery’ mean in your organization?",
                    "How do you achieve an efficient deployment pipeline?",
                    "How do you nurture the continuous delivery mindset among your development teams?",
                    "What key metrics (e.g., deployment frequency, change lead time, mean time to recovery, change fail rate, etc.) are you tracking across your pipeline?"
                ]
            },
            {
                "value": "Team Practice DevOps",
                "tips": "",
                questions: [
                    "What does DevOps mean to your organization?",
                    "What methods do you apply for agile planning?",
                    "How do you ensure active stakeholder participation?",
                    "To achieve an automated release pipeline, do you use any specific DevOps tools or build them from scratch?"
                ]
            },
            {
                "value": "Driven by Excellence",
                "tips": "Relentless in pursuit of excellence, the team pushes boundaries for innovation, delivering top results through hard work.",
                questions: [
                    "How do you encourage and support your employees to pursue excellence in their daily tasks and long-term goals?",
                    "What role does innovation play in your company's pursuit of excellence, and how do you integrate it into your operations?",
                    "How does your company define excellence in terms of product quality, customer service, and overall operations?"
                ]
            }
        ]
    },
    {
        index: 3,
        textLines: ['Career', 'Development'],
        cardTitle: "Pick your most important values",
        hPadding: 100,
        layout: [3, 2, 1],
        values: [
            {
                "value": "Promotes from within",
                "tips": "The company considers current employees for promotion opportunities before hiring candidates from outside of the organization",
                questions: [
                    "What is the company's proportion of junior engineers to senior engineers?",
                    "What are the coaching and mentoring opportunities offered to junior engineers for building a successful engineering career in the company?",
                    "How have you hired and trained people from other professions to become successful engineers at your company?",
                    "Share the success stories of junior engineers in your company."
                ]
            },
            {
                "value": "Internal mobility",
                "tips": "Opportunities for multi-directional career transitions.",
                questions: [
                    "How do you align your engineers’ career interests with the firm’s goals?",
                    "Do your engineers have easy access to opportunities for lateral moves?",
                    "Do you have mentoring & coaching programs that help engineers broaden their skills and experiences to move across functions, projects, jobs, etc.?",
                    "Can you share real-life stories of how your engineers successfully transitioned into different organizational roles?"
                ]
            },
            {
                "value": "Good for junior engnineers",
                "tips": "Junior developers with <1 year of experience are encouraged to apply and can expect mentorship.",
                questions: [
                    "What is the company's proportion of junior engineers to senior engineers?",
                    "What are the coaching and mentoring opportunities offered to junior engineers for building a successful engineering career in the company?",
                    "How have you hired and trained people from other professions to become successful engineers at your company?",
                    "Share the success stories of junior engineers in your company."
                ]
            },
            {
                "value": "Has Intership programs",
                "tips": "",
                questions: [
                    "What’s the goal of your internship program?",
                    "What kind of exposure and learning is gained by the interns?",
                    "What type of projects are assigned to interns?",
                    "What opportunities are available to interns to interact with the leadership team?",
                    "How does the internship at your company provide real-world experience to the interns?",
                    "How closely do the interns work with the managers and technical experts in the company?"
                ]
            },
            {
                "value": "High employee retention",
                "tips": "",
                questions: [
                    "What keeps the team happy and engaged at your company?",
                    "What makes engineers feel connected with your company so they don’t leave too soon?",
                    "Can you share the experiences and testimonials by long-tenured engineers about why they like working at your company?"
                ]
            },
            {
                "value": "Promote growth mindset",
                "tips": "Executives and Managers are committed to providing training and support on how to constantly learn and change.",
                questions: [
                    "How do company leaders and team managers consciously nurture engineers’ drive to grow in their careers?",
                    "What opportunities like hackathons are provided to stretch and showcase your engineers’ passions and talents?",
                    "What are some innovative projects pursued by the engineers at your company?",
                    "Do engineers collaborate across roles and advocate for ideas that the company could develop?"
                ]
            }
        ]
    },
    {
        index: 4,
        textLines: ['Workplace', 'Culture'],
        cardTitle: "Pick your most important values",
        hPadding: 100,
        layout: [3, 2, 1],

        values: [
            {
                "value": "Lunch together",
                "tips": "Team members enjoy eating lunch together while exchanging ideas.",
                questions: [
                    "How is lunchtime enjoyed by everyone at your company?",
                    "Describe the friendly vibes, connections, and creativity sparked among teams over lunch. ",
                    "Do you buy lunch for your team or have a budget to host offsite budgets?",
                    "What are the popular lunchtime conversations?",
                ]
            },
            {
                "value": "Flexible work",
                "tips": "The team uses an alternate to the traditional in-person, 9am to 5pm, 40-hour work week. Engineers have flexibility in their work hours and/or locations.",
                questions: [
                    "How is lunchtime enjoyed by everyone at your company?",
                    "Describe the friendly vibes, connections, and creativity sparked among teams over lunch. ",
                    "Do you buy lunch for your team or have a budget to host offsite budgets?",
                    "What are the popular lunchtime conversations?",
                ]
            },
            {
                "value": "Light meetings",
                "tips": "Engineers are expected to attend very few meetings each week in recognition that their productivity requires long, uninterrupted time blocks.",
                questions: [
                    "How many regular meetings do you have every month? What are these regular meetings? For example, 2 hours minutes all-hands, 10-minute daily stand-up, 1-hour weekly huddle, scrums, etc.",
                    "What asynchronous communication platforms and tools are used to share information and make ad-hoc work discussions?",
                    "How are decisions made during meetings?"
                ]
            },
            {
                "value": "Colleagues are friends",
                "tips": "",
                questions: [
                    "Daily, how do engineers like to hang out outside work?",
                    "Are there any resources in the office to build these friendly moments among colleagues? ",
                    "How do these personal connections help fuel enthusiasm and liveliness in the workplace?"
                ]
            },
            {
                "value": "Innovative Office Design",
                "tips": "Strategically designed floor plan and workspace to increase productivity and improve performance.",
                questions: [
                    "How does your office design balance both collaboration and concentration?",
                    "How does the office design (e.g., natural lighting, bright colors, artwork on walls) spark creativity, friendliness, and a cool quotient?",
                    "Do you have large, open-plan spaces to encourage staff to work collaboratively and creatively in an inclusive environment?"
                ]
            },
            {
                "value": "Flexible public holidays",
                "tips": "Employer allows employees to swap holidays on which they usually have off for days that have meaning to their culture, religion or lifestyle.",
                questions: [
                    "How does your holiday calendar consider diverse personal beliefs, observations, or lifestyles?",
                    "Do you let employees swap holidays they usually have off for days that have meaning to their culture, religion, or lifestyle?",
                    "Can you briefly talk about this policy-in-action by sharing the experience of any employee who leverages this policy? For example, an employee who doesn’t celebrate Christmas may work that day and take off another religious day, such as Yom Kippur or Diwali, or an employee who swaps a nationally observed day for a cause they support."
                ]
            },

        ]
    },
    {
        index: 5,
        cardTitle: "Pick your most important values",
        textLines: ['Strategy', ''],
        hPadding: 300,
        layout: [3, 3],

        values: [
            {
                "value": "Growing Team",
                "tips": "Team expects to hire 5 or 10+ engineers within the next 6 months.",
                questions: [
                    "By what number has your engineering team grown in the last 12 months?",
                    "What’s fueling your growth?",
                    "By what number do you plan to increase your engineering team in the times ahead?",
                    "What talent will be important in driving your current and future business growth?"
                ]
            },
            {
                "value": "Customer First",
                "tips": "",
                questions: [
                    "What are your customer demographics?",
                    "How do you bring developers and UX/UI designers together to deliver useful products for customers?",
                    "How are customers involved in different stages of product development?",
                    "What are the different forums and opportunities for customers to share their feedback?"
                ]
            },

            {
                "value": "Engineering-Driven",
                "tips": "",
                questions: [
                    "How does the engineering function drive your company’s decisions?",
                    "What percentage of your workforce are engineers?",
                    "How vital are sophisticated software, data, and analytics for building your products?",
                    "What role is played by the engineering team in determining the overall direction and priorities for the company?"
                ]
            },
            {
                "value": "Product-Driven",
                "tips": "",
                questions: [
                    "Can you describe your product-driven mindset?",
                    "Can you share some personal stories of how engineers have been in situations you try to solve and how they’re passionate and confident about solving them?",
                    "What is your product’s contribution to business growth?",
                    "How do you monitor and communicate with your customers to take their feedback to shape your product(s)?"
                ]
            },
            {
                "value": "Design-Driven",
                "tips": "",
                questions: [
                    "What is the focus on ‘user experience’ in your product development and upgrades? ",
                    "What makes your product ‘design-centric’ or ‘user-centric’?",
                    "How does everyone share ownership of the user experience?"
                ]
            },
            {
                "value": "Data-Driven",
                "tips": "",
                questions: [
                    "How do you use data to drive business decisions?",
                    "How do you leverage data to build and upgrade applications to solve customers’ problems? ",
                    "How do your data scientists collaborate with engineers to build the applications?"
                ]
            }
        ]
    },
    {
        index: 6,
        textLines: ['Health', 'and Wellbeing'],

        cardTitle: "Pick your most important values",
        layout: [3, 3, 1],

        values: [
            {
                "value": "Practices Inclusion & belonging",
                "tips": "Executives and managers are committed to building a diverse, equal, and inclusive workplace. From recruiting to training and advancement, the emphasis is on providing a supportive environment for all individuals, regardless of sexual orientation, gender, race, religion, disability, or ethnicity",
                questions: [
                    "How is ‘inclusion & belonging’ defined in your organization111?",
                    "What is the existing makeup of your workforce? What is the distribution of women and underrepresented groups across levels and functions?",
                    "What are your current organizational priorities for inclusion & belonging?",
                    "How do you communicate your commitment to diversity and inclusion internally and externally?",
                    "What resources and support do you provide to employees to develop their knowledge and skills to work effectively within a diverse environment?"
                ]
            },
            {
                "value": "Work/Family Balance",
                "tips": "Engineers have the flexibility, freedom, and support to achieve fulfillment and enjoyment in both their personal and professional lives.",
                questions: [
                    "How do you let your engineers thrive in and out of the office?",
                    "What opportunities do you provide for engineers to recharge and reenergize themselves? For example, compressed workweek, sabbaticals, etc.",
                    "Do you give any paid time off so engineers can spend time doing the things that they love outside of work, like travel, hobbies, and family time?",
                    "Is there any flexibility in hours or days worked?",
                    "How do engineers use these opportunities to grow professionally and personally?"
                ]
            },
            {
                "value": "Personal Growth Strategy",
                "tips": "Executives and Managers are committed to use proven models to support their employees to grow to their best.",
                questions: [
                    "How do you demonstrate your commitment to the personal growth of your engineers?",
                    "Do you have individual and group coaching programs to help engineers enhance their skills?",
                    "Are there formal mentorship programs where you connect new and less experienced engineers with experienced, knowledgeable engineers to help the new employee quickly absorb the organization’s norms?",
                    "Are there any mentoring opportunities to help the continuing engineers grow in their current position and become ready for new technology and roles?",
                    "Do you assign mentors to a new engineer for orientation and onboarding?",
                    "Are there peer-to-peer learning and feedback to help engineers learn from each other and grow?"
                ]
            },
            {
                "value": "Safe Environment To Fail",
                "tips": "Team members hold, socialize, and apply the attitude that it is the idea or its execution that falls short of expectations, not those associated with it.",
                questions: [
                    "Do you have safety nets to recover from failures? You may be leveraging technology to minimize the consequences of failing. E.g., automated software testing to recognize code failures and fix them, machine learning to reduce the chances of costly wrong decisions, etc.",
                    "Do you talk about failures and learnings from them to make people accept failures and not fear them?",
                    "How do you ensure engineers feel confident to take risks without being shamed or criticized for failure?",
                    "Share an incidence when your team learned from failure and acted on that learning which turned out to be a success.",
                    "When something fails, how do you analyze the root cause of the problem without blaming anyone?"
                ]
            },
            {
                "value": "Supports Physical Wellness",
                "tips": "The team promotes physical activity and/or nutritional eating habits for its members making it easy for engineers to invest in their own health.",
                questions: [
                    "Do your engineers engage in outdoor sports activities like hiking, biking, golf, marathon, rafting, etc.?",
                    "Do you give any allowance to encourage participation in races, marathons, triathlons, or other such events?",
                    "Do you provide any reimbursements for gym memberships, club memberships, or buying equipment?",
                    "Does your cafeteria offer healthy snacks and lunches, or have you partnered with any healthy snack delivery service to deliver healthy snacks to the engineers instead of them having to go out looking for fast food?",
                    "Do you provide ergonomic equipment such as sit-stand desks, chairs, etc., to ensure efficiency and comfort to the engineers?"
                ],
                order: 1
            },
            {
                "value": "Fosters Psychological Safety",
                "tips": "Team members feel comfortable expressing conflicting opinions and taking risks, without fear of negative consequences of self-image, status, or career.",
                questions: [
                    "How do you promote a speak-up culture to encourage everyone to share their ideas and opinions without fear of penalty?",
                    "How do you promote constructive conflict where ideas are debated rather than judged, and engineers don’t hesitate to bring even their whacky ideas?",
                    "Do managers hold conversations asking team members their preferred work, communication, and feedback styles?",
                    "Do engineers feel safe for interpersonal risk-taking and make product innovation possible?"
                ]
            },
            {
                "value": "Remote won't feel lonely",
                "tips": "Executives and Managers are committed to provide guidelines for routines to remote engineers. So that they don't feel lonely and get burned out or become disengaged.",
                questions: [
                    "What opportunities are available for remote engineers to make meaningful connections with their teams?",
                    "Are there any “in-the-office” days when remote employees are encouraged to come in? What is your onboarding process for new remote hires?",
                    "How do you keep the remote engineers in the communication loop to reinforce their sense of belonging?",
                    "Are there any guidelines and routine kits to help remote engineers stay productive and focused and not get burned out or lose engagement?",
                    "Are there any work practices (e.g. strict no emails/Slack chats/text message after-hours rule, pay for coworking, or office space) that help remote engineers maintain work/life balance?"
                ]
            }
        ]
    },
    {
        index: 7,
        textLines: ['Company', 'Structure'],
        cardTitle: "Pick your most important values",
        hPadding: 250,
        layout: [4, 2],

        values: [
            {
                "value": "B2B",
                "tips": ""
            },
            {
                "value": "B2C",
                "tips": ""
            },
            {
                "value": "Self-Funded",
                "tips": ""
            },
            {
                "value": "Technical Founder(s)",
                "tips": ""
            },
            {
                "value": "Remote-OK",
                "tips": ""
            }
        ]
    }
]

export function getQuestions(value) {
    for (const config of defaultValuesConfig2) {
        const found = config.values.filter(v => v.value === value);
        if (found.length > 0) {
            return found[0].questions;
        }
    }
    return [];
}

export const defaultValuesConfig3 = [
    {
        index: 0,
        key: "Team values",
        cardTitle: "Pick your most important values",
        values: [
            {
                "value": "Continuous Feedback",
                "tips": "",
                "isSelected": false
            },
            {
                "value": "EQ>IQ",
                "tips": "An individual's emotional quotient (ability to identify, evaluate, control, and express emotions) is of greater importance than their intelligence quotient (IQ)",
                "isSelected": false
            },
            {
                "value": "Cross-functional Collaboration",
                "tips": "Engineers work directly alongside peers from other departments.",
                "isSelected": false
            },
            {
                "value": "Risk-taking > Stability",
                "tips": "",
                "isSelected": false
            },
            {
                "value": "Love for Product",
                "tips": "",
                "isSelected": false
            },
            {
                "value": "Diversity",
                "tips": "Members of your team are diverse in gender, race, age, or professional background.",
                "isSelected": false
            },
            {
                "value": "Creative and Innovative",
                "tips": "Engineers have a broad set of responsibilities and can take on additional roles in addition to writing and pushing code.",
                "isSelected": false
            },
            {
                "value": "Wears Many Hats",
                "tips": "Engineers have a broad set of responsibilities and can take on additional roles.",
                "isSelected": false
            },
            {
                "value": "Heavily Team Oriented",
                "tips": "",
                "isSelected": false
            },
            {
                "value": "Open Communication",
                "tips": "",
                "isSelected": false
            },
            {
                "value": "Flat Organization",
                "tips": "The company is structured with few or no levels of middle management between employees and executives.",
                "isSelected": false
            },
            {
                "value": "Impressive Team Members",
                "tips": "",
                "isSelected": false
            },
        ]
    },
    {
        index: 1,
        key: 'Working practices',
        cardTitle: "Pick your most important values",
        values: [
            {
                "value": "Agile Methods",
                "tips": "",
                "isSelected": false
            },
            {
                "value": "Safety & Reliability",
                "tips": "",
                "isSelected": false
            },
            {
                "value": "Ethical AI",
                "tips": "",
                "isSelected": false
            },
            {
                "value": "Start-to-Finish Ownership",
                "tips": "",
                "isSelected": false
            },
            {
                "value": "Cutting-Edge Technologies",
                "tips": "",
                "isSelected": false
            },
            {
                "value": "High Quality Code Base",
                "tips": "A work environment that is best suited for engineers who want to learn a broad set of skills in a short period of time, enjoy working under a deadline, and/or are energized by changing requirements and technological challenges.",
                "isSelected": false
            },
            {
                "value": "Fast-Paced Environment",
                "tips": "A work environment that is best suited for engineers who want to learn a broad set of skills in a short period of time, enjoy working under a deadline, and/or are energized by changing requirements and technological challenges.",
                "isSelected": false
            },
            {
                "value": "Open Source Contributer",
                displayValue: "Open Source Contributor",
                "tips": "",
                "isSelected": false
            },
            {
                "value": "Pair Programs",
                "tips": "Members commonly program in pairs.",
                "isSelected": false
            },
            {
                "value": "Continuous Delivery",
                "tips": "The team deploys code in short cycles, ensuring that their software can be reliably released at any time. They have designed a process that aims to build, test, and release code faster and more frequently.",
                "isSelected": false
            },
            {
                "value": "Team Practice DevOps",
                "tips": "",
                "isSelected": false
            },
            {
                "value": "Driven by Excellence",
                "tips": "",
                "isSelected": false
            }
        ]
    },
    {
        index: 2,
        key: "Career development",
        cardTitle: "Pick your most important values",
        values: [
            {
                "value": "Promotes From Within",
                "tips": "The company considers current employees for promotion opportunities before hiring candidates from outside of the organization",
                "isSelected": false
            },
            {
                "value": "Internal Mobility",
                "tips": "Opportunities for multi-directional career transitions.",
                "isSelected": false
            },
            {
                "value": "Good for Junior Engineers",
                "tips": "Junior developers with <1 year of experience are encouraged to apply and can expect mentorship.",
                "isSelected": false
            },
            {
                "value": "Has Internship programs",
                "tips": "",
                "isSelected": false
            },
            {
                "value": "High Employee Retention",
                "tips": "",
                "isSelected": false
            },
            {
                "value": "Promote Growth Mindset",
                "tips": "Executives and Managers are committed to providing training and support on how to constantly learn and change.",
                "isSelected": false
            }
        ]
    },
    {
        index: 3,
        key: "Workplace culture",
        cardTitle: "Pick your most important values",
        values: [
            {
                "value": "Lunch Together",
                "tips": "Team members enjoy eating lunch together while exchanging ideas.",
                "isSelected": false
            },
            {
                "value": "Flexible Work",
                "tips": "The team uses an alternate to the traditional in-person, 9am to 5pm, 40-hour work week. Engineers have flexibility in their work hours and/or locations.",
                "isSelected": false
            },
            {
                "value": "Light Meetings",
                "tips": "Engineers are expected to attend very few meetings each week in recognition that their productivity requires long, uninterrupted time blocks.",
                "isSelected": false
            },
            {
                "value": "Colleagues are Friends",
                "tips": "",
                "isSelected": false
            },
            {
                "value": "Innovative Office Design",
                "tips": "Strategically designed floor plan and workspace to increase productivity and improve performance.",
                "isSelected": false
            },
            {
                "value": "Flexible Public Holidays",
                "tips": "Employer allows employees to swap holidays on which they usually have off for days that have meaning to their culture, religion or lifestyle.",
                "isSelected": false
            },
        ]
    },
    {
        index: 4,
        key: "Strategy",
        cardTitle: "Pick your most important values",
        values: [
            {
                "value": "Growing Team",
                "tips": "Team expects to hire 5 or 10+ engineers within the next 6 months.",
                "isSelected": false
            },
            {
                "value": "Customer First",
                "tips": "",
                "isSelected": false
            },
            {
                "value": "Engineering-Driven",
                "tips": "",
                "isSelected": false
            },
            {
                "value": "Product-Driven",
                "tips": "",
                "isSelected": false
            },
            {
                "value": "Design-Driven",
                "tips": "",
                "isSelected": false
            },
            {
                "value": "Data-Driven",
                "tips": "",
                "isSelected": false
            }
        ]
    },
    {
        index: 5,
        key: "Health and Wellbeing",
        cardTitle: "Pick your most important values",
        values: [
            {
                "value": "Practices Inclusion & Belonging",
                "tips": "Executives and managers are committed to building a diverse, equal, and inclusive workplace. From recruiting to training and advancement, the emphasis is on providing a supportive environment for all individuals, regardless of sexual orientation, gender, race, religion, disability, or ethnicity",
                "isSelected": false
            },
            {
                "value": "Work/Family Balance",
                "tips": "Engineers have the flexibility, freedom, and support to achieve fulfillment and enjoyment in both their personal and professional lives.",
                "isSelected": false
            },
            {
                "value": "Personal Growth Strategy",
                "tips": "Executives and Managers are committed to use proven models to support their employees to grow to their best.",
                "isSelected": false
            },
            {
                "value": "Safe Environment To Fail",
                "tips": "Team members hold, socialize, and apply the attitude that it is the idea or its execution that falls short of expectations, not those associated with it.",
                "isSelected": false
            },
            {
                "value": "Supports Physical Wellness",
                "tips": "The team promotes physical activity and/or nutritional eating habits for its members making it easy for engineers to invest in their own health.",
                "isSelected": false
            },
            {
                "value": "Fosters Psychological Safety",
                "tips": "Team members feel comfortable expressing conflicting opinions and taking risks, without fear of negative consequences of self-image, status, or career.",
                "isSelected": false
            },
            {
                "value": "Remote Won't Feel Lonely",
                "tips": "Executives and Managers are committed to provide guidelines for routines to remote engineers. So that they don't feel lonely and get burned out or become disengaged.",
                "isSelected": false
            }
        ]
    },
    {
        index: 6,
        key: "Sustainability",
        cardTitle: "Pick your most important values",
        type: "icon",
        values: [
            {
                value: "Climate Action",
                displayValue: "Climate action",
                image: "climate-action",
                tips: "Take urgent action to combat climate change and its widespread, unprecedented effects which disproportionately burden the poorest and the most vulnerable.",
                "isSelected": false
            },
            {
                value: "Affordable and Clean Energy",
                displayValue: "Clean energy",
                image: "affordable-and-clean-energy",
                tips: "Ensure access to affordable, reliable, sustainable and modern energy for all. This includes promoting broader energy access and increased use of renewable energy.",
                "isSelected": false
            },
            {
                value: "Gender Equality",
                displayValue: "Gender equality",
                image: "gender-equality",
                tips: "Achieve gender equality and empower all women and girls to reach their full potential, which requires eliminating all forms of discrimination and violence against them, including harmful practices.",
                "isSelected": false
            },
            {
                value: "Good Health And Well being",
                displayValue: "Good health & wellbeing",
                image: "good-health-and-well-being",
                tips: "Ensure healthy lives and promote well-being for all at all ages by ending the epidemics; reducing non-communicable and environmental diseases as well as achieving universal health coverage; and ensuring access to safe, affordable and effective medicines and vaccines for all.",
                "isSelected": false
            },
            {
                value: "Quality Education",
                displayValue: "Quality education",
                image: "qualtity-education",
                tips: "Ensure inclusive and equitable quality education and promote lifelong learning opportunities for all.",
                "isSelected": false
            },
            {
                value: "Industry, Innovation and Infrastructure",
                displayValue: "Industry innovation",
                image: "industry-innovation-and-infrastructure",
                tips: "Build resilient infrastructure, promote inclusive and sustainable industrialization and foster innovation.",
                "isSelected": false
            },
            {
                value: "Sustainable Cities and Communities",
                displayValue: "Sustainable cities",
                image: "sustainable-cities-and-communities",
                tips: "Aims to make cities and human settlements inclusive, safe, resilient and sustainable by renewing and planning cities and other human settlements in a way that fosters community cohesion and personal security while stimulating innovation and employment.",
                "isSelected": false
            },
            // {
            //     value: "Reduced Inequality",
            //     displayValue: "Reduced inequality",
            //     image: "reduced-inequalities",
            //     tips: "Reduce inequality in income, as well as those based on sex, age, disability, race, class, ethnicity, religion and opportunity—both within and among countries."
            //     "isSelected": false
            // },
            {
                value: "Decent Work and Economic Growth",
                displayValue: "Decent work & economic growth",
                image: "eecent-work-and-wconomic-growth",
                tips: "Promote sustained, inclusive and sustainable economic growth, full and productive employment and decent work for all. This includes eradicating forced labour, human trafficking and child labour.",
                "isSelected": false
            },
            {
                value: "Responsible Consumption and Production",
                displayValue: "Responsible consumption and production",
                image: "responsible-consumption-and-production",
                tips: "Promote sustainable consumption and production patterns through measures such as specific policies and international agreements on the management of materials that are toxic to the environment.",
                "isSelected": false
            },
            // {
            //     value: "Clean Water and Sanitation",
            //     displayValue: "Clean water & sanitation",
            //     image: "clean-water-and-sanitation",
            //     tips: "Ensure availability and sustainable management of water and sanitation for all including addressing the quality and sustainability of water resources."
            //     "isSelected": false
            // },
            // {
            //     value: "Life on Land",
            //     displayValue: "Life on land",
            //     image: "life-on-land",
            //     tips: "Protect, restore and promote sustainable use of terrestrial ecosystems, sustainably manage forests, combat desertification, and halt and reverse land degradation and halt biodiversity loss"
            //     "isSelected": false
            // },
            // {
            //     value: "Life Below Water",
            //     displayValue: "Life below water",
            //     image: "life-belwo-water",
            //     tips: "Promote the conservation and sustainable use of marine and coastal ecosystems, prevent marine pollution and increase the economic benefits from the sustainable use of marine resources."
            //     "isSelected": false
            // },
            {
                value: "Peace and Justice Strong Institutions",
                displayValue: "Peace and justice strong institutions",
                image: "peace-and-justice-strong-instutuions",
                tips: "Promote peaceful and inclusive societies for sustainable development, provide access to justice for all and build effective, accountable and inclusive institutions at all levels",
                "isSelected": false
            },
            // {
            //     value: "Partnerships to achieve the Goal",
            //     displayValue: "Partnerships for the goals",
            //     image: "partnerships-for-the-goals",
            //     tips: "The 2030 Agenda requires a revitalized and enhanced global partnership that mobilizes all available resources from Governments, civil society, the private sector, the United Nations system and other actors in order to strengthen the means of implementation."
            //     "isSelected": false
            // },
            // {
            //     value: "No Poverty",
            //     displayValue: "No poverty",
            //     image: 'no-poverty',
            //     tips: "End poverty in all its forms everywhere. All people everywhere, including the poorest and most vulnerable, should enjoy a basic standard of living and social protection benefits."
            //     "isSelected": false
            // },
            // {
            //     value: "Zero Hunger",
            //     displayValue: "Zero hunger",
            //     image: "zero-hunger",
            //     tips: "End hunger and all forms of malnutrition, achieve food security to sufficient nutritious food, and promote sustainable food production"
            //     "isSelected": false
            // },
        ]
    }
];

export const companyWithHighPriority = [
    'archer',
    'polarium',
    'vertical-aerospace',
    'einride',
    'figure ai',
    'cake',
    'truecaller',
    'heart aerospace',
    'normative',
    'climateview',
    'electron aviation',
    'medoma ab',
    'vntrs',
    'pliance',
    'lilium',
    'candela'
];

export const companyWithSecondPriority = [
    'seatwirl',
    '7analytics'
];

export const shouldIgnoreCompany = [
    'atomler',
    'wirba ab',
    'sum',
    'logary analytics',
    'your beet',
    'grazer',
    'landell games',
    'nortical',
    'mindville',
    'iamrunbox',
    'landell group',
    'volocopter',
    'awrosoft',
    'coody.io',
    'h2 green steel',
    'fixi bike',
    'aline – learn better',
    'future closets',
    'test',
    'working group two',
    'war on cancer'
];
