import React, { useContext } from "react";

import "./RankValues.less"
import EmptySpan from "../../ui/EmptySpan";
import EmptyLine from "../../ui/EmptyLine";
import { HCenterContainer } from "../../ui/VCenterContainer";
import DndContainer from "../../ui/dnd/DndContainer";
import { DndProvider } from "react-dnd";
import Backend from 'react-dnd-html5-backend'
import { useNavigate } from "react-router-dom";
import PickValuesContext from "../../context/PickValuesContext";
import { requirePickedValueCount } from "../../context/PickValuesProvider";
import { message } from 'antd'
import { useParams } from "react-router-dom";
import { reOrderDescribeValues } from "../../storage/AJStorage";


// TODO

export function UpdateRankValues(props) {
    const { id } = useParams()

    const newProps = {
        ...props,
        update: true,
        companyId: id
    }

    return <RankValues {...newProps} />
}

export default function RankValues(props) {
    const navigate = useNavigate()
    const { pickedValues } = useContext(PickValuesContext)
    const { orderedValues } = useContext(PickValuesContext)
    let valuesCount = 0
    const selectedValues = pickedValues()
    for (const key in selectedValues) {
        valuesCount += selectedValues[key] ? selectedValues[key].length : 0
    }

    if (valuesCount < requirePickedValueCount) {
        message.error(`Missing ${requirePickedValueCount - valuesCount} value(s)!`)
        navigate('/register/filter-key-values')
        return null
    }


    return (
        <HCenterContainer>
            <div className={'rank-values'}>

                <div className={'rank-values-container'}>

                    <div className={'rank-values-promotion-container'}>
                        <EmptyLine height={62} />

                        <img src={'/img/icons/check-right.svg'} />
                        <div className={'rank-values-promotion-title'}>
                            Fantastic!
                        </div>
                        <div className={'rank-values-promotion-body'}>
                            <br />
                            Now it’s time for you to Rank your values.<br /> <br />By doing so, it will alow you to reach and find talents that align with your own values.
                        </div>

                        <div className={'rank-values-promotion-footer-title'}>
                            How to
                        </div>
                        <div className={'rank-values-promotion-footer-body'}>
                            <br />
                            Rearrange the order of your values<br /> by drag and drop.
                        </div>
                    </div>
                    <div className={'rank-values-order-container'}>
                        <DndProvider backend={Backend}>
                            <DndContainer {...props} />
                        </DndProvider>
                    </div>
                    <div className={'rank-values-order-container-background'}>
                    </div>


                </div>
            </div>
        </HCenterContainer>
    )
}
