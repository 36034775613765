import React, { useEffect, useState } from 'react';
import { Input, message } from 'antd';
import { AiGeneratePrinciplesTextareaProps } from 'saas/model/SaasCommonModel';
import { getAiStreamReader } from 'saas/utils/http/SaasHttpClient';
import AiGenerateIcon from 'saas/icons/AiGenerateIcon';
import './AiGeneratePrinciplesTextarea.less';
import AnimatedCheckmark from 'saas/icons/AnimatedCheckmark';
const { TextArea } = Input;


const AiGeneratePrinciplesTextarea: React.FC<AiGeneratePrinciplesTextareaProps> = ({
    principle,
    questions,
    onChange,
    onFinishAiGenerate
}) => {
    const [messageApi, contextHolder] = message.useMessage();

    const [inputMessage, setInputMessage] = useState<string>("");
    const [showHappyButton, setShowHappyButton] = useState<boolean>(false);
    const [showImHappy, setShowImHappy] = useState<boolean>(false);

    useEffect(() => { setInputMessage(questions) }, [questions])

    const generateAiText = () => {
        startStream();
    }

    const startStream = async () => {
        try {
            console.log("User input message: ", inputMessage);
            const reader = await getAiStreamReader(inputMessage, principle);

            if (!reader) {
                console.error("Can not get response stream from AI.");
                messageApi.error("Get AI response failed, please try again.");
                return;
            }

            const decoder = new TextDecoder();
            let done = false;
            setInputMessage("");
            let currentResponse = "";

            while (!done) {
                const { value, done: streamDone } = await reader.read();
                done = streamDone;
                if (value) {
                    const chunk = decoder.decode(value, { stream: true });
                    const lines = chunk.split("\n\n");
                    for (const line of lines) {
                        if (line.startsWith("data: ")) {
                            const data = line.replace("data: ", "");
                            if (data === "[DONE]") {
                                done = true;
                                onChange(currentResponse); // Save the AI response
                                setShowHappyButton(true);
                                setShowImHappy(true);
                            } else {
                                currentResponse += data;
                                setInputMessage(currentResponse);
                            }
                        }
                    }
                }
            }

        } catch (error) {
            console.error("Get AI response failed:", error);
            messageApi.error("Get AI response failed.");
        }
    };

    const onValueChanged = (value: string) => {
        setInputMessage(value);
        onChange(value);
        if (showHappyButton) {
            setShowImHappy(true);
        }
    }


    return (
        <div className='ai-editor-container'>
            <TextArea rows={6} className='ai-editor-textarea' value={inputMessage} onChange={(e) => {
                onValueChanged(e.target.value);
            }} />
            <div className='ai-editor-button-container'>
                {!showHappyButton && <span className='generate-text-button-label'>Lucy, create draft! <span onClick={() => generateAiText()} ><AiGenerateIcon /></span></span>}
                {showHappyButton &&
                    <AnimatedCheckmark onFinish={() => {
                        setShowImHappy(false);
                        setTimeout(() => {
                            onFinishAiGenerate(inputMessage);
                        }, 800);
                    }}
                        showImHappy={showImHappy} />}
            </div>
        </div>
    )
}

export default AiGeneratePrinciplesTextarea;