import { Collapse, Typography, Image } from "antd";
import { valuePatch } from "component/values/ValuesConfig";
import React, { useState } from "react";
import SaasPrincipleCard from "saas/component/SaasPrincipleCard";
import SaasProfileLayout from "saas/component/SaasProfileLayout";
import EditIcon from "saas/icons/EditIcon";
import { SaasUserProfileInfo } from "saas/model/SaasCommonModel";
import EmptyLine from "ui/EmptyLine";
import './EngineeringPrincipleContainer.less';
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";

const { Paragraph } = Typography;
const { Panel } = Collapse;

const EngineeringPrincipleContainer = React.memo(({
    profileInfo,
    onEditOverlay
}: {
    profileInfo: SaasUserProfileInfo,
    onEditOverlay: (panelKey: string) => void;
}) => {
    const [activePanelKey, setActivePanelKey] = useState<string | string[]>(['0']);
    const EngineeringPrincipleCards: React.FC = () => {
        const handleCardClick = (index: number) => {
            const panelId = `panel-${index}`;
            const panelElement = document.getElementById(panelId);

            if (panelElement) {
                panelElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
                setTimeout(() => {
                    const panelKey = index.toString();
                    setActivePanelKey((prevKeys) => {
                        if (prevKeys.includes(panelKey)) {
                            return prevKeys;
                        } else {
                            return [...(prevKeys as string[]), panelKey];
                        }
                    });
                }, 800);
            }
        };

        return (
            <SaasProfileLayout>
                <div className="engineering-principle-card-container">
                    <div className="engineering-principle-title">
                        {profileInfo.engineeringPrincipleTitle}
                        <span className="edit-icon" onClick={() => onEditOverlay("0")}>
                            <EditIcon />
                        </span>
                    </div>
                    <div className="engineering-principle-cards">
                        {profileInfo.engineeringPrinciples.map((principle, index) => (
                            <SaasPrincipleCard
                                key={index}
                                value={principle.principle}
                                index={index}
                                onClick={handleCardClick}
                            />
                        ))}
                    </div>
                </div>
                <EmptyLine height={90} />
            </SaasProfileLayout>
        );
    };

    const PanelHeader = React.memo(({ header, panelKey, }: { header: string; panelKey: string; }) => {
        return (
            <SaasProfileLayout>
                <div className="principle-title-container">
                    <p className="principle-title" id={`panel-${panelKey}`}>{header}</p>
                </div>
            </SaasProfileLayout>
        );
    });
    const expandIcon = ({ isActive }: { isActive: boolean }) => {
        return isActive ? (
            <MinusOutlined style={{ fontSize: '30px' }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
        ) : (
            <PlusOutlined style={{ fontSize: '30px' }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
        );
    }

    return (
        <>
            <EngineeringPrincipleCards />
            <Collapse bordered={false} expandIconPosition={'end'} activeKey={activePanelKey} expandIcon={(isActive: any) => expandIcon(isActive)} onChange={(key) => { setActivePanelKey(key) }}>
                {
                    profileInfo.engineeringPrinciples.map((principle, index) => {
                        const panelKey = index.toString();
                        const header = valuePatch(principle.principle);
                        return (
                            <Panel key={panelKey} header={<PanelHeader header={header} panelKey={panelKey} />}>
                                {(activePanelKey.includes(panelKey)) && <span className="principle-edit-icon"
                                    style={{ left: `${getEditButtonMarginLeft(panelKey)}px` }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onEditOverlay(panelKey);
                                    }}>
                                    <EditIcon />
                                </span>}

                                <SaasProfileLayout>
                                    <div className="principle-panel-content">
                                        <div className="opening-job-container">Job opening</div>
                                        <div className="principle-details-container">
                                            <div className="principle-description">
                                                {principle.description || "No description available"}
                                            </div>
                                            <div className="principle-image-container">
                                                <Image className="principle-image" src={principle.image} preview={false}
                                                    fallback=""
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </SaasProfileLayout>
                                <EmptyLine height={160} />
                            </Panel>
                        );
                    })
                }
            </Collapse>
        </>
    );
});

function getEditButtonMarginLeft(panelKey: string) {
    const panelHeaderTextElement = document.getElementById('panel-' + panelKey);
    const panelHeaderTextWidth = panelHeaderTextElement?.clientWidth || 0;
    const screenWidth = window.innerWidth;
    return panelHeaderTextWidth + (screenWidth / 24) + 25;
}

export default EngineeringPrincipleContainer;