import React, { useState } from "react";

import "./StartFooter.css"
import { Row, Col } from "antd";
import EmptyLine from "../../ui/EmptyLine";
import Contact from "../Contact";
import AJModalV2 from "../../ui/AJModalV2";
import SocialMediaIcons from "../SocialMediaIcons";
import { useNavigate } from 'react-router-dom'
import { socialLinkConfig } from "../Menu";
import AJLogo from "../../ui/AJLogo";
import { clearLoginInfo, isLogged } from "../../storage/AJStorage";


export default function StartFooter() {
    const [showContact, setShowContact] = useState(false)
    const navigate = useNavigate()

    const companyLogout = (event) => {
        // TODO
        // logout
        clearLoginInfo()

        navigate("/")
    }

    return (
        <div className={'start-footer'} id={'aj-start-footer'}>
            <div className={'start-footer-container'}>
                <Row>
                    <Col span={5}>
                        <div className={'start-footer-title'} onClick={() => {
                            navigate('/')
                        }}>
                            <AJLogo />
                        </div>
                    </Col>
                    <Col span={14}>
                        <div className={'start-footer-menu'}>
                            <Row>
                                <Col span={8}>
                                    <EmptyLine height={12} />
                                    {
                                        isLogged() ? <div className={'start-footer-clickable'} onClick={companyLogout}>
                                            Employer sign out
                                        </div>

                                            : <div className={'start-footer-clickable'} onClick={() => {
                                                navigate("/register")
                                            }}>
                                                Employer sign in
                                            </div>
                                    }

                                    <br />
                                    <div className={'start-footer-clickable'}
                                        onClick={() => {
                                            addGA('Contact', '/contact');
                                            gotoLink("/contact", navigate)
                                        }}
                                    >
                                        Contact
                                    </div>
                                    <AJModalV2 visible={showContact}
                                        width={569}
                                        top={200}>
                                        <Contact onClose={() => setShowContact(false)} />
                                    </AJModalV2>
                                    <br />

                                    <div className={'start-footer-clickable'} onClick={() => {
                                        addGA('About us', '/about');
                                        gotoLink('/about', navigate)
                                    }}>
                                        About us
                                    </div>
                                    <br />

                                    <div className={'start-footer-clickable'} onClick={() => {
                                        addGA('News & Media', '/latest');
                                        gotoLink('/latest', navigate)
                                    }}>
                                        News & Media
                                    </div>
                                    <br />

                                </Col>
                                <Col span={8}>
                                    <EmptyLine height={12} />
                                    {/*<div className={'start-footer-clickable'}*/}
                                    {/*     onClick={() => {*/}
                                    {/*         gotoLink('/nft', navigate)*/}
                                    {/*     }}*/}
                                    {/*>*/}
                                    {/*    NFT*/}
                                    {/*</div>*/}
                                    {/*<br/>*/}
                                    <div className={'start-footer-clickable'}
                                        onClick={() => {
                                            addGA('Talent', '/talent');
                                            gotoLink('/talent', navigate)
                                        }}
                                    >
                                        Talent
                                    </div>
                                    <br />
                                    <div className={'start-footer-clickable'}
                                        onClick={() => {
                                            addGA('Employer', '/employer');
                                            gotoLink('/employer', navigate)
                                        }}
                                    >
                                        Employer
                                    </div>
                                    <br />
                                    <div className={'start-footer-clickable'} onClick={() => {
                                        addGA('True Fit', '/toolbox');
                                        gotoLink('/toolbox', navigate)
                                    }}>
                                        True Fit
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <EmptyLine height={12} />
                                    <div className={'start-footer-clickable'}
                                        onClick={() => {
                                            addGA('Pricing', '/pricing');
                                            gotoLink('/pricing', navigate)
                                        }}
                                    >
                                        Pricing
                                    </div>
                                    <br />

                                    <div className={'start-footer-clickable'}
                                        onClick={() => {
                                            addGA('Privacy', '/privacy');
                                            gotoLink('/privacy', navigate)
                                        }}>
                                        Privacy
                                    </div>

                                    <br />
                                    <div className={'start-footer-clickable'}
                                        onClick={() => {
                                            addGA('Contact', '/terms-conditions');
                                            gotoLink('/terms-conditions', navigate)
                                        }}
                                    >
                                        Terms of use
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col span={5}>
                        <div style={{ width: 270 }}>
                            <EmptyLine height={12} />
                            <div>
                                <SocialMediaIcons socials={socialLinkConfig} section={'footer'} />
                            </div>
                            <EmptyLine height={106} />
                            <div className={'start-footer-company'}>
                                Copyright 2024
                            </div>
                        </div>

                    </Col>
                </Row>
            </div>
        </div>
    )
}

export function gotoLink(link, navigate) {
    if (window.location.pathname === link) {

    }
    else {
        navigate(link)
    }
}

function addGA(eventAction, pageUrl) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        'event': 'custom_event',
        'eventCategory': 'footer_menu',
        'eventAction': `${eventAction}`,
        'eventlabel': `${pageUrl}`
    });
}
