import React from "react";
import {
    Routes,
    Route,
} from "react-router-dom";
import CreateAccount from "../component/login/CreateAccount";
import CompanyLogin, { ChangePassword, ChangePasswordSuccess, ResetPassword } from "../component/login/CompanyLogin";
import CompanyStructure from "../component/login/CompanyStructure";
import CompanyRegisterProvide from "../context/CompanyRegisterProvider";
import FilterKeyValues, { UpdateFilterKeyValues } from "../component/login/FilterKeyValues";
import RankValues, { UpdateRankValues } from "../component/login/RankValues";
import DescribeValuesContainer, {
    CompanyDescribeValue,
    DescribeValues2,
    UpdateDescribeValuesContainer
} from "../component/login/DescribeValues";
import {
    AddJobOpeningManually2,
    AddSocialLinks,
    ConfirmOpenings,
    InputCareerUrl,
    SyncJobOpenings
} from "../component/login/AddJobOpenings";
import NotFound404 from "../page/NotFound404";

export default function RegisterRoute(props) {
    return (
        <div>
            <CompanyRegisterProvide>
                <Routes>
                    <Route path="/" element={<CompanyLogin login={props.login || (() => { })} />} />
                    <Route path={`/reset-password`} element={<ResetPassword />} />
                    <Route path={`/change-password`} element={<ChangePassword />} />
                    <Route path={`/change-password-success`} element={<ChangePasswordSuccess />} />
                    <Route path={`/create-account`} element={<CreateAccount login={props.login || (() => { })} />} />
                    <Route path={`/company-structure`} element={<CompanyStructure />} />
                    <Route path={`/filter-key-values`} element={<FilterKeyValues />} />
                    <Route path={`/filter-key-values/update/:id`} element={<UpdateFilterKeyValues />} />
                    <Route path={`/rank-values`} element={<RankValues />} />
                    <Route path={`/rank-values/update/:id`} element={<UpdateRankValues />} />
                    <Route path={`/describe-values/update/:companyId`} element={<UpdateDescribeValuesContainer />} />
                    <Route path={`/describe-values`} element={<DescribeValuesContainer />} />
                    <Route path={`/describe-values/:id`} element={<CompanyDescribeValue />} />
                    <Route path={`/sync-job-openings`} element={<SyncJobOpenings />} />
                    <Route path={`/add-job-openings-manually`} element={<AddJobOpeningManually2 />} />
                    <Route path={`/input-career-url`} element={<InputCareerUrl />} />
                    <Route path={`/confirm-openings`} element={<ConfirmOpenings />} />
                    <Route path={`/add-social-links`} element={<AddSocialLinks />} />
                    <Route path="*" element={<NotFound404 />} />
                </Routes>
            </CompanyRegisterProvide>
        </div>
    );
}